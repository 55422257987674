import {GET_INDIVIDUAL_AREAS, GET_INDIVIDUAL_CATEGORIES, GET_INDIVIDUAL_ITEMS, GET_PRESETS} from '../actions/types';

const INITIAL_STATE = {
    individualItems: [],
    individualCategories: [],
    individualAreas: [],
    presets: []
};

export default (state = INITIAL_STATE, actions) => {

    switch (actions.type) {
        case GET_INDIVIDUAL_ITEMS:
            return {
                ...state,
                individualItems: actions.payload
            };
        case GET_INDIVIDUAL_CATEGORIES:
            return {
                ...state,
                individualCategories: actions.payload
            };
        case GET_INDIVIDUAL_AREAS:
            return {
                ...state,
                individualAreas: actions.payload
            };
        case GET_PRESETS:
            return {
                ...state,
                presets: actions.payload
            }
        default:
            return state;
    }
}
