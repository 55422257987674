import React, {Component} from 'react';
import ItemCard from "./ItemCard";
import Collapsible from "react-collapsible";
import {CompactPicker, GithubPicker} from "react-color";
import {SM} from "../../../SceneManager";
import {PrettoSlider} from "../../../General/UserInputs/PrettoSlider";


import {toast} from "react-toastify";
import firebase from "../../../../config/firebase";

var inputStyles = {
    wrap:{
        border:'2px solid red',
        width:'100%',
        height:'100%'
    },
    label: {
        fontSize: '12px',
        color: '#999',
    },
};

const CategoryDisplay = ({category,selectedCategory,onSelectCategory}) => {
    return (
        <button className={selectedCategory === category.name ? 'red-gradient':'gray-gradient'} onClick={() => onSelectCategory(category.name)}>
            <img className={'category-img'} alt={category.name} src={category.iconURL}/>
            <p className={'category-p'}>{category.name}</p>
        </button>
    );
};

class AdvertiserItemList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: null,
            selectedCategory: "Curtains",
            selectedArea: "General",
            areaOpen: true,
            categoriesOpen: true,
            itemsOpen: true,
            background: '#fff',
            tiles: [],
            tile: null,
            tileWidth: 3,
            tileLength: 3,
            insideLuminosity: 0.5,
            outsideLuminosity: 1
        }
        this.fetchTiles();
    }
    fetchTiles = async () => {
        var storageRef = firebase.storage().ref('tiles');

        var imagePromises = [];

        var results = await storageRef.listAll();
        results.items.forEach(imageRef => {
            imagePromises.push(imageRef.getDownloadURL())
        });
        var urls = await Promise.all(imagePromises);
        this.setState({tiles:urls})
    }
    onSelectCategory = categoryName => {
        this.props.onCategoryChange(categoryName);
        this.setState({selectedCategory:categoryName})
    };

    renderAreas = () => {

        const {tab} = this.props;


        var areas = this.props.areas;


        function compare( a, b ) {
            if ( a.name < b.name ){
                return -1;
            }
            if ( a.name > b.name ){
                return 1;
            }
            return 0;
        }
        areas.sort(compare);

        return (
            <div className={'customize-tabs'}>
                {
                    areas.map(area => {
                        return (
                            <button key={area.name} onClick={() => this.props.onTabChange(area.name)}
                                    className={tab === area.name ? "red-gradient" : "gray-gradient"}>
                                <img className={'category-img'} alt={area.name} src={area.iconURL}/>
                                <br/>
                                <p className={'category-p'}>{area.name}</p>
                            </button>
                        )
                    })
                }
            </div>

        );

    };
    renderCategoryItems = () => {
        return this.renderItemList(this.props.category,this.props.tab);

    };

    getTestModeCategories = () => {
        var {categories,tab} = this.props;
        var cats = [];
        categories.forEach(cat => {
            if (cat.area === tab) {
                cats.push(cat);
            }
        });
        return cats;
    };
    renderCategories = () => {
        var categories = [];
        var {tab} = this.props;
        if (!tab) { return null}
        categories = this.getTestModeCategories();

        return categories.map(category => {
            return <CategoryDisplay key={category.name} category={category} onSelectCategory={this.onSelectCategory} selectedCategory={this.props.category}/>;
        })


    };

    getCategoriesToShow = () => {
        var {categories, items} = this.props;

        var categoriesToUse = [];

        categories.forEach(cat => {
            var itemsCheck = items.filter(item => {
                return item.category === cat.name;
            });
            if (itemsCheck.length > 0) {
                categoriesToUse.push(cat);
            }
        });

        return categoriesToUse;


    };
    getTypeToOnClick = (type, param) => {
        /*
        if (type === "wall_tile") {
            return () => SM.tileWall(param);
        } else if (type === "floor_tile") {
            return () => SM.tileFloor(param);
        } else if (type === "paint") {
            return () => SM.paintWall(param)
        } else if (type === "wall") {
            return () => SM.BuilderManager.startDrawWall(param);
        } else if (type === "mirror_custom") {
            return () => SM.BuilderManager.startMirrorDraw();
        } else if (type === "glass_wall") {
            return () => SM.RoomManager.setWallMaterial(param);
        }

         */
        /*
        else {
            return () => SM.ObjectManager.loadNewObject(param+".obj")
        }
         */
        //Undefined objects will need to be spawned and can ignore the onclick event.
        return undefined;
    };

    renderItemList = (category,area) => {
        var {items} = this.props;

        items = items.filter(item => {
            return item.public && item.category === category && item.area.toLowerCase() === area.toLowerCase();
        });
        return items.map(item => {
            return <ItemCard mobile={this.props.mobile}
                             onClick={this.getTypeToOnClick(item.type, item.slug)}
                             key={item.name + item.objURL}
                             item={item}/>
        })
    };

    changeCollapsible = (key,open) => {

        var val = open;
        var state = this.state;
        state[key] = val;

        this.setState(state);
    };
    renderCategoriesAndItems = () => {

        return (
            <div>
                {this.renderCategoriesWrapper()}
                {this.renderItemsWrapper()}
            </div>
        )
    };
    renderItemsWrapper = () => {
        var {category} = this.props;
        var {itemsOpen} = this.state;
        if (!category) return null;

        return (
            <Collapsible trigger={'Items'}  open={itemsOpen}
                         onTriggerClosing={() => this.changeCollapsible('itemsOpen',false)}
                         onTriggerOpening={() => this.changeCollapsible('itemsOpen',true)}>
                <div className={'items-wrapper'}>
                    {this.renderCategoryItems()}
                </div>
            </Collapsible>
        )
    }
    renderCategoriesWrapper = () => {
        var {tab} = this.props;
        var {categoriesOpen} = this.state;
        if (!tab) return null;
        return (
            <Collapsible trigger={'Categories'} open={categoriesOpen}
                         onTriggerClosing={() => this.changeCollapsible('categoriesOpen',false)}
                         onTriggerOpening={() => this.changeCollapsible('categoriesOpen',true)}>
                <div className={'customize-tabs'}>
                    {this.renderCategories()}
                </div>
            </Collapsible>
        )
    }
    onPick = (index) => {
        console.log(index);
        var tile = this.state.tiles.find(x => x === index);

        this.setState({tile})
    };

    get Tile() {
        var {tile,tileWidth,tileLength} = this.state;
        return {
            tile,tileWidth,tileLength
        }
    }
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };
    onDrop = (files) =>{
        this.setState({
            tile: URL.createObjectURL(files[0])
        })
    };
    handleLightColorChange = v => {
        var lights = SM.lightObjects;
        //var color = Color3.new()
        lights.forEach(light => {
            var color = v.hex;
            var hex = parseInt(color.replace(/^#/, ''), 16);
            light.color.setHex(hex);
        });
        this.props.changeLightColor(v.hex);

        SM._dirty = true;

    };
    handleLuminosityChange = v => {
        var lights = SM.lightObjects;
        lights.forEach(light => {
            light.intensity = v;
        });
        this.props.changeLuminosity(v);
        // this.setState({luminosity:v});
        SM._dirty = true;
    };
    handleOutsideLuminosityChange = v => {
        SM.CameraManager.updateOutsideLight(v);
        //SM.CameraManager.updateInsideLight(v);
        SM._dirty = true;
        this.setState({outsideLuminosity:v})

    }

    handleInsideLuminosityChange = v => {
        SM.CameraManager.updateInsideLight(v);
        SM._dirty = true;
        this.setState({insideLuminosity:v})
    }
    tileWall = () => {
        if (!this.state.tile) {
            toast.error("You must upload an image to tile.");
            return;
        }
        SM.BuilderManager.startTileWall(this.state.tile);
    };
    tileFillWall = () => {
        if (!this.state.tile) {
            toast.error("You must upload an image to tile.");
            return;
        }
        SM.RoomManager.selectTile(this.state.tile,this.state.tileLength,this.state.tileWidth)
    }
    mapLightObjects = () => {
        //Grab Lighting -> Lights objects,
        var items = this.props.items.filter(x => x.area==='Lighting');
        return (
            <div className={'items-wrapper'}>
                {items.map(item =>
                    {return  <ItemCard mobile={this.props.mobile}
                                       key={item.name}
                                       item={item}
                                      />}
                )}
            </div>
        )
    }
    tileFloor = () => {
        if (!this.state.tile) {
            toast.error("You must upload an image to tile.");
            return;
        }
        SM.BuilderManager.startTileFloor(this.state.tile,this.state.tileLength,this.state.tileWidth)
    }
    renderUtility = () => {
        var {tab} = this.props;
        if (tab === "Paints") {
            return (
                <div className={'utility-bg'}>
                    <section style={{'width':'100%','margin':'auto'}}>
                        <p className="title-clean">Paint Colour</p>
                    <div style={{marginTop:'1em'}}>
                        <CompactPicker
                            color={ this.state.background }
                            style={inputStyles}
                            onChangeComplete={ this.handleChangeComplete }
                        />
                    </div>
                        <p className="title-clean">Actions</p>
                        <div style={{marginTop:'1em'}}>

                        <button
                                onClick={() => SM.RoomManager.selectPaint(this.state.background)}
                                className={'main-gradient'} style={{width:'100%'}}><p>Paint Wall</p></button>
                        </div>
                    </section>
                </div>
            )
        }
        if (tab === 'Tiles') {
            return (
                <div className={'utility-bg'}>
                    <section>
                        <p className="title-clean">Selected Tile</p>

                        {this.state.tile ? <img src={this.state.tile} alt={'preview'} className={'preview-img'}/> : <p>None Selected</p>}

                        <p className="title-clean">Tile Selection</p>

                        <div className={'image-picker'}>
                            {this.state.tiles.map(img => {
                                return (
                                    <div className={'image-picker-img-wrapper'} key={img} onClick={() => this.onPick(img)}>
                                        <img alt={'tile'} src={img} className={'image-picker-img'}/>
                                    </div>
                                )
                            })}
                        </div>
                        {
                            /*
                        <FileDropzone callback={this.onDrop}/>
                             */
                        }
                        <p className="title-clean">Tile Measurements</p>

                        {
                                !this.props.mobile &&
                                <div style={{display:'flex',flexDirection:'row'}}>

                                    <div style={{display:'flex',flexDirection:'column'}}>
                                        <p className={'input-label-small'}>Length (Metres)</p>
                                        <input className={'input-small'} value={this.state.tileWidth} onChange={(val) => this.setState({tileWidth:val.target.value})}/>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'column'}}>
                                    <p className={'input-label-small'}>Width (Metres)</p>
                                    <input className={'input-small'}  value={this.state.tileLength} onChange={(val) => this.setState({tileLength:val.target.value})}/>
                                    </div>
                                </div>
                            }
                        <p className="title-clean">Actions</p>

                        <div style={{display:'flex',flexDirection:'row',margin: 0,width:'100%'}}>
                            {false && <button onClick={() => this.tileWall()} className={'button_custom main-gradient'} style={{flex:'0 0 33.3%'}}><p>Draw Wall Tile</p></button>}
                            <button onClick={() => this.tileFloor()} className={'main-gradient'} style={{flex:'0 0 50%'}}><p>Fill Floor</p></button>
                            <button onClick={() => this.tileFillWall()} className={'main-gradient'} style={{flex:'0 0 50%'}}><p>Fill Wall</p></button>
                        </div>
                    </section>
                </div>
            )
        }
        if (tab === 'Lighting') {
            return (
                <div className={'utility-bg'}>
                    <p className="title-clean">Light Objects</p>
                    <div style={{marginTop:'1em',marginBottom:'0.5em',display:'flex',flexDirection:'row'}}>
                        {this.mapLightObjects()}
                    </div>
                    <p className="title-clean">Light Colour</p>
                    <div style={{marginTop:'1em',marginBottom:'0.5em'}}>
                        <GithubPicker
                            style={{border:'1px solid red'}}
                            color={ this.props.lightColor }
                            onChangeComplete={ this.handleLightColorChange }
                        />
                    </div>

                    <p className="title-clean">Light Intensity</p>
                    <div style={{'width':'85%','margin':'auto'}}>
                        <PrettoSlider
                            value={this.props.luminosity !== undefined ? this.props.luminosity : 0.5}
                            onChange={(e, v) => this.handleLuminosityChange(v)}
                            aria-labelledby="input-slider"
                            step={0.1}
                            valueLabelDisplay="auto"
                            min={0}
                            max={1}
                        />
                    </div>
                    <p className="title-clean">Ambient Lighting</p>
                    <div style={{'width':'85%','margin':'auto'}}>
                        <PrettoSlider
                            value={this.state.outsideLuminosity !== undefined ? this.state.outsideLuminosity : 1}
                            onChange={(e, v) => this.handleOutsideLuminosityChange(v)}
                            aria-labelledby="input-slider"
                            step={0.1}
                            valueLabelDisplay="auto"
                            min={0}
                            max={1}
                        />
                    </div>
                    <p className="title-clean">Hemisphere Lighting</p>
                    <div style={{'width':'85%','margin':'auto'}}>
                        <PrettoSlider
                            value={this.state.insideLuminosity !== undefined ? this.state.insideLuminosity : 1}
                            onChange={(e, v) => this.handleInsideLuminosityChange(v)}
                            aria-labelledby="input-slider"
                            step={0.1}
                            valueLabelDisplay="auto"
                            min={0}
                            max={1}
                        />
                    </div>

                </div>
            )
        }
        return null;
    };

    render() {
        //need to keep this object state constant. dosent render if catalogue closed.
        var {areaOpen} = this.state;
        var {isOpen} = this.props;
        if (!isOpen) return null;

        return (
            <div className={'advertiser-item-list'} >
                <Collapsible trigger={'Area'} open={areaOpen}
                             onTriggerClosing={() => this.changeCollapsible('areaOpen',false)}
                             onTriggerOpening={() => this.changeCollapsible('areaOpen',true)}>
                    {this.renderAreas()}
                    {['Lighting','Paints','Tiles'].includes(this.props.tab) ? this.renderUtility() : null}

                </Collapsible>
                {!['Lighting','Paints','Tiles'].includes(this.props.tab) ? this.renderCategoriesAndItems() : null}

            </div>
        );
    }
}

export default AdvertiserItemList;
