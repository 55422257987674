import React, {useCallback, useState} from 'react'
import CustomDragLayer from './customDragLayer'

const DragAroundCustomDragLayer = () => {
    const [snapToGridAfterDrop, setSnapToGridAfterDrop] = useState(false);
    const [snapToGridWhileDragging, setSnapToGridWhileDragging] = useState(true);

    // eslint-disable-next-line no-unused-vars
    const handleSnapToGridAfterDropChange = useCallback(() => {
        setSnapToGridAfterDrop(!snapToGridAfterDrop)
    }, [snapToGridAfterDrop]);

    // eslint-disable-next-line no-unused-vars
    const handleSnapToGridWhileDraggingChange = useCallback(() => {
        setSnapToGridWhileDragging(!snapToGridWhileDragging)
    }, [snapToGridWhileDragging]);

    return (
        <div>
            <CustomDragLayer snapToGrid={snapToGridWhileDragging}/>
        </div>
    )
};
export default DragAroundCustomDragLayer
