import React, {useState} from 'react'
import {useDrop} from 'react-dnd'
import {ItemTypes} from '../../../utils/constants/constantValues'
import DraggableBox from './draggableBox'
import {SM} from "../../SceneManager";
import * as THREE from "three";
import {canPlaceAnywhere, isFloorItem, isRoofItem} from "../../../modules/floorplanner/items/factory";

const styles = {
    height: "100%",
    width: "100%",
    left: 0,
    right: 0,
    position: "absolute",
    zIndex: -1,
    opacity: 1
};

let mouse = new THREE.Vector2();

function renderBox(item, key) {
    return <DraggableBox key={key} id={key} {...item} />
}

const DropContainer = ({snapToGrid, isDragging}) => {


    // eslint-disable-next-line no-unused-vars
    const [boxes, setBoxes] = useState({});

    const zIndex = isDragging ? 1 : -1;

    /*
    const moveBox = useCallback(
        (id, left, top) => {
            setBoxes(
                update(boxes, {
                    [id]: {
                        $merge: { left, top },
                    },
                }),
            )
        },
        [boxes],
    );

     */

    // eslint-disable-next-line no-unused-vars
    const [{isOver, canDrop}, drop] = useDrop({
        accept: ItemTypes.SHOWER,
        drop: (item, monitor) => dropped(item, monitor),
        collect: mon => ({
            isOver: !!mon.isOver(),
            canDrop: !!mon.canDrop(),
        }),
        hover: (item, monitor) => onHover(item, monitor)
    });

    function onHover(item, monitor) {
        //SM.renderer.domElement
        //const monitorItem = monitor.getItem();
        // Get size and position of the hovered page
        var domElement = SM.renderer.domElement;
        var rect = domElement.getBoundingClientRect();

        //var initialPostion = monitor.getInitialSourceClientOffset();
        var position = monitor.getClientOffset();

        mouse.x = ((position.x - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((position.y - rect.top) / rect.height) * 2 + 1;

        var _raycaster = new THREE.Raycaster();
        _raycaster.setFromCamera(mouse, SM.CameraManager.camera);



        var intersects = null;

        //START WITH FLOOR ITEMS
        if (canPlaceAnywhere(item.itemType)) {
            intersects = _raycaster.intersectObject(SM.skybox.ground,true);
        } else if (isFloorItem(item.itemType)) {
            intersects = _raycaster.intersectObjects(SM.model.floorplan.floorPlanes(), true);
        } else if (isRoofItem(item.itemType)) {
            intersects = _raycaster.intersectObjects(SM.model.floorplan.roofPlanes(), true);
        } else {
            var edges = [];
            if (SM.ExteriorMode) {
                edges = SM.model.floorplan.wallEdges();
                //console.log(SM.model.floorplan.wallEdgePlanes());
            } else {
                edges = SM.model.floorplan.wallEdges()
            }
            var planesIntersecting = [];
            var exteriorMode = SM.ExteriorMode;

            if (!exteriorMode) {
                edges.forEach(edge => {if (edge.main) {
                    if (edge.plane != null) {
                        planesIntersecting.push(edge.plane)

                    } else {
                        console.error("EDGE PLANE IS NULL, WALL WAS DRAWN OVER ANOTHER.")
                        //console.log(edge.wall.remove());
                    }
                }
                })
            } else {
                edges.forEach(edge => {
                    if (edge.plane != null) {
                        planesIntersecting.push(edge.plane)

                    } else {
                        console.error("EDGE PLANE IS NULL, WALL WAS DRAWN OVER ANOTHER.")
                    }
                })
            }

            //console.error(planesIntersecting);
            intersects = _raycaster.intersectObjects(planesIntersecting, true);

            //console.log(intersects);
        }


        var canSpawn = false;
        if (intersects.length > 0) {
            //Point these objects intersected at!
            let spawnPoint = intersects[0].point;
            item.changeSpawnPoint(spawnPoint, intersects[0].object);
            canSpawn = true;
        }



        //if in 2d, instead of tesitng intersection, just test that its on the canvas somewhere.
        if (SM.isIn2D) {

             canSpawn = SM.floorPlanner.isOnCanvas(position);




        }

        item.changeAllow(canSpawn);

    }

    function dropped(item, monitor) {
        var position = monitor.getClientOffset();


        item.onSpawn(position);
    }

    return (
        <div ref={drop} style={{...styles, zIndex}}>
            {Object.keys(boxes).map(key => renderBox(boxes[key], key))}
        </div>
    )
};
export default DropContainer
