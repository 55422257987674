import {WallItem} from "./wall_item";


export class CustomItem extends WallItem {

    constructor(model, metadata, geometry, material, position, rotation, scale, isgltf = false) {
        super(model, metadata, geometry, material, position, rotation, scale, isgltf);
    }
}

