import * as THREE from 'three';
import {Mesh, MeshBasicMaterial, MeshStandardMaterial, PointLight, SphereBufferGeometry, Vector2, Vector3} from 'three';
import {EVENT_DELETED} from '../events.js';
import {Utils} from '../utils.js';
import {Item} from './item.js';
import {Dimensioning} from "../dimensioning";
import {SM} from "../../../components/SceneManager";

/**
 * A Wall Item is an entity to be placed related to a wall.
 */
export class WallItem extends Item {
    constructor(model, metadata, geometry, material, position, rotation, scale, isgltf = false) {
        super(model, metadata, geometry, material, position, rotation, scale, isgltf);
        /** The currently applied wall edge. */
        this.currentWallEdge = null;
        /* TODO:
         This caused a huge headache.
         HalfEdges get destroyed/created every time floorplan is edited.
         This item should store a reference to a wall and front/back,
         and grab its edge reference dynamically whenever it needs it.
         */

        /** used for finding rotations */
        this.refVec = new Vector2(0, 1.0);
        /** */
        this.wallOffsetScalar = 0;
        /** */
        this.sizeX = 0;
        /** */
        this.sizeY = 0;
        /** */
        this.addToWall = false;
        /** */
        this.boundToFloor = false;
        /** */
        this.frontVisible = false;
        /** */
        this.backVisible = false;
        this.allowRotate = false;
        this._freePosition = false;

        if (position) {
            this.position.set(position.x,position.y,position.z)

        }
        this.createDragHelper();


        this.createIfLight();
    }

    createIfLight = () => {
        if (this.metadata.isLight) {
            var bulbLight, bulbMat;
            this.geometry = new SphereBufferGeometry( 5, 16, 8 );
            this.geometry.computeBoundingBox();
            bulbLight = new PointLight( 0xffee88, 1, 100, 2 );
            this.light = new PointLight(SM.LightColor, SM.Luminosity,500, 1 );
            this.light.castShadow = true;
            this.light.shadow.mapSize.width = 1024;
            this.light.shadow.mapSize.height = 1024;
            var d = 300;
            this.light.shadow.camera.left = - d;
            this.light.shadow.camera.right = d;
            this.light.shadow.camera.top = d;
            this.light.shadow.camera.bottom = - d;
            //dirLight.shadow.camera.near = 0.1;
            //dirLight.shadow.camera.far = 40;
            var topSegment = new Mesh(this.geometry,new MeshBasicMaterial( {color: 0x3a3a3a} ))
            topSegment.position.set(0,1,0);
            this.add(topSegment)
            SM.addLight(this.light);
            //var sphereSize = 5;
            //var pointLightHelper = new PointLightHelper( this.light, 30 );
            //SM.scene.add( pointLightHelper );

            bulbMat = new MeshStandardMaterial( {
                emissive: 0xffffee,
                emissiveIntensity: 0.95,
                color: 0x000000
            } );
            this.material = bulbMat;
            //bulbLight.add( new Mesh( bulbGeometry, bulbMat ) );
            bulbLight.position.set( 0, 0, 0 );
            bulbLight.castShadow = true;
            this.add(this.light);
            this.castShadow = false;
            //hemiLight = new HemisphereLight( 0xddeeff, 0x0f0e0d, 0.02 );
            //SM.scene.add( hemiLight );
            //var position = this.position;
            //this.position.set(position.x,295,position.z);
        }
    }

    createDragHelper = () => {

        this.bbox = new THREE.Box3().setFromObject(this).getSize();

        var objectScale = this.scale;
        var width = 0;

        if (this.bbox.x > this.bbox.z) {
            width = this.bbox.x / objectScale.x;
        } else {
            width = this.bbox.z / objectScale.z;
        }

        //console.log("Drag helper starts rendering the outside over everything... dosent really work,using holes in wall, what about tiles?");
        let dragHelper = new THREE.Mesh(new THREE.PlaneGeometry(width, this.bbox.y / objectScale.y),
            new THREE.MeshPhongMaterial({color: 0x87CEEB, side: THREE.DoubleSide,visible:false, transparent: true, opacity: 0}));

        dragHelper.name = "dragHelper";
        dragHelper.position.set(dragHelper.position.x, dragHelper.position.y, dragHelper.position.z);
        this.dragHelper = dragHelper;
        //dragHelper.material.colorWrite = false;
        //dragHelper.renderOrder = 50;
        this.add(dragHelper);
        //this.dragHelper.needsUpdate = true;
    };

    /** Get the closet wall edge.
     * @returns The wall edge.
     */
    closestWallEdge(room,isMovingCanvas = false) {
        var wallEdges = this.model.floorplan.wallEdges();
        var smallestWallEdge = null;
        var wallEdge2 = null;
        var minDistance = null;
        var itemX = this.position.x;
        var itemZ = this.position.z;

        //var edges = SM.getAllEdges();
        wallEdges.forEach((halfedge) => {
            var distance = halfedge.distanceTo(itemX, itemZ);
                if (minDistance === null || distance < minDistance) {
                    //console.log("Min distance: " + minDistance);
                    minDistance = distance;
                    smallestWallEdge = halfedge;
                }
        });
        if (smallestWallEdge.front) {
            wallEdge2 = smallestWallEdge.wall.backEdge;
        } else {
            wallEdge2 = smallestWallEdge.wall.frontEdge;
        }



        var wallEdgeToReturn = smallestWallEdge;

        if (wallEdge2 && smallestWallEdge.front && smallestWallEdge.main === false) {
            wallEdgeToReturn = wallEdge2;
        } else {
            wallEdgeToReturn = smallestWallEdge;
        }

        if (isMovingCanvas) {
            if (room) {
                if (smallestWallEdge.room=== room && smallestWallEdge.room === room) {


                    if (wallEdge2 && smallestWallEdge.front && !smallestWallEdge.shouldBeInverse) {
                        wallEdgeToReturn = wallEdge2;
                    } else if (wallEdge2 && !smallestWallEdge.front && smallestWallEdge.shouldBeInverse) {
                        wallEdgeToReturn = wallEdge2;
                    }
                }
                else if (room === wallEdge2.room) {
                    wallEdgeToReturn = wallEdge2;
                } else if (room === smallestWallEdge.room) {
                    wallEdgeToReturn = smallestWallEdge;
                }
            }
        }




        //console.log("Found closest wall edge: ");
        //console.log(wallEdge);
        return wallEdgeToReturn;
    }

    /**
     * FIXME: This dosent work.
     * @returns {*}
     */
    secondClosestWallEdge(wall) {
        var wallEdges = this.model.floorplan.wallEdges();
        var wallEdge = null;
        var minDistance = null;
        var itemX = this.position.x;
        var itemZ = this.position.z;
        //var i = 0;

        wallEdges.forEach((edge) => {

            //Return before the last element
            if (wall !== edge) {
                var distance = edge.distanceTo(itemX, itemZ);
                if (minDistance === null || distance < minDistance) {
                    minDistance = distance;
                    wallEdge = edge;
                }
                //i++;
            }


        });
        return wallEdge;
    }


    drawMeasurements = (floorPlannerView, ctx, x, y, width, height, rotation) => {
        ctx.save();
        ctx.moveTo(0, 0);
        ctx.translate(x, y);
        ctx.rotate(-rotation);
        ctx.translate(-width / 2, -height / 2);
        ctx.strokeStyle = "#000";
        var cm = Dimensioning.pixelToCm(width);
        var m = Dimensioning.cmToMeasureRaw(cm);
        ctx.strokeRect(0, 40, width, 1);
        floorPlannerView.drawArrowhead(ctx, new Vector2(width - 20, 40), new Vector2(width, 40), 10);
        floorPlannerView.drawArrowhead(ctx, new Vector2(0 + 10, 40), new Vector2(0, 40), 10);
        floorPlannerView.drawTextLabel(m + "m", width / 2, 45);
        ctx.restore();
    };

    /** */
    removed() {
        if (this.currentWallEdge != null && this.addToWall) {
            Utils.removeValue(this.currentWallEdge.wall.items, this);
            this.redrawWall();
        }
    }

    /** */
    redrawWall() {
        if (this.addToWall) {
            if (this.currentWallEdge) {
                this.currentWallEdge.wall.fireRedraw();

            } else {
                //   var closestWallEdge = this.closestWallEdge();
                //         this.changeWallEdge(closestWallEdge);
            }
        }
    }

    /** */
    updateEdgeVisibility(visible, front) {
        if (front) {
            this.frontVisible = visible;
        } else {
            this.backVisible = visible;
        }
        var vis = (this.frontVisible || this.backVisible);
        //TODO:
        if (vis === this.vis) return;

        this.vis = vis;

        //this.visible = this.vis;

        //if (this.selected) this.GUIControls.changeVisibility(vis);


        if (this.material.length) {
            this.material.forEach((material) => {
                // TODO_Ekki emissive doesn't exist anymore?
                if (!this.vis) {
                    material.opacity = 0.2;
                    material.transparent = true;
                } else {
                    material.transparent = false;
                }

                //this.material.emissive = new Color(hex);
            });
        } else {
            if (!this.vis) {
                this.material.opacity = 0.2;
                this.material.transparent = true;
            } else {
                this.material.transparent = false;
            }
        }
    }

    /** */
    updateSize() {
        this.wallOffsetScalar = (this.geometry.boundingBox.max.z - this.geometry.boundingBox.min.z) * this.scale.z / 2.0;
        var wallThickness = 10;
        this.wallOffsetScalar+=wallThickness;
        this.sizeX = (this.geometry.boundingBox.max.x - this.geometry.boundingBox.min.x) * this.scale.x;
        this.sizeY = (this.geometry.boundingBox.max.y - this.geometry.boundingBox.min.y) * this.scale.y;
    }

    /** */
    resized() {
        this.geometry.computeBoundingBox();
        if (this.boundToFloor) {
            this.position.y = 0.5 * (this.geometry.boundingBox.max.y - this.geometry.boundingBox.min.y) * this.scale.y + 0.01;
        }
        this.updateSize();
        this.redrawWall();
    }

    findClosestWallEdgeInRoom = room => {
        var wallEdges = this.model.floorplan.wallEdges();
        var smallestWallEdge = null;
        var wallEdge2 = null;
        var minDistance = null;
        var itemX = this.position.x;
        var itemZ = this.position.z;

        //var edges = SM.getAllEdges();
        wallEdges.forEach((halfedge) => {
            var distance = halfedge.distanceTo(itemX, itemZ);
            if (minDistance === null || distance < minDistance) {
                //console.log("Min distance: " + minDistance);
                minDistance = distance;
                smallestWallEdge = halfedge;
            }
        });
        if (smallestWallEdge.front) {
            wallEdge2 = smallestWallEdge.wall.backEdge;
        } else {
            wallEdge2 = smallestWallEdge.wall.frontEdge;
        }

        var wallEdgeToReturn = smallestWallEdge;


        //console.log(this.metadata.onOrphan);
        //console.log(this.metadata.attachedToFront);
        if (this.metadata.onOrphan) {
            if (this.metadata.attachedToFront === true) {
                if (smallestWallEdge.front) {
                    return smallestWallEdge;
                } else {
                    if (wallEdge2) {
                        return wallEdge2;
                    }
                }
            } else if (this.metadata.attachedToFront === false) {
                if (smallestWallEdge.front) {
                    if (wallEdge2) {
                        return wallEdge2;
                    }
                } else {
                    return smallestWallEdge;
                }
            }
        }


        //DONT NEED THIS?
        //DEPRECREATED
        if (room) {

            if (smallestWallEdge.room !== room) {
                wallEdgeToReturn = wallEdge2
            }
            else if (smallestWallEdge.room=== room && smallestWallEdge.room === room) {

                if (wallEdge2 && smallestWallEdge.front && !smallestWallEdge.shouldBeInverse) {
                    wallEdgeToReturn = wallEdge2;
                } else if (wallEdge2 && !smallestWallEdge.front && smallestWallEdge.shouldBeInverse) {
                    wallEdgeToReturn = wallEdge2;
                }
            }

        } else {
            //console.log("Returning smallest edge here, should be inverse of the other alg")
            if (wallEdge2 && !smallestWallEdge.front && !smallestWallEdge.shouldBeInverse) {
                wallEdgeToReturn = wallEdge2;
            } else if (wallEdge2 && smallestWallEdge.front && smallestWallEdge.shouldBeInverse) {
                wallEdgeToReturn = wallEdge2;
            }
        }

        //console.log(this.metadata.outside);
        //console.log(this.metadata);
        if (this.metadata.outside && smallestWallEdge && wallEdgeToReturn === wallEdge2) {
            wallEdgeToReturn = smallestWallEdge;
        } else if (this.metadata.outside && wallEdge2 && wallEdgeToReturn === smallestWallEdge) {
            wallEdgeToReturn = wallEdge2
        }





        //console.log("Found closest wall edge: ");
        //console.log(wallEdge);
        return wallEdgeToReturn;
    }
    /** */
    placeInRoom() {

        //console.log("placing in room.");
        //console.log(this.metadata.outside);
        if (this.metadata.roomId) {
            this.model.floorplan.rooms.forEach(room => {
                if (room.roomIdentifier() === this.metadata.roomId) {
                    this.room = room;
                }
            })
        }
        var closestWallEdge = this.findClosestWallEdgeInRoom(this.room);
        this.wallEdgeMountedTo = closestWallEdge;
        this.changeWallEdge(closestWallEdge);
        this.updateSize();

        //If not given a position to spawn, spawn in center of wall.

        if (!this.position_set) {
            // position not set
            var center = closestWallEdge.interiorCenter();
            var newPos = new Vector3(center.x, 300 / 2.0, center.y);
            this.boundMove(newPos);
            this.position.copy(newPos);
            this.redrawWall();
        } else {
            //Just ensure that the position is bound to the wall.
            var pos = this.position;
            if (pos.y === 0) {
                pos.y = 150;
            }
            this.boundMove(pos);
            this.position.copy(pos);
            this.redrawWall();
        }
    }

    /** */
    moveToPosition(vec3, intersection,isMovingCanvas = false,isSoftReset=false) {

        var room = this.model.floorplan.overlappedRoom(vec3.x,vec3.z);
        var intersectionEdge = (intersection) ? (intersection.object) ? intersection.object.edge : intersection : this.closestWallEdge(room,isMovingCanvas);
        //If soft reset (redraw event) and there is a wall edge mounted to, do not change it.
        if (isSoftReset && this.wallEdgeMountedTo) {
            intersectionEdge = this.wallEdgeMountedTo;
            //Save the wall this object is mounted to.
        } else {
            this.wallEdgeMountedTo = intersectionEdge;
        }


        //console.log(intersectionEdge);
        //console.log(vec3);
        this.changeWallEdge(intersectionEdge);
        this.boundMove(vec3);

//		this.position.copy(vec3);
        super.moveToPosition(vec3);
        //console.log("Moved to position");
        this.redrawWall();
    }

    /** */
    getWallOffset() {
        return this.wallOffsetScalar;
    }

    /** */
    changeWallEdge(wallEdge) {
        //No point of doing this if the same wall.
        //if (wallEdge === this.currentWallEdge)return;

        //console.log("CHANGED WALL EDGE");
        if (this.currentWallEdge != null) {
            if (this.addToWall) {
                Utils.removeValue(this.currentWallEdge.wall.items, this);
                Utils.removeValue(this.currentWallEdge.wall.onItems, this);
                this.redrawWall();
            } else {
                Utils.removeValue(this.currentWallEdge.wall.onItems, this);
            }
        } else {
            //console.error("Current wall edge is null");
        }

        var scope = this;

        function __remove(event) {
            scope.remove(event.item);
        }

        // handle subscription to wall being removed
        if (this.currentWallEdge != null) {
//			this.currentWallEdge.wall.dontFireOnDelete(this.remove.bind(this));
            this.currentWallEdge.wall.removeEventListener(EVENT_DELETED, __remove);
        }
//		wallEdge.wall.fireOnDelete(this.remove.bind(this));
        wallEdge.wall.addEventListener(EVENT_DELETED, __remove);

        // find angle between wall normals
        var normal2 = new Vector2();
        var normal3 = wallEdge.plane.geometry.faces[0].normal;
        normal2.x = normal3.x;
        normal2.y = normal3.z;

        var angle = Utils.angle(new Vector2(this.refVec.x, this.refVec.y), new Vector2(normal2.x, normal2.y));
        this.rotation.y = angle;
        // update currentWall
        this.currentWallEdge = wallEdge;
        if (this.addToWall) {
            //console.log("Added to new walledge!");
            //console.log(wallEdge);
            //console.log("Pushed to new walledge.");
            wallEdge.wall.items.push(this);
            this.redrawWall();
        } else {
            wallEdge.wall.onItems.push(this);
        }

        //console.trace('changing metadata');
        this.metadata.outside = !!wallEdge.isOuterWall();
        this.metadata.onOrphan = wallEdge.wall.orphan;
        this.metadata.attachedToFront = wallEdge.front;
    }

    /** Returns an array of planes to use other than the ground plane
     * for passing intersection to clickPressed and clickDragged */
    customIntersectionPlanes() {
        var edges = [];
        if (SM.ExteriorMode) {
            edges = SM.model.floorplan.wallEdges();
            //console.log(SM.model.floorplan.wallEdgePlanes());
        } else {
            edges = SM.model.floorplan.wallEdges()
        }
        var planesIntersecting = [];
        var exteriorMode = SM.ExteriorMode;

        if (!exteriorMode) {
            edges.forEach(edge => {if (edge.main) {
                if (!edge.wall.isInvisible) {
                    planesIntersecting.push(edge.plane)
                }
            }})
        } else {
            edges.forEach(edge => {
                if (!edge.wall.isInvisible) {
                    planesIntersecting.push(edge.plane)
                }
            })
        }

        return planesIntersecting;
    }

    /** takes the move vec3, and makes sure object stays bounded on plane */
    boundMove(vec3) {
        var tolerance = 1;
        var edge = this.currentWallEdge;
        vec3.applyMatrix4(edge.interiorTransform);
        if (vec3.x < this.sizeX / 2.0 + tolerance) {
            vec3.x = this.sizeX / 2.0 + tolerance;
        } else if (vec3.x > (edge.interiorDistance() - this.sizeX / 2.0 - tolerance)) {
            vec3.x = edge.interiorDistance() - this.sizeX / 2.0 - tolerance;
        }

        if (this.boundToFloor) {
            vec3.y = 0.5 * (this.geometry.boundingBox.max.y - this.geometry.boundingBox.min.y) * this.scale.y + 0.01;
        } else {
            if (vec3.y < this.sizeY / 2.0 + tolerance) {
                vec3.y = this.sizeY / 2.0 + tolerance;
            }
            //Commenting the below condition where it will restrict the movement of the item to an uniform height
            else if (vec3.y > edge.height - this.sizeY / 2.0 - tolerance) {
                vec3.y = (edge.height-5) - this.sizeY / 2.0 - tolerance;
            }
        }
        if (this.getWallOffset() === 0) {
            vec3.z = 0;
        } else {
            vec3.z = this.getWallOffset();
        }

        vec3.applyMatrix4(edge.invInteriorTransform);
    }
}
