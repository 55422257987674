import React from 'react'
import {useDragLayer} from 'react-dnd'
import BoxDragPreview from './itemDragPreview'
import snapToGrid from './snapToGrid'
import {SM} from "../../SceneManager";

/*
THIS IS THE WHOLE WINDOW THAT IS DRAGGABLE!
 */
const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: "50px",
    top: "50px",
    width: '100%',
    height: '100%',
};

function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        }
    }
    let {x, y} = currentOffset;
    if (isSnapToGrid) {
        x -= initialOffset.x;
        y -= initialOffset.y
        ;[x, y] = snapToGrid(x, y);
        x += initialOffset.x;
        y += initialOffset.y
    }
    const transform = `translate(${x}px, ${y}px)`;

    return {
        transform,
        WebkitTransform: transform,
    }
}

const CustomDragLayer = props => {
    const {
        isDragging,
        item,
        initialOffset,
        currentOffset,
    } = useDragLayer(monitor => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));
    SM.setDragging((isDragging && item.type));

    if (!isDragging) return null;
    if (!item.type) return null;

    return (
        <div style={layerStyles}>
            <div
                style={getItemStyles(initialOffset, currentOffset, props.snapToGrid)}>
                <BoxDragPreview title={item.name} canSpawn={item.canSpawn()} img={item.img()}/>
            </div>
        </div>
    )
};
export default CustomDragLayer
