import {
    BoxGeometry,
    DoubleSide,
    EventDispatcher,
    ExtrudeGeometry,
    Face3,
    FrontSide,
    Geometry,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    RepeatWrapping,
    Shape,
    TextureLoader,
    Vector2,
    Vector3,
} from 'three';
import {EVENT_CHANGED} from '../events.js';
import {Configuration, configWallHeight} from '../configuration.js';
import {SM} from "../../../components/SceneManager";

export class Floor extends EventDispatcher {
    constructor(scene, room) {
        super();
        this.scene = scene;
        this.room = room;
        this.floorPlane = null;
        this.roofPlane = null;
        this.roofDecoration = null;
        this.tilesThatShouldFitInArea = 0;
        this.changedevent = () => {
            this.redraw();
        };
        this.init();

    }

    get TileDetails() {
        var textureSettings = this.room.getTexture();
        var details = SM.ObjectManager.getObjectInformation(textureSettings.slug);
        if (!details) return null;
        details.itemCount = this.tilesThatShouldFitInArea;
        return details;
    }

    switchWireframe(flag) {
        //this.floorPlane.visible = !flag;
        this.floorPlane.material.wireframe = flag;
        this.roofPlane.material.wireframe = flag;

    }

    init() {
        //console.log("INIT");
//		this.room.fireOnFloorChange(redraw);
        this.room.addEventListener(EVENT_CHANGED, this.changedevent);

        this.floorPlane = this.buildFloor();
        this.roofDecoration = this.buildRoofDecoration();
        // roofs look weird, so commented out
        if (this.room.ShouldHaveRoof) {
            this.roofPlane = this.buildRoofUniformHeight();
        }
        //this.roofPlane = this.buildRoofUniformHeight();
        //this.roofHeightArrow = new RoofHeightArrow(this.roofPlane,this.room)
    }

    redraw() {
        //console.log("Redrawing floor");
        this.removeFromScene();
        this.floorPlane = this.buildFloor();
        if (this.room.ShouldHaveRoof) {
            this.roofPlane = this.buildRoofUniformHeight();
        }
        this.addToScene();
    }

    generateHeightArrow = () => {
    };

    onLoadTexture = texture => {
        SM._dirty = true;
    };

    buildFloor() {
        //console.log("Building floor, not resizing.");
        var textureSettings = this.room.getTexture();
        //console.log(textureSettings);
        //console.log(textureSettings);
        // setup texture
//		var floorTexture = ImageUtils.loadTexture(textureSettings.url);
        var floorTexture = SM.TextureLoader.load(textureSettings.url, texture => this.onLoadTexture(texture));
        floorTexture.wrapS = RepeatWrapping;
        floorTexture.wrapT = RepeatWrapping;
        //floorTexture.minFilter = LinearFilter;
        var tileLengthMetres = textureSettings.measurements.length;
        var tileWidthMetres = textureSettings.measurements.width;


        floorTexture.wrapT = RepeatWrapping;
        floorTexture.wrapS = RepeatWrapping;

        floorTexture.repeat.set(tileLengthMetres/10, tileWidthMetres/10);

        //default texture hiding map and using color.
        var floorMaterialTop = new MeshPhongMaterial({
            map: textureSettings.slug === "default" ? null : floorTexture,
            side: DoubleSide,
            // ambient: 0xffffff, TODO_Ekki
            color: textureSettings.slug === "default" ? 0xE0E0E0 : 0xffffff,
            specular: 0x0a0a0a
        });
        floorMaterialTop.wireframe = SM.wireframeFlag;

        var textureScale = textureSettings.scale;
        // http://stackoverflow.com/questions/19182298/how-to-texture-a-three-js-mesh-created-with-shapegeometry
        // scale down coords to fit 0 -> 1, then rescale

        var points = [];
        this.room.interiorCorners.forEach((corner) => {
            points.push(new Vector2(corner.x / textureScale, corner.y / textureScale));
        });
        var shape = new Shape(points);
        var extrusionSettings = {
            steps: 1,
            depth: 0.2,
            bevelEnabled: true,
            bevelThickness: 0.05,
            bevelSize: 0,
            bevelOffset: 0,
            bevelSegments: 1
        };

        var geometry = new ExtrudeGeometry(shape, extrusionSettings);
        geometry.computeBoundingBox();
        //var geometry = new ShapeGeometry(shape);
        var floor = new Mesh(geometry, floorMaterialTop);

        floor.rotation.set(Math.PI / 2, 0, 0);
        floor.scale.set(textureScale, textureScale, textureScale);
        floor.receiveShadow = true;
        floor.castShadow = false;
        return floor;
    }

    buildRoofVaryingHeight() {
        //console.log("Building roof varying height.");

        // setup texture
        var roofMaterial = new MeshBasicMaterial({side: FrontSide, color: 0xe5e5e5});
        var geometry = new Geometry();

        this.room.corners.forEach((corner) => {
            var vertex = new Vector3(corner.x, corner.elevation, corner.y);
            geometry.vertices.push(vertex);
        });
        for (var i = 2; i < geometry.vertices.length; i++) {
            var face = new Face3(0, i - 1, i);
            geometry.faces.push(face);
        }
        var roof = new Mesh(geometry, roofMaterial);
        // roof.rotation.set(Math.PI / 2, 0, 0);
        // roof.position.y = Configuration.getNumericValue(configWallHeight);
        return roof;
    }

    buildRoofDecoration() {
        var roofMaterial = new MeshPhongMaterial({side: DoubleSide, color: 0xffffff,reflectivity:1,emissive:0xffffff,emissiveIntensity:0.5});

        var geometry = new BoxGeometry( 500, 275.84, 10 );
        var roof = new Mesh(geometry, roofMaterial);
        roof.rotateX(0.785398);
        roof.position.y = Configuration.getNumericValue(configWallHeight)+275.84/2;
        roof.position.x = 0;
        return roof;
    }
    buildRoofUniformHeight() {
        // setup texture
       // this.room.wall
        var side = SM.ExteriorMode ? DoubleSide : FrontSide;

        var roofTex = new TextureLoader().load(`${process.env.PUBLIC_URL}/Assets/roof_texture.jpg`, onLoad => {

        });
        var textureSettings = this.room.getTexture();
        var floorTexture = new TextureLoader().load(textureSettings.url, texture => this.onLoadTexture(texture));
        floorTexture.wrapS = RepeatWrapping;
        floorTexture.wrapT = RepeatWrapping;
        roofTex.wrapS = RepeatWrapping;
        roofTex.wrapT = RepeatWrapping;
        roofTex.repeat.set(20,20);

        //roofMaterial.map = roofTex;
        var roofMaterial = new MeshPhongMaterial({
            map: roofTex,
            side,

        });
        var points = [];
        this.room.interiorCorners.forEach((corner) => {
            points.push(new Vector2(corner.x, corner.y));
        });
        var shape = new Shape(points);
        var geometry = null;

        var extrusionSettings = {
            steps: 2,
            depth: 10,
            bevelEnabled: true,
            bevelThickness: 5,
            bevelSize: 10,
            bevelOffset: 35,
            bevelSegments: 1
        };

        geometry = new ExtrudeGeometry(shape, extrusionSettings);

        //var geometry = new ShapeGeometry(shape);
        var roof = new Mesh(geometry, roofMaterial);
        roof.visible = SM.ExteriorMode;
        roof.rotation.set(Math.PI / 2, 0, 0);
        roof.position.y = Configuration.getNumericValue(configWallHeight);
        return roof;
    }

    addToScene() {
        this.scene.add(this.floorPlane);
        if (this.room.ShouldHaveRoof) {
            this.scene.add(this.roofPlane);
        }
        //this.scene.add(this.roofDecoration);
        //scene.add(roofPlane);
        // hack so we can do intersect testing
        this.scene.add(this.room.floorPlane);
        this.scene.add(this.room.roofPlane);
    }

    removeFromScene() {
        this.scene.remove(this.floorPlane);
        this.scene.remove(this.roofPlane);
        this.scene.remove(this.room.floorPlane);
        this.scene.remove(this.room.roofPlane);
    }

    showRoof(flag) {

        // this.roofPlane.visible = flag;
        this.roofPlane.visible = SM.ExteriorMode;


    }
}
