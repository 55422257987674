import React, {Component} from 'react';
import ItemFolder from "./ItemFolder";
import CreateCategoryPopup from "./CreateCategoryPopup";
import {compose} from "redux";
import {connect} from "react-redux";
import {createCategory, getAreas, getCategories, getItems} from "../../../../../redux/actions/itemActions";
import {firestoreConnect, withFirestore} from "react-redux-firebase";
import firebase from '../../../../../config/firebase'
import CreateItemPopup from "./CreateItemPopup";
import LoadingSpinner from "../../../../General/Loading/LoadingSpinner";
import {changeLoadingState} from "../../../../../redux/actions/actionResultActions";
import CreateAreaPopup from "./CreateAreaPopup";

class AddItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryCreateOpen: false,
            createItemOpen: false,
            areaCreateOpen: false,
            categories: [],
            areas: [],
            selectedArea: null,
            selectedAreaObject: null,
            selectedCategory: null,
            selectedCategoryObject: null,
        }
    }

    componentDidMount() {
        //CAN NOW CALL AUTH.
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.fetchData();
            }
        })
        //body.style.overflow = "hidden";
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps !== this.props) {
            if (!this.props.auth.isLoaded) this.props.changeLoadingState(true);
        }
    }

    fetchData = () => {
        this.props.getItems();
        this.props.getCategories();
        this.props.getAreas();
    };

    toggleCategoryCreation = (flag) => {
        this.setState({categoryCreateOpen: flag});
    };
    toggleAreaCreation = flag => {
        this.setState({areaCreateOpen:flag})
    };

    toggleCreateItem = (flag) => {
        this.setState({createItemOpen: flag})
    };

    changeSelectedCategory = categoryName => {
        var selectedCategory = null;
        this.props.categories.forEach(category => {
            if (category.name === categoryName) {
                selectedCategory = category;
            }
        });
        this.setState({selectedCategory: categoryName, selectedCategoryObject: selectedCategory});
    };

    goBack = () => {
        this.setState({selectedCategory: null})

    };

    setCategory = (category) => {
        this.setState({selectedCategory: category.name, selectedCategoryObject: category});
    };
    setArea = (area) => {
        this.setState({selectedArea: area.name, selectedAreaObject: area});
    };

    render() {
        const {categoryCreateOpen, createItemOpen, selectedCategory,selectedArea,areaCreateOpen} = this.state;
        return (
            <div >
                {this.props.isLoading ? <LoadingSpinner/> : null}
                <ItemFolder isLoading={this.props.isLoading}
                            selectedCategoryObject={this.state.selectedCategoryObject}
                            goBack={this.goBack} items={this.props.items}
                            onSelectCategory={this.setCategory}
                            onSelectArea = {this.setArea}
                            selectedArea={selectedArea}
                            selectedCategory={selectedCategory}
                            toggleCreateItem={this.toggleCreateItem}
                            changeCategory={this.changeSelectedCategory}
                            categories={this.props.categories}
                            areas={this.props.areas}
                            toggleArea={this.toggleAreaCreation}
                            toggleCategory={this.toggleCategoryCreation}/>
                <CreateCategoryPopup toggleWindow={this.toggleCategoryCreation} isOpen={categoryCreateOpen}
                                     categories={this.props.categories} area={selectedArea}/>
                <CreateAreaPopup toggleWindow={this.toggleAreaCreation} isOpen={areaCreateOpen}
                                     areas={this.props.areas}/>

                <CreateItemPopup profile={this.props.profile} categories={this.props.categories} category={selectedCategory} toggleWindow={this.toggleCreateItem}
                                 isOpen={createItemOpen} area={selectedArea}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        items: state.items.individualItems,
        categories: state.items.individualCategories,
        areas: state.items.individualAreas,
    }
};

export default compose(
    withFirestore,
    firestoreConnect(props => {

        }
    ),
    connect(mapStateToProps, {createCategory, getItems, getCategories,getAreas, changeLoadingState}),
)(AddItem);
