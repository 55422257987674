import React, {Component} from 'react';
import {SM} from "../../../SceneManager";
//TODO: Load these from JSON file.

import newFile from '../../../../assets/images/files/new.png'
import loadFile from '../../../../assets/images/files/upload.png'
import saveFile from '../../../../assets/images/files/save.png'

import Collapsible from "react-collapsible";
import Tutorial from "../../../Tutorial/Tutorial";

const Preset = ({file,image,imageOutside,label}) => {
    return (
        <button onClick={() => SM.loadDesignFromUrl(file)} className={'main-gradient'}>
            <img className={'preset-img'} alt={label} src={image}/>
        </button>
    );
};


class SuiteHome extends Component {


    state = {
        homesOpen: true,
        saveLoadOpen: true,
        helpOpen: true
    }
    onNewDesign = () => {
        SM.newDesign(true);
        SM.floorplan3D.redrawRoom();
    };
    changeCollapsible = (key,open) => {

        var val = open;
        var state = this.state;
        state[key] = val;

        this.setState(state);
    };


    fetchPresetHomes = async () => {

    }


    render() {
        const {isOpen,presets} = this.props;
        const {homesOpen,saveLoadOpen,helpOpen} = this.state;
        function compare(a,b) {
            if (a.dateCreated < b.dateCreated) return -1;
            if (a.dateCreated > b.dateCreated) return 1;
            return 0;
        }
        var presetsSorted = presets.sort(compare)
        if (!isOpen) return null;
        return (
            <div>
                {
                <Collapsible trigger={'Preset Homes'} open={homesOpen}
                             onTriggerClosing={() => this.changeCollapsible('homesOpen',false)}
                             onTriggerOpening={() => this.changeCollapsible('homesOpen',true)}>
                    <div className={'customize-tabs preset-shapes'}>
                        {presetsSorted.map(preset => {
                            return <Preset key={preset.file} image={preset.image} file={preset.file}/>
                        })}


                    </div>
                </Collapsible>}
                {

                       <Collapsible trigger={'Save & Load'} open={saveLoadOpen}
                                    onTriggerClosing={() => this.changeCollapsible('saveLoadOpen',false)}
                                    onTriggerOpening={() => this.changeCollapsible('saveLoadOpen',true)}>
                           <div style={{display:'flex',flexDirection:'row'}} className={'save-load-designs'}>
                               <div href="#"
                                    className="main-gradient"
                                    id="new2d" title="New Layout" onClick={this.onNewDesign}>
                                   <img className={'save-load-img'}  alt={"new file"} src={newFile}/>

                                   <p className={'btn-font-styling'}>Reset</p>
                                   {
                                       //    <img alt={"new file"} src={newFile}/>
                                   }
                               </div>
                               <div href="#"
                                    className="main-gradient"
                                    id="saveFile2d" onClick={SM.saveDesign}
                                    title="Save Layout">
                                   <img className={'save-load-img'}  alt={"save file"} src={saveFile}/>
                                   <p className={'btn-font-styling'}>Save</p>
                                   {
                                   }
                               </div>
                               <div className="btn-file main-gradient">
                                   <img className={'save-load-img'} alt="upload file" src={loadFile}/>
                                   <p className={'btn-font-styling'}>Load</p>
                                   <input type="file" className="hidden-input" id="loadFile2d" onChange={SM.loadDesign}/>
                                   {
                                       //    <img alt="upload file" src={uploadFile}/>
                                   }
                               </div>
                           </div>

                </Collapsible>

                }
                <Collapsible trigger={'Help'} open={helpOpen}
                             onTriggerClosing={() => this.changeCollapsible('helpOpen',false)}
                             onTriggerOpening={() => this.changeCollapsible('helpOpen',true)}>
                    <Tutorial/>
                </Collapsible>


            </div>
        );
    }
}

export default SuiteHome;
