import React from 'react';
import DropContainer from "../DragNDrop/dropContainer";

const GameContainer = ({setRef, isDragging, isHidden, isLoading, viewerMode, height, width}) => {
    return (
        <div>
            <div id={'webgl-container'} className={`game-container ${isHidden ? "hidden" : ""}`}
                 style={renderViewerModeStyle()} ref={setRef}>
                <DropContainer isDragging={isDragging}/>
            </div>
        </div>
    );
    function renderViewerModeStyle() {
        //adding + and margin to ensure it covers entire screen
        if (!viewerMode) return {height: height+10, width: width+50, margin: '-5 -5'};
        return {
            height: "600px",
            width: '900px',
            position: 'static'
        }
    }
};

export default GameContainer;
