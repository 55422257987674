/// <reference path="../../lib/three.d.ts" />
/// <reference path="../model/model.ts" />
/// <reference path="wall_item.ts" />
/// <reference path="metadata.ts" />
import {WallItem} from './wall_item.js';
import {Dimensioning} from "../dimensioning";

export class InWallItem extends WallItem {
    constructor(model, metadata, geometry, material, position, rotation, scale, isgltf = false) {
        super(model, metadata, geometry, material, position, rotation, scale, isgltf);
        this.addToWall = true;
        //Create drag helper
    }

    /**
     * Draw this item to the canvas.
     * Overriden by different types of items as they need to be displayed differently.
     *
     * @param canvasContext Context of the 2D canvas.
     */
    drawToCanvas = (floorplannerView, activeItem, clickedItem, isBuilding) => {
        var isActiveItem = activeItem === this;
        var isClickedItem = clickedItem === this;

        /*
        var width = Dimensioning.cmToMeasureRaw(this.getWidth());
        var depth = Dimensioning.cmToMeasureRaw(this.getDepth());
        var height = Dimensioning.cmToMeasureRaw(this.getHeight());
        var position = new Vector3(
            Dimensioning.cmToMeasureRaw(this.position.x),
            Dimensioning.cmToMeasureRaw(this.position.y),
            Dimensioning.cmToMeasureRaw(this.position.z)
        );

         */

        var ctx = floorplannerView.context;

        /*
        const rotateAndCache = (image,angle) => {
            var offscreenCanvas = document.createElement('canvas');
            var offscreenCtx = offscreenCanvas.getContext('2d');

            var size = Math.max(image.width, image.height);
            offscreenCanvas.width = size;
            offscreenCanvas.height = size;

            offscreenCtx.translate(size/2, size/2);
            offscreenCtx.rotate(angle + Math.PI/2);
            offscreenCtx.drawImage(image, -(image.width/2), -(image.height/2));

            return offscreenCanvas;
        };

         */

        const drawImageCentered = (ctx, img, x, y, width, height, rotation) => {

            //Save canvas context.
            ctx.save();
            //Move canvas to center of image point
            ctx.moveTo(0, 0);
            //Move to the x,y position.
            ctx.translate(x, y);
            //Rotate the canvas by inverse of the object position.
            ctx.rotate(-rotation);
            //Translate it to the width and height in order to center the object.
            ctx.translate(-width / 2, -height / 2);
            //Drawing image at the canvas position now.
            ctx.drawImage(img, 0, 0, width, height);
            if (isClickedItem) {
                ctx.strokeStyle = '#C9302C';  // some color/style
                ctx.strokeRect(0, 0, width, height);
            } else if (isActiveItem) {
                ctx.strokeStyle = '#008cba';  // some color/style
                ctx.strokeRect(0, 0, width, height);
            }
            //Restore canvas context.
            ctx.restore();
        };

        //Change rotation

        //var rotObj = rotateAndCache(this.canvasImg,this.rotation.y);


        var xPos = floorplannerView.viewmodel.convertX(this.position.x);
        var yPos = floorplannerView.viewmodel.convertY(this.position.z);
        var pxWidth = Dimensioning.cmToPixel(this.getWidth());
        var pxDepth = Dimensioning.cmToPixel(this.getDepth());


        drawImageCentered(ctx,
            isBuilding ? this.buildingImage : this.designImage,
            xPos,
            yPos,
            pxWidth,
            pxDepth,//floorplannerView.widthOfWall,
            this.rotation.y
        );
        //rotate context back

        //DRAW RECT
        /*
        ctx.beginPath();
        ctx.rect(floorplannerView.viewmodel.convertX(this.position.x),
            floorplannerView.viewmodel.convertY(this.position.z),
            Dimensioning.pixelToCm(this.getWidth()),
            Dimensioning.pixelToCm(this.getDepth()));
        ctx.strokeStyle = "#FF0000";
        ctx.stroke();
        */

        var centerX = floorplannerView.viewmodel.convertX(this.position.x);
        var centerY = floorplannerView.viewmodel.convertY(this.position.z);

        //draw measurements
        //var w = this.getWidth();
        //var l = this.getDepth();

        //DRAW LABEL
        if (isBuilding) floorplannerView.drawTextLabel(this.metadata.itemName, centerX, centerY - 15);

        if (isClickedItem) {
            this.drawCanvasControls();
        }

        if (isClickedItem) {
            //this.drawMeasurements(floorplannerView, ctx, xPos, yPos, pxWidth, pxDepth, this.rotation.y);
        }
    };

    calibrateGUIControls = () => {
        this.GUIControls.setScale(this.scale);
        this.GUIControls.setHeight(this.halfSize.y / this.scale.x + 5);
        //this.GUIControls.setBound(this.getWallOffset());
        console.error(this.getDepth());
        this.GUIControls.position.set(this.GUIControls.position.x + 0.5, this.GUIControls.position.y, this.GUIControls.position.z + 2);

        this.add(this.GUIControls);
    };

    /** */
    getWallOffset() {
        // fudge factor so it saves to the right wall
        return -this.currentWallEdge.offset + 0.5;
    }
}
