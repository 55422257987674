import React from 'react'

const styles = {
    cursor: 'move',
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    backgroundColor:0x000000,
};
const Box = ({title, yellow, img}) => {

    const border = yellow ? '5px solid green' : '5px solid red'
    const opacity = yellow ? '1' : '0.5';

    return (
        <div style={{...styles, opacity, border}} className={'fade-in'}>
            <img alt={'box'} src={img} className={'item-card-img'}/>
        </div>);
};
export default Box
