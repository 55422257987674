import Manager from "./Manager";

export default class ControlsManager extends Manager {


    /**
     * When a manager starts.
     */
    start = () => {

    };

    /**
     * All managers are updated every frame.
     */
    update = () => {
    };

    /**
     * Whenever an object is selected, notify all managers.
     * @param object
     */
    notifyOnObjectClicked = (object) => {
    };

    /**
     * Whenever an object is hovered, notify all managers.
     * @param object
     */
    notifyOnObjectHovered = (object) => {
    };

}