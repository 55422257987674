import React from 'react';

const CartItem = ({itemName,amount}) => {
    return (
        <div className={'cart-item bg-normal'}>
            <p>{itemName} - x{amount}</p>
        </div>
    )
};
const Cart = ({sections, items,isOpen}) => {

    //Map=>section, OldCart's with items
    if (!isOpen) return null;
    return (
        <div>
            {
                //sections.map(section=>(<OldCart key={section} items={getItemsForSection(section)} section={section}/>))
                renderCartItems()
            }
        </div>
    );

    function getItemsSorted() {
        var sorted = {};
        items.forEach(item => {
            if (!sorted[item.name]) {
                sorted[item.name] = {
                    "amount": 1,
                    "name": item.name};
            } else {
                sorted[item.name].amount++;
            }
        })
        return sorted;
    };
    function renderCartItems() {
        var sorted = getItemsSorted();
        var itemsSorted = [];
        for (var item in sorted) {
            itemsSorted.push(sorted[item]);
        }
        return (
            itemsSorted.map(item => {
                return (
                    <CartItem key={item.name} amount={item.amount} itemName={item.name}/>
                )
            })
        )
    }


    /*
    function getItemsForSection(section) {
        var sectionItems = [];

        var iterations = 0;
        for (var i = 0 ; i < items.length ;i++ ) {
            iterations++;

            if (!items[i] || !items[i].section) {
                continue;
            }
            if(items[i].section === section) {
                if (!sectionItems.includes(items[i])) {
                    if (!items[i].itemCount) {
                        items[i].itemCount = getItemCount(items[i]);
                    }
                    sectionItems.push(items[i])
                } else {

                }
            }
        }
        return sectionItems;
    }

    function getItemCount(item) {
        let count = 0;
        for (let i = 0 ; i < items.length ;i++ ) {
            if (items[i] === item) {
                count++;
            }
        }
        return count;
    }

     */
};

export default Cart;
