import React from 'react';
import Lottie from 'react-lottie'


const LottiePlayer = ({lottieData, height, width,autoplay,loop}) => {
    const defaultOptions = {
        loop: typeof loop !== "undefined" ? loop : true,
        autoplay: typeof autoplay !== "undefined" ? autoplay : true,
        animationData: lottieData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Lottie options={defaultOptions}
                height={height}
                width={width}
        />
    );
};

export default LottiePlayer;