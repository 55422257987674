import * as THREE from 'three';
import ModelLoader from './ModelLoader.js';
import {OrbitControls} from "../floorplanner/three/orbitcontrols";
import React, {Component} from 'react';
// model-viewer element
// Loads and displays a 3D model

// Events
// model-change: Fires when a model is going to load
// model-loaded: Fires when all the geometry has been fully loaded
// error: Fires when there's a problem loading the model

export default class ModelViewer extends Component {

    constructor(props) {

        super(props);

        this.state = {
            style: {
                backgroundColor: '#C73A3C'
            }
        };
    }

    setup = () => {
        this.container = document.getElementById('model-viewer-container');
        console.log(this.container);
        //document.body.appendChild(this.container);

        /* Camera */

        const camera = new THREE.PerspectiveCamera( 75, 1, 0.1, 1000 );
        camera.position.z = 10;

        this.camera = camera;
        /* Scene */

        this.scene = new THREE.Scene();
        this.lighting = false;

        const ambientLight = new THREE.HemisphereLight( this.ambientColor, '#000' );
        ambientLight.groundColor.lerp( ambientLight.color, 0.5 );
        ambientLight.intensity = 0.5;
        ambientLight.position.set( 0, 1, 0 );
        this.scene.add( ambientLight );

        // Light setup
        const dirLight = new THREE.DirectionalLight( 0xffffff );
        dirLight.position.set( 4, 10, 4 );
        dirLight.shadow.bias = - 0.0001;
        dirLight.shadow.mapSize.width = 2048;
        dirLight.shadow.mapSize.height = 2048;
        dirLight.castShadow = true;
        this.scene.add( dirLight );
        this.scene.add( dirLight.target );


        this.directionalLight = dirLight;
        this.ambientLight = ambientLight;


        // Containers setup
        const scaleContainer = new THREE.Object3D();
        this.scene.add( scaleContainer );

        const rotator = new THREE.Object3D();
        scaleContainer.add( rotator );

        const plane = new THREE.Mesh(
            new THREE.PlaneGeometry(),
            new THREE.ShadowMaterial( { side: THREE.DoubleSide, transparent: true, opacity: 0.25 } )
        );
        plane.rotation.set( - Math.PI / 2, 0, 0 );
        plane.scale.multiplyScalar( 20 );
        plane.receiveShadow = true;
        scaleContainer.add( plane );

        this.scaleContainer = scaleContainer;
        this.plane = plane;
        const gridHelper = new THREE.GridHelper( 10, 10, 0xffffff, 0xeeeeee );
        gridHelper.material.transparent = true;
        gridHelper.material.opacity = 0.6;
        gridHelper.visible = false;
        scaleContainer.add( gridHelper );

        this.scene.add(scaleContainer);
        this.rotator = rotator;
        this.gridHelper = gridHelper;

        this.keyLight = new THREE.DirectionalLight(new THREE.Color('hsl(30, 100%, 75%)'), 1.0);
        this.keyLight.position.set(-100, 0, 100);

        this.fillLight = new THREE.DirectionalLight(new THREE.Color('hsl(240, 100%, 75%)'), 0.75);
        this.fillLight.position.set(100, 0, 100);

        this.backLight = new THREE.DirectionalLight(0xffffff, 1.0);
        this.backLight.position.set(100, 0, -100).normalize();
        this.camera.add(this.backLight);

        /* Model */

        /* Renderer */

        const renderer = new THREE.WebGLRenderer({antialias:true,alpha:true});
        renderer.setClearColor( 0xffffff );
        renderer.setClearAlpha( 0 );
        renderer.shadowMap.enabled = true;

        // enable gamma correction before display
        renderer.gammaOutput = true;

        this.renderer = renderer;

        this.container.appendChild(this.renderer.domElement);

        /* Controls */
        this.renderer.setSize(500, 500);

        // enable gamma correction before display
        renderer.gammaOutput = true;

        // Controls setup
        const controls = new OrbitControls( this.camera,document);
        controls.rotateSpeed = 2.0;
        controls.zoomSpeed = 5;
        controls.panSpeed = 2;
        controls.enableZoom = true;
        controls.enablePan = true;
        controls.enableRotate = true;

        controls.enableDamping = false;
        controls.maxDistance = 50;
        controls.minDistance = 0.25;
        controls.addEventListener( 'change', () => console.log("Dirty"));


        this.controls = controls;

        /* Events */



        this.camera.position.z = 5;


        //https://github.com/gkjohnson/threejs-model-loader/blob/ba9f5c103594a1c338d5717bf0948a9f9c35c538/src/model-viewer-element.js
        var animate = () => {
            requestAnimationFrame( animate );

            //cube.rotation.x += 0.01;
            //cube.rotation.y += 0.01

            if (this._model) {
                const bbox = new THREE.Box3().setFromObject( this._model );
                const center = bbox.getCenter( new THREE.Vector3() );
                const sphere = bbox.getBoundingSphere( new THREE.Sphere() );
                const minmax = sphere.radius;
                const cam = this.directionalLight.shadow.camera;
                cam.left = cam.bottom = - minmax;
                cam.right = cam.top = minmax;

                // Update the camera to focus on the center of the model so the
                // shadow can encapsulate it
                const dirLight = this.directionalLight;
                const offset = dirLight.position.clone().sub( dirLight.target.position );
                dirLight.target.position.copy( center );
                dirLight.position.copy( center ).add( offset );
                cam.updateProjectionMatrix();
            }


            this.controls.update();

            this.renderer.render( this.scene, this.camera );
        };

        animate();
    }

    componentDidMount() {
        //let div = this.dropRef.current;
        const bgColors = [
            '#FFC107',
            '#F06292',
            '#009688',
            '#3F51B5',
            '#CDDC39'
        ];
        document.addEventListener('dragover', e => e.preventDefault());
        document.addEventListener('dragenter', e => e.preventDefault());
        document.addEventListener('drop', e => {

            e.preventDefault();

            console.log(e);
            const newcol = bgColors.shift();
            bgColors.push(newcol);
            //viewer.ambientColor = '#' + new THREE.Color( newcol ).lerp( new THREE.Color( 0xffffff ), 0.7 ).getHexString();
            /*
            var style = this.state.style;
            style = {
                backgroundColor: newcol
            }
             */
            // convert the files
            /*
            The DataTransfer object is used to hold the data that is being dragged during a drag and drop operation.
            It may hold one or more data items, each of one or more data types.
            For more information about drag and drop, see HTML Drag and Drop API.
             */
            //console.log(e.dataTransfer);
            dataTransferToFiles( e.dataTransfer )
                .then( files => {

                    // removes '..' and '.' tokens and normalizes slashes
                    const cleanFilePath = path => {

                        return path
                            .replace( /\\/g, '/' )
                            .split( /\//g )
                            .reduce( ( acc, el ) => {

                                if ( el === '..' ) acc.pop();
                                else if ( el !== '.' ) acc.push( el );
                                return acc;

                            }, [] )
                            .join( '/' );

                    };

                    // set the loader url modifier to check the list
                    // of files
                    const fileNames = Object.keys( files ).map( n => cleanFilePath( n ) );
                    this.loadingManager.setURLModifier( url => {

                        // find the matching file given the requested url
                        const cleaned = cleanFilePath( url );
                        const fileName = fileNames
                            .filter( name => {

                                const len = Math.min( name.length, cleaned.length );

                                // check if the end of file and url are the same
                                return cleaned.substr( cleaned.length - len ) === name.substr( name.length - len );

                            } ).pop();

                        if ( fileName !== undefined ) {

                            const bloburl = URL.createObjectURL( files[ fileName ] );

                            // revoke the url after it's been used
                            requestAnimationFrame( () => URL.revokeObjectURL( bloburl ) );

                            return bloburl;

                        }

                        return url;

                    } );

                    // set the source of the element to the most likely intended display model
                    const filesNames = Object.keys( files );
                    //viewer.src =
                    filesNames.filter( n => this.modelLoader.canLoadModel( n )).shift();
                    //console.log("LOADING FIRST FILE NAME IN ARRAY");
                    console.log(filesNames);
                    this._loadModel(fileNames[0]);

                } );
            //this.setState({style});

        });

        this.modelLoader.getLoader = function ( loaderName, manager, loadercb ) {

            function createLoader( ln ) {

                ln = ln || loaderName;

                return new THREE[ ln ]( manager );

            }

            function getSource( name ) {

                let f =
                    fetch( `../node_modules/three/examples/js/loaders/${ name }.js` )
                        .then( res => res.text() );
                // eslint-disable-next-line no-eval
                f.then( text => eval( text ) );

                return f;

            }

            if ( THREE[ loaderName ] == null ) {

                if ( loaderName === 'OBJLoader2' ) {

                    getSource( 'LoaderSupport' )
                        .then( () => getSource( loaderName ) )
                        .then( () => loadercb( createLoader() ) );

                } else if ( loaderName === 'KMZLoader' ) {

                    getSource( 'ColladaLoader' )
                        .then( () => getSource( loaderName ) )
                        .then( () => loadercb( createLoader() ) );

                } else if ( loaderName === '3MFLoader' ) {

                    getSource( loaderName )
                        .then( () => loadercb( createLoader( 'ThreeMFLoader' ) ) );

                } else {

                    getSource( loaderName )
                        .then( () => loadercb( createLoader() ) );

                }

            } else {

                loadercb( createLoader() );

            }

        };

        this.setup();

    }

    render() {
        return (
            <div style={{height:'100%'}}>
                <div id="model-viewer" style={this.state.style} ref={this.dropRef}>
                    <div className={'noselect'}>Drag geometry files and dependencies to load and view them <br/>
                        ( Dragging folders will only work in chrome )</div>
                    <div id={'model-viewer-container'}>

                    </div>
                </div>
                <div>
                    <button className={'centre-big-button'}>Upload Model</button>

                </div>
            </div>
        )
    }



    onMouseDown = e => {
        console.log(e);
    }

    get loadingManager() {

        return this._loadingManager = this._loadingManager || new THREE.LoadingManager();

    }

    get modelLoader() {

        if ( !this._modelLoader ) {

            const loader = new ModelLoader( this.loadingManager );
            loader.getLoadCallback = function ( ext, done ) {

                const loaderName = ModelLoader.ExtensionToThreeLoader[ ext ];
                if ( loaderName in THREE ) {

                    done( function( url, manager, onLoad, onProgress, onError ) {

                        new THREE[ loaderName ]( manager ).load( url, onLoad, onProgress, onError );

                    } );

                } else {

                    done( null );

                }

            }

            loader.canLoadModel = function ( urlOrExt ) {

                const extregex = new RegExp(
                    `(${ Object
                        .keys( ModelLoader.ExtensionToThreeLoader )
                        .join( '|' )
                    })$`, 'i' );

                return extregex.test( urlOrExt );

            }

            this._modelLoader = loader;

        }

        return this._modelLoader;

    }

    /* Lifecycle Functions */


    connectedCallback() {

        // Add our initialize styles for the element if they haven't
        // been added yet
        if ( ! this.constructor._styletag ) {

            const styletag = document.createElement( 'style' );
            styletag.innerHTML =
                `
                ${this.tagName} { display: block; }
                ${this.tagName} canvas {
                    width: 100%;
                    height: 100%;
                }
            `;
            document.head.appendChild( styletag );
            this.constructor._styletag = styletag;

        }

        // add the renderer
        if ( this.childElementCount === 0 ) {

            this.appendChild( this.renderer.domElement );

        }

    }

    disconnectedCallback() {

        cancelAnimationFrame( this._renderLoopId );

    }

    attributeChangedCallback( attr, oldval, newval ) {

        this._dirty = true;

        switch ( attr ) {

            case 'src': {

                this._loadModel( this.src );
                break;

            }

            case 'ambient-color': {

                this.ambientLight.color.set( this.ambientColor );
                this.ambientLight.groundColor.set( '#000' ).lerp( this.ambientLight.color, 0.5 );
                break;

            }

            case 'show-grid': {

                this.gridHelper.visible = this.showGrid;
                break;

            }

            default: {
                break;
            }

        }

    }

    /* Public API */
    redraw() {

        this._dirty = true;

    }

    /* Private Functions */
    _refresh() {

        const r = this.renderer;
        const w = this.clientWidth;
        const h = this.clientHeight;
        const currsize = r.getSize();

        if ( currsize.width !== w || currsize.height !== h ) {

            this._dirty = true;

            r.setPixelRatio( window.devicePixelRatio );
            r.setSize( w, h, false );

            this.camera.aspect = w / h;
            this.camera.updateProjectionMatrix();

        }

    }

    _loadModel( src ) {

        console.error("LOADING MODEL INTO VIEWER----");
        if ( this._prevsrc === src ) return;
        this._prevsrc = src;

        if ( this._model ) {

            this._model.parent.remove( this._model );
            this._model = null;
            this._dirty = true;

        }

        if ( src ) {

            //this.dispatchEvent( new CustomEvent( 'model-change', { bubbles: true, cancelable: true, composed: true, detail: src } ) );

            // Keep track of this request and make
            // sure it doesn't get overwritten by
            // a subsequent one
            this._requestId ++;
            //const requestId = this._requestId;

            this.modelLoader
                .load( src, res => {

                    //if ( this._requestId !== requestId ) return;

                    this._addModel( res.model );
                    //this.dispatchEvent( new CustomEvent( 'model-loaded', { bubbles: true, cancelable: true, composed: true } ) );

                }, null, err => {

                    //this.dispatchEvent( new CustomEvent( 'error', { bubbles: true, cancelable: true, composed: true, detail: err } ) );
                    console.error(err);

                } );

        }

    }

    _addModel( obj ) {

        /*
        var geometry = new THREE.BoxGeometry();
        var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
        var cube = new THREE.Mesh( geometry, material );
        this.scaleContainer.add( cube );


         */
        console.log(obj);
        const rotator = this.rotator;
        const scaleContainer = this.scaleContainer;
        const plane = this.plane;
        const gridHelper = this.gridHelper;

        this._model = obj;

        // Get the bounds of the model and scale and set appropriately
        obj.updateMatrixWorld( true );
        const box = new THREE.Box3().expandByObject( obj );
        const sphere = box.getBoundingSphere( new THREE.Sphere() );
        const s = 3 / sphere.radius;

        rotator.add( obj );
        rotator.rotation.set( 0, 0, 0 );
        obj.position
            .copy( sphere.center )
            .negate();

        scaleContainer.scale.set( 1, 1, 1 ).multiplyScalar( s );

        // add an additional tiny offset so the shadow plane won't
        // z-fight with the bottom of the model
        const offset = Math.abs( box.max.y - box.min.y ) * 1e-5;
        plane.position.y = obj.position.y + box.min.y - offset;
        plane
            .scale
            .set( 1, 1, 1 )
            .multiplyScalar( 100 / s );

        gridHelper.position.copy( plane.position );
        gridHelper.position.y -= offset;

        // make sure the obj will cast shadows
        obj.traverse( c => {

            c.castShadow = true;
            c.receiveShadow = true;

            if ( c.isMesh ) {

                if ( c.material ) {

                    const mats = Array.isArray( c.material ) ? c.material : [ c.material ];
                    mats.forEach( ( m, i ) => {

                        if ( m instanceof THREE.MeshBasicMaterial ) {

                            const mat = new THREE.MeshPhongMaterial( { color: 0x888888 } );
                            if (
                                // eslint-disable-next-line no-mixed-operators
                                c.geometry.isBufferGeometry && 'color' in c.geometry.attributes || c.geometry.isGeometry
                            ) {

                                mat.vertexColors = THREE.VertexColors;

                            }

                            if ( c.geometry.isBufferGeometry && ! ( 'normal' in c.geometry.attributes ) ) {

                                c.geometry.computeVertexNormals();

                            }

                            mat.map = m.map;

                            mats[ i ] = mat;
                            m = mat;

                        }

                        // TODO: Lambert materials don't handle shadows well, so
                        // we replace them here. Remove this once the THREE bug is fixed
                        // Mentioned in https://github.com/mrdoob/three.js/issues/8238
                        if ( m instanceof THREE.MeshLambertMaterial ) {

                            // Can't use the `copy` function because the phong material expects
                            // a specular color
                            // https://github.com/mrdoob/three.js/issues/14401
                            const mat = new THREE.MeshPhongMaterial();
                            Object.keys( m )
                                .filter( key => key in mat && m[ key ] && key !== 'type' )
                                .forEach( key => mat[ key ] = m[ key ] );

                            mats[ i ] = mat;
                            m = mat;

                        }

                        if ( m.map ) {

                            // The texture's color space is assumed to be
                            // in sRGB, though most of the THREE loaders assume
                            // a Linear color space.
                            m.map.encoding = THREE.GammaEncoding;
                            m.needsUpdate = true;

                        }

                        m.shadowSide = THREE.DoubleSide;

                    } );

                    if ( Array.isArray( c.material ) ) c.material = mats;
                    else c.material = mats[ 0 ];

                }

            }

        } );

        this._dirty = true;

    }
    dropRef = React.createRef()



}

// Converts a datatransfer structer into an object with all paths and files
// listed out. Returns a promise that resolves with the file structure.
function dataTransferToFiles( dataTransfer ) {

    console.log("Converting DataTransfer object to files.")
    if ( ! ( dataTransfer instanceof DataTransfer ) ) {

        throw new Error( 'Data must be of type "DataTransfer"', dataTransfer );

    }

    const files = {};

    // recurse down the webkit file structure resolving
    // the paths to files names to store in the `files`
    // object
    function recurseDirectory( item ) {

        console.log("Recursing directory...");
        if ( item.isFile ) {

            return new Promise( resolve => {

                item.file( file => {

                    files[ item.fullPath ] = file;
                    resolve();

                } );

            } );

        } else {

            const reader = item.createReader();

            return new Promise( resolve => {

                const promises = [];
                reader.readEntries( et => {

                    et.forEach( e => {

                        promises.push( recurseDirectory( e ) );

                    } );

                    Promise.all( promises ).then( () => resolve() );

                } );

            } );

        }

    }

    return new Promise( resolve => {

        // Traverse down the tree and add the files into the zip
        const dtitems = dataTransfer.items && [ ...dataTransfer.items ];
        const dtfiles = [ ...dataTransfer.files ];

        if ( dtitems && dtitems.length && dtitems[ 0 ].webkitGetAsEntry ) {

            const promises = [];
            for ( let i = 0; i < dtitems.length; i ++ ) {

                const item = dtitems[ i ];
                const entry = item.webkitGetAsEntry();

                promises.push( recurseDirectory( entry ) );

            }
            Promise.all( promises ).then( () => resolve( files ) );

        } else {

            // add a '/' prefix to math the file directory entry
            // on webkit browsers
            dtfiles
                .filter( f => f.size !== 0 )
                .forEach( f => files[ '/' + f.name ] = f );

            resolve( files );

        }

    } );

}
