import {SM} from '../../SceneManager';
import Manager from "./Manager";
import * as THREE from "three";
import models from '../../../assets/models/mastersuiteModels';
import kitchenModels from '../../../assets/models/kitchenModels';
import diningModels from '../../../assets/models/bathroomModels';
import bedroomModels from '../../../assets/models/bedroomModels';
import exteriorModels from '../../../assets/models/exteriorModels';

export default class ObjectManager extends Manager {

    objectsToIgnoreWhenZoomingToWall = ["wall_tile"];

    constructor() {
        super();
        //This is the actual THREE.js object.
        this.objects = [];
        //This is the wrapper class SceneObject that contains a THREE.js object.
        this.sceneObjects = [];
        //This is the currently selected object.
        this.selectedObject = null;
        this.objectDatabase = [];
        this.kitchenDatabase = [];
        this.diningDatabase = [];
        this.bedroomDatabase = [];
        this.exteriorDatabase = [];

        this.databaseCategories = [];
        this.kitchenCategories = [];
        this.diningCategories = [];
        this.bedroomCategories = [];
        this.exteriorCategories = [];

        this.objectDatabaseSearch = {};
        this.objectControls = null;
        //this.loadObjectDatabase(models);
        this.loadObjectDatabase(models,this.objectDatabase,this.databaseCategories);
        this.loadObjectDatabase(kitchenModels,this.kitchenDatabase,this.kitchenCategories);
        this.loadObjectDatabase(diningModels,this.diningDatabase,this.diningCategories);
        this.loadObjectDatabase(bedroomModels,this.bedroomDatabase,this.bedroomCategories);
        this.loadObjectDatabase(exteriorModels,this.exteriorDatabase,this.exteriorCategories);

    }

    get ClickableObjects() {
        let clickableObjects = [];
        for (var i = 0; i < this.sceneObjects.length; i++) {
            var sceneObject = this.sceneObjects[i];
            if (sceneObject.isVisible && sceneObject.object) {
                clickableObjects.push(sceneObject.object);
            }
        }

        SM.scene.traverse((element) => {
            if (element.name.includes("cubicle_arrow")) {
                clickableObjects.push(element);
            }
        });
        clickableObjects.push(this.objectControls.buttonsTool.object);
        clickableObjects.push(this.objectControls.rotationalTool.object);

        return clickableObjects;
    }

    /**
     * Get the database categories.
     * @returns {[]|Array}
     * @constructor
     */
    get DatabaseCategories() {
        return this.databaseCategories;
    }

    /**
     * Get the items that are in the cart.
     * @constructor
     */
    get CartItems() {
        let cartItems = [];
        for (let i = 0; i < this.sceneObjects.length; i++) {
            cartItems.push(this.sceneObjects[i].details);
        }
        return cartItems;
    }

    /**
     * Start this manager.
     */
    start = () => {
        this.setupScaleContainer();
        this.loadStartingObjects();
        this.setupObjectControls();
    };

    /**
     * Recursive method that matches slug: {all properties} "
     */
    loadObjectDatabase = (models,database,categories) => {
        //start iterating the models list.
        for (let key in models) {
            //if (models.hasOwnProperty(key)) this.databaseCategories.push(key);
            if (key !== "Tiles") categories.push(key);
            if (models.hasOwnProperty(key)) {

                //depth 2... TODO recursive if > 2...
                for (let key2 in models[key]) {
                    if (isNaN(key2)) {
                        categories.push(key2);
                        for (let i = 0; i < models[key][key2].length; i++) {
                            let model = models[key][key2][i];
                            model.category = key2;
                            if (model.ignore) continue;
                            database.push(model);
                            this.objectDatabaseSearch[model.slug] = model;

                        }
                    }
                }
                for (let i = 0; i < models[key].length; i++) {
                    let model = models[key][i];
                    model.category = key;
                    if (model.ignore) continue;
                    database.push(model);
                    this.objectDatabaseSearch[model.slug] = model;
                }
            }
        }
    };

    /**
     * Get information from the object database about an item.
     * @param name The name {slug} to get the details of.
     * @returns {*} All the properties of the object.
     */
    getObjectInformation = (name) => {

        return this.objectDatabaseSearch[name];
    };

    /**
     * This needs to be a recursive function to iterate through properties of the list.
     * @param list
     */
    iterateThroughProperties = (list) => {

    };

    /**
     * Create the objectControls object and required setup.
     */
    setupObjectControls = () => {
        //this.objectControls = new ObjectController();
    };

    /**
     * Update every frame.
     */
    update = () => {
    };

    /**
     * Whenever an object is hovered, notify all managers.
     * @param object
     */
    notifyOnObjectHovered = (object) => {
    };

    /**
     * Whenever an object is selected, notify all managers.
     * @param object
     */
    notifyOnObjectClicked = (object) => {

        let shouldUpdate = true;

        if (object) {

            if (object.parent && object.parent.name === "arrow_left") {
                this.selectedObject.userData.container.rotate("left");
                this.objectControls.onRotated("left");
                shouldUpdate = false;
            } else if (object.parent && object.parent.name === "arrow_right") {
                this.selectedObject.userData.container.rotate("right");
                this.objectControls.onRotated("right");
                shouldUpdate = false;
            } else if (object.name === "delete") {
                //this.selectedObject.
                this.deleteSelectedObject(this.selectedObject);
                shouldUpdate = true;
            } else if (object.name === "plus") {
                //replicate
                shouldUpdate = false;
            } else if (object.name === "lock") {
                this.lockSelectedObject();
                shouldUpdate = false;
            }
        }
        if (shouldUpdate) this.onClickVisualIndicator(object);
        else this.onClickVisualIndicator(this.selectedObject);
    };

    /**
     * Notified that an object changed walls.
     * @param prevWall
     * @param newWall
     */
    selectedObjectChangedWall = (prevWall, newWall) => {
        this.objectControls.rotateButtonControls(newWall)
    };

    onWallWentInvisible = (wallName) => {
        this.objectControls.setFloorOrientation(wallName);
    };

    /**
     * Any objects the scene needs to start with load here.
     */
    loadStartingObjects = () => {
        //this.ObjectManager.loadNewObject("light.obj")
        //this.ObjectManager.loadNewObject("bed.obj");
        //this.ObjectManager.loadNewObject("window.obj");
        //this.loadNewObject("door_frame.obj");
        //this.ObjectManager.loadNewObject("mirror.obj");
        //this.ObjectManager.loadNewObject("washbasin.obj");
        //this.ObjectManager.loadNewObject("doccia.obj");
        //this.ObjectManager.loadNewObject("clark_round.obj");
        //this.loadNewObject("clark_prima.obj");
        //this.loadNewObject("caroma_aura.obj");
    };

    /**
     * Load a new object with the given name.
     * @param name The resource name to load.
     * @param objectMountedTo The object this scene object is mounted to?
     * @deprecated
     * For now walls.
     */
    loadNewObject = (name, spawnPoint, objectMountedTo) => {
        //let sceneObject = new SceneObject(name,spawnPoint,objectMountedTo);
        //this.sceneObjects.push(sceneObject);
        alert("Method loadNewObject removed!")
    };

    /**
     * Add object to the wallName.
     * @param object
     * @param wallName
     */
    addObjectToWall = (object, wallName) => {
        //Get wall object.
        let wall = SM.RoomManager.getWallByName(wallName);
        //Add object to wall.
        if (wall) {
            wall.addObject(object);
            wall.needsUpdate = true;
            wall.checkVisibility(SM.CameraManager.camera, true);
        }
    };

    /**
     * Get the wall and remove the object from it.
     * @param object
     * @param wallName
     */
    removeObjectFromWall(object, wallName) {
        //Get wall object.
        let wall = SM.RoomManager.getWallByName(wallName);
        //Add object to wall.
        if (wall) {
            wall.removeObject(object);
        }
    }

    /**
     * Remove a scene object from the list.
     * @param obj
     */
    removeSceneObject = (obj) => {
        for (let i = this.sceneObjects.length - 1; i >= 0; i--) {
            if (this.sceneObjects[i].object === obj) {
                this.sceneObjects.splice(i, 1);
            }
        }
    };

    /**
     * When the model of an object is loaded, push it to this internal list.
     * @param object
     */
    onSceneObjectUpdated = (object) => {
        //push the model of the object.
        this.objects.push(object);
        this.selectedObject = object;
        this.createSelectionVisual(object);
    };

    /**
     * Scene object moved
     * @param object The object that moved.
     */
    onSceneObjectMoved = (object) => {
        this.objectControls.setObject(object);
    };

    onSceneObjectMovedSetPosition = (object) => {
        this.objectControls.setObjectPosition(object);
    };

    /**
     * Setup the container that scales all the objects.
     */
    setupScaleContainer = () => {
        this.scaleContainer = new THREE.Object3D();
        const gridHelper = new THREE.GridHelper(10, 10, 0xffffff, 0xeeeeee);
        gridHelper.material.transparent = true;
        gridHelper.material.opacity = 0.6;
        gridHelper.visible = false;
        this.scaleContainer.add(gridHelper);
        SM.scene.add(this.scaleContainer);
    };

    /**
     * Toggle the visibility of all scene objects.
     * @param isVisible
     */
    toggleSceneObjectVisibility = (isVisible) => {
        SM.scene.traverse((element) => {
            if (element.userData.type === "sceneObject" || element.name.includes("cubicle")) {
                if (element.userData.details && this.objectsToIgnoreWhenZoomingToWall.includes(element.userData.details.type)) {
                    return;
                }
                element.visible = isVisible;
            }
        })
    };

    /**
     * Toggle wireframes for objects.
     * @param on
     */
    toggleWireframes = (on) => {
        for (var i = 0; i < this.sceneObjects.length; i++) {
            this.sceneObjects[i].toggleWireframe(on);
        }
    };

    /**
     * Delete the selected object.
     * @param obj
     */
    deleteSelectedObject = (obj) => {
        if (obj.userData.type === "tile") {
            SM.RoomManager.removeTile(obj);
        } else if (obj.userData.type === "sceneObject") {
            //Object specific removal.
            obj.userData.container.tearDown();
            //Remove from scene.
            SM.scene.remove(obj);
            //remove from list of objects.
            this.removeSceneObject(obj);
        } else {
            obj.userData.container.remove();
        }
        //SM.updateObjectSelectedForWebsite(null);
    };

    removeAllItems = () => {
        var allObjects = [];
        SM.scene.traverse((element) => {
            if (element.userData.type === "sceneObject") {
                allObjects.push(element);
            }
        });
        //Must do outside traversal as cannot modify list during iteration.
        for (var i = 0; i < allObjects.length; i++) {
            this.deleteSelectedObject(allObjects[i]);
        }
    };


    /**
     * Lock the selected object.
     */
    lockSelectedObject = () => {
        let obj = this.selectedObject;
        if (obj) {
            obj.userData.container.switchLockObject();
        }
    };

    changeButtonIcon = (locked) => {
        this.objectControls.buttonsTool.changeButtonIcon(locked);
    };

    /**
     * Spawn a visual indicator when an object is clicked.
     * @param object
     */
    onClickVisualIndicator = (object) => {


        if (this.ignoreVisualIndicatorForObject(object)) return;
        if (!object || object.userData.type !== "sceneObject") {
            this.selectedObject = null;
            this.removeSelectionVisual()
        } else {
            this.selectedObject = object;
            this.createSelectionVisual(object);
        }

    };

    ignoreVisualIndicatorForObject = (object) => {
        if (!object) return false;

        if (object.name.includes("cubicle")) {
            return true;
        }
    };

    createSelectionVisual = (obj) => {
        this.objectControls.setObject(obj);
    };

    removeSelectionVisual = () => {

        this.objectControls.setObject(null);
    };

    createInternalWall = (start, end, spawnPoint, wallDrawing) => {
        // let wall = new FeatureWall(start,end,spawnPoint,wallDrawing);
        //this.sceneObjects.push(wall);
        console.warn("Method removed.");
    };

    createTiles = (wall, start, end, spawnPoint) => {
        console.log("Creating a tile at " + spawnPoint);
        console.log(start);
        console.log(end);
        //let tile = new Tile(wall,start,end,spawnPoint);
        //this.sceneObjects.push(tile);
        //create tiles here.
    };

    createMirror = (wall, start, end, spawnPoint) => {
        //let mirror = new Mirror(wall,start,end,spawnPoint);
        //this.sceneObjects.push(mirror);
        alert("Method createMirror removed!");
        console.warn("Method createMirror removed!");
    };

    /**
     * Get the scene objects information to serialize.
     * @returns {[]}
     */
    getSerializableObjects = () => {
        var objects = [];

        for (var i = 0; i < this.sceneObjects.length; i++) {
            var sceneObj = this.sceneObjects[i];
            var obj = sceneObj.object;
            var details = sceneObj.details;
            details.position = sceneObj.position;

            objects.push({
                "uuid": obj.uuid,
                "slug": details.slug,
                "visible": obj.visible,
                "position": obj.position,
                "directionFacing": sceneObj.directionFacing,
                "wallMountedTo": sceneObj.wallMountedTo
            });
        }
        return objects;
    }
}