export const SCENE_OBJECT_CREATED = "object_created";
export const REGISTER = "register";
export const LOGIN = "login";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_FAILURE = "login_failure";
export const LOGOUT_SUCCESS = "logout_success";
export const REGISTER_FAILURE = "register_failure";
export const REGISTER_SUCCESS = "register_success";
export const CREATE_AREA = "create_area";
export const CREATE_AREA_ERROR = "create_area_error";
export const CREATE_CATEGORY = "create_category";
export const CREATE_CATEGORY_ERROR = "create_category_error";
export const CREATE_ITEM = "create_item";
export const CREATE_ITEM_ERROR = "create_item_error";
export const CREATE_PRESET = 'create_preset';
export const GET_PRESETS = 'get_presets';
export const GET_INDIVIDUAL_ITEMS = "get_individual_items";
export const GET_INDIVIDUAL_CATEGORIES = "get_individual_categories";
export const GET_INDIVIDUAL_AREAS = "get_individual_areas";
export const GET_ADVERTISERS = "get_advertisers";
export const GET_ADVERTISER_ITEMS = "get_advertiser_items";
export const GET_ADVERTISER_AREAS = "get_advertiser_areas";
export const GET_ADVERTISER_CATEGORIES = "get_avertiser_categories";
export const ACTION_RESULT_SUCCESS = "action_result_success";
export const ACTION_RESULT_ERROR = "action_result_error";
export const CLEAR_ALL_ACTION_RESULTS = "clear_all_action_results";
export const ACTION_LOADING = 'action_loading';
