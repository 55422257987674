import * as THREE from 'three';
import {removeFromArray} from '../../../utils/helpers/helperMethods';
import {SM} from '../../SceneManager';

import Manager from "./Manager";

export default class RoomManager extends Manager {

    constructor() {
        super();
        //Hold the feature walls.
        this.featureWalls = [];
        //Hold the selected paint colour.
        this.selectedPaintColour = null;
        //The texture loader.
        this.loader = new THREE.TextureLoader();
        //Create the click controls.
    }

    /**
     * Get a list of the paints on the walls.
     * @constructor
     */
    get Paints() {
        let paints = [];
        for (let i = 0; i < this.walls.length; i++) {
            paints.push(this.walls[i].paint);
        }
        return paints;
    }

    get Tiles() {
        let tiles = [];
        for (let i = 0; i < this.walls.length; i++) {
            let wall = this.walls[i];
            for (let j = 0; j < wall.tiles.length; j++) {
                let tile = wall.tiles[j];
                //tile.details.keyProperty = [`${tile.length} x ${tile.height} metres`];
                tiles.push(tile.details);
            }
        }

        tiles.push(this.floor.tile);

        return tiles;
    }

    /**
     * The walls of the room.
     * @returns {[]|Array}
     * @constructor
     */
    get Walls() {
        return this.walls;
    };

    get WallObjects() {
        let wallObjects = [];
        for (var i = 0; i < this.walls.length; i++) {
            wallObjects.push(this.walls[i].object)
        }
        wallObjects.push(this.floor.object);
        return wallObjects;
    }


    /**
     * Return the roof.
     * @returns {Roof}
     */
    get Roof() {
        return this.roof;
    }

    /**
     * Return the floor.
     * @returns {Floor}
     */
    get Floor() {
        return this.floor;
    }

    //#region Getters

    /**
     * Get the floor object of the room.
     * @returns {Mesh}
     * @constructor
     */
    get FloorObject() {
        if (!this.floor) return null;
        return this.floor.object;
    }

    /**
     * Get the roof object of the room.
     * @returns {Mesh}
     * @constructor
     */
    get RoofObject() {
        if (!this.roof) return null;
        return this.roof.object;
    }

    /**
     * All objects that extend structure.
     * @returns {Roof[]}
     */
    get AllStructures() {
        return this.Walls.concat([this.Roof, this.Floor]);
    };

    /**
     * This is a list of objects that are clickable by groupClickControls.
     * @returns {*[]} List of clickable items.
     */
    get ClickableObjects() {

        // TODO: fix how this works
        // Right now getting the tile and wall objects.
        var tileObjects = [];
        var wallObjects = [];
        for (let i = 0; i < this.walls.length; i++) {
            if (this.walls[i].tiles.length > 0) {
                for (let j = 0; j < this.walls[i].Tiles.length; j++) {
                    tileObjects.push(this.walls[i].Tiles[j].object);
                }
            }
            wallObjects.push(this.walls[i].object);
        }

        return [];

        /*
        return wallObjects
            .concat(tileObjects)
            .concat([this.FloorObject, this.RoofObject])
            .concat(this.featureWalls);

         */
    }

    start = () => {
    };

    update = () => {
    };

    wallClicked = () => {
        console.log("wall clicked");
        if (this.selectedPaintColour) {

        }
    };

    /**
     * Notify the RoomManager that an object as been clicked.
     * @param object The object that has been clicked.
     */
    notifyOnObjectClicked = (object) => {
        this.paintWall(object);
    };

    /**
     * Whenever an object is hovered, notify all managers.
     * @param object
     */
    notifyOnObjectHovered = (object) => {
    };

    /**
     * Get wall by it's name.
     * @param name
     * @returns {null|*}
     */
    getWallByName = (name) => {
        let walls = this.Walls;
        for (let i = 0; i < walls.length; i++) {
            if (walls[i].name === name) {
                return walls[i];
            }
        }
        return null;
    };

    updateRoomWalls = () => {
        for (let i = 0; i < this.walls.length; i++) {
            this.walls[i].object.matrixAutoUpdate = false;

            this.walls[i].object.updateMatrix()
        }

    };

    //#endregion

    //#region Paint

    setWallMaterial = material => {
        this.selectedWallMaterial = material;
        SM.GUIManager.notifyClientInfo(`Select a wall to set to ${material}.`);
    };

    /**
     * Select the colour to prime the painter.
     * @param colour String of the paint colour.
     */
    selectPaint = (colour) => {
        //Prime the paint colour.
        //Find the paint colour in DB.
        let paint = colour;
        if (paint) {
            this.selectedPaintColour = paint;
            //Notify the client to do something.
            SM.GUIManager.notifyClientInfo(`Select a wall to paint ${paint}.`);
        }
    };
    selectTile = (details,length,width) => {
        this.selectedTile = {details,length,width};
        SM.GUIManager.notifyClientInfo(`Select a wall to paint with the selected texture.`);
    };

    /**
     * Paint the selected wall.
     * @param object The wall object to paint.
     */
    paintWall = (object) => {
        //Ensure this is a wall.
        alert("Paint");
        if (!object || object.userData.type !== "wall") return;

        //If a colour has been selected, paint the wall.
        if (this.selectedPaintColour) {
            let wall = this.getWallByName(object.name);
            wall.selectPaint(this.selectedPaintColour);
            //Unselect the paint.
            this.selectedPaintColour = null;
        }
        if (this.selectedTile) {
            alert("Tiling wall");
        }
    };

    //#endregion

    //#region Removal

    removeFeatureWall = (wall) => {
        let toDelete = [];
        SM.scene.traverse((element) => {
            if (wall === element) {
                toDelete.push(element);
            }
        });
        for (let i = 0; i < toDelete.length; i++) {
            SM.scene.remove(toDelete[i]);
        }
        for (var i = 0; i < this.featureWalls.length; i++) {
            if (this.featureWalls[i] === wall) {
                removeFromArray(this.featureWalls, this.featureWalls[i]);
            }
        }
    };

    removeTile = (tile) => {
        tile.userData.container.remove();
    };

    //#endregion

    //#region Creation

    /**
     * Create the whole room and what is required.
     */
    createRoom = () => {
        this.createWall("wall1");
        this.createWall("wall2");
        this.createWall("wall3");
        this.createWall("wall4");
        this.createFloor();
        this.createRoof();
    };

    /**
     * Create the plane for the world.
     * What the building sits upon.
     */
    createWorldPlane = () => {
        // this.worldPlane = new WorldPlane();
    };

    /**
     * Create a floor.
     */
    createFloor = () => {
        //add floor
        //this.floor = new Floor();
    };

    /**
     * Create a roof.
     */
    createRoof = () => {
        //this.roof = new Roof();
    };


    /**
     * Create a new feature wall.
     * @param startpoint The vector2 startpoint, min and max of x.
     * @param endpoint The vector2 endpoint, min and max of y.
     */
    drawFeatureWall = (startpoint, endpoint) => {
        //Create the feature wall.
        //new FeatureWall(startpoint,endpoint);
    };

    /**
     * Add a feature wall to internal room lists.
     * @param wall
     */
    addWall = (wall) => {
        SM.scene.add(wall);
        wall.userData.type = "featureWall";
        this.featureWalls.push(wall);
    };

    /**
     * Refresh the room.
     */
    refreshRoom = () => {
        //this.removeRoom();
        //this.createRoom();
        this.AllStructures.forEach((entry) => {
            entry.rebuild();
        });

        SM.CameraManager.setCameraInMiddle();
    };

    //#endregion

    //#region Editing
    /**
     * Redraw the floor with the given material.
     * @param material To draw the floor with.
     */
    redrawFloor = (material) => {
        this.floor.changeMaterial(material);
    };

    /**
     * Redraw the wall.
     * @param name The name of the wall to rebuild.
     */
    redrawWall = (name) => {
        let wall = this.getWallByName(name);
        wall && wall.rebuild();
    };

    /**
     * Create a new wall object.
     * This should only be done once per wall.
     * @param name
     */
    createWall = (name) => {//let wall = new Wall(name);
        //this.walls.push(wall);
    };

    //#endregion

    /**
     * Tile the selected wall.
     * @param wallTiled The name of the wall that was just tiled.
     * @param start The x,y start.
     * @param end The x,y end.
     */
    tileWall = (wallTiled, start, end) => {
        let wall = this.getWallByName(wallTiled);
        wall.tileWall(start, end);
    };

}