import React, {Component} from 'react';
import LoginForm from "./LoginForm";
import {withRouter} from "react-router-dom";
import HomeHeader from "../../Navigation/HomeHeader";
import SiteFooter from "../../Navigation/SiteFooter";
import ParticlesPlayer from "../../../General/Effects/ParticlesPlayer";

class Login extends Component {
    render() {
        if (this.props.auth.isLoaded && !this.props.auth.isEmpty) {
            this.props.history.push(`/admin/items`);
        }
        return (
            <div>
                <ParticlesPlayer/>
                <HomeHeader {...this.props}/>
                <LoginForm {...this.props}/>
                <SiteFooter/>
            </div>
        );
    }
}

export default withRouter(Login);
