import {ACTION_LOADING, ACTION_RESULT_ERROR, ACTION_RESULT_SUCCESS, CLEAR_ALL_ACTION_RESULTS} from '../actions/types';

const INITIAL_STATE = {
    actionResultSuccessMessage: null,
    actionResultErrorMessage: null,
    isLoading: false
};

export default (state = INITIAL_STATE, actions) => {

    switch (actions.type) {
        case ACTION_LOADING:
            return {
                ...state,
                isLoading: actions.payload
            };
        case ACTION_RESULT_SUCCESS:
            return {
                ...state,
                actionResultSuccessMessage: actions.message
            };
        case ACTION_RESULT_ERROR:
            return {
                ...state,
                actionResultErrorMessage: actions.message
            };
        case CLEAR_ALL_ACTION_RESULTS:
            return {
                ...state,
                actionResultErrorMessage: null,
                actionResultSuccessMessage: null
            };
        default:
            return state;
    }
}
