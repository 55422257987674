import React, {Component} from 'react';
import AdvertiserItemList from "./AdvertiserItemList";
import AdvertiserList from "./AdvertiserList";

const defaultCategoryIcons = {
    "Showers": 'fas fa-shower',
    "Baths": 'fas fa-bath',
    "Windows": 'fas fa-th-large',
    "Curtains": 'fas fa-th-large',
    "Lighting": 'fas fa-lightbulb',
    "Fans": 'fas fa-wind',
    "Tables":'far fa-square',
    "Chairs":'fas fa-chair',
    "Doors": 'fas fa-door-open',
    "Mirrors": 'fas fa-square-full',
    'Toilets': 'fas fa-toilet',
    'Tapware': 'fas fa-water',
    "Wall Tiles": 'fas fa-border-all',
    "Floor Tiles": 'fas fa-border-all',
    "Paints": 'fas fa-paint-roller',
    "Accessories": 'fas fa-tags',
    "Wastes": 'fas fa-recycle',
    "Electrical": 'fas fa-bolt',
};
class Catalogue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: null
        }
    }
    renderAdvertiserList = () => {
        return <AdvertiserList height={this.props.height} mobile={this.props.mobile} selectAdvertiser={this.props.selectAdvertiser} advertisers={this.props.advertisers}/>
    };
    renderItemList = () => {

        return <AdvertiserItemList mobile={this.props.mobile}
                                   isOpen={this.props.isOpen}
                                   categories={this.getCategories()}
                                   areas={this.getAreas()}
                                   tab={this.state.tab}
                                   category={this.state.category}
                                   onTabChange={tab => this.setState({tab,category:null})}
                                   onCategoryChange={category=>this.setState({category})}
                                   testMode={this.props.testMode}
                                   items={this.getItems()}
                                   outsideLuminosity={this.props.outsideLuminosity}
                                   changeOutsideLuminosity={v => this.props.changeOutsideLuminosity(v)}
                                   luminosity={this.props.luminosity}
                                   lightColor={this.props.lightColor}
                                   changeLightColor={v => this.props.changeLightColor(v)}
                                   changeLuminosity={(v) => this.props.changeLuminosity(v)}/>
    };

    getCategories = () => {
        /*
            var {tab} = this.state;

            var dbCategories = [];
            if (tab === 'general') {
                dbCategories = SM.ObjectManager.databaseCategories;
            } else if (tab === 'kitchen') {
                dbCategories = SM.ObjectManager.kitchenCategories;
            } else if (tab === 'bathroom') {
                dbCategories = SM.ObjectManager.diningCategories;
            } else if (tab === 'bedroom') {
                dbCategories = SM.ObjectManager.bedroomCategories;
            } else if (tab === 'exterior') {
                dbCategories = SM.ObjectManager.exteriorCategories;
            }
            var categories = [];
            dbCategories.forEach(cat => {
                categories.push({name: cat, icon: this.nameToCategory(cat)})
            });

         */
        //var categories =
        return this.props.advertiserCategories
    }
    getAreas = () => {
        return this.props.advertiserAreas;
    }
    nameToCategory = category => {
        return defaultCategoryIcons[category];
    };
    getItems = () => {
        /*
      var {tab} = this.state;
      var items = [];
      if (this.props.isMastersuite) {
          var uploadedItems = this.props.uploadedMastersuiteItems;
          var uploadedItemsInArea = [];
          uploadedItems.forEach(item => {
              if (item.area.toLowerCase() === tab) {
                  uploadedItemsInArea.push(item);
              }
          });
          if (tab === 'general') {
              items = this.props.bathroomItems;
          } else if (tab === 'kitchen') {
              items = this.props.kitchenItems;
          } else if (tab === 'bathroom') {
              items = this.props.diningItems;
          } else if (tab === 'bedroom') {
              items = this.props.bedroomItems;
          } else if (tab === 'exterior') {
              items = this.props.exteriorItems;
          }
          return [...items,...uploadedItemsInArea];

      }

       */
        return this.props.advertiserItems;
    };
    render() {
        const {selectedAdvertiser, testMode} = this.props;
        return selectedAdvertiser || testMode ? this.renderItemList() : this.renderAdvertiserList()
    }
}

export default Catalogue;
