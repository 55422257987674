//import firebase from 'firebase';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';


export let config;
config = {
    apiKey: "AIzaSyDAflYilImUEYUk8vAWzeOtSrCspRQnP4Q",
    authDomain: "mastersuite-78d43.firebaseapp.com",
    databaseURL: "https://mastersuite-78d43.firebaseio.com",
    projectId: "mastersuite-78d43",
    storageBucket: "mastersuite-78d43.appspot.com",
    messagingSenderId: "1083319497067",
    appId: "1:1083319497067:Website:4e68653ab5f49b0aebdc2e",
    measurementId: "G-LBZ97YC29S"
};


const fire = firebase.initializeApp(config);
firebase.firestore().settings({});
export default fire;
