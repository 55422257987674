import React from 'react';
import PropTypes from 'prop-types';
import HomeHeader from "../../../Navigation/HomeHeader";

const Minimal = props => {
    const {children} = props;

    return (
        <div>
            <HomeHeader/>
            <main>{children}</main>
        </div>
    );
};

Minimal.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default Minimal;
