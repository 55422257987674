import {SM} from "../components/SceneManager";

/**
 * Listens to key events.
 * Keybindings for:
 * Copy and paste [x]
 * Delete [x]
 * Undo - Redo [ ]
 */
export default class KeyEvents {


    constructor() {
        this.copiedObject = null;
        this.shiftKeyDown = false;

        document.addEventListener('keydown', (event) => {
            this.keyDown(event)
        });
        document.addEventListener('keyup', (event) => {
            this.keyUp(event)
        });

        document.addEventListener('mousedown',event => {
            //this.makeDirty(event);
        })

        document.addEventListener('mouseup',event => {
            //this.makeClean(event);
        })

        document.addEventListener('scroll',event => {
            //console.log("scroll");
            //this.makeDirty(event);
        })
    }

    makeDirty = e => {
        SM._dirty = true;
    };

    makeClean = e => {
        SM._dirty = false;
    };
    //Do I need to teardown?
    tearDown = () => {

    };

    keyUp = e => {
        if (e.code === "ControlLeft") {
            this.shiftKeyDown = false;
            this.ctrlKeyDown = false;
        }
    };

    keyDown = e => {
        let selectedObject = SM.SelectedObject;
        if (e.code === "Delete") {
            if (selectedObject) {
                selectedObject.setUnselected();
                SM.onDeleteItem(selectedObject);
            }
        }
        if (this.ctrlKeyDown && e.code === 'KeyC') {
            if (selectedObject) {
                this.copiedObject = selectedObject;
            }
        }
        if (this.ctrlKeyDown && e.code === "KeyS") {
            SM.saveDesign();
            e.preventDefault()

        }
        if (this.ctrlKeyDown && e.code === 'KeyV') {
            let {copiedObject} = this;
            //spawn centre of room.
            if (copiedObject) {
                let positionToSpawn = copiedObject.position.clone();
                positionToSpawn.x += copiedObject.getWidth();
                SM.addItem(copiedObject.metadata,positionToSpawn,copiedObject.wallEdgeMountedTo);
            }
        }

        if (this.ctrlKeyDown && e.code === 'KeyZ') {
            //SM.undoHistory.undoPressed();
            SM._dirty = true;
        }
        if (this.ctrlKeyDown && e.code === 'KeyV') {
            //SM.undoHistory.redoPressed();
            SM._dirty = true;
        }
        if (e.code === 'ControlLeft') {
            this.ctrlKeyDown = true;
        }


    };


}
