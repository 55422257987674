import React, {Component} from 'react';
import {SM} from "../../../SceneManager";
import {Configuration, configWallHeight} from "../../../../modules/floorplanner/configuration";
import shadowIcon from '../../../../assets/images/settingsIcons/shadow.png'
import wireframeIcon from '../../../../assets/images/settingsIcons/wireframe.png'

import Collapsible from "react-collapsible";


const CheckBox = ({label,icon,onChange,checked,checkedName}) => {
    return (
        <div className={checked ? 'checkbox selected-gradient' : 'checkbox main-gradient'} onClick={() => onChange(checkedName)}>
            <p className={'btn-font-styling'} style={{padding: '0.5em',fontWeight:'bolder'}}>{label}</p>
        </div>
    );
};


class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedLighting: true,
            checkedShadows: true,
            checkedWireframes: false,
            checkedGrass: false,
            checkedExterior: false,
            graphicsOpen: true,
            roofHeight: Configuration.getNumericValue(configWallHeight)
        }
    }
    handleChange = (name) => {
        if (name === 'checkedLighting') {
            if (this.state.checkedLighting) {
                SM.toggleRoomLighting("full");
            } else {
                SM.toggleRoomLighting("room")
            }
        } else if (name === "checkedWireframes") {
            SM.switchWireframe();
        } else if (name === "checkedShadows") {
            SM.switchShadows();
        } else if (name === "checkedGrass") {
            SM.switchGrass();
        } else if (name ==='checkedExterior') {
            SM.switchExterior();
        }
        this.setState({ ...this.state, [name]: !this.state[name] });
    };
    changeCollapsible = (key,open) => {

        var val = open;
        var state = this.state;
        state[key] = val;

        this.setState(state);
    };
    render() {
        const {isOpen} = this.props;
        var {graphicsOpen} = this.state;

        if (!isOpen) return null;
        return (
            <div>
                <Collapsible trigger={'Graphics'} open={graphicsOpen}
                             onTriggerClosing={() => this.changeCollapsible('graphicsOpen',false)}
                             onTriggerOpening={() => this.changeCollapsible('graphicsOpen',true)}>
                    <CheckBox label={"Shadows"} checkedName={'checkedShadows'} icon={shadowIcon} checked={this.state.checkedShadows} onChange={this.handleChange}/>
                    <CheckBox label={"Wireframes"} checkedName={'checkedWireframes'} icon={wireframeIcon} checked={this.state.checkedWireframes} onChange={this.handleChange}/>
                    <CheckBox label={"Grid Floor"} checkedName={'checkedGrass'} icon={wireframeIcon} checked={this.state.checkedGrass} onChange={this.handleChange}/>

                </Collapsible>
                {
                    /*
                    <Collapsible trigger={'Mechanics'} open={CollapsibleOpen}>
                        {
                            //<CheckBox label={"Show Exterior"} checkedName={'checkedExterior'} icon={exteriorIcon} checked={state.checkedExterior} onChange={handleChange}/>
                        }
                        <CheckBox label={"Free Object Move"} checkedName={'checkedExterior'} icon={exteriorIcon} checked={this.state.checkedExterior} onChange={this.handleChange}/>
                    </Collapsible>

                     */
                }

            </div>

        );
    }
}
export default Settings;



