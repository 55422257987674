import {
    CREATE_AREA, CREATE_AREA_ERROR,
    CREATE_CATEGORY,
    CREATE_CATEGORY_ERROR,
    CREATE_ITEM,
    CREATE_ITEM_ERROR, CREATE_PRESET, GET_INDIVIDUAL_AREAS,
    GET_INDIVIDUAL_CATEGORIES,
    GET_INDIVIDUAL_ITEMS, GET_PRESETS
} from "./types";
import {addActionResultError, addActionResultSuccess, loadingState} from "./actionResultActions";

export const getPresets = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        //const uid = getState().firebase.auth.uid;

        dispatch(loadingState(true));
        firestore.collection('presets').where('businessName', '==', 'MasterSuite')
            .get().then(snapshot => {
            var docs = [];
            snapshot.docs.forEach(doc => {
                var data = doc.data();
                docs.push(data);
            });
            dispatch({type: GET_PRESETS, payload: docs});
            dispatch(loadingState(false));
        }).catch(err => {
            dispatch(loadingState(false));
        });

    }
};
export const createPreset = preset => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;
        var businessName = getState().firebase.profile.businessName;

        firestore.collection('presets').add({
            ...preset,
            uid: uid,
            businessName: businessName,
            dateCreated: Date.now()
        }).then(() => {
            dispatch({type: CREATE_PRESET, preset});
            dispatch(getPresets());
            dispatch(addActionResultSuccess("Successfully created preset!"));

        }).catch(err => {
            dispatch({type: CREATE_CATEGORY_ERROR, err})
        })
    }
};
export const deletePreset = (preset) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;
        console.log("deleting")
        //console.error("MAKE THIS A CLOUD FUNCTION! THIS IS SO UNSAFE BUT FAST FOR TESTING.");
        //console.error("ALSO ARE YOU GOING TO DELETE ALL ITEMS?");
        var query = firestore.collection('presets').where("uid", "==", uid);
        query.get().then(function (querySnapshot) {
            querySnapshot.forEach(doc => {
                if (doc.data().file === preset.file && doc.data().image === preset.image) {
                    doc.ref.delete().then(() => {
                        dispatch(addActionResultSuccess("Successfully deleted preset!"));
                        dispatch(getPresets());
                    }).catch(err => {
                        dispatch(addActionResultError("An error occurred, please try again."));
                    });
                }

            })
        });
    }
};
//Get all items for business
export const getItems = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;
        dispatch(loadingState(true));

        //See this could easily just query all documents within another document with uid securely.
        //Although may be another way as my method is a good way to store data flattened.
        //TODO: this will be replaced with more secure auth.
        firestore.collection('items').where('uid', '==', uid)
            .get()
            .then(snapshot => {
                var docs = [];
                snapshot.docs.forEach(doc => {
                    var data = doc.data();
                    docs.push(data);
                });
                dispatch({type: GET_INDIVIDUAL_ITEMS, payload: docs});
                dispatch(loadingState(false));
            }).catch(err => {
            dispatch(loadingState(false));

        });
    }
};
export const getAreas = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;

        dispatch(loadingState(true));
        firestore.collection('areas').where('uid', '==', uid)
            .get().then(snapshot => {
            var docs = [];
            snapshot.docs.forEach(doc => {
                var data = doc.data();
                docs.push(data);
            });
            dispatch({type: GET_INDIVIDUAL_AREAS, payload: docs});
            dispatch(loadingState(false));
        }).catch(err => {
            dispatch(loadingState(false));
        });

    }
};
export const getCategories = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;

        dispatch(loadingState(true));
        firestore.collection('categories').where('uid', '==', uid)
            .get().then(snapshot => {
            var docs = [];
            snapshot.docs.forEach(doc => {
                var data = doc.data();
                docs.push(data);
            });
            dispatch({type: GET_INDIVIDUAL_CATEGORIES, payload: docs});
            dispatch(loadingState(false));
        }).catch(err => {
            dispatch(loadingState(false));
        });

    }
};

export const editItem = (item) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;

        firestore.collection("items").where("uid", "==", uid)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    //console.log(doc.id, " => ", doc.data());
                    // Build doc ref from doc.id
                    if (doc.data().uid === item.uid && doc.data().name === item.prevName) {
                        firestore.collection("items").doc(doc.id).update({
                            ...item
                        }).then(() => {
                            dispatch(getItems());
                            dispatch(addActionResultSuccess("Successfully edited item!"));
                        }).catch(err => {
                            dispatch(addActionResultError("An error occurred, please try again."));
                        });
                    }

                });
            }).catch(err => {
            console.log(err);
            dispatch(addActionResultError("An error occurred, please try again."));
        })
    }
};

export const deleteArea = (area) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;
        //console.error("MAKE THIS A CLOUD FUNCTION! THIS IS SO UNSAFE BUT FAST FOR TESTING.");
        //console.error("ALSO ARE YOU GOING TO DELETE ALL ITEMS?");
        var query = firestore.collection('areas').where("uid", "==", uid);
        query.get().then(function (querySnapshot) {
            querySnapshot.forEach(doc => {
                if (doc.data().name === area.name) {
                    doc.ref.delete().then(() => {
                        dispatch(addActionResultSuccess("Successfully deleted area!"));
                        dispatch(getAreas());
                    }).catch(err => {
                        dispatch(addActionResultError("An error occurred, please try again."));
                    });
                }

            })
        });
    }
};
export const deleteCategory = (category,area) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;
        //console.error("MAKE THIS A CLOUD FUNCTION! THIS IS SO UNSAFE BUT FAST FOR TESTING.");
        //console.error("ALSO ARE YOU GOING TO DELETE ALL ITEMS?");
        var query = firestore.collection('categories').where("uid", "==", uid);
        query.get().then(function (querySnapshot) {
            querySnapshot.forEach(doc => {
                if (doc.data().name === category.name && doc.data().area === area.name) {
                    doc.ref.delete().then(() => {
                        dispatch(addActionResultSuccess("Successfully deleted category!"));
                        dispatch(getCategories());
                    }).catch(err => {
                        dispatch(addActionResultError("An error occurred, please try again."));
                    });
                }

            })
        });
    }
};

export const deleteItem = (item) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        var query = firestore.collection('items').where("uid", "==", item.uid);
        query.get().then(function (querySnapshot) {
            querySnapshot.forEach(doc => {
                if (doc.data().name === item.name) {
                    doc.ref.delete().then(() => {
                        dispatch(addActionResultSuccess("Successfully deleted item!"));
                        dispatch(getItems());
                    }).catch(err => {
                        dispatch(addActionResultError("An error occurred, please try again."));
                    });
                }

            })
        });
    }
};

export const createItem = (item) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;

        //console.error("THIS NEEDS TO BE A CLOUD FUNCTION!");

        if (item.area==='Lighting') {
            item.putLight=true;
        }
        if (!item.photoURL) {
            dispatch(addActionResultError("You must choose a catalogue image."));
            return;
        }
        if (!item.photoURL2D) {
            dispatch(addActionResultError("You must choose a 2D top down image."));
            return;
        }
        if (!item.public) {
            //dispatch(addActionResultError("You must choose whether this item is public."));
            //return;
        }
        item.public = true;
        delete item.avatar;
        delete item.progress;
        delete item.step;
        delete item.rotations;
        if (item.rotation) {
            item.rotation = {
                x: item.rotation.x,
                y: item.rotation.y,
                z: item.rotation.z
            };
        }

        //Probably unnecesary?
        item.businessName = getState().firebase.profile.businessName;
        item.avatar = getState().firebase.profile.avatar;

        //remove uneccesary properties.
        delete item.isUploading;
        delete item.isUploadingPhoto;
        delete item.progress;
        delete item.progressPhoto;

        firestore.collection('items').add({
            ...item,
            uid: uid
        }).then(() => {
            dispatch({type: CREATE_ITEM, item});
            dispatch(getItems());
            dispatch(addActionResultSuccess("Successfully added item!"));
        }).catch(err => {
            dispatch({type: CREATE_ITEM_ERROR, err})
        })
    }
};

export const createArea = area => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;
        var businessName = getState().firebase.profile.businessName;

        console.error("MAKE THIS A CLOUD FUNCTION!!");
        if (!area.name || area.name.trim() === '') {
            dispatch(addActionResultError("Please enter a valid area name."));
            return;
        }
        firestore.collection('areas').add({
            ...area,
            uid: uid,
            businessName: businessName,
            iconURL: area.iconURL
        }).then(() => {
            dispatch({type: CREATE_AREA, area});
            dispatch(getAreas());
            dispatch(addActionResultSuccess("Successfully created area!"));

        }).catch(err => {
            dispatch({type: CREATE_AREA_ERROR, err})
        })
    }
}
export const createCategory = (category) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;
        var businessName = getState().firebase.profile.businessName;

        console.error("MAKE THIS A CLOUD FUNCTION!!");
        if (!category.name || category.name.trim() === '') {
            dispatch(addActionResultError("Please enter a valid category name."));
            return;
        }
        firestore.collection('categories').add({
            ...category,
            uid: uid,
            businessName: businessName
        }).then(() => {
            dispatch({type: CREATE_CATEGORY, category});
            dispatch(getCategories());
            dispatch(addActionResultSuccess("Successfully created category!"));

        }).catch(err => {
            dispatch({type: CREATE_CATEGORY_ERROR, err})
        })
    }
};

