import {SCENE_OBJECT_CREATED} from '../actions/types';

const INITIAL_STATE = {
    objects: []
};

export default (state = INITIAL_STATE, actions) => {

    switch (actions.type) {
        case SCENE_OBJECT_CREATED:
            return {
                ...state,
                objects: actions.payload
            };
        default:
            return state;
    }
}