import React from 'react';
import SmallCard from "../../../../General/Card/SmallCard";
import BasicChart from "../../../../General/Charts/BasicChart";


const Dashboard = (props) => {
    console.log(props);
    return (
        <div className={'dashboard-side-margin'}>
            <div className={'dashboard-apps'}>
                <SmallCard title={"Items Uploaded"}/>
                <SmallCard title={"Unique Views"}/>
                <SmallCard title={"Total Cost"}/>
                <SmallCard title={"Total Item Views"}/>

            </div>
            <div>
                <BasicChart/>
            </div>
        </div>
    );
};

export default Dashboard;
