import {
    DoubleSide,
    EventDispatcher,
    ExtrudeGeometry,
    Face3,
    FrontSide,
    Geometry,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Path,
    RepeatWrapping,
    Shape,
    ShapeGeometry,
    TextureLoader,
    Vector2,
    Vector3,
} from 'three';
import {Utils} from '../utils.js';
import {EVENT_CAMERA_ACTIVE_STATUS, EVENT_CAMERA_MOVED, EVENT_REDRAW} from '../events.js';
import {GLASS_MATERIAL} from "../../../utils/constants/constantValues";
import {WallDrawTypes} from "../constants";
import {SM} from "../../../components/SceneManager";
import {Configuration, configWallHeight} from "../configuration";

export class Edge extends EventDispatcher {
    constructor(scene, edge, controls,isInnerRoom=false,isFront=true) {
        super();
        this.name = 'edge';
        this.scene = scene;
        this.edge = edge;
        this.controls = controls;
        this.isInnerRoom = isInnerRoom;

        this.wall = edge.wall;
        this.front = edge.front;
        this.isFront = isFront;
        this.wallMesh = null;
        this.planes = [];
        this.phantomPlanes = [];
        this.basePlanes = []; // always visible

        //Debug wall intersection planes. Edge.plane is the plane used for intersection
//		this.phantomPlanes.push(this.edge.plane);//Enable this line to see the wall planes

        this.texture = null

        this.lightMap = new TextureLoader().load( process.env.PUBLIC_URL + '/Assets/images/textures/red_brick.jpg');
        this.fillerColor = 0xff0000;
        this.sideColor = 0xcccccc;
        this.baseColor = 0xdddddd;
        this.visible = false;

        var scope = this;

        this.redrawevent = () => {
            scope.redraw();
        };
        this.visibilityevent = () => {
            scope.updateVisibility();
        };
        this.showallevent = () => {
            scope.showAll();
        };

        this.visibilityfactor = true;
        this.init();


    }

    remove() {
        this.edge.removeEventListener(EVENT_REDRAW, this.redrawevent);
        this.controls.removeEventListener(EVENT_CAMERA_MOVED, this.visibilityevent);
        this.controls.removeEventListener(EVENT_CAMERA_ACTIVE_STATUS, this.showallevent);
        this.removeFromScene();
    }

    init() {
        this.edge.addEventListener(EVENT_REDRAW, this.redrawevent);
        this.controls.addEventListener(EVENT_CAMERA_MOVED, this.visibilityevent);
        this.controls.addEventListener(EVENT_CAMERA_ACTIVE_STATUS, this.showallevent);

        this.updateTexture();
        this.updatePlanes();
        this.addToScene();
    }

    redraw() {

        this.removeFromScene();
        this.colour = this.edge.getColour();
        if (!this.colour) {
            this.colour = 0xffffff;
        }
        if (this.needsUpdate) {
            this.updateTexture();
        }
        this.updatePlanes();
        this.addToScene();
    }

    removeFromScene() {
        var scope = this;
        scope.planes.forEach((plane) => {
            scope.scene.remove(plane);
        });
        scope.basePlanes.forEach((plane) => {
            scope.scene.remove(plane);
        });
        scope.phantomPlanes.forEach((plane) => {
            scope.scene.remove(plane);
        });
        scope.planes = [];
        scope.basePlanes = [];
    }

    addToScene() {
        var scope = this;
        this.planes.forEach((plane) => {
            scope.scene.add(plane);
        });
        this.basePlanes.forEach((plane) => {
            scope.scene.add(plane);
        });
        this.phantomPlanes.forEach((plane) => {
            scope.scene.add(plane);
        });
        this.updateVisibility();
    }

    showAll() {
        var scope = this;
        scope.visible = true;
        scope.planes.forEach((plane) => {
            plane.material.transparent = !scope.visible;
            plane.material.opacity = 1.0;
            plane.visible = scope.visible;
        });

        this.wall.items.forEach((item) => {
            item.updateEdgeVisibility(scope.visible, scope.front);
        });
        this.wall.onItems.forEach((item) => {
            item.updateEdgeVisibility(scope.visible, scope.front);
        });
    }

    switchWireframe(flag) {
        var scope = this;
        scope.visible = true;
        scope.wireframes = flag;
        scope.planes.forEach((plane) => {
            plane.material.wireframe = flag;
        });
    }

    isGlass = () => {
        return this.wall.wallDrawType === WallDrawTypes.GLASS;
    };

    updateVisibility() {
        var scope = this;
        if (this.isGlass()) return;
        if (this.wall.orphan) return;


        // finds the normal from the specified edge
        var start = scope.edge.interiorStart();
        var end = scope.edge.interiorEnd();
        var x = end.x - start.x;
        var y = end.y - start.y;
        // rotate 90 degrees CCW
        var normal = new Vector3(-y, 0, x);
        normal.normalize();

        // setup camera: scope.controls.object refers to the camera of the scene
        var position = scope.controls.object.position.clone();
        var focus = new Vector3((start.x + end.x) / 2.0, 0, (start.y + end.y) / 2.0);
        var direction = position.sub(focus).normalize();

        /*
        var planeVector = (new THREE.Vector3( 0, 0, 1 )).applyQuaternion(planeMesh.quaternion);
        var cameraVector = (new THREE.Vector3( 0, 0, -1 )).applyQuaternion(camera.quaternion );

        if(planeVector.angleTo(cameraVector)>Math.PI/2) 'facing front'
        else 'facing back'
         */
        // find dot
        var dot = normal.dot(direction);
        // update visible
        scope.visible = (dot >= 0);
        // show or hide planes
        if (SM.ExteriorMode) {scope.visible=true}

        var opacity = (scope.visible) ? 0.8 : 0.001;

        if (this.isInnerRoom) opacity = 0.5;
        else opacity = 0;

        var transparent =  this.isInnerRoom || !scope.visible;
        this.canBeIntersected = scope.visible;

        var forceDepthWrite = false;

        if (SM.ExteriorMode || this.wall.isInvisible) {transparent=false}
        else {
            if (!this.isInnerRoom) {


                if (this.isFront) {
                   // console.log("Back panel is transparent: " + transparent);
                    if (this.edge.shouldBeInverse) {
                        transparent= dot<=0

                    } else {
                        transparent= dot>=0
                    }
                }
                else {
                    if (this.edge.shouldBeInverse) {
                        transparent= dot>=0

                    } else {
                        transparent= dot<=0

                    }

                }
        } else {
                //transparent = false;
                //THIS IS INNER WALL.
                forceDepthWrite=true;
            }
        }

        if (this.wall.orphan) transparent = false;

        //inner room items are always transparent
        if (!this.isInnerRoom) {
            scope.visible = !transparent;
        } else {
            scope.visible = true;
        }

        scope.planes.forEach((plane) => {
            plane.material.transparent = transparent
            plane.material.opacity = opacity;

            if (transparent) {
                plane.material.depthWrite = false;
            } else {
                plane.material.depthWrite = true;
            }

            if (forceDepthWrite) {
                plane.material.depthWrite = false;
            }
            if (plane.isRoof) {
                //plane.material.transparent = false;
            }
            //plane.visible = scope.visible;
        });
        scope.updateObjectVisibility();
    }
    //When placing item, if wall is on outside, spawn item there.
    isOuterWallEdge = () => {
        if (!this.isInnerRoom) {
            if (this.isFront) {
                // console.log("Back panel is transparent: " + transparent);
                return this.edge.shouldBeInverse;
            }
            else {
                return !this.edge.shouldBeInverse;

            }
        }
        return false;
    }

    updateObjectVisibility() {
        var scope = this;
        this.wall.items.forEach((item) => {
            item.updateEdgeVisibility(scope.visible, scope.front);
        });
        this.wall.onItems.forEach((item) => {
            item.updateEdgeVisibility(scope.visible, scope.front);
        });
    }

    updateTexture(callback) {
        var scope = this;
        // callback is fired when texture loads
        callback = callback || function () {
            scope.scene.needsUpdate = true;
            SM._dirty = true;

        };
        var textureData = this.edge.getTexture();

        if (!this.texture || this.texture !== textureData) {

            if (textureData) {
                var stretch = textureData.stretch;
                var url = textureData.url;
                var scale = textureData.scale;
                this.texture = SM.TextureLoader.load(url, callback);
                //console.log(textureData);
                //console.log("set height and width properly.");
                var tileLengthMetres = textureData.length;
                var tileWidthMetres = textureData.width;
                //let textureLength = (this.wall.height) / tileLengthMetres;
                //let textureWidth = (this.edge.interiorDistance()) / tileWidthMetres;
                this.texture.wrapT = RepeatWrapping;
                this.texture.wrapS = RepeatWrapping;
                this.texture.repeat.set(tileLengthMetres, tileWidthMetres);

                if (!stretch) {
                    var height = this.wall.height;
                    var width = this.edge.interiorDistance();
                    this.texture.wrapT = RepeatWrapping;
                    this.texture.wrapS = RepeatWrapping;
                    this.texture.repeat.set(width / scale, height / scale);
                    this.texture.needsUpdate = true;
                }
                this.texture.url = url;

            }
        }
        this.colour = this.edge.getColour();


    }

    updatesGlassPlanes = () => {

        var wallMaterial = GLASS_MATERIAL;

        if (this.edge.wall.start.getAttachedRooms().length < 2 || this.edge.wall.end.getAttachedRooms().length < 2) {
            //this.planes.push(this.makeWall(this.edge.exteriorStart(), this.edge.exteriorEnd(), this.edge.exteriorTransform, this.edge.invExteriorTransform, fillerMaterial));
        }
        // interior plane
        this.planes.push(this.makeWall(this.edge.interiorStart(), this.edge.interiorEnd(), this.edge.interiorTransform, this.edge.invInteriorTransform, wallMaterial));
        this.planes.push(this.buildFillerVaryingHeights(this.edge, DoubleSide, this.fillerColor));

    };
    updateInvisiblePlanes = () => {



    };
    updatePlanes() {
        if (this.wall.wallDrawType === WallDrawTypes.GLASS) {
            this.updatesGlassPlanes();
            return;
        } else if (this.wall.wallDrawType === WallDrawTypes.INVISIBLE) {
            this.updateInvisiblePlanes();
            return;
        }
//		var extStartCorner = this.wall.getClosestCorner(this.edge.exteriorStart());
//		var extEndCorner = this.wall.getClosestCorner(this.edge.exteriorEnd());
        this.fillerColor = this.colour;

        var extStartCorner = this.edge.getStart();
        var extEndCorner = this.edge.getEnd();

        if (extStartCorner == null || extEndCorner == null) {
            return;
        }

        var wallMaterial = new MeshPhongMaterial({
            color: this.colour,
            side: FrontSide,
            transparent: true,
            opacity: 1.0,
            wireframe: this.wireframes ? this.wireframes : false,
        });

        var fillerMaterial = new MeshPhongMaterial({
            color: 0xff0000,
            side: DoubleSide,
            transparent: true,
            opacity: 1.0,
            wireframe: this.wireframes ? this.wireframes : false,
        });

        if (this.texture) {
            wallMaterial.map = this.texture;
            fillerMaterial.map = this.texture;
        }

        this.matForSideEdges = wallMaterial;
        //wallMaterial = GLASS_MATERIAL;

        // exterior plane for real exterior walls
        //If the walls have corners that have more than one room attached
        //Then there is no need to construct an exterior wall

        //EXTERIOR PLANE!!!
       // if (this.edge.wall.start.getAttachedRooms().length < 2 || this.edge.wall.end.getAttachedRooms().length < 2) {
       // }

        //Make an interior walll.
        //interiorStart() ->
        //interiorEnd() ->
        //interiorTransform() ->
        //invInteriorTransforrm ->
        //var wall = this.wall;
        //var halfEdge = this.edge;
        var edge = this.edge;

        /*
        if ((wall.frontEdge && wall.frontEdge.shouldBeInverse) && (wall.backEdge && wall.backEdge.shouldBeInverse)) {
            if (this.isFront) {
                edge = wall.frontEdge;
            } else {
                edge = wall.backEdge;
            }
        }
         */
        this.wallMesh = this.makeWall(edge.interiorStart(), edge.interiorEnd(), edge.interiorTransform, edge.invInteriorTransform, wallMaterial);
        this.planes.push(this.wallMesh);

        //this.planes.push(this.makeWall(this.edge.exteriorStart(), this.edge.exteriorEnd(), this.edge.exteriorTransform, this.edge.invExteriorTransform, wallMaterial));

        if (this.isFront) {
//            this.planes.push(this.makeWall(this.edge.interiorStart(), this.edge.interiorEnd(), this.edge.interiorTransform, this.edge.invInteriorTransform, wallMaterial));
        } else {
//            this.planes.push(this.makeWall(this.edge.exteriorStart(), this.edge.exteriorEnd(), this.edge.exteriorTransform, this.edge.invExteriorTransform, fillerMaterial));
        }

        // INTERIOR PLANE!!!!


        this.baseColor = 0xffffff;
        this.sideColor = 0xffffff;
        //FILLER MATERIAL


       // if (this.edge.wall.start.getAttachedRooms().length < 2 || this.edge.wall.end.getAttachedRooms().length < 2) {
            //
      //  }
        //If you make both sides, overlap eachother.
        if (!this.isFront) {
            //Should be the colour of the roof.
            this.fillerColor = 0xffffff;
            //this.planes.push(this.buildFillerVaryingHeights(this.edge, DoubleSide, this.fillerColor,true));

            //THREE.DirectGeometry: Faceless geometries are not supported.
            //this.basePlanes.push(this.buildFillerUniformHeight(this.edge, 0, BackSide, this.baseColor));

        }

        // sides1
        //THIS IS THE FRAME OF THE WALL!.


        const buildSidePanels = () => {
            this.planes.push(this.buildSideFillter(this.edge.interiorStart(), this.edge.exteriorStart(), extStartCorner.elevation, this.sideColor));
            this.planes.push(this.buildSideFillter(this.edge.interiorEnd(), this.edge.exteriorEnd(), extEndCorner.elevation, this.sideColor));
            this.planes.push(this.buildSideFillter(this.edge.interiorStart(), this.edge.exteriorStart(), this.wall.startElevation, this.sideColor));
            this.planes.push(this.buildSideFillter(this.edge.interiorEnd(), this.edge.exteriorEnd(), extEndCorner.endElevation, this.sideColor));
        };


        this.sideColor = 0xff0000;
        buildSidePanels();

        if (!this.isFront && !this.edge.shouldBeInverse) {

            this.sideColor = this.colour;
            this.sideColor = 0xff0000;
            buildSidePanels();

        } else if (this.isFront && this.edge.shouldBeInverse) {
            this.sideColor = this.colour;
            //this.sideColor = 0xff0000;
            //buildSidePanels();
        }

    }

    // start, end have x and y attributes (i.e. corners)
    makeWall(start, end, transform, invTransform, material) {

        //
        var v1 = this.toVec3(start);
        var v2 = this.toVec3(end);
        var v3 = v2.clone();
        var v4 = v1.clone();

        v3.y = Configuration.getNumericValue(configWallHeight) -5
        v4.y = Configuration.getNumericValue(configWallHeight) -5

//		v3.y = this.wall.getClosestCorner(end).elevation;
//		v4.y = this.wall.getClosestCorner(start).elevation;

        var points = [v1.clone(), v2.clone(), v3.clone(), v4.clone()];

        points.forEach((p) => {
            p.applyMatrix4(transform);
        });

        var spoints = [new Vector2(points[0].x, points[0].y), new Vector2(points[1].x, points[1].y), new Vector2(points[2].x, points[2].y), new Vector2(points[3].x, points[3].y)];
        var shape = new Shape(spoints);

        // add holes for each wall item
        this.wall.items.forEach((item) => {
            var pos = item.position.clone();
            pos.applyMatrix4(transform);
            var halfSize = item.halfSize;
            var min = halfSize.clone().multiplyScalar(-1);
            var max = halfSize.clone();
            min.add(pos);
            max.add(pos);

            var holePoints = [new Vector2(min.x, min.y), new Vector2(max.x, min.y), new Vector2(max.x, max.y), new Vector2(min.x, max.y)];
            shape.holes.push(new Path(holePoints));
        });
        var extrusionSettings = {
            steps: 1,
            depth: 10,
            bevelEnabled: true,
            bevelThickness: 2,
            bevelSize: 5,
            bevelOffset: 1,
            bevelSegments: 1
        };

        var geometry;
        if (this.isInnerRoom) {
            geometry = new ExtrudeGeometry(shape, extrusionSettings);

        } else {
            geometry = new ExtrudeGeometry(shape, extrusionSettings);

        }
        geometry.vertices.forEach((v) => {
            v.applyMatrix4(invTransform);
        });

        // make UVs
        var totalDistance = Utils.distance(new Vector2(v1.x, v1.z), new Vector2(v2.x, v2.z));
        var height = this.wall.height;
        geometry.faceVertexUvs[0] = [];

        geometry.faces.forEach((face) => {
            var vertA = geometry.vertices[face.a];
            var vertB = geometry.vertices[face.b];
            var vertC = geometry.vertices[face.c];
            geometry.faceVertexUvs[0].push([vertexToUv(vertA), vertexToUv(vertB), vertexToUv(vertC)]);
        });

        geometry.faceVertexUvs[1] = geometry.faceVertexUvs[0];
        geometry.computeFaceNormals();
        geometry.computeVertexNormals();

        function vertexToUv(vertex) {
            var x = Utils.distance(new Vector2(v1.x, v1.z), new Vector2(vertex.x, vertex.z)) / totalDistance;
            var y = vertex.y / height;
            return new Vector2(x, y);
        }
        material.wireframe = SM.wireframeFlag;

        var mesh = new Mesh(geometry, material);

        if (this.isGlass()) {
            mesh.receiveShadow= false;
            mesh.castShadow = false;
        } else {
            mesh.receiveShadow= true;
            mesh.castShadow = true;
        }

        mesh.name = 'wall';
        return mesh;
    }

    buildSideFillter(p1, p2, height, color) {
        var points = [this.toVec3(p1), this.toVec3(p2), this.toVec3(p2, height), this.toVec3(p1, height)];

        var geometry = new Geometry();
        points.forEach((p) => {
            geometry.vertices.push(p);
        });
        geometry.faces.push(new Face3(0, 1, 2));
        geometry.faces.push(new Face3(0, 2, 3));


        var fillerMaterial = new MeshBasicMaterial({color: this.colour, side: DoubleSide});

        if (this.texture) {
            fillerMaterial.map = this.texture;
        }
        var filler = new Mesh(geometry, fillerMaterial);
        filler.castShadow = true;
        filler.opacity = 0.5;
        filler.receiveShadow = true;
        return filler;
    }

    buildFillerVaryingHeights(edge, side, color,isRoof=false) {
        var a = this.toVec3(edge.exteriorStart(), this.edge.getStart().elevation);
        var b = this.toVec3(edge.exteriorEnd(), this.edge.getEnd().elevation);
        var c = this.toVec3(edge.interiorEnd(), this.edge.getEnd().elevation);
        var d = this.toVec3(edge.interiorStart(), this.edge.getStart().elevation);

//		var a = this.toVec3(edge.exteriorStart(), this.wall.getClosestCorner(edge.exteriorStart()).elevation);
//		var b = this.toVec3(edge.exteriorEnd(), this.wall.getClosestCorner(edge.exteriorEnd()).elevation);
//		var c = this.toVec3(edge.interiorEnd(), this.wall.getClosestCorner(edge.interiorEnd()).elevation);
//		var d = this.toVec3(edge.interiorStart(), this.wall.getClosestCorner(edge.interiorStart()).elevation);


        var fillerMaterial = new MeshBasicMaterial({color: color, side: side});

        var geometry = new Geometry();
        geometry.vertices.push(a, b, c, d);
        geometry.faces.push(new Face3(0, 1, 2));
        geometry.faces.push(new Face3(0, 2, 3));

        var filler = new Mesh(geometry, fillerMaterial);
        filler.isRoof = isRoof;
        return filler;
    }

    buildFillerUniformHeight(edge, height, side, color) {
        var points = [this.toVec2(edge.exteriorStart()), this.toVec2(edge.exteriorEnd()), this.toVec2(edge.interiorEnd()), this.toVec2(edge.interiorStart())];

        var fillerMaterial = new MeshPhongMaterial({color: color, side: side});
        var shape = new Shape(points);
        var geometry = new ShapeGeometry(shape);
        var filler = new Mesh(geometry, fillerMaterial);
        filler.rotation.set(Math.PI / 2, 0, 0);
        filler.position.y = height;
        return filler;
    }

    toVec2(pos) {
        return new Vector2(pos.x, pos.y);
    }

    toVec3(pos, height) {
        height = height || 0;
        return new Vector3(pos.x, height, pos.y);
    }
}
