import React from 'react';
import Particles from "react-particles-js";
import Lottie from 'react-lottie'
import animationData from './notFound.json'
import SiteFooter from "../../Navigation/SiteFooter";


const NotFound = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    document.body.style.overflow = "hidden";
    return (
        <div className={'overlay'} style={{color: 'white'}}>
            <Particles
                params={{
                    "particles": {
                        "number": {
                            "value": 100
                        },
                        "size": {
                            "value": 2
                        }
                    },
                    "interactivity": {
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "repulse"
                            }
                        }
                    }
                }}
                style={{
                    width: '100%',
                    backgroundImage: "linear-gradient(to bottom right, black, red)",
                    position: 'fixed',
                    top: 0,
                    zIndex: 0
                }}
            />
            <div >
                <Lottie options={defaultOptions}
                        height={600}
                        width={600}
                />
            </div>

            <SiteFooter/>

        </div>

    );
};

export default NotFound;
