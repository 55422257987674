import React, {Component} from 'react';
import firebase from "../../../config/firebase";
import defaultPhoto from "../../../assets/images/defaultPhoto.png";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import LoadingSpinnerProgress from "../Loading/LoadingSpinnerProgress";

/**
 *
 */
class FileUploaderWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isUploadingPhoto: false,
            progressUpload: 0
        }
    }

    handleUploadStart = () => {
        this.setState({isUploadingPhoto: true, progressUpload: 0});
    };
    handleProgress = progressUpload => this.setState({progressUpload});
    handleUploadError = error => {
        this.setState({isUploadingPhoto: false});
        console.error(error);
    };
    handleUploadSuccess = filename => {
        if (this.props.returnImg) {
            this.setState({isUploadingPhoto: false});
            this.props.onFileUploaded(filename);
            return;
            //don't upload to firebase
        }
        firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL()
            .then(url => {
                this.props.onFileUploaded(url);
                this.setState({isUploadingPhoto: false});
            });
    };

    renderLoading = () => {
        if (this.state.isUploadingPhoto) {
            return (
                <div style={{margin:'1em'}}>
                    <LoadingSpinnerProgress progress={this.state.progressUpload}/>
                    <b>Uploading photo...</b>
                    <p>{`${this.state.progressUpload}%`}</p>
                </div>
            )
        }
        return null;
    }
    render() {
        let {textPrimary, textSecondary} = this.props;
        return (
            <div className={"obj-uploader"}>
                {this.renderImage()}

                <div className={'obj-uploader-details'}>
                    <b>{textPrimary}</b>
                    <i>{textSecondary}</i>
                    <div style={{position: 'relative'}}>
                        {this.renderLoading()}
                        <CustomUploadButton
                            accept="image/*"
                            name="avatar"
                            randomizeFilename
                            storageRef={firebase.storage().ref("images")}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                            className={'default-button'}
                        >Upload Photo</CustomUploadButton>
                    </div>
                </div>
            </div>
        );
    }
    renderImage = () => {
        let {photoURL} = this.props;

        return <img alt={'Uploaded'} className={'photo-frame'} src={photoURL ? photoURL : defaultPhoto}/>

    }
}

export default FileUploaderWrapper;
