import React, {Component} from 'react';
import CustomInput from "../../General/UserInputs/CustomInput";
import Tabs from "./Tabs/tabs";
import EditItemPanel from "./ItemInformation/EditItemPanel";
import Cart from "./Cart/Cart";
import ItemInformation from "./ItemInformation/ItemInformation";
import SuiteHome from "./Home/SuiteHome";
import Settings from "./Settings/settings";
import Catalogue from "./Catalogue/Catalogue";
import {connect} from "react-redux";
import {pullFile, pushFile} from "../../../redux/actions/fileActions";
import {
    getAdvertiserAreas,
    getAdvertiserCategories,
    getAdvertiserItems,
    getAdvertisers
} from "../../../redux/actions/suiteActions";
import {getAreas, getCategories, getItems, getPresets} from "../../../redux/actions/itemActions";
import firebase from "../../../config/firebase";

const defaultAdvertiser = {
    avatar: "https://firebasestorage.googleapis.com/v0/b/mastersuite-78d43.appspot.com/o/images%2F5e2db728-c8c4-41bb-a718-2bcf126da71a.png?alt=media&token=945bd53e-a8ca-4aae-84a7-43230bfa436d",
    businessName:'MasterSuite',
    public: true,
};
class Inspector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabOpen: null,
            selectedAdvertiser: defaultAdvertiser, //make null if want to select
            advertiserList: [],
            advertiserSearchText : "",
        }
    }
    componentDidMount() {

        if (this.props.testMode)
            this.syncBackendTest();
        else
            this.syncBackend();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (prevProps.advertisers !== this.props.advertisers) {
                this.setState({advertiserList:this.props.advertisers});
                //auto select msuite as default
                if (this.state.selectedAdvertiser.businessName === "MasterSuite") {
                    this.onSelectAdvertiser(defaultAdvertiser);
                }
            }
        }
    }
    syncBackend = () => {
        this.props.getAdvertisers();
        const {selectedAdvertiser} = this.state;

        //TODO: if default advertiser
        if (selectedAdvertiser) {
            this.props.getAdvertiserItems(selectedAdvertiser);
            this.props.getAdvertiserCategories(selectedAdvertiser);
            this.props.getAdvertiserAreas(selectedAdvertiser);
        }
        this.props.getPresets();

    };
    syncBackendTest = () => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.props.getItems();
                this.props.getCategories();
                this.props.getAreas();
            }
        });
    };
    render() {
        //console.error(this.props.isLoading);
        return this.renderSidePanelContainer();
    }

    renderSidePanelContainer = () => {
        const gameContainer = document.getElementById("webgl-container");
        const {testMode} = this.props;

        if (gameContainer) {
                return (
                    <div>
                        <div className={'panel-container'}
                             style={this.renderTestSidePanel()} >
                            {(!testMode) && this.renderTabs()}

                            <div id={"side-panel"}>
                                {this.renderSidePanelInnerContent()}
                            </div>
                        </div>
                    </div>

                )

        }

        //If no game container.
        return null;
    };
    renderSidePanelInnerContent = () => {
        let shouldBeClosed = false;
        if (!this.props.testMode || this.props.viewerMode || !this.props.selectedObject) shouldBeClosed = true;
        if (shouldBeClosed) {
            return (
                <div className={'side-panel-inner'}>
                    {this.renderSidePanel()}
                </div>
            )
        } else {
            return (
                <div>
                    <div id={"side-panel-title"}>
                        <h2>Edit Item</h2>
                        <div style={{borderBottom: "2px inset darkgray"}}/>
                    </div>
                    {this.renderEditItem()}
                </div>
            )
        }
    };
    renderTestSidePanel = () => {
        if (!this.props.testMode) return {}

        var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if (width >= 1280 && !this.state.moveSideBar) {
            return {
                marginTop: '4em'
            }
        } else {
            return {};
        }
    };
    renderSidePanelTitle = () => {

        //if (this.props.mobile) return null;
        return (
            <div id={"side-panel-title"}>
                {(this.state.selectedAdvertiser) && <h2 className={'tab-open'}>{this.state.tabOpen}</h2>}
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {this.renderCatalogueTitle()}
                </div>
                <div style={{borderBottom: "2px inset darkgray"}}/>
                {(this.catalogueAdvertisersOpen()) &&
                <div>
                    <CustomInput label={"Search Catalogue"} value={this.state.advertiserSearchText} onChange={this.onSearchAdvertiser}/>
                </div>}
            </div>
        )
    };
    renderCatalogueTitle = () => {

        //Ignore unique catalogue title for now.
        return null;

        /*
        return (
            this.catalogueIsOpen() &&
            <div className={'advertiser-title-panel'}>
                <img alt={'Go Back'} src={goBack} className={'go-back-img'} onClick={() => this.onSelectAdvertiser(null)}/>
                <img alt='advertiser logo' src={this.state.selectedAdvertiser.avatar} className={'advertiser-logo'}/>
            </div>
        )
         */

    }
    onSearchAdvertiser = (text) => {
        var filteredList = [];
        this.props.advertisers.forEach(advertiser => {
            if (advertiser.businessName.toLowerCase().includes(text.toLowerCase())) {
                filteredList.push(advertiser);
            }
        });
        this.setState({advertiserList:filteredList,advertiserSearchText:text})

    };


    renderTabs = () => {
        return <Tabs onClick={this.changeTab} tabOpen={this.state.tabOpen} mobile={false}/>
    };

    changeTab = (type) => {

        if (["birdsView", "frontView"].includes(type)) {

        } else {
            if (this.state.tabOpen === type) {
                this.setState({tabOpen: null})
            } else this.setState({tabOpen: type})


        }
    };
    /**
     * The edit item panel that appears when clicking an item.
     */
    renderEditItem = () => {

        var actualItem = null;
        this.props.items.forEach(item => {
            if (item.name === this.props.selectedObject.details.name) {
                actualItem = item;
            }
        });
        return (
            <div className={'advertiser-item-list'} style={{height: this.props.userHeight - 100}}>
                <EditItemPanel categories={this.props.categories}
                               item={actualItem}
                               areas={this.props.areas}
                               inSceneItem={this.props.selectedObject}/>
            </div>

        )
    };
    catalogueIsOpen = () => {
        return this.state.tabOpen === "Catalogue" && this.state.selectedAdvertiser;
    };
    catalogueAdvertisersOpen = () => {
        return this.state.tabOpen === "Catalogue" && !this.state.selectedAdvertiser;
    };
    onSelectAdvertiser = advertiser => {
        if (advertiser) {
            this.props.getAdvertiserItems(advertiser.businessName);
            this.props.getAdvertiserCategories(advertiser.businessName);
            this.props.getAdvertiserAreas(advertiser.businessName);
        }
        this.setState({selectedAdvertiser: advertiser})
    };
    /**
     * Get all the purchaseable items.
     * tiles - paints - objects
     * @constructor
     */
    get CartItems() {
        var items_arr = [];
        var objects = this.props.model.scene.getItems();
        for (var i = 0; i < objects.length; i++) {
            var obj = objects[i];
            items_arr[i] = obj.getMetaData();
        }
        return items_arr;
    };
    renderSidePanel = () => {
        const isTabOpen = (tab) => {
            return (tab === this.state.tabOpen);
        };
        return (
            <div>
                <Cart isOpen={isTabOpen('Cart')} sections={this.props.objectManager.DatabaseCategories} items={this.CartItems}/>
                <ItemInformation isOpen={isTabOpen('Information')} item={this.props.selectedObject}/>
                <SuiteHome presets={this.props.presets} isOpen={isTabOpen('Home')} mobile={this.props.mobile} />
                <Settings isOpen={isTabOpen('Settings')}/>
                { (this.props.testMode) ?
                 <Catalogue
                     isOpen={true}
                     selectedAdvertiser={this.state.selectedAdvertiser}
                selectAdvertiser={this.onSelectAdvertiser}
                advertiserItems={this.props.items}
                testMode={this.props.testMode}
                advertiserCategories={this.props.categories}
                advertiserAreas={this.props.areas}
                advertisers={this.props.advertisers}
                     luminosity={this.state.luminosity}
                     lightColor={this.state.lightColor}
                     outsideLuminosity={this.state.outsideLuminosity}
                     changeOutsideLuminosity={v => this.setState({outsideLuminosity:v})}
                     changeLightColor={v => this.setState({lightColor:v})}
                     changeLuminosity={v => this.setState({luminosity:v})}/>
                : <Catalogue
                isOpen={isTabOpen('Catalogue')}
                height={this.props.userHeight}
                mobile={this.props.mobile}
                selectedAdvertiser={this.state.selectedAdvertiser}
                selectAdvertiser={this.onSelectAdvertiser}
                advertiserItems={this.props.advertiserItems}
                advertiserAreas={this.props.advertiserAreas}
                advertiserCategories={this.props.advertiserCategories}
                advertisers={this.state.advertiserList}
                luminosity={this.state.luminosity}
                lightColor={this.state.lightColor}
                outsideLuminosity={this.state.outsideLuminosity}
                changeOutsideLuminosity={v => this.setState({outsideLuminosity:v})}
                changeLightColor={v => this.setState({lightColor:v})}
                changeLuminosity={v => this.setState({luminosity:v})}/>
            }
            </div>
        )



    };
}

const mapStateToProps = state => ({
    files: state.files,
    advertisers: state.suite.advertisers,
    advertiserItems: state.suite.advertiserItems,
    advertiserCategories: state.suite.advertiserCategories,
    advertiserAreas: state.suite.advertiserAreas,
    items: state.items.individualItems,
    categories: state.items.individualCategories,
    areas: state.items.individualAreas,
    presets: state.items.presets
});

export default connect(mapStateToProps, {
    pullFile,
    pushFile,
    getAdvertisers,
    getAdvertiserItems,
    getAdvertiserAreas,
    getAdvertiserCategories,
    getItems,
    getCategories,
    getAreas,
    getPresets
})(Inspector);
