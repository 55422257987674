import React from 'react';

const CardHeader = ({title,subtitle}) => {
  return (
      <div className={'card-header'}>
          <p>{title}</p>
          <p>{subtitle}</p>
      </div>
  )
};


const CardBody = ({children}) => {
    return (
        <div className={'card-body'}>
            {children}
        </div>
    )
}

const CardFooter = () => {
    return (
        <div className={'card-footer'}>
            <button className={'card-button'}>Update Profile</button>
        </div>
    )
}

const Card = ({children}) => {
    return (
        <div className={'card-main'}>
            <CardHeader title={"Edit Profile"} subtitle={"Complete your profile"}/>
            <CardBody children={children}/>
            <CardFooter/>
        </div>
    );
};

export default Card;