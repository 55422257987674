import {WallItem} from './wall_item.js';
import {Dimensioning} from "../dimensioning";
import {Vector2} from "three";
/// <reference path="../../lib/three.d.ts" />
/// <reference path="../model/model.ts" />
/// <reference path="wall_item.ts" />
/// <reference path="metadata.ts" />
/** */
export class WallFloorItem extends WallItem {
    constructor(model, metadata, geometry, material, position, rotation, scale, isgltf = false) {
        super(model, metadata, geometry, material, position, rotation, scale, isgltf);
        this.boundToFloor = true;
    }

    drawMeasurements = (floorPlannerView, ctx, x, y, width, height, rotation) => {
        ctx.save();
        //WIDTH!
        ctx.moveTo(0, 0);
        ctx.translate(x, y);
        ctx.rotate(-rotation);
        ctx.translate(-width / 2, -height / 2);
        ctx.strokeStyle = "#000";
        var cm = Dimensioning.pixelToCm(width);
        var m = Dimensioning.cmToMeasureRaw(cm);
        ctx.strokeRect(0, height + 20, width, 1);
        floorPlannerView.drawTextLabel(m + "m", width / 2, height + 20);
        floorPlannerView.drawArrowhead(ctx, new Vector2(width - 20, height + 20), new Vector2(width, height + 20), 10);
        floorPlannerView.drawArrowhead(ctx, new Vector2(10, height + 20), new Vector2(0, height + 20), 10);


        //HEIGHT!
        cm = Dimensioning.pixelToCm(height);
        m = Dimensioning.cmToMeasureRaw(cm);
        ctx.strokeStyle = "#000";
        ctx.strokeRect(width + 20, 0, 1, height);
        floorPlannerView.drawTextLabel(m + "m", width + 20, height / 2);
        floorPlannerView.drawArrowhead(ctx, new Vector2(width + 20, height), new Vector2(width + 20, height + 2), 10);
        floorPlannerView.drawArrowhead(ctx, new Vector2(width + 20, 0), new Vector2(width + 20, -2), 10);


        ctx.restore();
    };

}
