import {EventDispatcher} from "three";

export default class Manager extends EventDispatcher {

    /**
     * When a manager starts.
     */
    start = () => console.error("Override start()");

    /**
     * All managers are updated every frame.
     */
    update = () => console.error("Override update()");

    /**
     * Whenever an object is selected, notify all managers.
     * @param object
     */
    notifyOnObjectClicked = (object) => console.error("Override notifyOnObjectClicked()");

    /**
     * Whenever an object is hovered, notify all managers.
     * @param object
     */
    notifyOnObjectHovered = (object) => console.error("Override notifyOnObjectHovered()");


}