import React, {Component} from 'react';
import SceneManager from "./SceneManager";

class SceneManagerWrapperMobile extends Component {
    render() {
        window.scrollTo(0,1)
        document.body.style.overflow = "hidden";
        document.getElementById('root').style.overflow = 'hidden';
        return (
            <div>
                <SceneManager mobile={true}/>
            </div>
        );
    }
}

export default SceneManagerWrapperMobile;
