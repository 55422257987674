import React, {Component} from 'react';
import IconPicker from 'react-icon-picker';
import {connect} from "react-redux";
import {createCategory} from '../../../../../redux/actions/itemActions';
import {addActionResultError} from "../../../../../redux/actions/actionResultActions";
import CustomInput from "../../../../General/UserInputs/CustomInput";
import FileUploaderWrapper from "../../../../General/Files/FileUploaderWrapper";

const icons = [
    'fas fa-box',
    'fas fa-toilet',
    'fas fa-toilet-paper',
    'fas fa-couch',
    'fas fa-shower',
    'fas fa-bath',
    'fas fa-chair',
    'fas fa-bed',
    'fas fa-door-open',
    'fas fa-home',
    'fas fa-laptop',
    'fas fa-tv',
    'fas fa-plug',
];

/*
const iconNames = [
    'Box',
    'Toilets',
    'Accessories',
    'Showers',
    'Baths',
    'Chairs'
];
 */

class CreateCategoryPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            icon: icons[0],
            iconNames: icons[0],
            name: '',
        }
    }

    onChangeInput = (e) => {
        this.setState({
            name: e.target.value
        })
    };

    toggleOff = () => {
        this.props.toggleWindow(false);
        this.setState({name:'',iconURL:null})
    };

    onCreate = () => {
        const {categories} = this.props;
        //front end validation.
        var nameExists = false;
        categories.forEach(cat => {
            if (cat.name === this.state.name && cat.area === this.props.area) {
                this.props.addActionResultError("Name already exists.");
                nameExists = true;
            }
        });
        if (nameExists) return;
        var state = this.state;
        state.area = this.props.area;
        this.props.createCategory(state);
        this.toggleOff();
    };

    renderCancelButton = () => {
        return <button className={'msuite-btn-general'} onClick={() => this.toggleOff()}>Cancel</button>
    };
    renderCreateButton = () => {
        return <button className={'msuite-btn-general'} onClick={() => this.onCreate()}>Create</button>
    };

    renderNameInput = () => {

        return (
            <CustomInput label={'Category Name'} value={this.state.name} onChange={val => this.setState({name: val})}/>
        )
    };
    renderIconPicker = () => {
        return (
            <div style={{width: '100%'}}>
                <IconPicker
                    icons={icons}
                    defaultValue="fas fa-camera"
                    onChange={(icon) => {
                        this.setState({
                            ...this.state,
                            icon,
                        })
                    }}
                />
            </div>

        )
    };

    renderImageUpload = () => {
        return (
            <div style={{width: '100%'}}>
                <FileUploaderWrapper photoURL={this.state.iconURL}
                                     onFileUploaded={(url) => this.setState({iconURL: url})}/>
            </div>
        )
    }
    render() {
        if (!this.props.isOpen) return null;
        return (

            <div className={"shadow-screen"}>
                <div className={"category-inner"}>
                    <div className={"bottom-border"}>
                        <h3>Create Category</h3>
                    </div>
                    <div className={"create-category"}>
                        <div className={'straight-row'}>
                            {this.renderNameInput()}
                            {this.renderImageUpload()}
                        </div>
                        <div className={'straight-row2'}>
                            {this.renderCreateButton()}
                            {this.renderCancelButton()}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {}
};

export default connect(mapStateToProps, {createCategory, addActionResultError})
(CreateCategoryPopup);
