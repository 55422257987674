import React from 'react';
import {renderFade} from "../../../utils/fx/animations";
import {PrettoSlider} from "../../General/UserInputs/PrettoSlider";

const Side2DPopupMeasurements = ({wall, corner, room, currentVal, editCurrentValue, resizeWall, renameRoom, changeWallMaterial, wallDrawType, mobile, testsuite}) => {


    function renderInnerContent() {
        if (wall) {
            return (
                <>
                    <p className={'title-clean'}>Wall Controls</p>
                    <div>
                        <p className={'subtitle-clean'}>Wall Type</p>
                        <div style={{margin: "1.5em"}}>
                            <select className={"form-control"} value={wallDrawType} onChange={onChangeMaterial}>
                                <option>Wood</option>
                                <option>Invisible</option>
                            </select>
                        </div>
                    </div>
                    <div style={{margin: '0em'}}>
                        <p className={'subtitle-clean'}>Length (cm)</p>
                        <PrettoSlider
                            value={currentVal && !isNaN(currentVal) ? currentVal : 0}
                            onChange={(e, v) => editCurrentValue(v)}
                            aria-labelledby="discrete-slider-always"
                            min={100}
                            max={1000}
                        />
                        {
                            // valueLabelDisplay="on"
                        }
                        <br/>
                        <input className={'input-small'} value={currentVal && !isNaN(currentVal) ? currentVal : 0} onChange={(e) => editCurrentValue(e.target.value)}/>

                    </div>
                    <br/>
                    {

                    }

                </>
            )
        }
        if (corner) {
            return (
                <>
                    <p className={'title-clean'}>Corner</p>
                    <div>
                        <label>x Point: </label>
                        <input onChange={onChangeCurrentValue} value={currentVal}/>
                    </div>
                    <div>
                        <label>y Point: </label>
                        <input onChange={onChangeCurrentValue} value={currentVal}/>
                    </div>
                    <button className={"btn default-button"}>Move</button>

                </>
            )
        }
        if (room) {
            return (
                <>
                    <p className={'title-clean'}>Room Controls</p>
                    <div>
                        <p className={'subtitle-clean'}>Room Name</p>
                        <input className={'input-small'} value={currentVal ? currentVal : 0} onChange={val => changeCurrentValue(val.target.value)}/>
                    </div>
                    {
                        /*
          <div>
              <button className={'category-tab main-gradient'}>
                  Toggle Roof
              </button>
          </div>
           */
                    }

                </>
            )
        }
    }
    if (mobile) return null;
    return (
        <div id={"wall-measurements"} className={"bg-normal " + renderFade((room || corner || wall),true)} style={testsuite ? {left:'300px'}:{}}>
            {renderInnerContent()}
        </div>
    );


    function changeCurrentValue(val) {
        editCurrentValue(val);
        renameRoom(val)
    }
    function onChangeCurrentValue({currentTarget: input}) {
        editCurrentValue(input.value);
        renameRoom(input.value);
    }

    function onChangeMaterial({currentTarget: input}) {
        changeWallMaterial(input.value);
    }

};

export default Side2DPopupMeasurements;
