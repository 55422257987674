import {WallItem} from "./wall_item";

export class Shower extends WallItem {

    constructor(model, metadata, geometry, material, position, rotation, scale, isgltf = false) {
        super(model, metadata, geometry, material, position, rotation, scale, isgltf);
        this.constructShowerCubicle();
    }

    constructShowerCubicle = () => {

        /*
        console.log("Automatic shower wall construction under here.");
        return;

        var material = new THREE.MeshPhysicalMaterial( { side:THREE.DoubleSide,color: 0xffffff,reflectivity:0.8,metalness:0.4,transparent:true,opacity:0.4} );
        let shape = new THREE.Shape();

        //How long is the wall.
        let xMin = 0;
        //DEFAULT WALL LENGTH? This will change.
        let wallLength = 100;
        shape.moveTo(xMin, 0);
        shape.lineTo(xMin, 200);
        shape.lineTo(wallLength+10, 200);
        shape.lineTo(wallLength+10, 0);
        const geometry = new THREE.ShapeGeometry(shape);
        geometry.computeBoundingBox();

        let wallMesh = new THREE.Mesh(geometry, material);
        SM.scene.add(wallMesh);
        wallMesh.needsUpdate = true;

        let wallMesh2 = new THREE.Mesh(geometry, material);
        SM.scene.add(wallMesh2);
        wallMesh2.needsUpdate = true;

        var wallObjectIsOn = this.closestWallEdge();
        //Get the next adjacent wall, ignore the closest wall.
        var closestWallEdge2 = this.secondClosestWallEdge(wallObjectIsOn);

        wallMesh.position.copy(this.position);
        wallMesh.position.set(wallMesh.position.x,0,wallMesh.position.z);
        wallMesh2.position.copy(closestWallEdge2.center);
        wallMesh2.position.set(wallMesh2.position.x,0,wallMesh2.position.z);

        //Set rotation to be like the wall.
        var refVec = new Vector2(0, 1.0);
        var normal2 = new Vector2();
        var normal3 = wallObjectIsOn.plane.geometry.faces[0].normal;
        normal2.x = normal3.x;
        normal2.y = normal3.z;
        var angle1 = Utils.angle( new Vector2(refVec.x, refVec.y), new Vector2(normal2.x, normal2.y));
        wallMesh.rotation.y = angle1;

        refVec = new Vector2(0, 1.0);
        normal2 = new Vector2();
        normal3 = closestWallEdge2.plane.geometry.faces[0].normal;
        normal2.x = normal3.x;
        normal2.y = normal3.z;
        var angle2 = Utils.angle( new Vector2(refVec.x, refVec.y), new Vector2(normal2.x, normal2.y));
        wallMesh2.rotation.y = angle2;

        //move it forward by 1metre.
        //wallMesh.translateZ(100);

        var center = closestWallEdge2.center;
        var mat = new MeshBasicMaterial( {color: 0xff0000} );
        var geo = new SphereGeometry( 16, 32, 32 );
        var sphere = new Mesh( geo, mat );
        sphere.position.copy(center);
        this.sphere = sphere;
        SM.scene.add( this.sphere );

        var center = wallObjectIsOn.center;
        var mat = new MeshBasicMaterial( {color: 0x00ff00} );
        var geo = new SphereGeometry( 16, 32, 32 );
        var sphere2 = new Mesh( geo, mat );
        sphere2.position.copy(center);
        this.sphere2 = sphere;
        SM.scene.add( this.sphere2 );

        //Find rotation to this wall.
        var refVec = new Vector2(0, 1.0);
        // find angle between wall normals
        var normal2 = new Vector2();
        var normal3 = closestWallEdge2.plane.geometry.faces[0].normal;
        normal2.x = normal3.x;
        normal2.y = normal3.z;
        var angle = Utils.angle( new Vector2(refVec.x, refVec.y), new Vector2(normal2.x, normal2.y));
        sphere.rotation.y = angle;

        //Find rotation to this wall.
        var refVec = new Vector2(0, 1.0);
        // find angle between wall normals
        var normal2 = new Vector2();
        var normal3 = wallObjectIsOn.plane.geometry.faces[0].normal;
        normal2.x = normal3.x;
        normal2.y = normal3.z;
        var angle = Utils.angle( new Vector2(refVec.x, refVec.y), new Vector2(normal2.x, normal2.y));
        sphere2.rotation.y = angle;


        wallMesh.rotation.copy(sphere.rotation);
        wallMesh.position.copy(sphere.position);
        wallMesh.rotation.y = angle1;

        wallMesh2.rotation.copy(sphere2.rotation);
        wallMesh2.position.copy(sphere2.position);
        wallMesh2.rotation.y = angle2;

        wallMesh.position.set(wallMesh.position.x,0,wallMesh.position.z);
        wallMesh2.position.set(wallMesh2.position.x,0,wallMesh2.position.z);


        //wallMesh.position.set(wallMesh.position.x,0,closestWallEdge2.center.z);
        //wallMesh2.translateZ(100);

        SM.scene.remove(this.sphere);
        SM.scene.remove(this.sphere2);
        //Translate by bounding box to get at 0. (its length)
        //wallMesh.translateX(-100)


        //wallMesh2.translateX(wallObjectIsOn.center.x);
    }

         */
    }
}