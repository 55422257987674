import React, {Component} from 'react';
import AdvertiserPanel from "./AdvertiserPanel";

class AdvertiserList extends Component {
    render() {
        const advertisers = this.props.advertisers;
        if (advertisers.length === 0) return null;
        advertisers.sort(function (x, y) {
            return x.businessName === 'MasterSuite' ? -1 : y.businessName === 'MasterSuite' ? 1 : 0;
        });

        return (
            <div style={{height:this.props.height-170,overflow:'scroll'}}>
                {this.props.advertisers.map(advertiser => {
                    return <AdvertiserPanel selectAdvertiser={this.props.selectAdvertiser}
                                            key={advertiser.businessName}
                                            advertiser={advertiser}/>
                })}
            </div>
        );
    }
}

export default AdvertiserList;