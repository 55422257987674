import {GET_ADVERTISER_AREAS, GET_ADVERTISER_CATEGORIES, GET_ADVERTISER_ITEMS, GET_ADVERTISERS} from '../actions/types';

const INITIAL_STATE = {
    advertisers: [],
    advertiserItems: [],
    advertiserCategories: [],
    advertiserAreas: []
};

export default (state = INITIAL_STATE, actions) => {

    switch (actions.type) {
        case GET_ADVERTISERS:
            return {
                ...state,
                advertisers: actions.payload
            };
        case GET_ADVERTISER_ITEMS:
            return {
                ...state,
                advertiserItems: actions.payload
            };
        case GET_ADVERTISER_CATEGORIES:
            return {
                ...state,
                advertiserCategories: actions.payload
            };
        case GET_ADVERTISER_AREAS:
            return {
                ...state,
                advertiserAreas: actions.payload
            };
        default:
            return state;
    }
}
