import React, {Component} from 'react';
import LinearProgress from "@material-ui/core/LinearProgress";

class LoadingSpinnerProgress extends Component {
    render() {
        return (
            <LinearProgress value={this.props.progress}
                            valueBuffer={100}
                            variant={'buffer'}
                            color="primary"
                            styles={{
                                bar: {
                                    backgroundColor: 'purple',
                                },
                            }}/>
        );
    }
}

export default LoadingSpinnerProgress;