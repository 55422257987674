import {WallItem} from './wall_item.js';
import * as THREE from "three";
import {GroundSceneReflector} from "three-reflector2";
import {SM} from "../../../components/SceneManager";

/** */
export class Mirror extends WallItem {
    constructor(model, metadata, geometry, material, position, rotation, scale, isgltf = false) {
        super(model, metadata, geometry, material, position, rotation, scale, isgltf);
        this.addReflectiveSurface();
    }

    /** Add a reflective surface to a mirror. */
    addReflectiveSurface = () => {
        var ground = new THREE.Mesh(new THREE.PlaneGeometry(this.getWidth() / this.scale.x, this.getHeight() / this.scale.y, 1),
            new THREE.MeshPhongMaterial({color: 0xFFFFFF, side: THREE.FrontSide}));
        var reflector = new GroundSceneReflector(ground, SM.renderer, this.scene, {intensity: 0.7});
        console.log(this.metadata);
        if (!this.metadata.start) {
            ground.position.set(0, 0, 0.5);
        } else {
            ground.position.set(0, 0, this.getDepth());
        }
        reflector.renderOrder = 52;

        this.add(ground);
    }
}