import React from 'react';
import Particles from "react-particles-js";
import img from '../../../assets/images/reactImg.svg'
import img3 from '../../../assets/images/brushImg.svg';

const ParticlesPlayer = () => {
    return (
        <Particles
            params={{
                "particles": {
                    "number": {
                        "value": 10,
                        "density": {
                            "enable": true,
                            "value_area": 800
                        }
                    },
                    "line_linked": {
                        "enable": true
                    },
                    "move": {
                        "speed": 1,
                        "out_mode": "out"
                    },
                    "shape": {
                        "type": [
                            "image",
                            "circle"
                        ],
                        "image": [
                            {
                                "src": img3,
                                "height": 20,
                                "width": 23
                            },
                            {
                                "src":img,
                                "height": 20,
                                "width": 20
                            }
                        ]
                    },
                    "color": {
                        "value": "#CCC"
                    },
                    "size": {
                        "value": 30,
                        "random": false,
                        "anim": {
                            "enable": true,
                            "speed": 4,
                            "size_min": 10,
                            "sync": false
                        }
                    }
                },
                "retina_detect": false
            }}
            style={{
                width: '100%',
                backgroundColor: "white",
                position: 'fixed',
                color: 'black',
                top: 0,
                zIndex: -3
            }}/>
    );
};

export default ParticlesPlayer;
