import React, {Component} from 'react';
import TermsOfUse from './components/Website/Pages/TermsOfUse/TermsOfUse';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Login from "./components/Website/Pages/Login/Login";
import {rrfProps} from "./redux/store";
import {ReactReduxFirebaseProvider} from 'react-redux-firebase'
import {connect} from 'react-redux';
import {login, logout} from "./redux/actions/authActions";
import {Flip, toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {clearAllActionResults} from "./redux/actions/actionResultActions";
import RouteWithLayout from "./components/Website/Admin/RouteWithLayout";
import DashboardView from './components/Website/Admin/Views/Dashboard/Dashboard';
import NotFoundView from './components/Website/Pages/NotFound'
import {Main as MainLayout, Minimal as MinimalLayout} from "./components/Website/Admin/layouts";
import AddItem from "./components/Website/Admin/Views/ItemFolder/AddItem";
import TestSuite from "./components/Website/Admin/Views/TestSuite/TestSuite";
import Profile from "./components/Website/Admin/Views/Profile/Profile";
import Settings from "./components/Website/Admin/Views/Settings/Settings";
import SceneManagerWrapper from "./components/SceneManagerWrapper";
import PrivacyPolicy from "./components/Website/Pages/PrivacyPolicy/PrivacyPolicy";
import SceneManagerWrapperMobile from "./components/SceneManagerWrapperMobile";
import Home from "./components/Website/Pages/Home/Home";
import Contact from "./components/Website/Pages/Contact/Contact";
import Extras from "./components/Website/Admin/Views/Extras/Extras";

class App extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        //NOTE: If you don't want app to refresh on route, use <Link> from react-router-dom
        if (prevProps !== this.props) {
            if (this.props.errorMessage) {
                toast.error(this.props.errorMessage);
            }
            if (this.props.successMessage) {
                toast.success(this.props.successMessage);
            }
            this.props.clearAllActionResults();
        }
    }

    render() {
        return (
            <ReactReduxFirebaseProvider {...rrfProps}>
                <BrowserRouter>
                    <ToastContainer
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        position={'bottom-center'}
                        rtl={false}
                        transition={Flip}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover/>
                    <Switch>
                        <Route exact path="/home" component={Home}/>
                        <Route exact path="/" component={Home}/>
                        <Route path="/terms-of-use" component={TermsOfUse}/>
                        <Route path="/privacy-policy" component={PrivacyPolicy}/>
                        <Route path="/suite" component={SceneManagerWrapper}/>
                        <Route path="/suite_mobile" component={SceneManagerWrapperMobile}/>
                        <Route path="/contact" component={Contact}/>
                        <Route path="/login" render={props => <Login {...props} isLoading={this.props.isLoading} auth={this.props.auth}/>}/>
                        <RouteWithLayout component={DashboardView} layout={MainLayout} path="/admin/dashboard" {...this.props}/>
                        <RouteWithLayout component={Extras} layout={MainLayout} path="/admin/extras" {...this.props}/>
                        <RouteWithLayout component={Profile} layout={MainLayout} path="/admin/account" {...this.props}/>
                        <RouteWithLayout component={AddItem} layout={MainLayout} path="/admin/items" {...this.props}/>
                        <RouteWithLayout component={TestSuite} layout={MainLayout} path="/admin/test-suite" {...this.props}/>
                        <RouteWithLayout component={Settings} layout={MainLayout} path="/admin/settings" {...this.props}/>
                        <RouteWithLayout component={AddItem} layout={MainLayout} path="/admin" {...this.props}/>
                        <RouteWithLayout
                            component={NotFoundView}
                            exact
                            layout={MinimalLayout}
                            path="/not-found"
                        />
                        <Redirect from='*' to='/not-found'/>
                    </Switch>
                </BrowserRouter>
            </ReactReduxFirebaseProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError,
        profile: state.firebase.profile,
        errorMessage: state.actionResult.actionResultErrorMessage,
        successMessage: state.actionResult.actionResultSuccessMessage,
        isLoading: state.actionResult.isLoading
    }
};

export default connect(mapStateToProps, {login, clearAllActionResults, logout})(App);
