import React, {Component} from 'react';
import firebase from "../../../../../config/firebase";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {createPreset, deletePreset, getPresets} from "../../../../../redux/actions/itemActions";

class Extras extends Component {

    state = {
        startingHome: null,
        tiles:[],
        presets: [],
        creatingPresetHome: false,
        uploadedCreatePresetFile: null,
        uploadedCreatePresetImageFile: null,
        selectedTile: null,
        selectedPreset: null
    }
    componentDidMount() {
        this.fetchStartingHome();
        this.fetchTiles();
        this.props.getPresets();
    }
    fetchStartingHome = async () => {
        await fetch('https://firebasestorage.googleapis.com/v0/b/mastersuite-78d43.appspot.com/o/presetHomes%2FstartingHome.json?alt=media&token=577a2202-4ced-4c71-9fb6-315a1c203720')
            .then(res => {
                return res.json();
            }).then(data => {
                this.setState({startingHome:'A starting home is uploaded!'})
            })
    }

    fetchTiles = async () => {
        var storageRef = firebase.storage().ref('tiles');

        var imagePromises = [];

        var results = await storageRef.listAll();
        results.items.forEach(imageRef => {
            imagePromises.push(imageRef.getDownloadURL())
        });
        var urls = await Promise.all(imagePromises);
        this.setState({tiles:urls})
    }

    handleUploadSuccess = () => {
        toast.info("Uploaded file.")
    }

    renderStartingHome = () => {
        return (
            <CustomUploadButton
                accept="msuite/*"
                name="avatar"
                filename={'startingHome.json'}
                storageRef={firebase.storage().ref("presetHomes")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
                className={'default-button'}
            >Add a starting home</CustomUploadButton>
        )
    }

    renderPresetHomeList = presets => {
        var {selectedPreset} = this.state;
        return (
            <div className={'image-picker'}>
                {presets.map(preset =>
                    {return <div key={preset.file} className={'image-picker-img-wrapper ' + (selectedPreset === preset ? 'img-is-selected' : '')} onClick={() => this.selectPreset(preset)}> <img alt={'tile'} className={'image-picker-img'} src={preset.image}></img> </div>}
                )}
            </div>
        )
    }

    selectPreset = preset => {
        this.setState({selectedPreset:preset})
    }

    renderPresetHomes = () => {
        let {presets} = this.props;
        function compare(a,b) {
            if (a.dateCreated < b.dateCreated) return -1;
            if (a.dateCreated > b.dateCreated) return 1;
            return 0;
        }
        var presetsSorted = presets.sort(compare)
        return (
            <div>
                <div>
                        {(presetsSorted && presetsSorted.length > 0) ? this.renderPresetHomeList(presetsSorted) : <p>No presets</p>}
                </div>
                <button className={'default-button'} onClick={() => this.setState({creatingPresetHome:true})}>Add a preset home</button>
                <button className={'default-button'} onClick={this.deleteSelectedPreset}>Remove selected preset</button>
            </div>
        )
    }
    deleteSelectedPreset = () => {
        var {selectedPreset} = this.state;
        if (!selectedPreset) return;
        this.props.deletePreset(selectedPreset)
    }


    selectTile = tile => {
        this.setState({selectedTile: tile})
    }


    renderTileList = () => {
        var {selectedTile} = this.state;
        return (
            <div>
                <div className={'image-picker'}>
                    {this.state.tiles.map(tile =>
                        {return <div key={tile} className={'image-picker-img-wrapper ' + (selectedTile === tile ? 'img-is-selected' : '')} onClick={() => this.selectTile(tile)}> <img alt={'tile'} className={'image-picker-img'} src={tile}></img> </div>}
                    )}
                </div>
                <CustomUploadButton
                    accept="image/*"
                    name="avatar"
                    filename={Date.now()}
                    storageRef={firebase.storage().ref("tiles")}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={(res) => {
                        this.fetchTiles();
                    }}
                    onProgress={this.handleProgress}
                    className={'default-button'}
                >Add a new tile</CustomUploadButton>
                <button className={'default-button'} onClick={this.removeSelectedTile}>Remove selected tile</button>
            </div>
        )
    };
    removeSelectedTile = async () => {
        var {selectedTile} = this.state;
        console.log(selectedTile);
        if (!selectedTile) return;
        var storageRef = firebase.storage().ref('tiles');
        console.log(storageRef);
        var results = await storageRef.listAll();

        var imageRefs = [];
        results.items.forEach(imageRef => {
            imageRefs.push(
               {
                   imageRef,
                    downloadURL: imageRef.getDownloadURL()
               }
            )
        });

        console.log(imageRefs);
        for (let i = 0 ; i < imageRefs.length ;i++) {
            Promise.resolve(imageRefs[i].downloadURL).then(res => {
                if (selectedTile === res) {
                    imageRefs[i].imageRef.delete().then(res => {
                        toast.info("Deleted!")
                        this.fetchTiles();

                    }).catch(err => {
                        toast.error(err.message)
                    })
                }
            })
        }
        //var urls = await Promise.all(imagePromises);


    }

    getStartingHome = () => {
        //do async call and set state.
    }
    renderCancelButton = () => {
        return <button className={'msuite-btn-general'} onClick={() => this.setState({creatingPresetHome:false})}>Cancel</button>
    };
    renderCreateButton = () => {
        return <button className={'msuite-btn-general'} onClick={() => this.onCreatePreset()}>Create</button>
    };

    onCreatePreset = () => {
        const {presetFileName,uploadedCreatePresetImageFile,uploadedCreatePresetFile} = this.state;

        if (!uploadedCreatePresetFile) {
            toast.error("Must upload a .msuite preset file.")
            return;
        }
        console.log(presetFileName.split('.')[1]);
        if (presetFileName.split('.')[1] !== 'msuite' && presetFileName.split('.')[1] !== 'json') {
            toast.error("Must be a .msuite file. Please reupload.")
            return;
        }
        if (!uploadedCreatePresetImageFile) {
            toast.error("Must upload an image file as a thumbnail.")
            return;
        }
        const preset = {
            file:uploadedCreatePresetFile,
            image:uploadedCreatePresetImageFile
        }
        this.props.createPreset(preset);
        this.setState({creatingPresetHome:false,presetFileName:null,presetImageFileName:null});

    };

    renderNameInput = () => {

        return (
            <CustomUploadButton
                accept="msuite/*"
                name="avatar"
                randomizeFilename
                storageRef={firebase.storage().ref("presets")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={res => {
                    firebase
                        .storage()
                        .ref("presets")
                        .child(res)
                        .getDownloadURL()
                        .then(url => {
                            this.setState({presetFileName:res,uploadedCreatePresetFile:url})
                        });
                }}
                onProgress={this.handleProgress}
                className={'default-button'}
            >Upload .msuite file</CustomUploadButton>
        )
    };
    renderImageUpload = () => {
        return (
            <div style={{width: '100%'}}>
                <CustomUploadButton
                    accept="image/*"
                    name="avatar"
                    randomizeFilename
                    storageRef={firebase.storage().ref("presets")}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={res => {
                        firebase
                            .storage()
                            .ref("presets")
                            .child(res)
                            .getDownloadURL()
                            .then(url => {
                                this.setState({presetImageFileName:res,uploadedCreatePresetImageFile:url})
                            });
                    }}
                    onProgress={this.handleProgress}
                    className={'default-button'}
                >Upload the image thumbnail</CustomUploadButton>
            </div>
        )
    }
    renderCreatePresetPopup = () => {
        return (
            <div className={"shadow-screen"}>
                <div className={"category-inner"}>
                    <div className={"bottom-border"}>
                        <h3>Create Preset</h3>
                    </div>
                    <div className={"create-category"}>
                        <div className={'straight-row'}>
                            {this.renderNameInput()}
                            {this.state.presetFileName ? this.state.presetFileName : <p>.msuite file missing</p>}
                            {this.renderImageUpload()}
                            {this.state.presetImageFileName ? this.state.presetImageFileName : <p>thumbnail image missing</p>}
                        </div>
                        <div className={'straight-row2'}>
                            {this.renderCreateButton()}
                            {this.renderCancelButton()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        let {startingHome,creatingPresetHome} = this.state;
        return (
            <div>
                {creatingPresetHome && this.renderCreatePresetPopup()}
                <div className={"item-folder-wrapper"}>
                    <div className="item-folder-inner main-gradient-static">
                        <div style={{marginLeft:'1em'}}>
                            <div>
                                <h2 style={{marginTop:'1em',color:'white'}}>Starting Home</h2>
                                {startingHome ? <p style={{color:'green'}}>{startingHome}</p> : <p>STARTING HOME IS MISSING!</p>}
                                {this.renderStartingHome()}
                            </div>
                            <br/>
                            <div>
                                <h2 style={{marginTop:'1em',color:'white'}}>Preset Homes</h2>
                                {this.renderPresetHomes()}
                            </div>
                            <br/>
                            <div>
                                <h2 style={{marginTop:'1em',color:'white'}}>Tiles</h2>
                                {this.renderTileList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    presets: state.items.presets,
    isLoading: state.actionResult.isLoading
});

export default connect(mapStateToProps, {
    createPreset,
    getPresets,
    deletePreset
})(Extras);

