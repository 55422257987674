

/*
Get the height of the screen.
 */
export function getUserScreenHeight() {
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    let headerAndFooterHeight = 0; //this.props.mobile ? 0 : 45; //just an estimate, can probably grab those components to be accurate.
    return height - headerAndFooterHeight;
}
export function getUserScreenWidth() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let headerAndFooterHeight = 0; //this.props.mobile ? 0 : 45; //just an estimate, can probably grab those components to be accurate.
    return width - headerAndFooterHeight;
}

