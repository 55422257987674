import React, {Component} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Input from "@material-ui/core/Input";
import ArrowForward from "@material-ui/icons/ArrowForward";
import CallMade from "@material-ui/icons/CallMade";
import FileUploaderWrapper from "../../../../General/Files/FileUploaderWrapper";
import CustomInput from "../../../../General/UserInputs/CustomInput";
import {PrettoSlider} from "../../../../General/UserInputs/PrettoSlider";
import {center, down, left, right, up} from "../../../../../assets/images/controls";
import FileUploader from "react-firebase-file-uploader";
import firebase from "../../../../../config/firebase";

const acceptedFileTypes = [
    'obj',
    'gltf',
    'glb',
    'fbx',
    'FBX',
    'dae',
];
class EditItem extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        var item = this.props.item;

        if (item) {
            item.prevName = item.name;
            this.setState({item});
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevProps.item || prevProps.item !== this.props.item) {
            var item = this.props.item;
            item.prevName = item.name;
            this.setState({item});
        }
    }

    downloadObject = () => {
        var {item} = this.state;
        window.open(item.objURL);

    }
    render() {
        if (!this.state.item) return null;
        const {item} = this.state;
        return (
            <div className={'keep-height-100 transparent-panel'}>
                <div className={'edit-item-details'}>
                    {this.props.editItemText !== false ? <a href={'/admin/test-suite'}>
                        <p>Do you want to edit items in a viewer?</p>
                    </a> : null}
                    <button onClick={this.downloadObject}>Download object file</button>
                    <p>Upload a new object (glb)</p>
                    {this.renderObjectReuploader()}
                    {this.renderNameField(item)}
                    {this.renderDescriptionField(item)}
                    {this.renderItemUrl(item)}
                    {this.renderChooseArea(item)}
                    {this.renderChooseCategory(item)}
                    {this.renderChooseType(item)}
                    {this.renderChooseMeasurements(item)}
                    {this.renderRotationControls(item)}
                    {this.renderUploadPhotos(item)}
                    {this.renderMakePublic(item)}
                    <div style={{borderTop: '1px solid gray'}}>
                        <button className={'default-button'} onClick={this.completeEdit}>
                            Save Item
                        </button>
                    </div>
                </div>

            </div>
        );
    }
    handleUploadSuccess = filename => {
        //if (!this.state.isUploading) return;
        //this.props.actionResultSuccess(filename);
        var fileType = filename.split('.')[1];
        var {item} = this.state;

        if (acceptedFileTypes.includes(fileType)) {
            //this.setState({avatar: filename, progress: 100, filename});
            var format = filename.split('.')[1]
            if (format === "glb") format = "gltf";
            firebase
                .storage()
                .ref("objects/"+item.businessName)
                .child(filename)
                .getDownloadURL()
                .then(url => {
                    //ONLY LOAD IF NO MORE FILES
                    item.objURL = url;
                    this.setState({item,isUploading: false})
                });
        }
    };
    handleUploadStart = (f) => {
        this.setState({isUploading: true});
    };
    handleUploadError = error => {
        this.setState({isUploading: false});
        alert(error);
    };
    renderObjectReuploader = () => {
        const {item,isUploading} = this.state;

        return (
            <div>
                {isUploading && <p>Uploading...</p>}
                <FileUploader
                    accept="*"
                    name="mtl"

                    storageRef={firebase.storage().ref("objects/"+item.businessName)}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                    className={'dragndropzone'}
                    multiple
                />
            </div>

        )
    }
    rotateObject = (way) => {
        this.props.onValueEdited('rotation',way);
    };
    renderRotationControls = () => {
        if (this.props.lite) return null;
        return (
            <div className={'rotation-controls'}>
                <b>Rotation</b>
                <br/>
                <div className="btn button_custom" href="#" id="leftview" title="Show side view (left)"
                     onClick={() => this.rotateObject("left")}>
                    <img className={'rotate-img'} alt={'left'} src={left}/>
                </div>
                <span className="btn-group-vertical">
		              <div className="btn button_custom" href="#" id="topview" title="Show top view"
                           onClick={() => this.rotateObject("up")}>
                        <img className={'rotate-img'} alt={'up'} src={up}/>
		              </div>
		              <div className="btn button_custom" href="#" id="isometryview" title="Show 3d view"
                           onClick={() => this.rotateObject("center")}>
                        <img className={'rotate-img'} alt={'center'} src={center}/>
		              </div>
		              <div className="btn button_custom" href="#" id="frontview" title="Show front view"
                           onClick={() => this.rotateObject("down")}>
                        <img className={'rotate-img'} alt={'down'} src={down}/>
		              </div>
            </span>
                <div className="btn button_custom bottom" href="#" id="rightview" title="Show side view (right)"
                     onClick={() => this.rotateObject("right")}>
                    <img className={'rotate-img'} alt={'right'} src={right}/>
                </div>
            </div>
        );
    };
    completeEdit = () => {
        this.props.onEditItem(this.state.item, this.state.name);
    };

    renderMakePublic = (item) => {
        return (
            <div className={"yes-or-no"}>
                <b>Do you want to make this object public?</b>
                <br/>
                <i>This means that all users will be able to see this item in the catalogue.</i>
                <div style={{display: "flex", flexDirection: 'row'}}>
                    <Checkbox checked={item.public === false} onClick={() => this.editItemField('public', false)}/>
                    <p style={{paddingTop: '0.6em'}}>No</p>

                </div>
                <div style={{display: "flex", flexDirection: 'row'}}>
                    <Checkbox checked={item.public === true} onClick={() => this.editItemField('public', true)}/>
                    <p style={{paddingTop: '0.6em'}}>Yes</p>
                </div>
            </div>
        )
    };


    renderItemUrl = item => {
        return (
            <CustomInput label={'Website URL Link'} value={item.storeURL}
                         onChange={val => this.editItemField('storeURL', val)}/>
        )
    };
    renderDescriptionField = item => {
        return (
            <CustomInput rows={4} label={'Description'} value={item.description}
                         onChange={val => this.editItemField('description', val)}/>
        )
    };
    renderNameField = (item) => {
        return (
            <CustomInput label={'Name'} value={item.name} onChange={val => this.editItemField('name', val)}/>
        )
    };

    onChooseArea = val => {
        var area = this.props.areas[val];

        this.editItemField('area', area.name)

    }
    onChooseCategory = (val) => {
        var category = this.props.categories[val];
        this.editItemField('category', category.name)
    };

    editItemField = (field, value) => {

        if (this.props.onValueEdited) {
            this.props.onValueEdited(field, value);
        }
        var {item} = this.state;

        if (field === "area") {
            this.props.categories.forEach(cat => {
                if (cat.area === value) {
                    item.category = cat.name;
                }

            });
        }
        item[field] = value;
        this.setState({item})
    };

    handleMeasurementChange = (property, val) => {
        var {item} = this.state;
        var measurements = item.measurements;
        measurements[property] = val;
        item.measurements = measurements;
        if (this.props.onValueEdited) {
            this.props.onValueEdited('measurements', measurements);
        }
        this.setState({item})
    };

    renderChooseArea = item => {
        var areas = [];
        var i = 0;
        var value = 0;

        this.props.areas.forEach(area => {
            var obj = {
                label:area.name,
                value: i
            };
            areas.push(obj);
            if (item.area === area.name) {
                value = i;
            }
            i++;


        });
        if (areas.length < 1) {
            return (
                <div>
                    You have no categories.
                </div>
            )
        }
        return (
            <FormControl margin={'normal'}>
                <InputLabel>Areas</InputLabel>
                <Select
                    onChange={e => this.onChooseArea(e.target.value)}
                    value={value}
                >
                    {areas.map(t => {
                        return <MenuItem key={t.value} value={t.value}>{t.label}</MenuItem>
                    })}

                </Select>
            </FormControl>
        )
    };

    renderChooseCategory = (item) => {
        var categories = [];
        var i = 0;
        var value = 0;
        this.props.categories.forEach(cat => {
            if (cat.area === item.area) {
                var obj = {
                    label:cat.name,
                    value: i
                };
                categories.push(obj);
                if (cat.name === item.category) {
                    value = i;
                }
            }
            i++;

        });

        if (categories.length < 1) {
            return (
                <div>
                    You have no categories.
                </div>
            )
        }
        return (
            <FormControl margin={'normal'}>
                <InputLabel>Category</InputLabel>
                <Select
                    onChange={e => this.onChooseCategory(e.target.value)}
                    value={value}
                >
                    {categories.map(t => {
                        return <MenuItem key={t.value} value={t.value}>{t.label}</MenuItem>
                    })}

                </Select>
            </FormControl>
        )
    };
    renderChooseType = (item) => {
        //TODO RETRIEVE FROM SERVER
        const type = [
            {label: "FloorItem", value: 1},
            {label: "WallItem", value: 2},
            {label: "InWallItem", value: 3},
            {label: "RoofItem", value: 4},
            {label: "InWallFloorItem", value: 7},
            {label: "OnFloorItem", value: 8},
            {label: "WallFloorItem", value: 9},
            {label:'ExternalItem', value:20}
        ];
        return (
            <FormControl margin={'normal'}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                    onChange={e => this.editItemField('type', e.target.value)}
                    value={item.type}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select">

                    {type.map(t => {
                        return <MenuItem key={`${t.label}_${t.value}`} value={t.value}>{t.label}</MenuItem>
                    })}

                </Select>
            </FormControl>
        )
    };
    renderChooseMeasurements = (item) => {
        return (
            <div>
                <div style={{margin: '0em'}}>
                    <Typography id="input-slider" gutterBottom>
                        Height
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <ArrowUpward/>
                        </Grid>
                        <Grid item xs>
                            <PrettoSlider
                                value={typeof item.measurements.height === 'number' ? item.measurements.height : 0}
                                onChange={(e, v) => this.handleMeasurementChange("height", v)}
                                aria-labelledby="input-slider"
                                min={1}
                                max={500}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                value={item.measurements.height}
                                margin="dense"
                                onChange={e => this.handleMeasurementChange("height", e.target.value)}
                                inputProps={{
                                    step: 10,
                                    min: 1,
                                    max: 500,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div style={{margin: '0em'}}>
                    <Typography id="input-slider" gutterBottom>
                        Width
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <ArrowForward/>
                        </Grid>
                        <Grid item xs>
                            <PrettoSlider
                                value={typeof item.measurements.width === 'number' ? item.measurements.width : 0}
                                onChange={(e, v) => this.handleMeasurementChange("width", v)}
                                aria-labelledby="input-slider"
                                min={1}
                                max={500}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                value={item.measurements.width}
                                margin="dense"
                                onChange={e => this.handleMeasurementChange("width", e.target.value)}
                                inputProps={{
                                    step: 10,
                                    min: 1,
                                    max: 500,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div style={{margin: '0em'}}>
                    <Typography id="input-slider" gutterBottom>
                        Length
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <CallMade/>
                        </Grid>
                        <Grid item xs>
                            <PrettoSlider
                                value={typeof item.measurements.length === 'number' ? item.measurements.length : 0}
                                onChange={(e, v) => this.handleMeasurementChange("length", v)}
                                aria-labelledby="input-slider"
                                min={1}
                                max={500}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                value={item.measurements.length}
                                margin="dense"
                                onChange={e => this.handleMeasurementChange("length", e.target.value)}
                                inputProps={{
                                    step: 10,
                                    min: 1,
                                    max: 500,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>


        )
    };

    renderUploadPhotos = (item) => {
        return (
            <div>
                <FileUploaderWrapper photoURL={item.photoURL}
                                     onFileUploaded={(url) => this.editItemField('photoURL', url)}
                                     textPrimary={'Choose Catalogue Photo'}
                                     textSecondary={'This is how your object will be shown in the user catalogue.'}/>
                <FileUploaderWrapper photoURL={item.photoURL2D}
                                     onFileUploaded={(url) => this.editItemField('photoURL2D', url)}
                                     textPrimary={'Choose 2D Top Down Photo'}
                                     textSecondary={'This is how your object will look in 2D top-down view.'}/>
            </div>
        )
    };
}

export default EditItem;
