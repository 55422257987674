import React, {Component} from 'react';
import {connect} from "react-redux";
import {createArea} from '../../../../../redux/actions/itemActions';
import {addActionResultError} from "../../../../../redux/actions/actionResultActions";
import CustomInput from "../../../../General/UserInputs/CustomInput";
import FileUploaderWrapper from "../../../../General/Files/FileUploaderWrapper";


class CreateAreaPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
        }
    }

    onChangeInput = (e) => {
        this.setState({
            name: e.target.value
        })
    };

    toggleOff = () => {
        this.props.toggleWindow(false);
    };

    onCreate = () => {
        const {areas} = this.props;
        //front end validation.
        var nameExists = false;
        areas.forEach(area => {
            if (area.name.toLowerCase() === this.state.name.toLowerCase()) {
                this.props.addActionResultError("Name already exists.");
                nameExists = true;
            }
        });
        if (nameExists) return;
        this.props.createArea(this.state);
        this.toggleOff();
    };

    renderCancelButton = () => {
        return <button className={'msuite-btn-general'} onClick={() => this.toggleOff()}>Cancel</button>
    };
    renderCreateButton = () => {
        return <button className={'msuite-btn-general'} onClick={() => this.onCreate()}>Create</button>
    };

    renderNameInput = () => {

        return (
            <CustomInput label={'Area Name'} value={this.state.name} onChange={val => this.setState({name: val})}/>
        )
    };
    renderImageUpload = () => {
        return (
            <div style={{width: '100%'}}>
                <FileUploaderWrapper photoURL={this.state.iconURL}
                                     onFileUploaded={(url) => this.setState({iconURL: url})}
                                     textPrimary={'Choose Area Icon'}
                                     textSecondary={'This is how your object will be shown in the user catalogue.'}/>
            </div>

        )
    };

    render() {
        if (!this.props.isOpen) return null;
        return (

            <div className={"shadow-screen"}>
                <div className={"category-inner"}>
                    <div className={"bottom-border"}>
                        <h3>Create Area</h3>
                    </div>
                    <div className={"create-category"}>
                        <div className={'straight-row'}>
                            {this.renderNameInput()}
                            {this.renderImageUpload()}
                        </div>
                        <div className={'straight-row2'}>
                            {this.renderCreateButton()}
                            {this.renderCancelButton()}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {}
};

export default connect(mapStateToProps, {createArea, addActionResultError})
(CreateAreaPopup);
