import React from 'react';
import PropTypes from 'prop-types';
import {Sidebar} from './components';
//import LoadingSpinner from "../../../../Reusable/Loading/LoadingSpinner";
import HomeHeader from "../../../Navigation/HomeHeader";
import LoadingSpinner from "../../../../General/Loading/LoadingSpinner";


class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profile: null
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        const {children} = this.props;

        if (!children.props.profile.isLoaded) {
            return <LoadingSpinner/>
        }
        if (children.props.auth.isLoaded && children.props.auth.isEmpty) {
            children.props.history.push(`/login`);
            return null;
        }
        return (
            <div>
                <HomeHeader logout={children.props.logout}/>
                <Sidebar
                    profile={children.props.profile}
                    path={children.props.match}
                />
                <main>
                    {children}
                </main>
            </div>
        );
    }

};

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
