import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import logo from "../../../assets/images/logo.png";

class HomeHeader extends Component {

    render() {
        if (this.props.lite) {
            return this.renderLite();
        }

        return this.renderFull();
    }
    renderLite = () => {
        return (
            <div>
                <a href="/home">
                    <img alt={'MasterSuite logo'} className="msuite-watermark" src={logo}/>
                </a>
            </div>
        )
    }
    renderFull = () => {
        return (
            <nav className={`nav-home bg-normal`} style={this.props.location.pathname === '/home' ? {position:'fixed'} : {}} >
                <div/>
                <a href="/home">
                    <img alt={'MasterSuite logo'} className="nav-home-logo" src={logo}/>
                </a>
                <div className={'nav-links'}>
                    {this.renderNavLink()}
                </div>
            </nav>
        );
    }
    renderNavLink = () => {
        if (this.props.logout) {
            return (
                <div>
                    <span style={{marginRight:'2em'}} className={'fas fa-bell'} onClick={() => alert("Show notification list")}></span>
                    <span className={'fas fa-sign-out-alt'} onClick={this.props.logout}></span>
                </div>
            )
        }
        return (
            <div className={'nav-main-links'}>
                {(this.props.location.pathname === '/home' || this.props.location.pathname === '/') && <>
                    <a href="#about">{'About'}</a>
                    <a href='#contact'>{'Contact'}</a>
                </>}

                <a href={'/suite'} rel="noopener noreferrer" target={'_blank'}>
                    <button className={'transparent-button-main'}>Get started for FREE</button>
                </a>
            </div>
        )
    }
}

export default withRouter(HomeHeader);
