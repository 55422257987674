import React from 'react';
import lottie from '../../../../assets/lotties/clickItem'
import LottiePlayer from "../../../General/Effects/LottiePlayer";
/**
 * Give this component the wrapper object with saved properties.
 * @param {*} param0
 */
const ItemInformation = ({item,isOpen}) => {
    /**
     * Just do, item.getHTML(), and it returns dependent on the class,
     * Item has an implementaiton, and its overwritten in different types of objects.
     */
    if (!isOpen) return null;
    if (item) {
        return item.getHTML();
    } else {
        return <div className={"item-information"}>
            <h4>Select an Item</h4>
            <LottiePlayer lottieData={lottie} height={100} width={100} />
        </div>
    }

};

export default ItemInformation;
