import * as THREE from 'three';
import {
    Color,
    DoubleSide,
    EventDispatcher,
    Mesh,
    MeshBasicMaterial,
    PlaneGeometry,
    RepeatWrapping,
    ShaderMaterial,
    SphereGeometry,
    TextureLoader
} from 'three';
import groundTexture from './Ground_4K.jpg';
import groundTextureGrass from './grasslight-big.jpg';
import {Sky} from 'three/examples/jsm/objects/Sky';
import {SM} from "../../../components/SceneManager";

import frontTexture from '../../../assets/images/skyboxTextures/yonder_ft.jpg';
import backTexture from '../../../assets/images/skyboxTextures/yonder_bk.jpg';
import upTexture from '../../../assets/images/skyboxTextures/yonder_up.jpg';
import downTexture from '../../../assets/images/skyboxTextures/yonder_dn.jpg';
import rightTexture from '../../../assets/images/skyboxTextures/yonder_rt.jpg';
import leftTexture from '../../../assets/images/skyboxTextures/yonder_lf.jpg';
import {MeshPhongMaterial} from "three";


export class Skybox extends EventDispatcher {
    constructor(scene, renderer) {
        super();

        this.defaultEnvironment = './Garden.png';
        this.useEnvironment = false;
        this.topColor = 0x92b2ce;//0xe9e9e9; //0xf9f9f9;//0x565e63
        this.bottomColor = 0xffffff;//0xD8ECF9
        this.verticalOffset = 400;
        this.exponent = 0.5;

        var uniforms = {
            topColor: {type: 'c', value: new Color(this.topColor)},
            bottomColor: {type: 'c', value: new Color(this.bottomColor)},
            offset: {type: 'f', value: this.verticalOffset},
            exponent: {type: 'f', value: this.exponent}
        };

        this.scene = scene;
        this.renderer = renderer;

        this.sphereRadius = 6000;
        this.widthSegments = 32;
        this.heightSegments = 15;
        this.sky = null;

        this.plainVertexShader = ['varying vec3 vWorldPosition;', 'void main() {', 'vec4 worldPosition = modelMatrix * vec4( position, 1.0 );', 'vWorldPosition = worldPosition.xyz;', 'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0 );', '}'].join('\n');
        this.plainFragmentShader = ['uniform vec3 bottomColor;', 'uniform vec3 topColor;', 'uniform float offset;', 'uniform float exponent;', 'varying vec3 vWorldPosition;', 'void main() {', ' float h = normalize( vWorldPosition + offset ).y;', ' gl_FragColor = vec4( mix( bottomColor, topColor, max( pow( max(h, 0.0 ), exponent ), 0.0 ) ), 1.0 );', '}'].join('\n');

        this.vertexShader = ['varying vec2 vUV;', 'void main() {', '  vUV=uv;', '  vec4 pos = vec4(position, 1.0);', '   gl_Position = projectionMatrix * modelViewMatrix * pos;', '}'].join('\n');
        this.fragmentShader = ['uniform sampler2D texture;', 'varying vec2 vUV;', 'void main() {  ', 'vec4 sample = texture2D(texture, vUV);', 'gl_FragColor = vec4(sample.xyz, sample.w);', '}'].join('\n');

        this.texture = new TextureLoader();
        this.plainSkyMat = new ShaderMaterial({
            vertexShader: this.plainVertexShader,
            fragmentShader: this.plainFragmentShader,
            uniforms: uniforms,
            side: DoubleSide
        });
        this.skyMat = undefined;

        this.skyGeo = new SphereGeometry(this.sphereRadius, this.widthSegments, this.heightSegments);
        this.sky = new Mesh(this.skyGeo, this.skyMat);
//		this.sky.position.x += this.sphereRadius*0.5;

        this.addGround();
        //this.addGrid();
       // this.addFog();
        //var axesHelper = new AxesHelper(100);
        //this.scene.add( axesHelper );
        this.initNewSkybox();
        this.init();
        //this.initSky();
    }
    initNewSkybox = () => {
        let materialArray = [];
        let texture_ft = new THREE.TextureLoader().load( frontTexture);
        let texture_bk = new THREE.TextureLoader().load( backTexture);
        let texture_up = new THREE.TextureLoader().load( upTexture);
        let texture_dn = new THREE.TextureLoader().load( downTexture);
        let texture_rt = new THREE.TextureLoader().load( rightTexture);
        let texture_lf = new THREE.TextureLoader().load( leftTexture);

        materialArray.push(new THREE.MeshPhongMaterial( { map: texture_ft }));
        materialArray.push(new THREE.MeshPhongMaterial( { map: texture_bk }));
        materialArray.push(new THREE.MeshPhongMaterial( { map: texture_up }));
        materialArray.push(new THREE.MeshPhongMaterial( { map: texture_dn }));
        materialArray.push(new THREE.MeshPhongMaterial( { map: texture_rt }));
        materialArray.push(new THREE.MeshPhongMaterial( { map: texture_lf }));

        for (let i = 0; i < 6; i++)
            materialArray[i].side = THREE.BackSide;
        let skyboxGeo = new THREE.BoxGeometry( 10000, 10000, 10000);
        let skybox = new THREE.Mesh( skyboxGeo, materialArray );
        SM.scene.add( skybox );
    };

    addFog = () => {
        this.scene.fog = new THREE.Fog( 0xA39D9C, 500, 6000 );

    }
    addGrid = () => {
        var size = 500, step = 1;

        var geometry = new THREE.Geometry();
        var material = new THREE.LineBasicMaterial({color:'black'});

        for (var i = -size; i <= size; i+=step) {
            geometry.vertices.push(new THREE.Vector3(-size,-0.04,i));
            geometry.vertices.push(new THREE.Vector3(size,-0.04,i));

            geometry.vertices.push(new THREE.Vector3(i,-0.04,-size));
            geometry.vertices.push(new THREE.Vector3(i,-0.04,size));

        }
        var line = new THREE.Line( geometry,material,THREE.LinePieces);
        this.scene.add(line);
    }
    changeGrass = (flag) => {
        //console.log("Changing grass");
        var groundT = new TextureLoader().load(flag ? groundTexture : groundTextureGrass, function () {
            SM._dirty = true;

        });
        groundT.wrapS = groundT.wrapT = RepeatWrapping;
        //SET TO 1 metre.
        groundT.repeat.set(25, 25);
        this.ground.material = new MeshPhongMaterial({color: 0xffffff, side: DoubleSide, map: groundT});
        this.ground.receiveShadow = true;
    };
    addGround = () => {

        var groundT = new TextureLoader().load(groundTexture, function () {
            SM._dirty = true;
        });
        groundT.wrapS = groundT.wrapT = RepeatWrapping;
        //SET TO 1 metre.
        groundT.repeat.set(25, 25);

//		var uniforms2 = {topColor: {type: 'c',value: new Color(0xFFFFFF)},bottomColor: {type: 'c',value: new Color(0x999999)},offset: {type: 'f',value: this.verticalOffset}, exponent: {type:'f', value: this.exponent}};
        this.groundGeo = new PlaneGeometry(10000, 10000, 10);
        this.groundMat = new MeshBasicMaterial({color: 0xEAEAEA, side: DoubleSide, map: groundT});
        this.ground = new Mesh(this.groundGeo, this.groundMat);
        this.ground.rotateX(-Math.PI * 0.5);
        this.ground.position.y = -1;

        //this.groundSceneReflector = new GroundSceneReflector(this.ground, this.renderer, this.scene,{textureOne:'Assets/textures/Ground_4K.jpg', textureTwo:'Assets/textures/GroundRough.jpg', wrapOne:{x:40, y:40}, wrapTwo:{x:50, y:50}, textureWidth: 512, textureHeight: 512, intensity: 0.1, blendIntensity: 0.05});
       // var texture = new THREE.CanvasTexture( this.generateTexture() );
       // var geometry = new THREE.PlaneBufferGeometry( 100, 100 );

        /*
        this.grass = new THREE.Object3D();
        //build height
        for ( var i = 0; i < 15; i ++ ) {

            var material = new THREE.MeshBasicMaterial( {
                color: new THREE.Color().setHSL( 0.1, 0.75, ( i / 15 ) * 0.4 + 0.1 ),
                map: texture,
                depthTest: false,
                depthWrite: false,
                transparent: true
            } );

            var mesh = new THREE.Mesh( geometry, material );

            mesh.position.y = i * 0.25;
            mesh.rotation.x = - Math.PI / 2;
            this.grass.add(mesh);

        }
        SM.scene.add( this.grass );
        this.grass.position.x = - 500;
        this.grass.position.z = -500;

        for (var i = 0 ; i < 10 ; i++) {
            for (var z = 0 ; z < 10 ; z++) {
                var grass = this.grass.clone();
                grass.position.x = -500 + i*100;
                grass.position.z = -500 + z*100;
                SM.scene.add(grass);
            }

        }

         */
        //this.scene.add(this.sky);
        this.scene.add(this.ground);
    }
    generateTexture() {

        var canvas = document.createElement( 'canvas' );
        canvas.width = 512;
        canvas.height = 512;

        var context = canvas.getContext( '2d' );

        for ( var i = 0; i < 20000; i ++ ) {

            context.fillStyle = 'hsl(0,0%,' + ( Math.random() * 50 + 50 ) + '%)';
            context.beginPath();
            context.arc( Math.random() * canvas.width, Math.random() * canvas.height, Math.random() + 0.15, 0, Math.PI * 2, true );
            context.fill();

        }

        context.globalAlpha = 0.075;
        context.globalCompositeOperation = 'lighter';

        return canvas;

    }
    setEnabled(flag) {
        if (!flag) {
            this.scene.remove(this.sky);
            this.scene.remove(this.ground);
        } else {
            this.scene.add(this.sky);
            this.scene.add(this.ground);
        }
//		this.sky.visible = this.ground.visible = flag;
    }
    initSky = () => {

        // Add Sky
        var sky = new Sky();
        sky.scale.setScalar( 450000 );
        SM.scene.add( sky );

        // Add Sun Helper
        var sunSphere = new THREE.Mesh(
            new THREE.SphereBufferGeometry( 20000, 16, 8 ),
            new THREE.MeshBasicMaterial( { color: 0xffffff } )
        );
        sunSphere.position.y = - 700000;
        sunSphere.visible = false;
        SM.scene.add( sunSphere );

        /// GUI

        var effectController = {
            turbidity: 10,
            rayleigh: 2,
            mieCoefficient: 0.0062,
            mieDirectionalG: 0,
            luminance: 1,
            inclination: 0.4, // elevation / inclination
            azimuth: 0.3448, // Facing front,
            sun: true
        };

        var distance = 400000;

        function guiChanged() {

            var uniforms = sky.material.uniforms;
            uniforms[ "turbidity" ].value = effectController.turbidity;
            uniforms[ "rayleigh" ].value = effectController.rayleigh;
            uniforms[ "mieCoefficient" ].value = effectController.mieCoefficient;
            uniforms[ "mieDirectionalG" ].value = effectController.mieDirectionalG;
            uniforms[ "luminance" ].value = effectController.luminance;

            var theta = Math.PI * ( effectController.inclination - 0.5 );
            var phi = 2 * Math.PI * ( effectController.azimuth - 0.5 );

            sunSphere.position.x = distance * Math.cos( phi );
            sunSphere.position.y = distance * Math.sin( phi ) * Math.sin( theta );
            sunSphere.position.z = distance * Math.sin( phi ) * Math.cos( theta );

            sunSphere.visible = effectController.sun;

            uniforms[ "sunPosition" ].value.copy( sunSphere.position );

           // SM.renderer.render( scene, camera );

        }



        guiChanged();

    }

    toggleEnvironment(flag) {
        this.useEnvironment = flag;
        if (!flag) {
            if (this.ground) this.ground.visible = true;
            this.sky.material = this.plainSkyMat;
            this.sky.material.needsUpdate = true;
        } else {
            if (this.ground) this.ground.visible = false;
            if (!this.skyMat) {
                this.setEnvironmentMap(this.defaultEnvironment);
            } else {
                this.sky.material = this.skyMat;
            }
            this.sky.visible = true;
        }
        this.scene.needsUpdate = true;
    }

    setEnvironmentMap(url) {
        var scope = this;
        scope.texture.load(url, function (t) {
            var textureUniform = {type: 't', value: t};
            var uniforms = {texture: textureUniform};
            scope.skyMat = new ShaderMaterial({
                vertexShader: scope.vertexShader,
                fragmentShader: scope.fragmentShader,
                uniforms: uniforms,
                side: DoubleSide
            });
            scope.toggleEnvironment(scope.useEnvironment);
        }, undefined, function () {
            console.log('ERROR LOADEING FILE');
        });
    }

    init() {
        this.toggleEnvironment(false);
    }
}
