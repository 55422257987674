import React, {Component} from 'react';
import {connect} from "react-redux";
import {login} from "../../../../redux/actions/authActions";
import CustomInput from "../../../General/UserInputs/CustomInput";
import {changeLoadingState} from "../../../../redux/actions/actionResultActions";
import LoadingSpinner from "../../../General/Loading/LoadingSpinner";

class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                email: '',
                password: ''
            }
        }
    }

    componentDidMount() {
        //bug that has loading stuck after logging out after loading "items".
        this.props.changeLoadingState(false);
    }

    render() {
        return (

            <div>
                {this.props.isLoading ? <LoadingSpinner/> : null}
                <div className={"login-container-wrapper"}>

                    <div className="login-container">
                        <div className="card-header">Login</div>
                        <div className="card-body">
                            <form>
                                <CustomInput label={'Email'} required={true} value={this.state.data.email}
                                             onChange={val => this.changeField('email', val)}/>
                                <CustomInput label={'Password'} required={true} type={'password'}
                                             value={this.state.data.password}
                                             onChange={val => this.changeField('password', val)}/>

                                {
                                    /*
                                    https://firebase.google.com/docs/auth/web/facebook-login

                                                   <a className="btn btn-outline-dark" href="/users/googleauth" role="button"
                                   style={{textTransform:'none',marginBottom:'10px'}}>
                                    <img width="20px" style={{marginBottom:'3px',marginRight:'5px'}} alt="Google sign-in"
                                         src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"/>
                                    Login with Google
                                </a>
                                <br/>
                                <a className="btn btn-outline-dark" href="/users/googleauth" role="button"
                                   style={{textTransform:'none'}}>
                                    <img width="20px" style={{marginBottom:'3px',marginRight:'5px'}} alt="Facebook sign-in"
                                         src="https://upload.wikimedia.org/wikipedia/commons/8/82/Facebook_icon.jpg"/>
                                    Login with Facebook
                                </a>


                                     */
                                }

                            </form>
                            <div style={{margin: 80, width: '100%', textAlign: 'center'}}>
                                <button onClick={() => this.props.login(this.state.data)}
                                        className={'msuite-btn'}>
                                    Login
                                </button>
                            </div>
                        </div>
                        {
                           // <p className="linkable-text" style={{color: "blue"}} onClick={() => this.props.history.push(`/create-account`)}>Don't have an account?</p>
                        }

                    </div>
                </div>
            </div>

        );
    }

    changeField = (field, val) => {
        var {data} = this.state;
        data[field] = val;
        this.setState({data})
    };


}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
});
export default connect(mapStateToProps, {login, changeLoadingState})(LoginForm);
