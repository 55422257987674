import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0.5em'
    },
    textField: {

        borderColor: theme.palette.primary.dark,
        backgroundColor: '#f4f4f4',
        textShadow: 'none !important'

    },

    cssLabel: {
        color: '#000000 !important',
        fontWeight:'lighter',
        textShadow: 'none !important',
        backgroundColor:0x282A28

    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            color: '#a9a9a9 !important',
            borderWidth: '1px',
            borderColor: '#1B66CA !important',
            textShadow: 'none !important'
        }
    },

    //this is the text
    cssFocused: {
        color: `black !important`,
        borderWidth: '1px',
        borderColor: 'black !important'
    },

    notchedOutline: {
        color: '#a9a9a9 !important',
        borderWidth: '0.5px',
        borderColor: '#a9a9a9 !important'
    },

});

/**
 *
 */
class CustomInput extends Component {

    render() {
        const {classes} = this.props;
        var {onChange, rows, label, value, type} = this.props;
        return (
            <TextField

                label={label}
                type={type}
                className={classes.textField}
                value={value}
                onChange={e => onChange(e.target.value)}
                margin="normal"
                fullWidth
                multiline={rows > 0}
                rows={rows}
                variant="outlined"
                InputLabelProps={{
                    classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                    }
                }}
            />
        );
    }
}

export default withStyles(styles)(CustomInput);
