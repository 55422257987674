import SceneReducer from './SceneReducer';
import AuthReducer from "./AuthReducer";
import ItemsReducer from './ItemsReducer';
import SuiteReducer from "./SuiteReducer";
import ActionResultReducer from "./ActionResultReducer";

import {firestoreReducer} from "redux-firestore";
import {firebaseReducer} from "react-redux-firebase";
import {combineReducers} from "redux";

export default combineReducers({
    scene: SceneReducer,
    auth: AuthReducer,
    items: ItemsReducer,
    suite: SuiteReducer,
    actionResult: ActionResultReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
});