import React, {Component} from 'react';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import {connect} from "react-redux";
import {editItem} from "../../../../redux/actions/itemActions";
import EditItem from "../../../Website/Admin/Views/ItemFolder/EditItem";
import {SM} from "../../../SceneManager"; // The default

class  EditItemPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            measurements: {
                height: 0,
                width: 0,
                length: 0,
            },
            selectedItem: null,
            name: '',
            start: true
        }
    }

    onItemSelected = () => {
        var {inSceneItem} = this.props;

        this.setState({
            selectedItem: inSceneItem,
            name: inSceneItem.metadata.itemName,
            start: false
        })
    };

    onValueEdited = (field, value) => {
        var item = this.state.selectedItem;

        if (field === 'measurements') {
            this.props.inSceneItem.setMeasurements(value);
        }
        if (field === 'type') {
            item.metadata.type=value;
            SM.remakeItem(item)
        }
        if (field === 'rotation') {
            item.rotate(value);
        }
    };

    render() {
        //this is interesting , because need the item to render this.
        var {inSceneItem, item} = this.props;

        if (inSceneItem && inSceneItem !== this.state.selectedItem) {
            this.onItemSelected();
        }
        return (
            <EditItem categories={this.props.categories}
                      item={item}
                      areas={this.props.areas}
                      editItemText={false}
                      onValueEdited={this.onValueEdited}
                      onEditItem={this.props.editItem}/>
        );
    }
}


export default connect(null, {
    editItem
})(EditItemPanel);
