import React from 'react';

const CardHeader = ({title}) => {
    return (
        <div className={'custom-card-header'}>
            <p>{title}</p>
        </div>
    )
};


const CardBody = () => {
    return (
        <div className={'custom-card-body'}>
            <b>50 Items Uploaded</b>
        </div>
    )
}

const CardFooter = () => {
    return (
        <div className={'custom-card-footer'}>
            <span></span>
            <i>Just Updated</i>
        </div>
    )
}

const SmallCard = ({title}) => {
    return (
        <div className={'custom-card-main'}>
            <CardHeader title={title} />
            <CardBody/>
            <CardFooter/>
        </div>
    );
};

export default SmallCard;