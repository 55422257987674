import React, {Component} from 'react';
import ConfirmationPopup from "../../../../General/UserInputs/ConfirmationPopup";
import {connect} from "react-redux";
import {deleteCategory, deleteItem} from "../../../../../redux/actions/itemActions";

class FolderControls extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDeletePopup: false
        }
    }

    render() {
        if (this.props.selectedCategories.length > 0) return this.renderSelectedFolderBar();
        if (this.props.selectedItems.length > 0) return this.renderSelectedItemsBar();
        return this.renderNormalView();
    }


    renderGoBack = () => {
        var {selectedCategory} = this.props;
        var {selectedItem} = this.props;

        return (
            <div className={'folder-controls-header'}>
                <span onClick={this.props.onClickHome} className={"fas fa-home"}></span>
                {selectedCategory ? <span className={'fas fa-chevron-right'}/> : null}
                {selectedCategory ? <p>{selectedCategory.name}</p> : null}
                {selectedItem ? <span className={'fas fa-chevron-right'}/> : null}
                {selectedItem ? <p>{selectedItem.name}</p> : null}
            </div>
        )
    };

    showSelectedText = () => {
        const {selectedCategories} = this.props;
        return `${selectedCategories.length} categories selected`
    };
    showSelectedTextItems = () => {
        const {selectedItems} = this.props;
        return `${selectedItems.length} items selected`
    };
    onDelete = () => {
        if (this.props.selectedCategory) {
            this.onDeleteItem();
        } else {
            this.onDeleteCategory();
        }
    };
    onDeleteItem = () => {
        this.props.selectedItems.forEach(i => {
            this.props.deleteItem(i)
        });
        this.setState({showDeletePopup: false})
    };

    onDeleteCategory = () => {
        this.props.selectedCategories.forEach(c => {
            this.props.deleteCategory(c)
        });
        this.setState({showDeletePopup: false})
    };

    showConfirmationPopup = () => {
        this.setState({showDeletePopup: true})
    };

    renderSelectedFolderBar = () => {
        return (
            <div>
                {this.renderConfirmationPopup()}
                <div className={"delete-bar"}>
                    <span onClick={this.props.deselectAll} className={"icon-sm fas fa-times"}
                          style={{cursor: 'pointer', margin: "0.2em"}}></span>
                    {this.showSelectedText()}
                    <button className={'default-button at-the-end'} onClick={this.showConfirmationPopup}>Delete</button>
                </div>
            </div>

        )
    };
    renderSelectedItemsBar = () => {
        return (
            <div>
                {this.renderConfirmationPopup()}
                <div className={"delete-bar"}>
                    <span onClick={this.props.deselectAllItems} className={"icon-sm fas fa-cross"}
                          style={{cursor: 'pointer', margin: "0.2em"}}></span>
                    {this.showSelectedTextItems()}
                    <button onClick={this.showConfirmationPopup}>Delete</button>
                </div>
            </div>

        )
    };

    renderNormalView = () => {
        return (
            <div className={"bottom-border-light"} style={{display: 'flex'}}>
                <div>
                    {this.renderGoBack()}
                </div>

            </div>
        )
    };

    renderConfirmationPopup = () => {
        if (!this.state.showDeletePopup) return null;
        return (
            <ConfirmationPopup title={"Delete"}
                               message={"Are you sure you want to delete this data? This can't be recovered."}
                               type={"warning"}
                               onConfirm={this.onDelete}
                               onCancel={() => this.setState({showDeletePopup: false})}/>
        )
    };

}


export default connect(null, {deleteCategory, deleteItem})(FolderControls);

