import {LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT_SUCCESS, REGISTER_FAILURE, REGISTER_SUCCESS} from "./types";
import {addActionResultError, loadingState} from "./actionResultActions";


export const registerAdvertiser = (newUser) => (dispatch, getState, {getFirebase, getFirestore}) => {

    dispatch(loadingState(true));

    const firebase = getFirebase();
    const firestore = getFirestore();

    if (!newUser.avatar) {
        dispatch(addActionResultError("You must select an avatar."));
        return;
    }
    if (!newUser.businessName || newUser.businessName.trim() === '') {
        dispatch(addActionResultError("You must have a businessName."));
        return;
    }
    //uploadPhoto(newUser,firebase);
    firebase.auth().createUserWithEmailAndPassword(
        newUser.email,
        newUser.password
    ).then((resp) => {
        return firestore.collection('advertisers').doc(resp.user.uid).set({
            businessName: newUser.businessName,
            avatar: newUser.avatar,
            public: true
        })
    }).then(() => {
        dispatch({type: REGISTER_SUCCESS});
        dispatch(loadingState(false));
    }).catch(err => {
        dispatch({type: REGISTER_FAILURE, err});
        dispatch(addActionResultError(err.message));
        dispatch(loadingState(false));
    })
};
export const register = (newUser) => (dispatch, getState, {getFirebase, getFirestore}) => {

    const firebase = getFirebase();
    const firestore = getFirestore();

    dispatch(loadingState(true));

    firebase.auth().createUserWithEmailAndPassword(
        newUser.email,
        newUser.password
    ).then((resp) => {
        return firestore.collection('users').doc(resp.user.uid).set({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            initials: newUser.firstName[0] + newUser.lastName[0],
            username: newUser.username,
            photo: "1.png",
            description: "."
        })
    }).then(() => {
        dispatch({type: REGISTER_SUCCESS});
        dispatch(loadingState(false));
    }).catch(err => {
        dispatch({type: REGISTER_FAILURE, err});
        dispatch(addActionResultError(err.message));
        dispatch(loadingState(false));
    })
};

export const logout = () => (dispatch, getState, {getFirebase}) => {

    const firebase = getFirebase();

    firebase.auth().signOut().then(() => {
        dispatch(loadingState(false));
        dispatch({type: LOGOUT_SUCCESS});
    })
};

export const login = (credentials) => (dispatch, getState, {getFirebase}) => {

    dispatch(loadingState(true));

    const firebase = getFirebase();

    firebase.auth().signInWithEmailAndPassword(credentials.email.trim(), credentials.password)
        .then((data) => {
            //how to navigate
            dispatch(loadingState(false));

            dispatch({type: LOGIN_SUCCESS, data})
        }).catch((err) => {
        dispatch(addActionResultError(err.message));
        dispatch(loadingState(false));
        dispatch({type: LOGIN_FAILURE, err})
    })

};
