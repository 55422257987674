import React, {Component} from 'react';
import {SM} from "../../SceneManager";
import rubber from '../../../assets/images/rubber.png';
import pointer from '../../../assets/images/pointer.png';
import draw from '../../../assets/images/draw.png';
import grid from '../../../assets/images/grid.png';
import move from '../../../assets/images/move.png';
import {center} from '../../../assets/images/controls';

import {Configuration} from "../../../modules/floorplanner/configuration";

class FloorPlannerControls extends Component {

    constructor(props) {
        super(props);
        this.state = {
            minimised: false,
            snapToGrid: Configuration.getNumericValue('snapToGrid') === 1 ? true : false //This gives number
        }
    }

    onMaterialChange = (e) => {
        SM.setDrawWallMaterial(e.target.value);
    };

    renderInnerContent = () => {
        const {minimised} = this.state;

        if (minimised) {
            return (
                <p className={'title-clean'}>2D Controls</p>
            )
        } else {
            return (
                <div>
                    <p className={'title-clean'}>2D Controls</p>

                    <div id="floorplanner-controls">
                        {
                            /*
                                     <div id={"floorplanner-view-controls"}>
                            <button
                                className={"btn-sm " + (this.props.floorPlannerDesignMode === "design" ? "button_custom_selected" : "button_custom")}
                                onClick={() => SM.setFloorPlannerDesignMode("design")}>Design
                            </button>
                            <button
                                className={"btn-sm " + (this.props.floorPlannerDesignMode === "building" ? "button_custom_selected" : "button_custom")}
                                onClick={() => SM.setFloorPlannerDesignMode("building")}>Building
                            </button>
                        </div>
                            */
                        }
                        <section>
                            <p className={'subtitle-clean'}>Wall Drawing</p>

                            <div>
                                <button id="move"
                                        className={"btn " + (this.props.mode === 0 ? "category-tab red-gradient" : "category-tab gray-gradient")}
                                        title="Move Walls" onClick={SM.move} style={{padding:10}}>
                                    <img alt={'pointer'} src={pointer} style={{maxWidth: '16px'}}/>
                                    <p>Move Mode</p>
                                </button>
                                <button id="draw"
                                        className={"btn btn-lg " + (this.props.mode === 1 ? "category-tab red-gradient" : "category-tab gray-gradient")}
                                        title="Draw New Walls" onClick={SM.draw} style={{padding:10}}>
                                    <img alt={'draw'} src={draw} style={{maxWidth: '16px'}}/>
                                    <p>Draw Mode</p>
                                </button>
                                <button id="delete"
                                        className={"btn btn-lg " + (this.props.mode === 2 ? "category-tab red-gradient" : "category-tab gray-gradient")}
                                        title="Delete Walls" onClick={SM.remove} style={{padding:10}}>
                                    <img alt={'rubber'} src={rubber} style={{maxWidth: '16px'}}/>
                                    <p>Delete Mode</p>
                                </button>
                            </div>
                        </section>

                        <section>
                            <p className={'subtitle-clean'}>Draw Helpers</p>
                            <div>
                                <button id="snap"
                                        className={"btn btn-lg " + (this.state.snapToGrid === true ? "category-tab red-gradient" : "category-tab gray-gradient")}
                                        title="Grid Snap" onClick={this.onChangeSnap} style={{padding:10}}>
                                    <img alt={'grid'} src={grid} style={{maxWidth: '16px'}}/>
                                    <p>Grid Snap</p>
                                </button>
                                <button id="nosnap"
                                        className={"btn btn-lg " + (this.state.snapToGrid === false  ? "category-tab red-gradient" : "category-tab gray-gradient")}
                                        title="Free Movement" onClick={this.onChangeSnap} style={{padding:10}}>
                                    <img alt={'move'} src={move} style={{maxWidth: '16px'}}/>
                                    <p>Free Move</p>
                                </button>
                                <button id="centre"
                                        className={"btn btn-lg category-tab gray-gradient"}
                                        title="Free Movement" onClick={SM.centreFloorPlan} style={{padding:10}}>
                                    <img alt={'move'} src={center} style={{maxWidth: '16px'}}/>
                                    <p>Center</p>
                                </button>
                            </div>
                        </section>

                        {
                            /*
                                                    <div style={{margin: "1.5em"}}>
                                                                  <b style={{margin: '1em'}}>Material Type</b>
                                                                  <select className={"form-control"} value={this.props.wallDrawType}
                                                                  onChange={this.onMaterialChange}>


                                                        <option value="wall">Wall</option>
                                                        <option value="glass">Glass</option>
                                                        <option value="invisible">Invisible</option>
                                                    </select>


                                                    </div>
                            */
                        }


                    </div>
                    <div className="btn-hint ">Press the "Esc" key to stop drawing
                        walls
                    </div>
                    {
                        //                <p>Tips&#10;Shift Key: Snap To Axis and Snap to Grid&#10;ESC: Stop drawing walls&#10;DbL-Click(Corner): Adjust Elevation&#10;Click(Room): Change Name&#10;</p>
                    }
                </div>
            )
        }
    }
    changeWindow = () => {
        var {minimised} = this.state;
        minimised = !minimised;
        this.setState({minimised})
    }
    render() {
        return (
            <div className={"interface-controls transparent-background"}>
                <button onClick={this.changeWindow} className={'btn-close'}>{!this.state.minimised ? <>&lt;</> : <>&gt;</>}</button>
                {this.renderInnerContent()}
            </div>
        );
    }
    onChangeSnap = () => {
        var snapToGrid = !this.state.snapToGrid;
        Configuration.setValue('snapToGrid',snapToGrid);
        this.setState({snapToGrid})
    }
}

export default FloorPlannerControls;
