import React, {Component} from 'react';
import Card from "../../../../General/Card/Card";
import CustomInput from "../../../../General/UserInputs/CustomInput";
import countryList from 'react-select-country-list'
import Select from 'react-select'

class Profile extends Component {
    constructor(props) {
        super(props)

        var options = countryList().getData()

        this.state = {
            countryOptions: options,
            countryValue: null,
        }
    }

    render() {
        return (
            <div>
                <div className={'dashboard-side-margin'}>
                    <Card children={this.renderChildren()}/>
                </div>
            </div>
        );
    }

    changeHandler = countryValue => {
        this.setState({ countryValue })
    }

    renderChildren = () => {

        return (
            <div>
                <CustomInput label={'Company Name'}/>
                <CustomInput label={'Email'}/>
                <Select
                    options={this.state.countryOptions}
                    value={this.state.countryValue}
                    onChange={this.changeHandler}
                />
            </div>
        )
    }
}

export default Profile;