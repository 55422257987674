export class UndoHistory {

    constructor() {

        this.past = [];
        this.present = [];
        this.future = [];
    }

    actionPerformed = (action) => {
        var actionPerformed = new ActionPerformed(action);
        this.past.push(actionPerformed);
    };

    //Ctrl - z
    undoPressed = () => {
        //Want to move from the last index in past, to last index in future
        let lastActionPerformed = this.past.pop();
        //console.log(lastActionPerformed);
        if (lastActionPerformed) {
            lastActionPerformed.performAction();
            this.future.push(lastActionPerformed);
        }
    };

    //Ctrl - v
    redoPressed = () => {
        //Want to
        let lastActionPerformed = this.future.pop();
        //console.log(lastActionPerformed);
        if (lastActionPerformed) {
            lastActionPerformed.performAction();
            this.past.push(lastActionPerformed);
        }
    }

}

export class ActionPerformed {

    constructor(action) {
        this.action = action;
    }

    get Action() {
        return this.action;
    }

    performAction = () => {
        var {type,position,object} = this.action;

        if (type === "move") {
            this.action = {
                type: 'move',
                object: object,
                position: object.position.clone()
            }
            object.position.set(position.x,position.y,position.z);
        }

    }
}
