import * as THREE from 'three';

//CAMERA
export const DEFAULT_CAMERA_POSITION = new THREE.Vector3(11, 50, 56);
export const MAX_CAMERA_DISTANCE = 4000;

//MATERIALS
export const GLASS_MATERIAL = new THREE.MeshPhysicalMaterial({
    side: THREE.DoubleSide,
    color: 0xC2D6E4,
    reflectivity: 0.8,
    metalness: 0.1,
    transparent: true,
    opacity: 0.2
});

//TODO: This was an issue w/ module and needing to define a type.
export const ItemTypes = {
    SHOWER: 'shower',
};

export const CollapsibleOpen = true;
