import React, {Component} from 'react';

class ConfirmationPopup extends Component {
    render() {
        const {title, message, onConfirm, onCancel} = this.props;
        return (
            <div className={"shadow-screen"}>
                <div className={"confirmation-box-wrapper slide-in-elliptic-top-fwd"}>
                    <div className={"confirmation-box-inner"}>
                        <div className={"title"}>
                            {title}
                        </div>
                        <div className={"message"}>
                            {message}
                        </div>
                        <div className={"flex-row"}>
                            <button className={'grey-button'} onClick={onCancel}>Cancel</button>
                            <button className={'red-button'} onClick={onConfirm}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmationPopup;