import * as THREE from 'three';
import {Mesh, MeshBasicMaterial, SphereGeometry, Vector2, Vector3} from 'three';
import {SM} from '../../SceneManager';
import Manager from "./Manager";
import {DEFAULT_CAMERA_POSITION, MAX_CAMERA_DISTANCE} from "../../../utils/constants/constantValues";
import {OrbitControls} from "../../../modules/floorplanner/three/orbitcontrols";
import {Utils} from "../../../modules/floorplanner/utils";

/**
 * Manages lighting and cameras.
 */
export default class CameraManager extends Manager {

    /**
     * Construct a new camera manager.
     */
    constructor() {
        super();
        this.scene = SM.scene;
        this.lastCameraSavePosition = new THREE.Vector3(0, 0, 0);
    }
    triggerUpdate = () => {
        this.controls.forceUpdate();
    }
    /**
     * When the manager starts.
     */
    start = () => {
        //Create a position the camera.
        this.createCamera();
        this.computeBoundingBox();
        this.setupControls();
        this.setupLightingRealistic();
        //this.setupSkybox();
        //this.setCameraInMiddle();
    };

    updateRotationSphere = () => {

        if (!this.sphere) {

            //var newDir = new Vector3(1, 1, 1);
            //var pos = new Vector3();
            //pos.addVectors(newDir, s.position);
            //this.sphere.lookAt(new Vector3(0, 1, 0));
        } else {
            this.sphere.position.copy(this.center);

            if (this.room && this.room.center) {
                //var newDir = new Vector3(1, 1, 1);
                //var pos = new Vector3();
                //pos.addVectors(newDir, SM.CameraManager.camera.position);
            }
        }
    };

    translateCamera = direction => {
        if (direction === "center") this.setCameraInMiddle(); else this.controls.onClickPan(direction);
    };

    positionInFrontOfWall = (obj) => {

        var direction = new THREE.Vector3();
        this.camera.position.copy(obj.edge.center).add(direction.multiplyScalar(1000));
        this.controls.target = obj.edge.center;
        var center = obj.edge.center;
        var material = new MeshBasicMaterial({color: 0xff0000});
        var geometry = new SphereGeometry(16, 32, 32);
        var sphere = new Mesh(geometry, material);
        sphere.position.copy(center);
        this.sphere = sphere;
        SM.scene.add(this.sphere);
        //Find rotation to this wall.
        var refVec = new Vector2(0, 1.0);
        // find angle between wall normals
        var normal2 = new Vector2();
        var normal3 = obj.edge.plane.geometry.faces[0].normal;
        normal2.x = normal3.x;
        normal2.y = normal3.z;
        var angle = Utils.angle(new Vector2(refVec.x, refVec.y), new Vector2(normal2.x, normal2.y));
        sphere.rotation.y = angle;

        direction = new THREE.Vector3();
        this.sphere.getWorldDirection(direction);
        this.camera.position.copy(obj.edge.center).add(direction.multiplyScalar(1000));
        this.controls.target = obj.edge.center;
        SM.scene.remove(this.sphere);
        this.lock();
    };
    lock = () => {
        this.controls.enabled = false;
    };
    unlock = () => {
        this.controls.enabled = true;
    };

    forceAnUpdate = () => {
        this.controls.forceUpdate();
    }
    /**
     * When the camera manager updates.
     */
    update = () => {
        this.controls.update();
    };

    /**
     * Whenever an object is selected, notify all managers.
     * @param object
     */
    notifyOnObjectClicked = (object) => {
    };

    /**
     * Whenever an object is hovered, notify all managers.
     * @param object
     */
    notifyOnObjectHovered = (object) => {
    };

    /**
     * Drag started callback.
     */
    dragStartCallback = () => this.controls.enabled = false;

    /**
     * Drag ended callback.
     */
    dragEndCallback = () => this.controls.enabled = true;

    setupControls = () => {
        //Orbit controls.
        this.controls = new OrbitControls(this.camera, document.getElementById("webgl-container"));
        this.controls.enableZoom = true;
        this.controls.enablePan = true;
        this.controls.panSpeed = 1
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 1;
        this.controls.enableKeys = false;
        this.controls.rotateSpeed = 1;
        this.controls.screenSpacePanning = false;
        this.controls.maxPolarAngle = Math.PI / 2;
        this.controls.maxDistance = MAX_CAMERA_DISTANCE;
        this.controls.addEventListener('change', this.onPositionChange);

        //Drag controls.
        //TODO: Move
        //this.dragControls = new GroupDragControls(SM, this.camera, SM.renderer.domElement);
        //this.dragControls.addEventListener('dragstart', this.dragStartCallback);
        //this.dragControls.addEventListener('dragend', this.dragEndCallback);
    };

    /**
     * Create and position the camera.
     */
    createCamera = () => {
        let camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 1, 20000);
        this.scene.add(camera);
        camera.position.copy(DEFAULT_CAMERA_POSITION);
        this.camera = camera;

        // remember these initial values
        this.tanFOV = Math.tan(((Math.PI / 180) * this.camera.fov / 2));
        this.windowHeight = window.innerHeight;
    };

    /**
     * Called every time the camera moves, trigger visibility check for every structure.
     * @param o
     */
    onPositionChange = (o) => {
        if (SM.activeGUIControls) {
            SM.activeGUIControls.update();
        }

    };

    onExteriorChange = flag => {
        //this.pointLight.visible = flag;
        //this.hemisphereLight.visible = flag
    }
    /**
     * Setup realistic lighting.
     */
    setupLightingRealistic = () => {
        this.ambientLight = new THREE.AmbientLight(0x404040 );
        this.scene.add(this.ambientLight);
        this.light = new THREE.PointLight(0xffffff, 0.001);
        //this.light.position.set(300, 300, 300);
        this.light.visible = false;
        //this.camera.add(this.light);
        //this.scene.add(this.light);
        this.pointLight = new THREE.PointLight( 0xffffff,0.2 );
        //pointLight.position.set(0,0,0);
        this.pointLight.visible = SM.TestMode;
        this.camera.add(this.pointLight);

        //Hemisphere light?
        this.hemisphereLight = new THREE.HemisphereLight( 0xffffbb, 0xffffff, 0.7 );
        this.hemisphereLight.visible = true
        this.scene.add( this.hemisphereLight );
        //var sphereSize = 1;
        //this.pointLightHelper = new THREE.PointLightHelper( this.light, sphereSize );
        //SM.scene.add( this.pointLightHelper );
    };
    updateOutsideLight = v => {
        this.ambientLight.intensity = v;
    }
    updateInsideLight = v => {
        this.hemisphereLight.intensity = v;
    }
    /**
     * What the camera should do when the window is resized.
     */
    onWindowResizeFixCamera = () => {
        //this.updateProjectionMatrix();
        if (SM.isIn2D) return;
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        var container = document.getElementById('webgl-container');
        SM.renderer.setSize(container.offsetWidth - 5, container.offsetHeight - 5);
    };

    /**
     * Compute the bounding box of the renderer.
     */
    computeBoundingBox = () => {
        //This call causes most property changes to take effect.
        this.camera.updateProjectionMatrix();
        var container = document.getElementById('webgl-container');
        SM.renderer.setSize(container.offsetWidth - 5, container.offsetHeight - 5);
        SM.renderer.setClearColor(0x000000);
        SM.container.appendChild(SM.renderer.domElement);
    };

    /**
     * Update the projection matrix of the camera.
     */
    updateProjectionMatrix = () => {
        let width = window.innerWidth;
        let height = window.innerHeight;
        this.originalAspect = width / height;
        this.camera.aspect = this.originalAspect;
        this.computeBoundingBox();
    };

    toggleRoomLighting = visible => {
        //this.light.visible = visible;
        if (visible) {
            this.hemisphereLight.intensity = 0.5;
        } else {
            this.hemisphereLight.intensity = 0.5;
        }
    };

    /**
     * Set the camera in the middle of the room.
     */
    setCameraInMiddle = () => {
        var scope = this;
        //var yOffset = 1.0;
        //Walls set at 300M by default, so halfway.
        var yOffset = 150;
        var pan = SM.model.floorplan.getCenter();
        pan.y = yOffset;
        scope.controls.target = pan;
        var distance = SM.model.floorplan.getSize().z * 3;

        //If no room returns bad distance, just default it out
        if (distance === 0) {
            distance = 1000;
        }
        var offset = pan.clone().add(new Vector3(0, distance, distance));
        // scope.controls.setOffset(offset);
        //this.pointLightHelper.position.copy(offset);
        scope.camera.position.copy(offset);
        this.light.position.copy(pan);

        scope.controls.update();
        this.unlock();
    };



}
