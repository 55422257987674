import {save} from "save-file";

const uuidv1 = require('uuid/v1');

/**
 * Called from sceneStateManager
 * will upload the file tagged to the user that they can retrieve later.
 * @param name
 * @returns {Function}
 */
export const pushFile = (blob) => (dispatch, getState, {getFirebase, getFireStore}) => {

    const firebase = getFirebase();

    // Create a root reference
    var storageRef = firebase.storage().ref();
    var childRef = storageRef.child(`scenes/scene_${uuidv1()}.msuite`);

    childRef.put(blob).then(function (snapshot) {
        console.log('Uploaded a blob or file!');
    });

    save(blob, `scene_${uuidv1()}.msuite`);

};

export const pullFile = (name, callback) => (dispatch, getState, {getFirebase}) => {

    const firebase = getFirebase();
    var storage = firebase.storage();

    console.log("getting scene file");

    // Create a reference to the file we want to download
    var starsRef = storage.ref().child('scenes/scene_3a6509a0-0a6b-11ea-aacd-b7dfd087239e.msuite');

    // Get the download URL
    starsRef.getDownloadURL().then(function (url) {
        // Insert url into an <home> tag to "download"
        console.log("Downloaded -> " + url);
        callback(url);
    }).catch(function (error) {

        console.error(error.code);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
            case 'storage/object-not-found':
                // File doesn't exist
                break;

            case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;

            case 'storage/canceled':
                // User canceled the upload
                break;

            case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;

            default:
                break;
        }
    });
};
