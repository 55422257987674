//import $ from 'jquery';
import {EventDispatcher, Mesh, MeshBasicMaterial, PlaneGeometry, Raycaster, Vector2, Vector3} from 'three';
import {EVENT_ITEM_LOADED, EVENT_ITEM_REMOVED} from '../events.js';
import {Utils} from '../utils.js';
import {SM} from "../../../components/SceneManager";
import {
    EVENT_CAMERA_ACTIVE_STATUS,
    EVENT_CAMERA_VIEW_CHANGE,
    EVENT_FLOOR_CLICKED,
    EVENT_GLTF_READY,
    EVENT_ITEM_SELECTED,
    EVENT_ITEM_UNSELECTED,
    EVENT_NOTHING_CLICKED,
    EVENT_WALL_CLICKED
} from "../events";
import {VIEW_FRONT, VIEW_ISOMETRY, VIEW_LEFT, VIEW_RIGHT, VIEW_TOP} from '../constants.js';
import {Item} from "../items/item";

export const states = {UNSELECTED: 0, SELECTED: 1, DRAGGING: 2, ROTATING: 3, ROTATING_FREE: 4, PANNING: 5};

// Controller is the class that maintains the items, floors, walls selection in
// the 3d scene
export class Controller extends EventDispatcher {
    constructor(model, camera, element, controls) {
        super();
        this.model = model;
        this.camera = camera;
        this.element = element;
        this.controls = controls;
        //this.hud = new HUD(this,SM.scene);

        this.enabled = true;
        this.scene = model.scene;

        this.plane = null;
        this.mouse = new Vector2(0, 0);
        this.alternateMouse = new Vector2(0, 0);

        this.intersectedObject = null;
        this.mouseoverObject = null;
        this.selectedObject = null;

        this.mouseDown = false;
        this.mouseMoved = false; // has mouse moved since down click
        this.rotateMouseOver = false;

        this.state = states.UNSELECTED;
        this.needsUpdate = true;

        var scope = this;
        this.itemremovedevent = (o) => {
            scope.itemRemoved(o.item);
        };
        this.itemloadedevent = (o) => {
            scope.itemLoaded(o.item);
        };

        this.mousedownevent = (event) => {
            scope.mouseDownEvent(event);
        };
        this.mouseupevent = (event) => {
            scope.mouseUpEvent(event);
        };
        this.mousemoveevent = (event) => {
            scope.mouseMoveEvent(event);
        };
        this.init();
    }


    init() {

        this.element.addEventListener('mousedown', this.mousedownevent, false);
        this.element.addEventListener('mouseup', this.mouseupevent, false);
        this.element.addEventListener('mousemove', this.mousemoveevent, false);

        this.element.addEventListener('touchstart', this.mousedownevent, false);
        this.element.addEventListener('touchmove', this.mousemoveevent, false);
        this.element.addEventListener('touchend', this.mouseupevent, false);
        //this.element.bind('touchstart mousedown', this.mousedownevent);
        //this.element.bind('touchmove mousemove', this.mousemoveevent);
        //this.element.bind('touchend mouseup', this.mouseupevent);

        this.scene.addEventListener(EVENT_ITEM_REMOVED, this.itemremovedevent);
        this.scene.addEventListener(EVENT_ITEM_LOADED, this.itemloadedevent);
        this.setGroundPlane();
    }

    itemRemoved(item) {
        // invoked as a callback to event in Scene
        if (item === this.selectedObject) {
            this.selectedObject.setUnselected();
            this.selectedObject.mouseOff();
            this.setSelectedObject(null);
        }
    }

    // invoked via callback when item is loaded
    itemLoaded(item) {
        //return;
        /*
        var scope = this;
        if (!item.position_set) {
            scope.setSelectedObject(item);
            scope.switchState(states.DRAGGING);
            var pos = item.position.clone();
            pos.y = 0;
            var vec = this.projectVector(pos);
            scope.clickPressed(vec);
        }
        item.position_set = true;

         */
    }


    clickPressed(vec2) {
        this.mouse = vec2 || this.mouse;
        var intersection = this.itemIntersection(this.mouse, this.selectedObject);
        if (intersection) {
            this.selectedObject.clickPressed(intersection);
        }
    }

    clickDragged(vec2) {
        var scope = this;
        this.mouse = vec2 || this.mouse;
        var intersection = scope.itemIntersection(this.mouse, this.selectedObject);
        if (intersection) {
            if (this.selectedObject.isLocked) return;
            if (scope.isRotating()) {
                this.selectedObject.rotate(intersection);
            } else {
                this.selectedObject.clickDragged(intersection);
            }
        }
    }

    showGroundPlane(flag) {
        this.plane.visible = flag;
    }

    setGroundPlane() {
        // ground plane used to find intersections
        var size = 10000;

        // The below line was originally setting the plane visibility to false
        // Now its setting visibility to true. This is necessary to be detected
        // with the raycaster objects to click walls and floors.
        this.plane = new Mesh(new PlaneGeometry(size, size), new MeshBasicMaterial({visible: false}));
        this.plane.rotation.x = -Math.PI / 2;
        this.plane.visible = true;
        this.scene.add(this.plane);
    }

    checkWallsAndFloors(lite=false) {

        // double click on a wall or floor brings up texture change modal
        if (this.state === states.UNSELECTED && this.mouseoverObject == null) {
            // check walls
            //var wallEdgePlanes = this.model.floorplan.wallEdgePlanes();
            var edges = [];
            if (SM.ExteriorMode) {
                edges = SM.model.floorplan.wallEdges();
                //console.log(SM.model.floorplan.wallEdgePlanes());
            } else {
                edges = SM.model.floorplan.wallEdges()
            }
            var planesIntersecting = [];
            var exteriorMode = SM.ExteriorMode;

            if (!exteriorMode) {
                edges.forEach(edge => {if (edge.main) {planesIntersecting.push(edge.plane)}})
            } else {
                edges.forEach(edge => {
                    if (!edge.wall.isInvisible) {
                        planesIntersecting.push(edge.plane)
                    }
                })
            }
            var wallIntersects = [];
            try {
                wallIntersects = this.getIntersections(this.mouse, planesIntersecting, true);
            } catch (exception) {
                console.error("Intersections contains null object.")
            }
            if (wallIntersects.length > 0) {
                if (lite) return wallIntersects;
                var wall = wallIntersects[0].object.edge;
                this.wallIsClicked(wall, wallIntersects[0].object);
                return wallIntersects;
            }

            // check floors
            var floorPlanes = this.model.floorplan.floorPlanes();
            var floorIntersects = this.getIntersections(this.mouse, floorPlanes, false);
            if (floorIntersects.length > 0) {
                if (lite) return;
                var room = floorIntersects[0].object.room;
                this.floorIsClicked(room);
                return;
            }
            this.nothingIsClicked();


        }

    }

    isRotating() {
        return (this.state === states.ROTATING || this.state === states.ROTATING_FREE);
    }


    mouseDownEvent(event) {
        var rect = this.element.getBoundingClientRect();
        var clientX = event.clientX;
        var clientY = event.clientY;

        if (event.touches) {
            clientX = event.touches[0].clientX;
            clientY = event.touches[0].clientY;

            this.alternateMouse.x = event.touches[0].clientX;
            this.alternateMouse.y = event.touches[0].clientY;
        }
        this.mouse.x = ((clientX- rect.left) / rect.width) * 2 - 1;
        this.mouse.y = -((clientY - rect.top) / rect.height) * 2 + 1;

        //check GUI controls
        if (SM.activeGUIControls) {
            var intersects = this.getIntersections(this.mouse, SM.activeGUIControls, false, false, true);
            if (intersects.length > 0) {
                var obj = intersects[0].object;
                if (obj.name === "delete") {
                    obj.parent.item.setUnselected();
                    SM.onDeleteItem(obj.parent.item);
                    this.switchState(states.UNSELECTED);
                    this.intersectedObject = null;
                } else if (obj.name === "lock") {
                    obj.parent.item.lock();
                }
            }
        }


        if (this.enabled) {
            event.preventDefault();

            this.mouseMoved = false;
            this.mouseDown = true;

            if (event.touches) {

                //In case if this is a touch device do the necessary to click and drag items
                this.updateIntersections();
                this.checkWallsAndFloors();
                //this.updateMouseover();
            }

            switch (this.state) {
                case states.SELECTED:
                    if (this.rotateMouseOver) {
                        this.switchState(states.ROTATING);
                    } else if (this.intersectedObject != null) {
                        this.setSelectedObject(this.intersectedObject);
                        if (!this.intersectedObject.fixed) {
                            this.switchState(states.DRAGGING);
                        }
                    } else {
                        this.setSelectedObject(null);
                    }
                    break;
                case states.UNSELECTED:
                    if (this.intersectedObject != null) {
                        this.setSelectedObject(this.intersectedObject);
                        if (!this.intersectedObject.fixed) {
                            this.switchState(states.DRAGGING);
                        }
                    }
                    break;
                case states.DRAGGING:
                case states.ROTATING:
                    break;
                case states.ROTATING_FREE:
                    this.switchState(states.SELECTED);
                    break;
                default:
                    break;
            }
        }
    }

    mouseMoveEvent(event) {
        event.preventDefault();
        if (this.enabled) {
            event.preventDefault();
            this.mouseMoved = true;

            var clientX = event.clientX;
            var clientY = event.clientY;

            if (event.touches) {
                clientX = event.touches[0].clientX;
                clientY = event.touches[0].clientY;

                this.alternateMouse.x = event.touches[0].clientX;
                this.alternateMouse.y = event.touches[0].clientY;
            }

            //alert("Touched screen.")
            var rect = this.element.getBoundingClientRect();
            this.mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1;
            this.mouse.y = -((clientY - rect.top) / rect.height) * 2 + 1;


            if (!this.mouseDown) {
                this.updateIntersections();
            }

            switch (this.state) {
                case states.UNSELECTED:
                    this.updateMouseover();
                    break;
                case states.SELECTED:
                    this.updateMouseover();
                    break;
                case states.DRAGGING:
                case states.ROTATING:
                case states.ROTATING_FREE:
                    this.clickDragged();
                    //this.hud.update();
                    this.needsUpdate = true;
                    break;
                default:
                    break;
            }
        }
    }

    mouseUpEvent() {
        if (this.enabled) {
            this.mouseDown = false;
            switch (this.state) {
                case states.DRAGGING:
                    if (this.selectedObject && this.selectedObject.clickReleased) {
                        this.selectedObject.clickReleased();
                        this.switchState(states.SELECTED);
                    }

                    break;
                case states.ROTATING:
                    if (!this.mouseMoved) {
                        this.switchState(states.ROTATING_FREE);
                    } else {
                        this.switchState(states.SELECTED);
                    }
                    break;
                case states.UNSELECTED:
                    if (!this.mouseMoved) {
                        this.checkWallsAndFloors();
                    }
                    break;
                case states.SELECTED:
                    if (this.intersectedObject == null && !this.mouseMoved) {
                        this.switchState(states.UNSELECTED);
                        this.checkWallsAndFloors();
                    }
                    break;
                case states.ROTATING_FREE:
                    break;
                default:
                    break;
            }
        }
    }

    switchState(newState) {
        if (newState !== this.state) {
            this.onExit(this.state);
            this.onEntry(newState);
        }
        this.state = newState;
        //this.hud.setRotating(this.isRotating());
    }

    onEntry(state) {
        switch (state) {
            case states.UNSELECTED:
                this.setSelectedObject(null);
                break;
            case states.SELECTED:
                this.controls.enabled = true;
                break;
            case states.ROTATING:
            case states.ROTATING_FREE:
                this.controls.enabled = false;
                break;
            case states.DRAGGING:
                this.setCursorStyle('move');
                this.clickPressed();
                this.controls.enabled = false;
                break;
            default:
                break;
        }
    }

    onExit(state) {
        switch (state) {
            case states.UNSELECTED:
            case states.SELECTED:
                break;
            case states.DRAGGING:
                if (this.mouseoverObject) {
                    this.setCursorStyle('pointer');
                } else {
                    this.setCursorStyle('auto');
                }
                break;
            case states.ROTATING:
            case states.ROTATING_FREE:
                break;
            default:
                break;
        }
    }

    selectedObject() {
        return this.selectedObject;
    }

    // updates the vector of the intersection with the plane of a given
    // mouse position, and the intersected object
    // both may be set to null if no intersection found
    updateIntersections() {

        //check GUI controls.
        //TODO: like checking hud and objects, should check for GUI controls here.
        // recursively intersecting item at the moment though for drag helper.
        //CHECK GUI CONTROLS.
        /*
        if (SM.activeGUIControls) {
            var intersects = this.getIntersections(this.mouse,SM.activeGUIControls, false,false,true);
            if (intersects.length > 0) {
                this.setCursorStyle('pointer');

            } else {
                this.setCursorStyle('auto');
            }
        }
         */

        // check the rotate arrow
        /*
        var hudObject = this.hud.getObject();
        if (hudObject != null)
        {
            var hudIntersects = this.getIntersections(this.mouse, hudObject, false, false, true);
            if (hudIntersects.length > 0)
            {
                this.rotateMouseOver = true;
                this.hud.setMouseover(true);
                this.intersectedObject = null;
                return;
            }
        }
        this.rotateMouseOver = false;
        this.hud.setMouseover(false);
        */

        // check objects
        var items = [];

        if (SM.ExteriorMode) {
            items = SM.getOuterWallItems();
        } else {
            items = this.model.scene.getItems();
        }

        var walls = this.checkWallsAndFloors(true);
        if (walls) {
            //console.log(walls);
            if (walls.length > 1) {
                return;
            }
        }
        /*
        if (this.checkWallsAndFloors()) {
            console.log("OVER WALL");
            return;
        }
         */

        var intersects = this.getIntersections(this.mouse, items, false, true, true);
        if (intersects.length > 0) {
            //If we have intersected the drag helper, grab the Item class to use.
            //NOTE: trying to avoid gltf meshes inside object
            var intersectedObject = intersects[0].object;
            if (intersectedObject instanceof Item || intersectedObject.parent instanceof Item) {
                this.intersectedObject = intersectedObject;
            }
            if (this.intersectedObject) {
                if (this.intersectedObject.parent instanceof Mesh) {
                    this.intersectedObject = this.intersectedObject.parent;
                }
                if (this.intersectedObject.parent instanceof Item) {
                    this.intersectedObject = this.intersectedObject.parent;
                }
            }

        } else {
            this.intersectedObject = null;
        }


    }

    // returns the first intersection object
    itemIntersection(vec2, item) {

        if (!item) return null;
        var customIntersections = item.customIntersectionPlanes();
        if (item.freePosition) {
            return this.freeMouse3D(vec2);
        }
        var intersections = null;
        if (customIntersections && customIntersections.length > 0) {
            intersections = this.getIntersections(vec2, customIntersections, true);
        } else {
            intersections = this.getIntersections(vec2, this.plane);
        }
        if (intersections.length > 0) {
            return intersections[0];
        } else {
            return null;
        }
    }

    // sets coords to -1 to 1
    normalizeVector2(vec2) {
        var retVec = new Vector2();
        retVec.x = ((vec2.x - this.widthMargin) / (window.innerWidth - this.widthMargin)) * 2 - 1;
        retVec.y = -((vec2.y - this.heightMargin) / (window.innerHeight - this.heightMargin)) * 2 + 1;
        return retVec;
    }

    //
    mouseToVec3(vec2) {
        var normVec2 = this.normalizeVector2(vec2);
        var vector = new Vector3(normVec2.x, normVec2.y, 0.5);
        vector.unproject(this.camera);
        return vector;
    }

    freeMouse3D(vec2) {
        var distance;
        var pos = new Vector3();
        var vector = this.mouseToVec3(vec2);
        vector.sub(this.camera.position).normalize();
        distance = -this.camera.position.z / vector.z;
        pos.copy(this.camera.position).add(vector.multiplyScalar(distance));
        return {point: pos, distance: distance};
    }

    // filter by normals will only return objects facing the camera
    // objects can be an array of objects or a single object
    getIntersections(vec2, objects, filterByNormals, onlyVisible, recursive, linePrecision) {

        var vector = this.mouseToVec3(vec2);
        onlyVisible = onlyVisible || false;
        filterByNormals = filterByNormals || false;
        recursive = recursive || false;
        linePrecision = linePrecision || 20;

        var direction = vector.sub(this.camera.position).normalize();
        //var raycaster = new Raycaster(this.camera.position, direction);
        //raycaster.linePrecision = linePrecision;

        var raycaster = new Raycaster();
        raycaster.setFromCamera(this.mouse, this.camera);

        var intersections;


        if (objects instanceof Array) {
            intersections = raycaster.intersectObjects(objects, recursive);
        } else {
            intersections = raycaster.intersectObject(objects, recursive);
        }

        // filter by visible, if true
        if (onlyVisible) {
            intersections = Utils.removeIf(intersections, function (intersection) {
                return !intersection.object.visible;
            });
        }

        // filter by normals, if true
        if (filterByNormals) {
            intersections = Utils.removeIf(intersections, function (intersection) {
                var dot = intersection.face.normal.dot(direction);
                return (dot > 0);
            });
        }
        return intersections;
    }

    // manage the selected object
    setSelectedObject(object) {
        if (this.state === states.UNSELECTED) {
            this.switchState(states.SELECTED);
        }
        if (this.selectedObject != null) {
            this.selectedObject.setUnselected();
        }
        if (object != null && object.setSelected) {
            this.selectedObject = object;
            this.selectedObject.setSelected();
            this.itemIsSelected(object);
        } else {
            this.selectedObject = null;
            this.itemIsUnselected();
        }

        SM.onItemSelected(this.selectedObject);
        this.needsUpdate = true;
    }

    // TODO: there MUST be simpler logic for expressing this
    updateMouseover() {
        if (this.intersectedObject != null) {
            if (this.mouseoverObject != null) {
                if (this.mouseoverObject !== this.intersectedObject) {
                    if (this.mouseoverObject.mouseOff) this.mouseoverObject.mouseOff();
                    this.mouseoverObject = this.intersectedObject;
                    if (this.mouseoverObject.mouseOver) this.mouseoverObject.mouseOver();
                    this.needsUpdate = true;
                } else {
                    // do nothing, mouseover already set
                }
            } else {

                this.mouseoverObject = this.intersectedObject;
                if (this.mouseoverObject.mouseOver) this.mouseoverObject.mouseOver();
                this.setCursorStyle('pointer');
                this.needsUpdate = true;
            }
        } else if (this.mouseoverObject != null) {
            if (this.mouseoverObject.mouseOff) this.mouseoverObject.mouseOff();
            this.setCursorStyle('auto');
            this.mouseoverObject = null;
            this.needsUpdate = true;
        }
    }

    changeCamera(newCamera) {
        this.camera = newCamera;
    }


    exportForBlender() {
        this.skybox.setEnabled(false);
        this.controller.showGroundPlane(false);
        this.model.exportForBlender();
    }

    gltfReady(o) {
        this.dispatchEvent({type: EVENT_GLTF_READY, item: this, gltf: o.gltf});
        this.skybox.setEnabled(true);
        this.controller.showGroundPlane(true);
    }

    itemIsSelected(item) {
        this.dispatchEvent({type: EVENT_ITEM_SELECTED, item: item});
    }

    itemIsUnselected() {
        this.dispatchEvent({type: EVENT_ITEM_UNSELECTED});
    }

    wallIsClicked(wall, obj) {
        //Tell
        SM.onWallClicked(wall, obj);
        this.dispatchEvent({type: EVENT_WALL_CLICKED, item: wall, wall: wall});
    }

    floorIsClicked(item) {
        SM.onFloorClicked(item);
        this.dispatchEvent({type: EVENT_FLOOR_CLICKED, item: item});
    }

    nothingIsClicked() {
        this.dispatchEvent({type: EVENT_NOTHING_CLICKED});
    }

    spin() {
        var scope = this;
        scope.controls.autoRotate = scope.options.spin && !scope.mouseOver && !scope.hasClicked;
    }

    dataUrl() {
        var dataUrl = this.renderer.domElement.toDataURL('image/png');
        return dataUrl;
    }

    stopSpin() {
        this.hasClicked = true;
        this.controls.autoRotate = false;
    }

    options() {
        return this.options;
    }

    getModel() {
        return this.model;
    }

    getScene() {
        return this.scene;
    }

    getController() {
        return this.controller;
    }

    getCamera() {
        return this.camera;
    }


    /*
     * This method name conflicts with a variable so changing it to a different
     * name needsUpdate() { this.needsUpdate = true; }
     */

    ensureNeedsUpdate() {
        this.needsUpdate = true;
    }

    rotatePressed() {
        this.controller.rotatePressed();
    }

    rotateReleased() {
        this.controller.rotateReleased();
    }

    setCursorStyle(cursorStyle) {
        document.body.style.cursor = cursorStyle;
    }

    updateWindowSize() {
        var scope = this;

        scope.heightMargin = scope.element.offset().top;
        scope.widthMargin = scope.element.offset().left;
        scope.elementWidth = scope.element.innerWidth();

        if (scope.options.resize) {
            scope.elementHeight = window.innerHeight - scope.heightMargin;
        } else {
            scope.elementHeight = scope.element.innerHeight();
        }

        scope.orthocamera.left = -window.innerWidth / 1.0;
        scope.orthocamera.right = window.innerWidth / 1.0;
        scope.orthocamera.top = window.innerHeight / 1.0;
        scope.orthocamera.bottom = -window.innerHeight / 1.0;
        scope.orthocamera.updateProjectionMatrix();

        scope.perspectivecamera.aspect = scope.elementWidth / scope.elementHeight;
        scope.perspectivecamera.updateProjectionMatrix();

        scope.fpscamera.aspect = scope.elementWidth / scope.elementHeight;
        scope.fpscamera.updateProjectionMatrix();

        scope.renderer.setSize(scope.elementWidth, scope.elementHeight);
        scope.needsUpdate = true;
    }

    isHoveringOverFloor = () => {

        if (SM.state.mode === "2d") return true;

        var floorPlanes = this.model.floorplan.floorPlanes();
        var floorIntersects = this.getIntersections(this.mouse, floorPlanes, false);
        return floorIntersects.length > 0;
    };

    centerCamera() {
        var scope = this;
        var yOffset = 150.0;
        var pan = scope.model.floorplan.getCenter();
        pan.y = yOffset;
        scope.controls.target = pan;
        var distance = scope.model.floorplan.getSize().z * 1.5;
        var offset = pan.clone().add(new Vector3(0, distance, distance));
        // scope.controls.setOffset(offset);
        scope.camera.position.copy(offset);
        scope.controls.update();
    }

    // projects the object's center point into x,y screen coords
    // x,y are relative to top left corner of viewer
    projectVector(vec3, ignoreMargin) {
        var scope = this;
        ignoreMargin = ignoreMargin || false;
        var widthHalf = scope.elementWidth / 2;
        var heightHalf = scope.elementHeight / 2;
        var vector = new Vector3();
        vector.copy(vec3);
        vector.project(scope.camera);

        var vec2 = new Vector2();
        vec2.x = (vector.x * widthHalf) + widthHalf;
        vec2.y = -(vector.y * heightHalf) + heightHalf;
        if (!ignoreMargin) {
            vec2.x += scope.widthMargin;
            vec2.y += scope.heightMargin;
        }
        return vec2;
    }

    sceneGraph(obj) {
        console.group(' <%o> ' + obj.name, obj);
        obj.children.forEach(this.sceneGraph);
        console.groupEnd();
    }

    switchWireframe(flag) {
        this.model.switchWireframe(flag);
        this.floorplan.switchWireframe(flag);
        this.render(true);
    }

    pauseTheRendering(flag) {
        this.pauseRender = flag;
    }

    switchView(viewpoint) {
        var center = this.model.floorplan.getCenter();
        var size = this.model.floorplan.getSize();
        var distance = this.controls.object.position.distanceTo(this.controls.target);
        this.controls.target.copy(center);

        switch (viewpoint) {
            case VIEW_TOP:
                center.y = 1000;
                this.dispatchEvent({type: EVENT_CAMERA_VIEW_CHANGE, view: VIEW_TOP});
                break;
            case VIEW_FRONT:
                center.z = center.z - (size.z * 0.5) - distance;
                this.dispatchEvent({type: EVENT_CAMERA_VIEW_CHANGE, view: VIEW_FRONT});
                break;
            case VIEW_RIGHT:
                center.x = center.x + (size.x * 0.5) + distance;
                this.dispatchEvent({type: EVENT_CAMERA_VIEW_CHANGE, view: VIEW_RIGHT});
                break;
            case VIEW_LEFT:
                center.x = center.x - (size.x * 0.5) - distance;
                this.dispatchEvent({type: EVENT_CAMERA_VIEW_CHANGE, view: VIEW_LEFT});
                break;
            case VIEW_ISOMETRY:
            default:
                center.x += distance;
                center.y += distance;
                center.z += distance;
                this.dispatchEvent({type: EVENT_CAMERA_VIEW_CHANGE, view: VIEW_ISOMETRY});
        }
        this.camera.position.copy(center);
        this.controls.dispatchEvent({type: EVENT_CAMERA_ACTIVE_STATUS});
        this.controls.needsUpdate = true;
        this.controls.update();
        this.render(true);
    }

    lockView(locked) {
        this.controls.enableRotate = locked;
        this.render(true);
    }

    // Send in a value between -1 to 1
    changeClippingPlanes(clipRatio, clipRatio2) {
        var size = this.model.floorplan.getSize();
        size.z = size.z + (size.z * 0.25);
        size.z = size.z * 0.5;
        this.clippingPlaneActive.constant = (this.model.floorplan.getSize().z * clipRatio);
        this.clippingPlaneActive2.constant = (this.model.floorplan.getSize().z * clipRatio2);

        if (!this.clippingEnabled) {
            this.clippingEnabled = true;
            this.renderer.clippingPlanes = this.globalClippingPlane;
        }
        this.controls.dispatchEvent({type: EVENT_CAMERA_ACTIVE_STATUS});
        this.controls.needsUpdate = true;
        this.controls.update();
        this.render(true);
    }

    resetClipping() {
        this.clippingEnabled = false;
        this.renderer.clippingPlanes = this.clippingEmpty;
        this.controls.needsUpdate = true;
        this.controls.update();
        this.render(true);
    }

    switchOrthographicMode(flag) {
        if (flag) {
            this.camera = this.orthocamera;
            this.camera.position.copy(this.perspectivecamera.position.clone());
            this.controls.object = this.camera;
            this.controller.changeCamera(this.camera);
            this.controls.needsUpdate = true;
            this.controls.update();
            this.render(true);
            return;
        }

        this.camera = this.perspectivecamera;
        this.camera.position.copy(this.orthocamera.position.clone());
        this.controls.object = this.camera;
        this.controller.changeCamera(this.camera);
        this.controls.needsUpdate = true;
        this.controls.update();
        this.render(true);
    }

    switchFPSMode(flag) {
        this.firstpersonmode = flag;
        this.fpscontrols.enabled = flag;
        this.controls.enabled = !flag;
        this.controller.enabled = !flag;
        this.controls.dispatchEvent({type: EVENT_CAMERA_ACTIVE_STATUS});

        if (flag) {
            this.skybox.toggleEnvironment(true);
            this.fpscontrols.lock();
        } else {
            this.skybox.toggleEnvironment(false);
            this.fpscontrols.unlock();
        }

        this.model.switchWireframe(false);
        this.floorplan.switchWireframe(false);
        this.render(true);
    }

    shouldRender() {
        var scope = this;
        // Do we need to draw a new frame
        if (scope.controls.needsUpdate || scope.controller.needsUpdate || scope.needsUpdate || scope.model.scene.needsUpdate) {
            scope.controls.needsUpdate = false;
            scope.controller.needsUpdate = false;
            scope.needsUpdate = false;
            scope.model.scene.needsUpdate = false;
            return true;
        } else {
            return false;
        }
    }

    rendervr() {

    }

    render(forced) {
        var scope = this;
        forced = (forced) ? forced : false;
        if (this.pauseRender && !forced) {
            return;
        }

        scope.spin();
        if (scope.firstpersonmode) {
            scope.fpscontrols.update(scope.fpsclock.getDelta());
            scope.renderer.render(scope.scene.getScene(), scope.fpscamera);

        } else {
            if (this.shouldRender() || forced) {
                scope.renderer.render(scope.scene.getScene(), scope.camera);
            }
        }
        scope.lastRender = Date.now();
    }
}
