import Enum from 'es6-enum';

export const VIEW_TOP = 'topview';
export const VIEW_FRONT = 'frontview';
export const VIEW_RIGHT = 'rightview';
export const VIEW_LEFT = 'leftview';
export const VIEW_ISOMETRY = 'isometryview';

export const WallTypes = Enum('STRAIGHT', 'CURVED');
export const WallDrawTypes = Enum('NORMAL', 'GLASS', 'BRICK','INVISIBLE');

//Dont know how to go enum to string?
export function wallDrawTypeToString(wallDrawType) {
    if (wallDrawType === WallDrawTypes.NORMAL) {
        return "Wood";
    }
    if (wallDrawType === WallDrawTypes.GLASS) {
        return "Glass"
    }
    if (wallDrawType === WallDrawTypes.INVISIBLE) {
        return "Invisible"
    }
}