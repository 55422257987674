import React, {Component} from 'react';
import HomeHeader from "../../Navigation/HomeHeader";
import ParticlesPlayer from "../../../General/Effects/ParticlesPlayer";
import floorplanImg from '../../../../assets/images/floorplan_draw2.png'
import homeCatalogueImg from '../../../../assets/images/home_catalogue.png'
import homepageHouseImg from '../../../../assets/images/homepage_house2.png'
import phoneExampleImg from '../../../../assets/images/phone_example1.png'
import msuiteLogoBlack from '../../../../assets/images/msuite_logo_black.png'
import getOnGooglePlayImg from '../../../../assets/images/getOnGooglePlay.png'
import getOnAppleStoreImg from '../../../../assets/images/getOnAppleStore.png'
import savenShareImg from '../../../../assets/images/savenShare.png'
import step1Img from '../../../../assets/images/demo/step1.png'
import laptop3DImg from '../../../../assets/images/demo/laptop3d.png'
import exploreImg from '../../../../assets/images/demo/explore.png'
import envatePhoto from '../../../../assets/images/envate.png'
import edigPhoto from '../../../../assets/images/edig.png'
import asbestossPhoto from '../../../../assets/images/asbestoss.png'

import {toast} from "react-toastify";

class Home extends Component {

    state = {
        feedbackSent:false,
        contactName: '',
        contactEmail: '',
        contactPhone:'',
        contactMessage: ''
    }

    componentDidMount() {
        var video = document.getElementById('home-video');

        video.addEventListener('loadeddata', function() {
            // Video is loaded and can be played
            //video.play();

        }, false);
    }

    sendFeedback = async () => {
        //const {feedbackSent} = this.state;
        const {contactName,contactEmail,contactPhone,contactMessage} = this.state;

        if (!contactName || contactName === '')
        {
            toast.error("Please enter a name.")

            return;
        }
        if (!contactEmail || contactEmail === '')
        {
            toast.error("Please enter an email.")

            return;
        }
        if (!contactPhone || contactPhone === '')
        {
            toast.error("Please enter a phone number.")

            return;
        }
        if (!contactMessage || contactMessage === '')
        {
            toast.error("Please enter a message.")

            return;
        }
        //if (feedbackSent) return;
        //this.setState({feedbackSent: true})

        //console.log("Sending feedback")
        await fetch(
            "https://us-central1-mastersuite-78d43.cloudfunctions.net/sendMail?dest=test@gmail.com",
            {
                method: "POST",
                headers: {
                    "Content-Type": "Application/JSON",
                    "Access-Control-Request-Method": "POST",
                    'Access-Control-Allow-Credentials': 'true',
                    'Access-Control-Allow-Origin': 'http://localhost:3000'
                },
                body: JSON.stringify({ subject: "Default",message:contactMessage,email:contactEmail,phone:contactPhone,name:contactName }),
                maxAge: 3600
                //"mode": "cors",
            }
        )
            .then(response => response.json())
            .then(body => {
                console.log(body);
                //const reader = response.body.getReader()
                //reader.read().then(value => {
                //console.log(value);
                toast.info("Feedback submitted! Thank you.")
            })
            .catch(err => {
                toast.info("Feedback submitted! Thank you.")

            });
    }
    renderContent = () => {
        return (
            <div className={'home-main'}>
                <div className={'image-banner'}>
                    <video id={'home-video'} autoPlay='autoplay' loop={true}  muted="muted" className={'image-video'}>
                        <source src="https://firebasestorage.googleapis.com/v0/b/mastersuite-78d43.appspot.com/o/images%2FHome%20-%2031290.mp4?alt=media&token=36f51b9f-31dd-4701-8cc5-6d9acb85026a"
                                type="video/mp4"/>
                    </video>
                    <div className={'image-banner-content'}>
                        <h1>Design Your Dream Home</h1>
                        <p>Generate <em>free floorplans</em> fast and easily in <em>3D</em> & <em>2D</em> on any device. <br/>MasterSuite is the best place to design a realistic floorplan that looks how you envision it.</p>
                        <div className={'home-cta'}>
                            <a href={'/suite'} rel="noopener noreferrer" target={'_blank'}>
                                <button className={'transparent-button-main'}>Get started for FREE</button>
                            </a>
                            <a href={'#contact'}>Get in contact today</a>
                        </div>
                    </div>
                </div>
                <div className={'home-todo-banner'} style={{marginTop: 0}} >
                    <p className={'home-ins-title'}>Utilise MasterSuite's extensive functionality</p>
                    <p className={'home-ins-subtitle'}>Choose from a variety of functions that help you design a free floorplan for your home.</p>
                </div>
                <div className={'home-instructions'}>
                    <div className={'home-ins-functionality'}>
                        <div>
                            <img src={floorplanImg} alt={'default'}/>
                            <p>Draw Your Floorplan</p>
                        </div>
                        <div>
                            <img src={homepageHouseImg} alt={'default'}/>
                            <p>View Your Floorplan in 3D</p>
                        </div>
                        <div>
                            <img src={homeCatalogueImg} alt={'default'}/>
                            <p>Furnish Your Home</p>
                        </div>
                        <div>
                            <img src={savenShareImg} alt={'default'}/>
                            <p>Save and Share</p>
                        </div>
                    </div>
                </div>
                <div className={'home-how-it-works'}>
                    <div className={'hhiw-left'}>
                        <img className={'hhiw-img'} alt={'default'} src={msuiteLogoBlack}/>
                        <h1>So how does it work?</h1>
                        <p>MasterSuite is a mobile and web application that can be used to design a home. Generate floorplans, walkthrough your home in 3D, furnish it with demo furniture, with this free, easy-to-use software.</p>
                        <div className={'hhiw-do'}>
                            <b>Use MasterSuite for free to:</b>
                            <div className={'hhiw-do-columns'}>
                                <ul>
                                    <li>Draw floorplans in 2D</li>
                                    <li>Add items from a large catalogue</li>
                                    <li>Move items in 2D and 3D</li>
                                    <li>Paint and tile floors and walls</li>
                                </ul>
                                <ul>
                                    <li>Save and load your designs</li>
                                    <li>Use realistic measurements</li>
                                    <li>Add lighting, shadows and adjust settings</li>
                                    <li>View the interior and exterior of your home</li>
                                </ul>
                            </div>
                        </div>
                        <div className={'hhiw-access'}>
                            <a href={'/suite'} rel="noopener noreferrer" target={'_blank'}><button className={'transparent-button-main'}>Get started for FREE</button></a>
                            <a rel="noopener noreferrer" target={'_blank'} href={'https://play.google.com/store/apps/details?id=com.envate.mastersuite&hl=en'}><img alt={'default'} src={getOnGooglePlayImg}/></a>
                            <span style={{cursor:'pointer'}} onClick={() => toast.error('Coming soon.')}><img alt={'default'} src={getOnAppleStoreImg}/></span>
                        </div>
                    </div>
                    <div className={'hhiw-right'}>
                       <img alt={'default'} className={'hhiw-img-lg'} src={phoneExampleImg}/>
                   </div>
                </div>
                <div className={'home-todo-banner'} id={'about'}>
                    <h1 className={'home-ins-title'}>Say goodbye to bloated and confusing floor planning software </h1>
                    <p className={'home-ins-subtitle'}>MasterSuite runs on web and mobile, being accessible from millions of devices worldwide. <br/>This software requires no external download and it is free to use forever!<br/>Follow a few simple steps to design your floorplan.</p>
                </div>
                <div className={'home-steps'}>
                    <div className={'home-step'}>
                        <div>
                            <img alt={'default'} src={step1Img}/>
                        </div>
                        <div>
                            <h1 className={'home-step-number'}>Step 1</h1>
                            <h1>Make your floorplan</h1>
                            <p>Draw your floorplan with various tools and options. <br/>Choose from a number of template floorplans that are premade, to inspire your designs!</p>
                        </div>
                    </div>
                    <div className={'home-step mob-flex-reverse'}>
                        <div>
                            <h1 className={'home-step-number '}>Step 2</h1>
                            <h1>View your floorplan in 3D</h1>
                            <p>View your 2D floorplan in 3D. Walkthrough and view how your home will look in detail, from the inside and outside. <br/> With additional graphical options and settings, designing becomes easy and fun!</p>
                        </div>
                        <div>
                            <img alt={'default'} src={laptop3DImg}/>
                        </div>
                    </div>
                    <div className={'home-step'}>
                        <div>
                            <img alt={'default'} src={exploreImg}/>
                        </div>
                        <div>
                            <h1 className={'home-step-number'}>Step 3</h1>
                            <h1>Explore a large number of features</h1>
                            <p>Utilise MasterSuite's large range of features. From an extensive, evolving catalogue of furniture, to saving and loading designs. <br/>You will find the toolkit you need to design your home.</p>
                        </div>
                    </div>
                </div>
                <div className={'home-todo-banner'} >
                    <h1 className={'home-ins-title'}>Partners and Affiliations</h1>
                    <p className={'home-ins-subtitle'}>Learn more about our partners and affiliations that have years of expertise in the construction industry.</p>                </div>
                <div className={'home-afil'}>
                    <div className={'home-afil-step'}>
                        <a rel="noopener noreferrer" target={'_blank'} href={'https://www.envate.com.au'}>
                            <img alt={"Envate Logo"} src={envatePhoto}/>
                        </a>
                        <p>As professional builders and project managers, Envate strives towards building a better environment for the future. With expertise in delivering quality commercial and residential developments, their services extend the entire project cycle from concept design through to project completion.Envate is proud of its highly skilled and quality team member’s spanning all disciplines will meet your most challenging individual project requirements.</p>
                    </div>
                    <div className={'home-afil-step'}>
                        <a rel="noopener noreferrer" target={'_blank'} href={'https://www.asbestoss.com'}>
                            <img alt={"Envate Logo"} src={asbestossPhoto}/>
                        </a>
                        <p>Asbestoss has over 10 years of experience working locally, based at South Brisbane, Australia, they provide their customers with experience in the safe removal of asbestos and the assurance of compliance with regulators. During removal, they ensure the asbestos abatement area fully contained and strictly follow all safety guidelines to avoid contamination.</p>
                    </div>
                    <div className={'home-afil-step'}>
                        <a rel="noopener noreferrer" target={'_blank'} href={'https://www.edig.com.au'}>
                            <img alt={"Envate Logo"} src={edigPhoto}/>
                        </a>
                        <p>eDig specialises in tight access and technically complex new foundations and remediation. Their customised and bespoke drilling / piering equipment provides efficiencies in time and labour which result in cost savings to their clients.</p>
                    </div>
                </div>
                <div className={'home-todo-banner'} id={'contact'}>
                    <h1 className={'home-ins-title'}>Still have questions or feedback? </h1>
                    <p className={'home-ins-subtitle'}>Please get in contact with us via email.</p>
                </div>
                <div className={'home-contact-form'} id={'contact'}>
                    <h1>Contact form </h1>
                    <p>Let us know what you think! We strive to provide better service, please don't hesitate to leave feedback.</p>
                    <div className={'hcf-form'}>
                        <div className={'hcf-form-row'}>
                            <input onChange={(input) => this.onChangeValue('contactName',input.target.value)} value={this.state.contactName} placeholder={'Your Name'}/>
                        </div>
                        <div className={'hcf-form-row'}>
                            <input onChange={(input) => this.onChangeValue('contactEmail',input.target.value)} value={this.state.contactEmail} placeholder={'Your Email'}/>
                        </div>
                        <div className={'hcf-form-row'}>
                            <input onChange={(input) => this.onChangeValue('contactPhone',input.target.value)} value={this.state.contactPhone} placeholder={'Your Phone'}/>
                        </div>
                        <div className={'hcf-form-row'}>
                        <textarea onChange={(input) => this.onChangeValue('contactMessage',input.target.value)} value={this.state.contactMessage} id="w3review" name="w3review" rows="4" cols="50" placeholder={'Message'}>

                        </textarea>
                        </div>
                        <button className={'transparent-button-main'} onClick={this.sendFeedback}>
                            Submit
                        </button>
                    </div>
                </div>

                <div className={'home-footer'}>
                    <p>Copyright © MasterSuite, Inc. All rights reserved. <a href={'/terms-of-use'} rel="noopener noreferrer" target={'_blank'}>Terms of Use</a> | <a
                        href={'/privacy-policy'} rel="noopener noreferrer" target={'_blank'}>Privacy Policy</a></p>

                </div>

                <ParticlesPlayer/>


            </div>
        )
    }
    onChangeValue = (name,val) => {
        var state = this.state;
        state[name] = val;
        this.setState(state);
    }
    render() {
        return (
            <div>
                <HomeHeader/>
                {this.renderContent()}
            </div>
        );
    }
}

export default Home;
