import React, {Component} from 'react';
import * as THREE from 'three';
import {Cache, TextureLoader} from 'three';
import {connect} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {BuilderManager, CameraManager, ControlsManager, GUIManager, ObjectManager, RoomManager} from './Suite/Managers';
import GameContainer from "./Suite/Containers/GameContainer";
import {pullFile, pushFile} from "../redux/actions/fileActions";
import DevelopmentModes from "./Suite/Controls/DevelopmentModes";
import FloorPlannerContainer from "./Suite/Containers/FloorPlannerContainer";
import {Floorplan3D} from "../modules/floorplanner/three/floorPlan3d";
import {Controller} from "../modules/floorplanner/three/controller";
import {Model} from "../modules/floorplanner/model/model"
import {Floorplanner2D} from "../modules/floorplanner/floorplanner";
import {EVENT_UPDATED,} from "../modules/floorplanner/events";
import {floorplannerModes} from "../modules/floorplanner/floorplanner_view";
import $ from "jquery";
import {Skybox} from "../modules/floorplanner/three/skybox";
import {wallDrawTypeToString} from "../modules/floorplanner/constants";
import FloorPlannerControls from "./Suite/Controls/FloorPlannerControls";
import Side2DPopupMeasurements from "./Suite/Controls/Side2DPopupMeasurements";
import PanControls from "./Suite/Controls/PanControls";
import ObjectControls from "./Suite/Controls/ObjectControls";
import {actionResultError, actionResultSuccess, changeLoadingState} from "../redux/actions/actionResultActions";
import {getUserScreenHeight, getUserScreenWidth} from "./Suite/Utility";
import {DndProvider} from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/lib/HTML5toTouch";
import DragAroundCustomDragLayer from "./Suite/DragNDrop/dragAroundCustomerLayer";
import Inspector from "./Suite/Inspector/Inspector";
import KeyEvents from "../utils/KeyEvents";
import defaultFloorPlan from '../utils/defaultFloorPlan.json';
import testFloorPlan from '../utils/testFloorPlan.json';
import {UndoHistory} from "../utils/UndoHistory";
import {ExteriorItem} from "../modules/floorplanner/items/exterior_item";
import {InWallItem, WallItem} from "../modules/floorplanner/items";
import renderLoader from "./Suite/Containers/LoadingScreen";
import Advertisement from "./General/Advertisement";

//Export the singleton instance.
let SM = null;
export {SM};

/**
 * Creating this like would do in something like Unity.
 * This will have a much lower overhead, more customized and easily rendered into any webpage.
 *
 * It has instances of all managers that manage seperate logic.
 *
 * Singleton design pattern.
 * Observer pattern used for all managers, to be notified of events.
 * Builder pattern to create objects.
 *
 */

export const floorPlannerViewTypes = {DESIGN: 0, BUILD: 1};
class SceneManager extends Component {

    //#region Lifecycle Hooks
    constructor(props) {
        super(props);
        this.state = {
            userHeight: getUserScreenHeight(),
            userWidth: getUserScreenWidth(),
            isDragging: false,
            isLoading: true,
            //Modes?
            mode: "3d",
            floorPlannerMode: "move",
            floorPlannerDesignMode: 'design', //Should be enums here. like floorplannermodes.DRAW
            //Light details?
            lightMode: "room",
            luminosity: 0.5,
            lightColor: 0xffffff

        };

        if (SM === null) {
            //console.info("Creating SM.");
        } else {
            console.error("SM remade.");
        }
        SM = this;
    }

    componentDidMount() {
        //this.props.changeLoadingState(true);

        this.setupScene();
        this.loadShowcaseObject();
        this.setupEventListeners();

    }


    /**
     * Destroy all traces of this singleton instance.
     */
    componentWillUnmount() {
        this.stop();
        this.destroyContext();
        this.cameraManager = null;
        this.objectManager = null;
        this.guiManager = null;
        this.roomManager = null;
        this.builderManager = null;
        this.scene = null;
        this.floorPlanner.stopResize();
        SM = null;
        window.removeEventListener('resize', this.handleWindowResize);
    }
    componentDidUpdate(prevProps, prevState) {


        //If state has changed.
        if (prevState !== this.state) {

            //Update camera if window size changed.
            const didWindowSizeChange = this.state.userHeight !== prevState.userHeight || this.state.userWidth !== prevState.userWidth;
            if (didWindowSizeChange) {
                if (this.CameraManager && this.CameraManager.camera)
                    this.CameraManager.onWindowResizeFixCamera();
            }

            //If mode changed
            if (prevState.mode !== this.state.mode) {
                this.handleWindowResize(true);
            }
        }

        //If props have changed
        if (prevProps !== this.props) {
            if (this.props.viewerMode) {
                if (this.props.showcaseObj !== prevProps.showcaseObj) {

                    //IF TYPE CHANGED, NEED TO REMAKE THE ITEM.
                    if (this.props.showcaseObj.type !== prevProps.showcaseObj.type) {
                        this.onDeleteItem(this.currentShowcaseObject);
                        this.loadShowcaseObject();
                    }
                    //FOR MEASUREMENTS AND STUFF, JUST UPDATE IT.
                    if (this.currentShowcaseObject) {
                        this.currentShowcaseObject.setMeasurements(this.props.showcaseObj.measurements);
                        if (this.props.showcaseObj.rotations !== prevProps.showcaseObj.rotations) {
                            this.currentShowcaseObject.rotate(this.props.showcaseObj.rotations[this.props.showcaseObj.rotations.length - 1])
                        }
                    }
                }
            }
        }
    }

    /**
     * Render hook.
     */
    render() {
        return (
            <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                <DragAroundCustomDragLayer/>
                {this.renderIsLoading()}

                <div className='app-container'>
                    {this.renderGameContainers()}
                    {this.renderAllControls()}
                </div>
            </DndProvider>
        )
    }
    //#endregion


    onHistoryChange = action => {
        this.undoHistory.actionPerformed(action);
    }


    get IsMobile() {
        return this.props.mobile;
    }

    //#region Setup
    /**
     * Setup the scene.
     */
    setupScene = () => {
        //Set width and height of container for later reference.
        this.width = this.container.clientWidth;
        this.height = this.container.clientHeight;
        this.offset = new THREE.Vector3();
        //Create a new three.js scene.
        this.scene = new THREE.Scene();
        this.lightObjects = [];
        this.clock = new THREE.Clock();
        this.shadowFlag = true;
        this.grassFlag = true;
        this.exteriorFlag = false;
        this.undoHistory = new UndoHistory();
        this.setupRenderer();
        this.setupLoadingManager();
        //Setup camera.
        this.CameraManager.start();
        //Setup room.
        this.RoomManager.start();
        //Start object manager.
        this.ObjectManager.start();
        //Start GUI Manager.
        this.GUIManager.start();
        //Start the render loop.
        this._dirty = true;
        this.startRenderLoop();
        //Start floor planner that will create room
        //Setup the floor planner, that handles the 2D <-> 3D link.
        this.setupFloorPlanner();
        //Setup skybox and the material ground.
        this.setupSkyBox();





        this.setupLights();
        this.setupController();
    };
    changeDirty = (dirty) => {
        this._dirty = !this._dirty;
    }

    setupEventListeners = () => {

        window.addEventListener('resize', this.handleWindowResize)
        if (!this.TestMode) {
            this.keyEvents = new KeyEvents();
        }
    };
    /**
     * Setup the WEBGL Renderer.
     */
    setupRenderer = () => {
        //Create the WebGl renderer.
        let renderer = new THREE.WebGLRenderer({antialias: true,powerPreference: "high-performance"});
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.BasicShadowMap;
        //renderer.sortObjects = true;
        renderer.setClearColor(0xffffff, 0);
        this.renderer = renderer;
    };

    setupLoadingManager = () => {

        this.loadingManager = new THREE.LoadingManager();

        /*
        () => {
            console.error("ISLOADING - show something");
            const loadingScreen = document.getElementById('loading-screen');
            if (loadingScreen) {
                loadingScreen.classList.add('fade-out');
                loadingScreen.addEventListener('transitionend', event => {
                });
            }
        });
         */
        this.loadingScreen = document.getElementById('loading-screen');

        this.loadingManager.onStart = this.startLoading;
        //THIS IS FOR INITIAL LOAD
        this.loadingManager.onLoad = this.stopLoading;


    };
    startLoading = () => {
        //console.error("LOADING STARTED")
        if (this.loadingScreen) {
            this.loadingScreen.classList.remove('fade-out');
            this.loadingScreen.classList.add('fade-in');
        }
    }
    stopLoading = () => {
        //this.props.changeLoadingState(false);
        //console.error("LOADING FINISHED")
        if (this.loadingScreen) {
            this.loadingScreen.classList.remove('fade-in');
            this.loadingScreen.classList.add('fade-out');

        }
        this._dirty = true;
        this.setState({isLoading:false})
        this.CameraManager.forceAnUpdate();

    };
    //#endregion

    //#region RenderLoop


    /**
     * Start the render loop for WEBGL.
     */
    startRenderLoop = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate)
        }
    };

    /**
     * Called every frame.
     */
    update = () => {
        //Update all other managers.
        this.CameraManager.update();
    };

    /**
     * Animate this scene, called every frame.
     */
    animate = () => {
        this.frameId = requestAnimationFrame(this.animate);

        this.update();
        //TWEEN.update();

        //Tell renderer to render scene every frame.
        if (this._dirty) {
            this.renderer.render(this.scene, this.CameraManager.camera)
            this._dirty = false;
        }
    };

    /**
     * Stop the animation.
     */
    stop = () => {
        cancelAnimationFrame(this.frameId);
    };

    //#endregion

    //#region Manager Getters
    /**
     * Get an array that has all the managers.
     * @returns {*[]}
     */
    get AllManagers() {
        if (!this.allManagers) {
            this.allManagers = [this.GUIManager, this.CameraManager, this.RoomManager, this.ObjectManager, this.BuilderManager, this.ControlsManager]
        }
        return this.allManagers;
    }
    /**
     * Manages dynamic building of objects.
     */
    get BuilderManager() {
        if (!this.builderManager) {
            this.builderManager = new BuilderManager();
        }
        return this.builderManager;
    }

    /**
     * Manages all objects, collections of various types.
     */
    get ObjectManager() {
        if (!this.objectManager) {
            this.objectManager = new ObjectManager();
        }
        return this.objectManager;
    }

    /**
     * Manages the room, floors, walls, dimensions.
     */
    get RoomManager() {
        if (!this.roomManager) {
            this.roomManager = new RoomManager();
        }
        return this.roomManager;
    }

    /**
     * Manages the camera & lighting.
     */
    get CameraManager() {
        if (!this.cameraManager) {
            this.cameraManager = new CameraManager();
        }
        return this.cameraManager;
    }

    /**
     * Manages the GUI that overlays the screen, stuff like arrows and direction.
     */
    get GUIManager() {
        if (!this.guiManager) {
            this.guiManager = new GUIManager();
        }
        return this.guiManager;
    }

    /**
     * Manages all controls.
     */
    get ControlsManager() {
        if (!this.controlsManager) {
            this.controlsManager = new ControlsManager();
        }
        return this.controlsManager;
    }


    //#endregion

    //#region
    onItemLoaded = (item) => {
        if (this.props.viewerMode) {
            var initialMeasurements = {
                width: item.getWidth(),
                height: item.getHeight(),
                length: item.getDepth()
            };

            this.currentShowcaseObject = item;
            this.props.onObjectLoadedUpdateForm(initialMeasurements);
        }
    };

    //#endregion


    //#region Should not be in this file TODO -------------------------------

    //LIGHTING
    get Luminosity() {
        return this.state.luminosity;
    }
    get LightColor() {
        return this.state.lightColor;
    }
    addLight = light => {
        this.lightObjects.push(light);
    };


    get SelectedObject() {
        return this.state.selectedObject;
    }

    get isIn2D() {
        return this.state.mode === "2d";
    }

    updateForViewer = rotation => {
        if (this.props.viewerMode) {
            this.props.updateForViewer(rotation);
        }
    };
    /**
     * Set if we are dragging an object or not.
     * @param drag Is dragging an object?
     */
    setDragging = (drag) => {
        if (this.state.isDragging !== drag) {
            this.setState({isDragging: drag})
        }
    };



    //#endregion TODO --------------------------------------------------------










    //#endregion

    /**
     * Change2D Mode, design vs building, this will all be primarily set in
     */
    setFloorPlannerDesignMode = mode => {
        this.floorPlanner.drawMode = mode;
        this.setState({floorPlannerDesignMode: mode})

    };

    triggerRedraw2D = () => {
        this.floorPlanner.view.draw();
    };






    loadShowcaseObject = () => {
        const {showcaseObj} = this.props;
        if (!showcaseObj) return;
        var spawnPoint = null;

        spawnPoint = this.model.floorplan.getCenter();
        var metadata = {
            itemName: showcaseObj.name,
            resizable: true,
            modelUrl: showcaseObj.objURL,
            textures: showcaseObj.textures,
            mtlURL:showcaseObj.mtlURL,
            itemType: showcaseObj.type,
            format: showcaseObj.format,
            rotation: 0,
            scale: showcaseObj.scale,
            measurements: showcaseObj.measurements,
            businessName: showcaseObj.businessName
        };
        //TODO: tie this in to models.json to get "metadata" for scale and rotation.
        this.model.scene.addItem(showcaseObj.type, showcaseObj.objURL, metadata, spawnPoint, null, showcaseObj.scale);
    };

    get TestMode() {
        return this.props.testMode;
    }
    //#endregion

    //#region Setup




    /**
     * Lite version of redraw room.
     * Does stuff that dosent cost much computation power.
     */
    onRedrawRoomLite = () => {
        //console.log("redraw lite")
        //1. forall items = > moveToPosition
        /*
        //2. only make sure room is edited once at the end of process, not every single movement.
        if (this.model.scene && this.model.scene.items) {
            this.model.scene.items.forEach(item => {
                if (item instanceof WallItem) {
                    item.updatePosition(true);

                }
                //item.updatePositionLite();
            })
        }

         */
    };

    onWallsStopMoving = () => {
        if (this.model.scene && this.model.scene.items) {
            this.model.scene.items.forEach(item => {
                if (item instanceof WallItem) {
                    item.updatePosition(true);

                }
                //item.updatePositionLite();
            })
        }
    }


    addCustomItem = (details) => {
        this.model.scene.addCustomItem(details);
    };

    //call this on drag for now.
    addItem = (item, spawnPoint, objectMountedTo) => {
        //This is the data about all objects that can be spawned, placed in the models.json file.
        //var details = this.ObjectManager.getObjectInformation(name);
        //Data that needs to be saved/loaded.
        //TODO: tie this in to models.json to get "metadata" for scale and rotation.
        this.model.scene.addItem(item.type, item.objURL, item, spawnPoint, null, item.scale,false,objectMountedTo);
        //this.setState({isLoading: true});
    };

    get TextureLoader() {
        if (!this.textureLoader) {
            this.textureLoader = new TextureLoader();
            Cache.enabled = true;
        }
        return this.textureLoader;
    }

    onDeleteItem = (item) => {
        this.model.scene.removeItem(item, false);
        this.setState({selectedObject:null})
    };

    switchWireframe = () => {
        this.wireframeFlag = !this.wireframeFlag;

        this.model.switchWireframe(this.wireframeFlag);
        this.floorplan3D.switchWireframe(this.wireframeFlag);
        this._dirty = true;
    };

    switchShadows = () => {

        this.shadowFlag = !this.shadowFlag;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.shadowMap.enabled = this.shadowFlag;
        this.renderer.autoClear = true;
        this.renderer.needsUpdate = true;

        for (var i = 0 ; i < this.lightObjects; i++) {
            this.lightObjects[i].castShadow = this.shadowFlag;
        }
        this.scene.traverse(obj => {
            if (obj.material) {
               obj.material.needsUpdate = true;
            }
            if (obj.children) {
                obj.traverse( child => {
                    if (child.material) {
                        child.material.needsUpdate = true;
                    }
                })
            }

        })

        this._dirty = true;
        //this.renderer.shadowMap.autoUpdate = true;
    };
    switchGrass = () => {
        this.grassFlag = !this.grassFlag;
        this.skybox.changeGrass(this.grassFlag)
        this._dirty = true;
    };

    switchExterior = () => {
        this.exteriorFlag = !this.exteriorFlag;
        this.CameraManager.onExteriorChange(this.exteriorFlag);
        //this.floorplan3D.redrawRoom();
        this.floorplan3D.showRoofs();
        this.setState({exteriorFlag:this.exteriorFlag})
        this._dirty = true;
        this.CameraManager.forceAnUpdate();
    };

    get ExteriorMode() {
        return this.exteriorFlag;
    }

    setupLights = () => {
        //this.lights = new Lights(this.scene, this.model.floorplan);
        if (this.props.testMode) {
            this.toggleRoomLighting('room');

        } else {
            this.toggleRoomLighting('room');

        }
    };


    setupSkyBox = () => {
        this.skybox = new Skybox(this.scene, this.renderer);
        this.switchGrass();
    };

    setupController = () => {
        this.controller = new Controller(this.model, this.CameraManager.camera, this.renderer.domElement, this.CameraManager.controls);
    };

    setupFloorPlanner = () => {
        this.model = new Model('');
        this.floorPlanner = new Floorplanner2D("floorplanner-canvas", this.model.floorplan);
        this.newDesign();
        this.load3DPlanner();
    };

    load3DPlanner = () => {
        this.floorplan3D = new Floorplan3D(SM.scene, this.model.floorplan, SM.CameraManager.controls);
        this.CameraManager.setCameraInMiddle();
        this.model.floorplan.addEventListener(EVENT_UPDATED, this.CameraManager.setCameraInMiddle);
    };

    getAllEdges = () => {
        return this.floorplan3D.edges;
    }
    getOuterWallItems = () => {

        var items = [];
        var allItems = SM.model.scene.getItems();

        allItems.forEach(item => {
            if (item instanceof ExteriorItem || item instanceof WallItem || item instanceof InWallItem) {
                items.push(item);
            }
        });

        return items;
    };

    setDrawWallMaterial = (value) => {
        //console.log("Setting draw wall material: " + value);
        this.model.floorplan.wallDrawType = value;
        //console.log("Set wall draw type to : " + value);
        this.setState({wallDrawType: value})
    };

    onWallClicked = (halfEdge, obj) => {
        //Just doing it here for now, because what is the point of RoomManager? just makes it more confusing atm.
        if (this.RoomManager.selectedTile) {
            const tex = this.RoomManager.selectedTile;
            //alert(tex);
            this.floorplan3D.edges.forEach((edge) => {
                edge.needsUpdate = true;
            });

            halfEdge.setTexture(tex,true,20);
            this.RoomManager.selectedTile = null;
            this.floorplan3D.edges.forEach((edge) => {
                edge.needsUpdate = false;
            });
            this._dirty = true;
        }
        if (this.RoomManager.selectedPaintColour) {
            const paint = this.RoomManager.selectedPaintColour;
            //Paint the wall with the selected paint.
            halfEdge.setColour(paint);
            halfEdge.wall.fireRedraw();
            this.RoomManager.selectedPaintColour = null;
            this._dirty = true;
        } else if (this.RoomManager.selectedWallMaterial) {
            const material = this.RoomManager.selectedWallMaterial;
            halfEdge.setTexture(material);
            this.RoomManager.selectedMaterial = null;
            this._dirty = true;
        } else if (this.BuilderManager.isBuilding) {
            this.BuilderManager.buildTile(obj);
            this._dirty = true;
        }
    };

    onFloorClicked = (floor) => {
        if (this.BuilderManager.selectedFloorTile) {
            var material = this.BuilderManager.selectedFloorTile;
            floor.setTexture(material.slug, material.url, true, 50, material.measurements);
            this.BuilderManager.selectedFloorTile = null;
        }
    };

    onItemSelected = (object) => {
        this.updateObjectSelectedForWebsite(object);
    };

    saveDesign = () => {
        var data = this.model.exportSerialized();
        localStorage.setItem('design',data);
        if (!this.props.mobile) {
            var a = window.document.createElement('a');
            var blob = new Blob([data], {type: 'text'});
            a.href = window.URL.createObjectURL(blob);
            a.download = 'design.msuite';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a)
        }
        this.props.actionResultSuccess("Saved design to storage.")

    };

    loadDesignFromFile = (file) => {
        this.model.loadSerialized(JSON.stringify(file));
        this.floorplan3D.redrawRoom();
    }
    loadDesignFromUrl = url => {
        fetch(url).then(res => {
            return res.json()
        }).then(data => {
            this.model.loadSerialized(JSON.stringify(data));
            this.floorplan3D.redrawRoom();
        })
    }
    /**
     * Load a design from a file.
     */
    loadDesign = () => {
        var files = $("#loadFile2d").get(0).files;

        //return if no file was chosen.
        if (files.length === 0) return;

        var reader = new FileReader();
        reader.onload = event => {
            var data = event.target.result;
            this.model.loadSerialized(data);
            this.floorplan3D.redrawRoom();
        };
        reader.readAsText(files[0]);

    };
    getJSONP = (url, success) => {

        var ud = '_' + +new Date(),
            script = document.createElement('script'),
            head = document.getElementsByTagName('head')[0]
                || document.documentElement;

        window[ud] = function(data) {
            head.removeChild(script);
            success && success(data);
        };

        script.src = url.replace('callback=?', 'callback=' + ud);
        head.appendChild(script);

    }
    newDesign = (reset=false) => {



        var default_home = JSON.stringify(defaultFloorPlan);


        var saved = localStorage.getItem('design');
        if (!reset) {
            if (saved) {
                default_home = saved;
            } else {
                //This will hold everything up if async.
                //So continues execution and calls this
                fetch('https://firebasestorage.googleapis.com/v0/b/mastersuite-78d43.appspot.com/o/presetHomes%2FstartingHome.json?alt=media&token=577a2202-4ced-4c71-9fb6-315a1c203720')
                    .then(res => {
                        return res.json();
                    }).then(data => {
                    this.model.loadSerialized(JSON.stringify(data));
                    this.floorplan3D.redrawRoom();
                })
            }
        }



        if (this.props.testMode)
        {
            default_home = JSON.stringify(testFloorPlan);
        }
        this.model.loadSerialized(default_home);
    };
    centreFloorPlan = () => {
        this.floorPlanner.centerLayout();
    };
    draw = () => {
        this.floorPlanner.setMode(floorplannerModes.DRAW,this.props.mobile);
    };

    move = () => {
        this.floorPlanner.setMode(floorplannerModes.MOVE);
    };

    remove = () => {
        this.floorPlanner.setMode(floorplannerModes.DELETE);
    };

    setFloorPlannerMode = (mode) => {
        this.setState({floorPlannerMode: mode})
    };

    toggleRoomLighting = lighting => {
        //console.log(lighting);
        let lights = this.lightObjects;

        if (lighting === "room") {
            this.CameraManager.toggleRoomLighting(true);
            //this.lights.toggleRoomLighting(false);
            lights.forEach(light => {
                light.intensity = this.state.luminosity;
            });
        } else {
            this.CameraManager.toggleRoomLighting(false);
            //this.lights.toggleRoomLighting(true);
            lights.forEach(light => {
                light.intensity = 0;
            });
            //this.setState({luminosity:0})
        }

        this.setState({lightMode: lighting});
        this._dirty = true;
    };

    //#endregion

    //#region Updates

    /**
     * Update the select object for the HTML.
     * @param obj Object to set to state.
     */
    updateObjectSelectedForWebsite = (obj) => {
        this.setState({
            selectedObject: obj
        });
    };

    //#endregion

    //#region Event Handlers
    /**
     * Callback for window resize.
     */
    handleWindowResize = (force) => {
        //console.log("Window resize.");

        var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        var height = getUserScreenHeight();
        if (force) {
            this.cameraManager.onWindowResizeFixCamera()
        }

        this._dirty = true;
        this.setState({userHeight: height, userWidth: width})
    };

    //#endregion


    //#region Utility

    /**
     * Destroy the application.
     */
    destroyContext = () => {
        try {
            this.container.removeChild(this.renderer.domElement);
            this.renderer.forceContextLoss();
            //this.renderer.context = null;
            //this.renderer.domElement = null;
            this.renderer = null;
        } catch (e) {
            console.error("Caught exception: destroy context");
            console.error(e);
        }
    };


    /**
     * Callback to freeze GUI after the builderManager started.
     */
    onBuilderManagerStarted = () => {
        this.setState({isTiling: true});
    };


    /**
     * When the exit tile button is pressed.
     */
    onExitTileMode = () => {
        this.CameraManager.setCameraInMiddle();
        this.BuilderManager.tearDownSelectionBox();
        //this.ObjectManager.toggleSceneObjectVisibility(true);
        this.setState({isTiling: false})
    };

    /**
     * When the tile selected area button is pressed.
     */
    tileSelectedArea = () => {
        this.BuilderManager.tileArea();
        this.onExitTileMode();

        this.setState({isTiling: false})
    };

    duplicateSelectedObject = () => {
        var {selectedObject} = this.state;

        var positionToSpawn = selectedObject.position.clone();
        positionToSpawn.x += selectedObject.getWidth();
        this.addItem(selectedObject.metadata,positionToSpawn);
    };

    remakeItem = (item) => {
        this.onDeleteItem(item);
        this.addItem(item.metadata);
    };

    //#endregion

    //#region Web Display

    renderTilerControls = () => {

        if (this.state.isTiling) {
            return (
                <div id={"tile-controls"}>
                    <button className={"btn-lg button_custom"} onClick={() => this.tileSelectedArea()}>
                        Confirm
                    </button>
                    <button className={"btn-lg button_custom"} onClick={() => this.onExitTileMode()}>
                        Exit
                    </button>
                </div>
            );
        }

        return null;
    };

    changeDisplayMode = (mode) => {
        if (mode === this.state.mode) return;
        if (mode === "3d") {
            //update 2D -> 3D bridge (THIS NEEDS TO HAPPEN FOR HALFEDGE REPRESENTATIONS TO MOVE
            this.model.update();
            this.floorplan3D.redrawRoom();
            this._dirty = true;
        }
        this.floorPlanner.setMode(floorplannerModes.MOVE,this.props.mobile);

        //Cursor gets stuck on type.
        //console.log("Changing cursor")
        document.body.style.cursor = "default";
        this.setState({mode})
    };


    editCurrentValue = val => {
        //var val = (Math.round(val * 100) / 100).toFixed(2)
        this.setState({currentValue2D: val});
        this.resizeWall(val)
    };

    render2DMeasurements = () => {
        if (this.state.mode === "2d") {
            return <Side2DPopupMeasurements wall={this.state.clickedWall2D}
                                            corner={this.state.clickedCorner2D}
                                            room={this.state.clickedRoom2D}
                                            currentVal={this.state.currentValue2D}
                                            editCurrentValue={this.editCurrentValue}
                                            resizeWall={this.resizeWall}
                                            changeWallMaterial={material => this.changeWallMaterial(material)}
                                            wallDrawType={this.state.wallDrawType}
                                            renameRoom={this.renameRoom}
                                            testsuite={this.props.testMode}
                                            mobile={this.props.mobile}/>
        }
    };

    render2DOptions = () => {
        if (this.state.mode === "2d") return <FloorPlannerControls
            mobile={this.props.mobile}
            floorPlannerDesignMode={this.state.floorPlannerDesignMode} wallDrawType={this.state.wallDrawType}
            mode={this.state.floorPlannerMode}/>;
    };

    setSelectedItem = item => {
        this.setState({selectedItem: item})
    };
    renderSelectedObjectControls = () => {
        if (this.props.viewerMode) return null;
        return (
            <ObjectControls mobile={this.props.mobile} selectedItem={this.state.selectedObject}/>
        )
    };
    renderDevModes = () => {
        if (this.props.viewerMode) return null;
        return (
            <DevelopmentModes changeDisplayMode={this.changeDisplayMode}
                              currentMode={this.state.mode}
                              width={this.state.userWidth}
                              mobile={this.props.mobile}
                              checkedExterior={this.state.exteriorFlag}
                              testMode={this.props.testMode}/>
        )
    };


    setCameraInMiddle = () => {
        this.CameraManager.setCameraInMiddle();
    };

    renderPanControls = () => {
        if (this.state.mode === "3d" && !this.props.viewerMode) return <PanControls
            translateCamera={direction => this.CameraManager.translateCamera(direction)}
            width={this.state.userWidth}
            mobile={this.props.mobile}
            testMode={this.props.testMode}/>;
    };

    onClickedWall2D = (wall,mode) => {
        if (mode === floorplannerModes.DRAW) return;
        this.setState({clickedWall2D: wall});
        if (wall) {
            this.editCurrentValue(wall.wallLength());
            this.setState({wallDrawType: wallDrawTypeToString(wall.wallDrawType)})
        }

    };
    onClickedCorner2D = corner => {
        //this.setState({clickedCorner2D: corner})
    };

    onClickedRoom2D = room => {
        //
        this.setState({clickedRoom2D: room});

        if (room) {
            if (this.BuilderManager.selectedFloorTile) {
                var material = this.BuilderManager.selectedFloorTile;
                room.setTexture(material.slug, material.url, true, 50, material.measurements);
                this.BuilderManager.selectedFloorTile = null;
            }
            this.editCurrentValue(room.name);
        }



    };






    resizeWall = (val) => {
        if(this.state.clickedWall2D) {
            if (val >= 100) {
                this.state.clickedWall2D.resize(val);
            }
        }
    };

    changeWallMaterial = material => {
        this.state.clickedWall2D.changeMaterial(material);
        this.setState({wallDrawType: wallDrawTypeToString(this.state.clickedWall2D.wallDrawType)})
    };

    renameRoom = (name) => {
        this.state.clickedRoom2D.setName(name);
    };

    renderGameContainers = () => {
        //TODO: This is just skipping the Inspector? and changing height?
        var {viewerMode} = this.props;
        //if (viewerMode) return this.renderViewModeGameContainer();
        //Return Inspector (SidePanel), GameContainer (3D), and FloorPlanner (2D).
        return (
            <div>
                {!viewerMode  &&
                    <Inspector mobile={this.props.mobile}
                               selectedObject={this.state.selectedObject}
                               testMode={this.props.testMode}
                               viewerMode={this.props.viewerMode}
                               objectManager={this.ObjectManager}
                               userHeight={this.state.userHeight}
                               isLoading={this.props.isLoading}
                               model={this.model}/>

                }
                <GameContainer isLoading={this.state.isLoading}
                               changeDisplayMode={this.changeDisplayMode}
                               isHidden={this.state.mode === "2d"}
                               isDragging={this.state.isDragging}
                               viewerMode={viewerMode}
                               width={this.state.userWidth}
                               height={this.state.userHeight}
                               setRef={(container) => {
                                   this.container = container
                               }}/>
                <FloorPlannerContainer isLoading={this.state.isLoading}
                                       isDragging={this.state.isDragging}
                                       item={this.state.selectedItem}
                                       changeDisplayMode={this.changeDisplayMode}
                                       isHidden={this.state.mode === "3d"}/>
            </div>
        );
    };
    renderIsLoading = () => {
        return renderLoader();//<SuiteLoader isLoading={this.state.isLoading}/>
    };

    renderAllControls = () => {
        return (
            <div>
                {this.render2DOptions()}
                {this.render2DMeasurements()}
                {this.renderDevModes()}
                {this.renderSelectedObjectControls()}
                {this.renderTilerControls()}
                {this.renderAdvertisement()}
                {this.renderPanControls()}
            </div>
        )
    };
    renderAdvertisement = () => {
        if (this.props.mobile) return null;
        if (this.TestMode) return null;
        return <Advertisement minutes={5}/>
    }


    //#endregion

    addActionResultError = msg => this.props.actionResultError(msg);
    addActionResultSuccess = msg => this.props.actionResultSuccess(msg);
}


const mapStateToProps = state => ({
    files: state.files,
    isLoading: state.actionResult.isLoading
});

export default connect(mapStateToProps, {
    pullFile,
    pushFile,
    actionResultError,
    actionResultSuccess,
    changeLoadingState
})(SceneManager);
