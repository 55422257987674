/**
 * @author HypnosNova / https://www.threejs.org.cn/gallery
 */

import {Vector2} from "three";

var SelectionHelper = (function () {

    class SelectionHelper {
        constructor(selectionBox, renderer, cssClassName, tileManager) {
            this.element = document.createElement('div');
            this.element.classList.add(cssClassName);
            this.element.style.pointerEvents = 'none';
            this.parent = null;
            this.tileManager = tileManager;
            this.renderer = renderer;
            this.startPoint = new Vector2();
            this.pointTopLeft = new Vector2();
            this.pointBottomRight = new Vector2();
            this.isDown = false;
            this.renderer.domElement.addEventListener('touchstart', function (event) {
                //alert("TOUCH START")
                this.isDown = true;
                this.onSelectStartMobile(event);
            }.bind(this), false);
            this.renderer.domElement.addEventListener('touchmove', function (event) {
                if (this.isDown) {
                    this.onSelectMoveMobile(event);
                }
            }.bind(this), false);
            this.renderer.domElement.addEventListener('touchend', function (event) {
                this.isDown = false;
                this.onSelectOver(event);
            }.bind(this), false);
            this.renderer.domElement.addEventListener('mousedown', function (event) {
                this.isDown = true;
                this.onSelectStart(event);
            }.bind(this), false);
            this.renderer.domElement.addEventListener('mousemove', function (event) {
                if (this.isDown) {
                    this.onSelectMove(event);
                }
            }.bind(this), false);
            this.renderer.domElement.addEventListener('mouseup', function (event) {
                this.isDown = false;
                this.onSelectOver(event);
            }.bind(this), false);
        }

        onSelectStart(event) {
            if (this.tileManager.DrawDisabled)
                return;

            if (!this.startPoint) this.startPoint = new Vector2();

            this.renderer.domElement.parentElement.appendChild(this.element);
            this.parent = this.renderer.domElement.parentElement;
            this.element.style.left = event.clientX + 'px';
            this.element.style.top = event.clientY + 'px';
            this.element.style.width = '0px';
            this.element.style.height = '0px';
            this.startPoint.x = event.clientX;
            this.startPoint.y = event.clientY;
        }

        onSelectStartMobile(event) {
            if (this.tileManager.DrawDisabled)
                return;
            event = event.changedTouches[0];

            if (!this.startPoint) this.startPoint = new Vector2();

            this.renderer.domElement.parentElement.appendChild(this.element);
            this.parent = this.renderer.domElement.parentElement;
            this.element.style.left = event.clientX + 'px';
            this.element.style.top = event.clientY + 'px';
            this.element.style.width = '0px';
            this.element.style.height = '0px';
            this.startPoint.x = event.clientX;
            this.startPoint.y = event.clientY;
        }

        onSelectMove(event) {


            if (this.tileManager.DrawDisabled) {
                return;
            }
            this.pointBottomRight.x = Math.max(this.startPoint.x, event.clientX);
            this.pointBottomRight.y = Math.max(this.startPoint.y, event.clientY);
            this.pointTopLeft.x = Math.min(this.startPoint.x, event.clientX);
            this.pointTopLeft.y = Math.min(this.startPoint.y, event.clientY);
            this.element.style.left = this.pointTopLeft.x + 'px';
            this.element.style.top = this.pointTopLeft.y + 'px';
            this.element.style.width = (this.pointBottomRight.x - this.pointTopLeft.x) + 'px';
            this.element.style.height = (this.pointBottomRight.y - this.pointTopLeft.y) + 'px';
            //alert(this.element);
        }

        onSelectMoveMobile(event) {
            if (this.tileManager.DrawDisabled)
                return;
            event = event.changedTouches[0];
            this.pointBottomRight.x = Math.max(this.startPoint.x, event.clientX);
            this.pointBottomRight.y = Math.max(this.startPoint.y, event.clientY);
            this.pointTopLeft.x = Math.min(this.startPoint.x, event.clientX);
            this.pointTopLeft.y = Math.min(this.startPoint.y, event.clientY);
            this.element.style.left = this.pointTopLeft.x + 'px';
            this.element.style.top = this.pointTopLeft.y + 'px';
            this.element.style.width = (this.pointBottomRight.x - this.pointTopLeft.x) + 'px';
            this.element.style.height = (this.pointBottomRight.y - this.pointTopLeft.y) + 'px';
            //alert(this.element);
        }

        onSelectOver() {
            //if(this.tileManager.DrawDisabled) return;
            //this.element.parentElement.removeChild( this.element );
        }

        clear() {

            if (this.parent != null) {
                this.parent.removeChild(this.element);
            }
        }
    }


    return SelectionHelper;

})();

export {SelectionHelper};