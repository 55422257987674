import React, {Component} from 'react';

class Settings extends Component {
    render() {
        return (
            <div>
                <p>Settings Page</p>
                    <div className={'center-element'}>
                        <h1>Settings</h1>
                    </div>
            </div>
        );
    }
}

export default Settings;