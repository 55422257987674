import React from 'react'
import { Chart } from 'react-charts'

export default function BasicChart() {
    const data = React.useMemo(
        () => [
            {
                label: 'Series 1',
                data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
            }
        ],
        []
    )

    const axes = React.useMemo(
        () => [
            { primary: true, type: 'linear', position: 'bottom' },
            { type: 'linear', position: 'left' }
        ],
        []
    )

    const lineChart = (
        // A react-chart hyper-responsively and continuusly fills the available
        // space of its parent element automatically
        <div
            className={'box'}
            style={{
                width: '400px',
                height: '300px',
                marginLeft: '300px'
            }}
        >
            <Chart data={data} axes={axes} />
        </div>
    )
    return lineChart;
}