import React, {Component} from 'react';
import envateAd from './envate-ad.png'
import edigAd from './edig-ad.png'
import asbestossAd from './asbestoss-ad.png'

const ads = [
    {
        img: edigAd,
        referTo: 'https://www.edig.com.au'
    },
    {
        img: envateAd,
        referTo: 'https://www.envate.com.au'
    },
    {
        img: asbestossAd,
        referTo: 'https://www.asbestoss.com.au'
    }
]
class Advertisement extends Component {


    state = {
        secondsUntilAd: 600,
        adIndex: 0,
    }
    componentDidMount() {
        this.timer = setInterval(this.countDown, 1000);
        //this.setState({ seconds: 20});
    }
    countDown = () => {
        let secondsUntilAd = this.state.secondsUntilAd - 1;
        if (secondsUntilAd === 0) {
            var ad = document.getElementById('envate-ad')
            if (ad) {
                ad.classList.remove('faded-out');
                ad.classList.remove('fade-out');
                ad.classList.add('fade-in');
                clearInterval(this.timer);
                var adIndex = this.state.adIndex;
                adIndex++;
                if (adIndex > ads.length-1) {
                    adIndex = 0;
                }
                this.setState({adIndex: adIndex,secondsUntilAd:300})
            }

        } else {
            this.setState({secondsUntilAd})
        }



    }
    closeAd = () => {
        var ad = document.getElementById('envate-ad')
        if (ad) {
            ad.classList.remove('fade-in');
            ad.classList.add('fade-out');
        }
        this.timer = setInterval(this.countDown, 1000);


    }
    render() {
        var {adIndex} = this.state;

        return (
            <div id={'envate-ad'} className={'faded-out'}>
                <button className={'button_custom'} onClick={this.closeAd}>Close</button>
                <a href={ads[adIndex].referTo} rel="noopener noreferrer" target={'_blank'}>
                    <img alt={'envate'} src={ads[adIndex].img}/>

                </a>
            </div>
        );
    }
}

export default Advertisement;
