import React from 'react';
import {SM} from "../../SceneManager";
//import exteriorIcon from "../../../assets/images/settingsIcons/exterior.png";
//import {newFile, saveFile, uploadFile} from '../../../assets/images/files';
import floorplan2DIcon from '../../../assets/images/tabs/floorplan2d.png';
import floorplan3DIcon from '../../../assets/images/tabs/floorplan3d.png';
import showExteriorIcon from '../../../assets/images/exteriorShow.png'

const CheckBox = ({label,icon,onChange,checked,checkedName}) => {
    return (
        <button className={checked ? 'category-tab selected-gradient' : 'category-tab  main-gradient'} onClick={() => onChange(checkedName)}>
            <img alt={label} src={icon}/>
            <p>{label}</p>
        </button>
    );
};

const DevelopmentModes = ({changeDisplayMode,currentMode,mobile,checkedExterior}) => {

    /*
    function onNewDesign() {
        SM.newDesign(true);
        SM.floorplan3D.redrawRoom();
    };
    */

    /*
    function onRedoPressed() {
        SM.undoHistory.redoPressed();
        SM._dirty = true;
    }
    function onUndoPressed() {
        SM.undoHistory.undoPressed();
        SM._dirty = true;
    }
    */
    return (
        <div className={"development-modes ignore-input"} >
            <div className={'change-modes'}>
                            <button onClick={() => changeDisplayMode("2d")} className={currentMode === "2d" ? "category-tab red-gradient" : "category-tab gray-gradient"}>
                                <img src={floorplan2DIcon} alt={'floorplan'}/>
                                <p>Floorplan</p>
                            </button>
                <button onClick={() => changeDisplayMode("3d")} style={{borderRadius:''}} className={currentMode === "3d" ? "category-tab red-gradient" : "category-tab gray-gradient"}>
                    <img src={floorplan3DIcon} alt={'floorplan'} />
                    <p>3D View </p>
                </button>
            </div>

            {
                currentMode === "3d" &&
                <div style={{marginTop:'0.4em'}} className={'show-or-hide-house'}>
                    <CheckBox label={"Show Exterior"} checkedName={'checkedExterior'} icon={showExteriorIcon} checked={checkedExterior} onChange={SM.switchExterior}/>
                    {
                    //    <CheckBox label={"Center Home"} checkedName={'checkedExterior'} icon={exteriorIcon} checked={false} onChange={SM.setCameraInMiddle}/>
                    }
                </div>
            }

            {
                /*
                <div className={'state-control-icons'} >
                <button onClick={onUndoPressed} className={'main-gradient'}>
                    <img src={undoIcon} alt={'undo'}/>
                </button>
                <button onClick={onRedoPressed} className={'main-gradient'}>
                    <img src={redoIcon} alt={'redo'}/>
                </button>
            </div>

                 */

            }

            {
                /*


                 */

            }

            {
                /*

                 <div style={{margin:0,marginLeft:'1em'}} >
                <div style={{display:'flex',flexDirection:'row'}} className={'save-load-designs'}>
                    <div href="#"
                         className="btn-io btn-low btn-file btn-stretch"
                         id="new2d" title="Necw Layout" onClick={onNewDesign}>
                        <p>Reset</p>
                        {
                            //    <img alt={"new file"} src={newFile}/>
                        }
                    </div>
                    <div href="#" className="btn-io btn-low btn-file btn-stretch" id="saveFile2d" onClick={SM.saveDesign}
                         title="Save Layout">
                        <p>Save</p>
                        {
                            //    <img alt={"save file"} src={saveFile}/>
                        }
                    </div>
                    <div className="btn-io btn-low btn-file btn-stretch">
                        <p>Load</p>
                        <input type="file" className="hidden-input" id="loadFile2d" onChange={SM.loadDesign}/>
                        {
                            //    <img alt="upload file" src={uploadFile}/>
                        }
                    </div>
                </div>
            </div>
                 */
            }

        </div>
    );
};

export default DevelopmentModes;
