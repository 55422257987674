import React, {Component} from 'react';
import HomeHeader from "../../Navigation/HomeHeader";

class Contact extends Component {
    render() {
        return (
            <div>
                <HomeHeader/>
                <p>Contact</p>
            </div>
        );
    }
}

export default Contact;
