import React from 'react';
import homeIcon from '../../../../assets/images/tabs/home.png'
import infoIcon from '../../../../assets/images/tabs/info.png'
import catalogueIcon from '../../../../assets/images/tabs/catalogue.png'
import cartIcon from '../../../../assets/images/tabs/cart.png'
import settingsIcon from '../../../../assets/images/tabs/settings.png'
import customizeIcon from '../../../../assets/images/tabs/customize.png'

const icons = {
    "Home":homeIcon,
    "Information":infoIcon,
    "Catalogue":catalogueIcon,
    "Cart":cartIcon,
    "Settings":settingsIcon,
    "Customize":customizeIcon
};

const Tabs = (props) => {

    function createTab(type) {
        return (
            <span id={type} onClick={() => props.onClick(type)} title={type} className={type === props.tabOpen ? "tab-selected red-gradient" : "tab main-gradient"}>
                <img alt='icon' className={'tab-img'} src={icons[type]}/>
                <p>{type}</p>
            </span>
        )
    }

    return (
        <div id={props.mobile ? "tabs_mobile" : "tabs"}>
            {createTab('Home')}
            {createTab("Catalogue")}
            {createTab("Settings")}
        </div>
    );
};

export default Tabs;
