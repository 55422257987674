import React, {Component} from 'react';
import {connect} from "react-redux";
import {createCategory, createItem} from '../../../../../redux/actions/itemActions';
import firebase from "../../../../../config/firebase";
import FileUploader from "react-firebase-file-uploader";

import TestSuite from "../TestSuite/TestSuite";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import CallMade from '@material-ui/icons/CallMade';
import Input from "@material-ui/core/Input";
import {actionResultError, actionResultSuccess} from "../../../../../redux/actions/actionResultActions";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "../../../../General/UserInputs/CustomInput";
import FileUploaderWrapper from "../../../../General/Files/FileUploaderWrapper";
import {PrettoSlider} from "../../../../General/UserInputs/PrettoSlider";
import {center, down, left, right, up} from "../../../../../assets/images/controls";
import LoadingSpinnerProgress from "../../../../General/Loading/LoadingSpinnerProgress";
import LottiePlayer from "../../../../General/Effects/LottiePlayer";
import rocket from '../../../../../assets/lotties/rocket';
import ModelViewer from "../../../../../modules/FastModelUploader/ModelViewer";

//const obj2gltf = require('obj2gltf');
//const fs = require('fs');

const icons = [
    'fas fa-camera',
    'fas fa-fish',
    'fas fa-align-center',
    'fas fa-align-justify'
];

const acceptedFileTypes = [
    'obj',
    'gltf',
    'glb',
    'fbx',
    'FBX',
    'dae',
];

var initialState = {
    icon: icons[0],
    name: '',
    objURL: null,
    mtlURL: null,
    category: 0,
    description: '',
    type: 1,
    public: null,
    measurements: {
        length: 0,
        width: 0,
        height: 0
    },
    scale: {},
    step: 0,
    rotations: [],
    uploadingCount: 0,
    textures: [],
    renderFirebaseUploader: true,
}
class CreateItemPopup extends Component {


    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            //set this.state.category.
            var category = 0;
            for (var i = 0 ; i < this.props.categories.length ; i++) {
                if (this.props.categories[i].name === this.props.category) {
                    category = i;
                }
            }
            this.setState({category});
        }
    }

    render() {
        if (!this.props.isOpen) return null;
        const {step} = this.state;
        return (
            <div className={"shadow-screen"}>
                <div className={"create-item-wrapper middle-of-page slide-in-elliptic-top-fwd"}>
                    <div className={"create-item-inner"}>
                        {this.renderTitlePanel()}
                        {this.renderStep(step)}
                        {this.renderFooterPanel()}
                    </div>
                </div>
            </div>
        );
    }

    renderTitlePanel = () => {
        return (
            <div className={'popup-title'}>
                <h3 className={'flex-start'}>{this.getTitle()}</h3>
                <span style={{cursor: 'pointer'}} onClick={() => this.toggleOff()}
                      className={'flex-end fas fa-window-close'}/>
            </div>
        )
    };


    onChangeInput = (e) => {
        this.setState({
            name: e.target.value
        })
    };

    onDescriptionChange = e => {
        this.setState({
            description: e.target.value
        })
    };

    toggleOff = () => {
        this.props.toggleWindow(false);
        this.setState(initialState)
    };

    onCreate = () => {


    };


    handleUploadStart = (f) => {
        this.setState({isUploading: true, progress: 0,uploadingCount:this.state.uploadingCount+1});
    };
    handleProgress = progress => this.setState({progress});
    handleUploadError = error => {
        this.setState({isUploading: false});
        alert(error);
    };
    handleUploadSuccess = filename => {
        //if (!this.state.isUploading) return;
        //this.props.actionResultSuccess(filename);
        var fileType = filename.split('.')[1];
        if (fileType === 'mtl') {
            //this.setState({avatar: filename, progress: 100, filename});
            firebase
                .storage()
                .ref("objects/"+this.props.profile.businessName)
                .child(filename)
                .getDownloadURL()
                .then(url => {
                    if (this.state.objURL) {
                        this.setState({mtlURL: url, isUploading: this.state.uploadingCount>1, step: 1,uploadingCount:this.state.uploadingCount-1});
                    } else {
                        this.setState({mtlURL: url, isUploading: this.state.uploadingCount>1, step: 0,uploadingCount:this.state.uploadingCount-1});

                    }
                });
        }
        else if (acceptedFileTypes.includes(fileType)) {
            //this.setState({avatar: filename, progress: 100, filename});
            var format = filename.split('.')[1]
            if (format === "glb") format = "gltf";
            firebase
                .storage()
                .ref("objects/"+this.props.profile.businessName)
                .child(filename)
                .getDownloadURL()
                .then(url => {
                    //ONLY LOAD IF NO MORE FILES
                    var isUploading = this.state.uploadingCount>1;

                    this.setState({objURL: url, isUploading, step: isUploading ? 0 : 1,format,uploadingCount:this.state.uploadingCount-1});
                });
        } else {
            //this.setState({isUploading: false});
           //
            firebase
                .storage()
                .ref("objects/"+this.props.profile.businessName)
                .child(filename)
                .getDownloadURL()
                .then(url => {
                    //this.props.actionResultSuccess("Uploaded texture: " + filename);
                    //this.props.actionResultSuccess(url);
                    var textures = this.state.textures;
                    textures.push(url);
                    this.setState({isUploading: this.state.uploadingCount>1, step: this.state.objURL ? 1 : 0,format,uploadingCount:this.state.uploadingCount-1,textures});
                });
        }
    };

    loadingScreen = () => {
        return (
            <div className={'loading-screen-create'} style={{width:'100%',backgroundColor:'white'}}>
                <LoadingSpinnerProgress progress={this.state.progress}/>
                <p>{this.state.progress} / 100%</p>
                <br/>
                <p>Uploading files: {this.state.uploadingCount}</p>
                <LottiePlayer width={400} height={400} lottieData={rocket}/>
                <div style={{width:'100%'}}>
                    <button className={'default-button'} style={{margin: 'auto'}} onClick={this.cancelUpload}>Cancel Upload</button>
                </div>
            </div>
        )
    };
    cancelUpload = () => {
        this.setState({isUploading: false, progress: 0})
    }
    renderScene = () => {
        if (this.state.isUploading) return this.loadingScreen();
        if (!this.state.objURL) return null;
        // return (<div>
        //             <p>Drag and drop a file you want to upload (.obj)</p>
        //             <p>Your item will be private until you publish it</p>
        //         </div>)

        var item = {
            objURL: this.state.objURL,
            mtlURL: this.state.mtlURL,
            textures: this.state.textures,
            format: this.state.format,
            type: this.state.type ? this.state.type : 1,
            measurements: this.state.measurements ? this.state.measurements : {},
            rotations: this.state.rotations,
            businessName: this.props.profile.businessName
        };
        if (!this.state.format) {
            console.error("NO FORMAT")
            item.format = 'fbx'
        }
        if (item.type === 4) {
            /*
            var measurements = {
                length: 1,
                width: 1,
                height: 1
            }
            item.measurements = measurements;
            */
        }
        return (
            <TestSuite onObjectLoadedUpdateForm={this.onObjectLoadedUpdateForm} viewerMode={true}
                       updateForViewer={this.updateForViewerMode} showcaseObj={item}/>
        )
    };
    updateForViewerMode = (rotation) => {
        this.setState({rotation})
    };
    onObjectLoadedUpdateForm = (measurements) => {
        this.setState({measurements})
    };


    onChangeItem = e => {
        this.setState({type: e.target.value});
    };
    onChangeCategory = e => {
        this.setState({category: e.target.value});
    };

    renderChooseType = () => {
        //TODO RETRIEVE FROM SERVER
        const type = [
            {label: "FloorItem", value: 1},
            {label: "WallItem", value: 2},
            {label: "InWallItem", value: 3},
            {label: "RoofItem", value: 4},
            {label: "InWallFloorItem", value: 7},
            {label: "OnFloorItem", value: 8},
            {label: "WallFloorItem", value: 9},
            {label: "ExteriorItem", value: 20}
        ];
        return (
            <div style={{marginTop:'1em',marginBottom:'1em'}}>
            <FormControl size={'medium'} margin={'normal'}>
                <InputLabel id="demo-simple-select-label" style={{color:'gray'}}
                >Type</InputLabel>
                <Select
                    onChange={e => this.onChangeItem(e)}
                    labelId="demo-simple-select-label"
                    value={this.state.type}
                    style={{color:'gray'}}
                    id="demo-simple-select">

                    {type.map(t => {
                        return <MenuItem key={t.value} value={t.value}>{t.label}</MenuItem>
                    })}

                </Select>
            </FormControl>
            </div>
        )
    };

    renderChooseCategory = () => {
        var categories = [];
        var i = 0;

        this.props.categories.forEach(cat => {
            var obj = {
                label:cat.area + ' -> ' + cat.name,
                value: i
            };
            categories.push(obj);
            i++;
        });
        if (categories.length < 1) {
            return (
                <div>
                    You have no categories.
                </div>
            )
        }
        return (
            <FormControl size={'medium'} margin={'normal'}>
                <InputLabel>Category</InputLabel>
                <Select
                    onChange={e => this.onChangeCategory(e)}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.category}>
                    {categories.map(t => {
                        return <MenuItem key={t.value} value={t.value}>{t.label}</MenuItem>
                    })}

                </Select>
            </FormControl>
        )
    };


    onChangeSlider = (e) => {
        console.log(e);
    };
    handleMeasurementChange = (property, val) => {
        var measurements = this.state.measurements;
        if (!val || val === '' || val < 1) {
            val = 1;
        }
        measurements[property] = val;
        this.setState({measurements})
    };

    renderChooseMeasurements = () => {
        return (
            <div>
                <div style={{margin: '0.1em'}}>
                    <Typography id="input-slider" gutterBottom>
                        Height
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <ArrowUpward/>
                        </Grid>
                        <Grid item xs>
                            <PrettoSlider
                                value={typeof this.state.measurements.height === 'number' ? this.state.measurements.height : 1}
                                onChange={(e, v) => this.handleMeasurementChange("height", v)}
                                aria-labelledby="input-slider"
                                min={1}
                                max={1000}/>
                        </Grid>
                        <Grid item>
                            <Input
                                value={this.state.measurements.height}
                                margin="dense"
                                onChange={e => this.handleMeasurementChange("height", e.target.value)}
                                inputProps={{
                                    step: 10,
                                    min: 0,
                                    max: 500,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div style={{margin: '0.1em'}}>
                    <Typography id="input-slider" gutterBottom>
                        Width
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <ArrowForward/>
                        </Grid>
                        <Grid item xs>
                            <PrettoSlider
                                value={typeof this.state.measurements.width === 'number' ? this.state.measurements.width : 1}
                                onChange={(e, v) => this.handleMeasurementChange("width", v)}
                                aria-labelledby="input-slider"
                                min={1}
                                max={1000}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                value={this.state.measurements.width}
                                margin="dense"
                                onChange={e => this.handleMeasurementChange("width", e.target.value)}
                                inputProps={{
                                    step: 10,
                                    min: 0,
                                    max: 500,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div style={{margin: '0.1em'}}>
                    <Typography id="input-slider" gutterBottom>
                        Length
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <CallMade/>
                        </Grid>
                        <Grid item xs>
                            <PrettoSlider
                                value={typeof this.state.measurements.length === 'number' ? this.state.measurements.length : 1}
                                onChange={(e, v) => this.handleMeasurementChange("length", v)}
                                aria-labelledby="input-slider"
                                min={1}
                                max={1000}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                value={this.state.measurements.length}
                                margin="dense"
                                onChange={e => this.handleMeasurementChange("length", e.target.value)}
                                inputProps={{
                                    step: 10,
                                    min: 0,
                                    max: 500,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>


        )
    };
    renderChooseScale = () => {
        return (
            <div style={{display: 'flex'}}>
                <p>Scale: </p>
                <input placeholder={"X"} onChange={(e) => {
                    var scale = this.state.scale;
                    scale.x = parseInt(e.target.value);
                    this.setState({
                        scale
                    })
                }}/>
                <input placeholder={"Y"} onChange={(e) => {
                    var scale = this.state.scale;
                    scale.y = parseInt(e.target.value);
                    this.setState({
                        scale
                    })
                }}/>
                <input placeholder={"Z"} onChange={(e) => {
                    var scale = this.state.scale;
                    scale.z = parseInt(e.target.value);
                    this.setState({
                        scale
                    })
                }}/>
            </div>
        )
    };
    rotateObject = (way) => {
        var rotations = [...this.state.rotations];

        if (way === 'left') {
            rotations.push('left')
        }
        if (way === 'right') {
            rotations.push('right')
        }
        if (way === 'up') {
            rotations.push('up')
        }
        if (way === 'down') {
            rotations.push('down')
        }
        this.setState({rotations});
    };

    renderRotationControls = () => {
        return (
            <div className={'rotation-controls'}>
                <b>Rotation</b>
                <br/>
                <div className="btn button_custom" href="#" id="leftview" title="Show side view (left)"
                     onClick={() => this.rotateObject("left")}>
                    <img className={'rotate-img'} alt={'left'} src={left}/>
                </div>
                <span className="btn-group-vertical">
		              <div className="btn button_custom" href="#" id="topview" title="Show top view"
                           onClick={() => this.rotateObject("up")}>
                        <img className={'rotate-img'} alt={'up'} src={up}/>
		              </div>
		              <div className="btn button_custom" href="#" id="isometryview" title="Show 3d view"
                           onClick={() => this.rotateObject("center")}>
                        <img className={'rotate-img'} alt={'center'} src={center}/>
		              </div>
		              <div className="btn button_custom" href="#" id="frontview" title="Show front view"
                           onClick={() => this.rotateObject("down")}>
                        <img className={'rotate-img'} alt={'down'} src={down}/>
		              </div>
            </span>
                <div className="btn button_custom bottom" href="#" id="rightview" title="Show side view (right)"
                     onClick={() => this.rotateObject("right")}>
                    <img className={'rotate-img'} alt={'right'} src={right}/>
                </div>
            </div>
        );
    };
    renderFirstStep = () => {
        return (
            this.state.renderFirebaseUploader ? this.renderObjectUploaderToFirebase() : this.renderInstantModelViewer()
        )
    };
    renderInstantModelViewer = () => {
        return (
            <ModelViewer/>
        )
    };

    renderObjectUploaderToFirebase = () => {
        return (
            <div className={'object-uploader-wrapper'}>
                <div className={'object-uploader-content'}>
                    <label className={'dragndropzone-outer'}>
                        <b>Drag Files</b>
                        <br/>
                        <p>Upload texture files alongside an object file - </p>
                        <p>(obj,dae,gltf,glb,fbx)</p>
                        <p>Use files less than 30mb for best performance.</p>
                        <div>
                            <b>3D Models: </b>
                            <a href={'https://sketchfab.com/3d-models/'} rel="noopener noreferrer" target={'_blank'}>SketchFab</a>
                            ,
                            <a href={'https://www.cgtrader.com'} rel="noopener noreferrer" target={'_blank'}>CGTrader</a>
                            ,
                            <a href={'https://www.turbosquid.com/Search/3D-Models'} rel="noopener noreferrer" target={'_blank'}>TurboSquid</a>
                            ,
                            <a href={'https://clara.io/library'} rel="noopener noreferrer" target={'_blank'}>Clara</a>
                        </div>
                        Uploaded files: {this.state.textures.length}
                        <FileUploader
                            accept="*"
                            name="mtl"

                            storageRef={firebase.storage().ref("objects/"+this.props.profile.businessName)}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                            className={'dragndropzone'}
                            multiple
                        />
                    </label>
                    {/*
                        <div><p>Select the file to represent your item.</p>
                        {acceptedFileTypes.map(type => {
                            return (
                                <li key={type}>{type}</li>
                            )
                        })}
                    </div>
                    */
                    }
                </div>
            </div>
        )
    }

    renderUploadPhotos = () => {
        return (
            <div style={{padding: '1em', display: 'flex', flexDirection: 'row'}}>
                <FileUploaderWrapper photoURL={this.state.photoURL}
                                     onFileUploaded={(url) => this.setState({photoURL: url})}
                                     textPrimary={'Choose Catalogue Photo'}
                                     textSecondary={'This is how your object will be shown in the user catalogue.'}/>
                <FileUploaderWrapper photoURL={this.state.photoURL2D}
                                     onFileUploaded={(url) => this.setState({photoURL2D: url})}
                                     textPrimary={'Choose 2D Top Down Photo'}
                                     textSecondary={'This is how your object will look in 2D top-down view.'}/>
            </div>
        )
    };

    renderSecondStep = () => {
        return (
            <div className={'second-step-wrapper'}>
                <div className={'second-step-left'}>
                    {this.renderScene()}

                </div>
                <div className={'second-step-right'}>
                    <div className={'second-step-right-inner'}>
                        <div className={'column-stretch-width'}>
                            <CustomInput label={'Name'} value={this.state.name} onChange={this.onChangeName}/>
                            <CustomInput rows={4} label={'Description'} value={this.state.description}
                                         onChange={this.onChangeDescription}/>
                            {
                                /*
                                <CustomInput label={'Store Page URL (e.g. www.mastersuite.com)'} value={this.state.storeURL}
                                         onChange={this.onChangeURL}/>
                                         */
                            }

                        </div>

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {this.renderChooseType()}
                        </div>
                        {this.renderRotationControls()}
                        {this.renderChooseMeasurements()}
                    </div>
                </div>

            </div>
        )
    };
    onChangeName = val => {
        this.setState({name: val})
    };
    onChangeDescription = val => {
        this.setState({description: val});
    };

    onChangeURL = val => {
        this.setState({storeURL: val})
    };


    renderMakePublic = () => {
        return (
            <div className={"yes-or-no"} style={{padding: '1em'}}>
                <b>Do you want to make this object public?</b>
                <br/>
                <i>This object will be made available to all users to view.</i>
                <div style={{display: "flex", flexDirection: 'row'}}>
                    <Checkbox checked={this.state.public === false} onClick={() => this.setState({public: false})}/>
                    <p style={{paddingTop: '0.6em'}}>No</p>

                </div>
                <div style={{display: "flex", flexDirection: 'row'}}>
                    <Checkbox checked={this.state.public} onClick={() => this.setState({public: true})}/>
                    <p style={{paddingTop: '0.6em'}}>Yes</p>
                </div>
            </div>
        )
    };
    renderFinalStep = () => {
        return (
            <div style={{height: '80%', overflow: 'scroll'}}>
                {this.renderUploadPhotos()}
                {
                   // this.renderMakePublic()
                }


            </div>
        )
    };

    renderStep = (step) => {
        if (step === 0) {
            if (this.state.isUploading) return this.loadingScreen();
            return this.renderFirstStep();
        }
        if (step === 1) {
            return this.renderSecondStep();
        }
        if (step === 2) {
            return this.renderFinalStep();
        }

        return null;
        /*
        return (
          <div>
              <div className={""}>
                  <div style={{display:'flex',margin:'2em'}}>
                      {this.renderNameInput()}
                      <div style={{margin:'1em'}}>
                      </div>
                      {this.renderChooseCategory()}
                      {this.renderChooseType()}
                  </div>
                  {this.renderChooseMeasurements()}
                  {this.renderChooseScale()}
                  <input aria-multiline={true} placeholder={"Description"} onChange={e => this.onDescriptionChange(e)}/>
                  <div style={{display:'flex',margin:'2em'}}>
                      {this.renderScene()}
                      <div className={"obj-uploader"}>
                          <b>Choose Object</b>
                          <FileUploader
                              accept="*"
                              name="mtl"
                              randomizeFilename
                              storageRef={firebase.storage().ref("objects")}
                              onUploadStart={this.handleUploadStart}
                              onUploadError={this.handleUploadError}
                              onUploadSuccess={this.handleUploadSuccess}
                              onProgress={this.handleProgress}
                          />
                      </div>
                  </div>
                  <div className={"obj-uploader"}>
                      <b>Choose Catalogue Photo</b>
                      <br/>
                      <i>This is how your object will be shown in the user catalogue.</i>
                      <img alt={} className={'photo-frame'} src={this.state.photoURL} />
                      <FileUploader
                          accept="image/*"
                          name="avatar"
                          randomizeFilename
                          storageRef={firebase.storage().ref("images")}
                          onUploadStart={this.handleUploadStartPhoto}
                          onUploadError={this.handleUploadErrorPhoto}
                          onUploadSuccess={this.handleUploadSuccessPhoto}
                          onProgress={this.handleProgressPhoto}
                      />
                  </div>
                  <div className={"obj-uploader"}>
                      <b>Choose 2D Top Down Photo</b>
                      <br/>
                      <i>This is how your object will be seen from top-down view.</i>
                      <img style={{maxWidth:'50px'}} src={this.state.photoURL} />
                      <FileUploader
                          accept="image/*"
                          name="avatar"
                          randomizeFilename
                          storageRef={firebase.storage().ref("images")}
                          onUploadStart={this.handleUploadStartPhoto}
                          onUploadError={this.handleUploadErrorPhoto}
                          onUploadSuccess={this.handleUploadSuccessPhoto}
                          onProgress={this.handleProgressPhoto}
                      />
                  </div>
                  <div className={"bottom-buttons"}>
                      {this.renderCreateButton()}
                      {this.renderCancelButton()}
                  </div>
              </div>
          </div>
        );

         */
    };
    getTitle = () => {
        var {step} = this.state;
        if (step === 0) return "Upload Item";
        else return this.state.name !== '' ? this.state.name : this.state.filename;
    };


    onClickNext = () => {
        if (!this.state.name || this.state.name.trim() === '') {
            this.props.actionResultError("You must write a name for the item.");
            return;
        }
        if (!this.state.description || this.state.description.trim() === '') {
            this.props.actionResultError("You must write a description for the item.");
            return;
        }
        if (!this.state.storeURL || this.state.storeURL.trim() === '') {
           // this.props.actionResultError("You must enter a store page URL that user's can view your product.");
           // return;
        }
        this.setState({step: 2})
    };

    changeUploadType = () => {
        var uploadType = !this.state.renderFirebaseUploader;
        this.setState({renderFirebaseUploader:uploadType});
    }
    renderUploadType = () => {
        return null;
        //This is choosing b/w upload type.
        /*
        return (
            <div className={"popup-footer"}>
                <Checkbox style={{position:'absolute',left:0,top:0}} checked={this.state.renderFirebaseUploader} onChange={this.changeUploadType}/>
            </div>
        )
         */
    };
    renderFooterPanel = () => {
        var {step} = this.state;
        if (step === 0) return this.renderUploadType();

        if (step === 1) return (
            <div className={"popup-footer"}>
                <button
                    className={'default-button'}
                    onClick={() => this.setState({step: 0,objURL: null,mtlURL: null,textures:[]})}>
                    Go Back
                </button>
                <button
                    className={'default-button'}
                    onClick={this.onClickNext}>
                    Next
                </button>
            </div>
        );

        if (step === 2) {
            return (
                <div className={"popup-footer"}>
                    <button
                        className={'default-button'}
                        onClick={() => this.setState({step: 1})}>
                        Go Back
                    </button>
                    <button
                        className={'default-button'}
                        onClick={() => this.createItem()}>
                        Create Item
                    </button>
                </div>
            )
        }
    };


    createItem = () => {
        var obj = this.state;
        obj.category = this.props.categories[obj.category].name;
        obj.area=this.props.area;
        if (!obj.format) {
            console.error("FORMAT DID NOT SAVE, DEFAULTING TO FBX")
            obj.format = 'fbx'
        }
        this.props.createItem(obj);
        if (!this.state.photoURL) {
            return;
        }
        if (!this.state.photoURL2D) {
            return;
        }
        if (!this.state.public) {
            //return;
        }


        this.toggleOff();
    };


}

const mapStateToProps = (state, ownProps) => {
    return {}
};

export default connect(mapStateToProps, {createCategory, createItem, actionResultError, actionResultSuccess})
(CreateItemPopup);
