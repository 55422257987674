import $ from 'jquery';
import {EventDispatcher, Vector2} from 'three';
import {cmPerPixel, Dimensioning, pixelsPerCm} from './dimensioning.js';
import {configDimUnit, Configuration, snapTolerance} from './configuration.js';
//import {gridSpacing} from './configuration.js';
import {
	EVENT_CORNER_2D_CLICKED,
	EVENT_CORNER_2D_DOUBLE_CLICKED,
	EVENT_CORNER_2D_HOVER,
	EVENT_CORNER_ATTRIBUTES_CHANGED,
	EVENT_LOADED,
	EVENT_MODE_RESET,
	EVENT_NOTHING_CLICKED,
	EVENT_ROOM_2D_CLICKED,
	EVENT_ROOM_2D_DOUBLE_CLICKED,
	EVENT_ROOM_2D_HOVER,
	EVENT_ROOM_ATTRIBUTES_CHANGED,
	EVENT_WALL_2D_CLICKED,
	EVENT_WALL_2D_DOUBLE_CLICKED,
	EVENT_WALL_2D_HOVER,
	EVENT_WALL_ATTRIBUTES_CHANGED
} from './events.js';
import {floorplannerModes, FloorplannerView2D} from './floorplanner_view.js';
import {EVENT_ITEM_2D_HOVER} from "./events";
import {SM} from "../../components/SceneManager";
import {IS_TOUCH_DEVICE} from "../../config/DeviceInfo";


/** how much will we move a corner to make a wall axis aligned (cm) */

//export const snapTolerance = 25;//In CMS
/**
 * The Floorplanner implements an interactive tool for creation of floorplans in
 * 2D.
 */
export class Floorplanner2D extends EventDispatcher {
    /** */
    constructor(canvas, floorplan) {
        super();
        /** */
        this.mode = 0;
        /** */
        this.activeWall = null;
        /** */
        this.activeCorner = null;
        /** */
        this.activeRoom = null;

        /** */
        this._clickedWall = null;
        /** */
        this._clickedWallControl = null;
        /** */
        this._clickedCorner = null;
        /** */
        this._clickedRoom = null;
        /** */
        this.originX = 0;
        /** */
        this.originY = 0;
        /** */
        this.unScaledOriginX = 0;
        /** */
        this.unScaledOriginY = 0;
        this.needsTarget = false;
        /** drawing state */
        this.targetX = 0;
        /** drawing state */
        this.targetY = 0;
        /** drawing state */
        this.lastNode = null;
        /** */
        this.wallWidth = 0;
        /** */
        this.modeResetCallbacks = null;

        this._drawMode = "design";

        this.scale = Configuration.getNumericValue('scale');

        /** */
        this.mouseDown = false;
        /** */
        this.mouseMoved = false;
        /** in ThreeJS coords */
        this.mouseX = 0;
        /** in ThreeJS coords */
        this.mouseY = 0;
        /** in ThreeJS coords */
        this.rawMouseX = 0;
        /** in ThreeJS coords */
        this.rawMouseY = 0;
        /** mouse position at last click */
        this.lastX = 0;
        /** mouse position at last click */
        this.lastY = 0;

        this.canvas = canvas;
        this.floorplan = floorplan;
        this.canvasElement = $('#' + canvas);
        this.view = new FloorplannerView2D(this.floorplan, this, canvas);

        //		var cmPerFoot = cmPerFoot;
        //		var pixelsPerFoot = pixelsPerFoot;
        this.cmPerPixel = cmPerPixel;
        this.pixelsPerCm = pixelsPerCm;

        this.wallWidth = Dimensioning.cmToPixel(Configuration.getNumericValue('wallThickness'));
//		this.wallWidth = 10.0 * this.pixelsPerCm;
        this.gridsnapmode = false;
        this.shiftkey = false;
        // Initialization:

        this.setMode(floorplannerModes.MOVE);

        var scope = this;
        this.canvasElement.bind('mousedown', (event) => {
            scope.mousedown(event);
        });
        this.canvasElement.bind('touchstart', (event) => {
            scope.onTouchStart(event)
        });
        this.canvasElement.bind('touchmove', (event) => {
            scope.onTouchMove(event)
        });
        this.canvasElement.bind('mousemove', (event) => {
            scope.mousemove(event);
        });
        this.canvasElement.bind('mouseup', (event) => {
            scope.mouseup(event);
        });
        this.canvasElement.bind('touchend', (event) => {
            //scope.mouseup(event,true);
            scope.touchEnd(event);
        });
        this.canvasElement.bind('mouseleave', (event) => {
            scope.mouseleave(event);
        });
        this.canvasElement.bind('zoomed', event => {
            scope.zoomed();
        })
        //console.warn("Double click on 2D canvas not binded.");
        //this.canvasElement.bind('dblclick', (event) => {scope.doubleclick(event);});
        this.canvasElement.bind('mousewheel', this.onMouseZoom);
        //,,this.canvasElement.bind('gestureend', this.onTouchZoom);
        //this.hammer = Hammer(this.canvasElement);
        //this.hammer.on('')

        document.addEventListener('keyup', function (event) {
            scope.keyUp(event)
        });
        document.addEventListener('keydown', function (event) {
            scope.keyDown(event)
        });
        floorplan.addEventListener(EVENT_LOADED, function () {
            scope.reset();
        });

        function updateView() {
            scope.view.draw();
        }

        floorplan.addEventListener(EVENT_CORNER_ATTRIBUTES_CHANGED, updateView);
        floorplan.addEventListener(EVENT_WALL_ATTRIBUTES_CHANGED, updateView);
        floorplan.addEventListener(EVENT_ROOM_ATTRIBUTES_CHANGED, updateView);


    }

    get isBuilding() {
        return this._drawMode === "building";
    }

    get isDesigning() {
        return this._drawMode === "design";
    }

    get drawMode() {
        return this._drawMode;
    }

    set drawMode(val) {
        this._drawMode = val;
        this.view.draw();
    }

    get selectedCorner() {
        return this._clickedCorner;
    }

    get selectedWall() {
        return this._clickedWall;
    }

    get selectedRoom() {
        return this._clickedRoom;
    }

    get selectedItem() {
        return this._clickedItem;
    }

    get carbonSheet() {
        return this.view.carbonSheet;
    }

    zoomed() {
        alert('zoomed');
    }
    doubleclick() {

        var userinput, cid;

        function getAValidInput(message, current) {
            var uinput = window.prompt(message, current);
            if (uinput != null) {
                return uinput;
            }
            return current;
        }

        if (this.activeCorner) {
            //console.log("Corner dbl clicked");

            this.floorplan.dispatchEvent({type: EVENT_CORNER_2D_DOUBLE_CLICKED, item: this.activeCorner});
            if (!Configuration.getNumericValue('systemUI')) {
                return;
            }
            cid = this.activeCorner.id;
            var units = Configuration.getStringValue(configDimUnit);
            this.activeCorner.elevation = getAValidInput(`Elevation at this point (in ${units},\n${cid}): `, Dimensioning.cmToMeasureRaw(this.activeCorner.elevation));//Number(userinput);
            var x = getAValidInput(`Location: X (${Dimensioning.cmToMeasureRaw(this.activeCorner.x)}): `, Dimensioning.cmToMeasureRaw(this.activeCorner.x));//Number(userinput);
            var y = getAValidInput(`Location: Y (${Dimensioning.cmToMeasureRaw(this.activeCorner.y)}): `, Dimensioning.cmToMeasureRaw(this.activeCorner.y));//Number(userinput);
            this.activeCorner.move(Dimensioning.cmFromMeasureRaw(x), Dimensioning.cmFromMeasureRaw(y));

        } else if (this.activeWall) {
            //console.log("Wall dbl clicked");

            this.floorplan.dispatchEvent({type: EVENT_WALL_2D_DOUBLE_CLICKED, item: this.activeWall});
            if (!Configuration.getNumericValue('systemUI')) {

            }
        } else if (this.activeRoom) {
            //console.log("Room dbl clicked");

            this.floorplan.dispatchEvent({type: EVENT_ROOM_2D_DOUBLE_CLICKED, item: this.activeRoom});
            if (!Configuration.getNumericValue('systemUI')) {
                return;
            }
            userinput = window.prompt('Enter a name for this Room: ', this.activeRoom.name);
            if (userinput != null) {
                this.activeRoom.name = userinput;
            }
            this.view.draw();
        }
    }

    keyUp(e) {
        if (e.keyCode === 27) {
            this.escapeKey();
        }
        //this.gridsnapmode = true;
        this.shiftkey = false;
    }

    keyDown(e) {
        if (e.shiftKey || e.keyCode === 16) {
            this.shiftkey = true;
        }
        //this.gridsnapmode = this.shiftkey;
    }

    /** */
    escapeKey() {
        this.setMode(floorplannerModes.MOVE);
    }

    /** */
    updateTarget() {
        //if ((this.mode === floorplannerModes.DRAW && this.lastNode)) {


            /*
            if (Math.abs(this.mouseX - this.lastNode.x) < Configuration.getNumericValue(snapTolerance)) {
                this.targetX = this.lastNode.x;
            } else {
                this.targetX = this.mouseX;
            }
            if (Math.abs(this.mouseY - this.lastNode.y) < Configuration.getNumericValue(snapTolerance)) {
                this.targetY = this.lastNode.y;
            } else {
                this.targetY = this.mouseY;
            }

        } else {

            this.targetX = this.mouseX;
            this.targetY = this.mouseY;
        }
             */
        this.targetX = this.mouseX;
        this.targetY = this.mouseY;

        if (this.gridsnapmode || Configuration.getNumericValue('snapToGrid')) {

            //console.log({xBeforeClamp:this.targetX,yBeforeClamp:this.targetY});
            //Snap it to the grid.
            this.targetX = Math.round(this.targetX / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);
            this.targetY = Math.round(this.targetY / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);

            //console.log({xAfterClamp:this.targetX,yAfterClamp:this.targetY});
            //The below will not work, the snapTolerance is necessary for X, Y axis snapping, where as grid snapping is for snapping to grid lines
//			this.targetX = Math.floor(this.targetX / Configuration.getNumericValue(gridSpacing)) * Configuration.getNumericValue(gridSpacing);
//			this.targetY = Math.floor(this.targetY / Configuration.getNumericValue(gridSpacing)) * Configuration.getNumericValue(gridSpacing);
        }

        this.view.draw();
    }

    //Calc x mouse on canvas.
    //rawVal in screen px
    calculateXMouse = rawVal => {
        return Dimensioning.pixelToCm(rawVal - this.canvasElement.offset().left) + Dimensioning.pixelToCm(this.originX);
    };

    //Calc y mouse on canvas.
    calculateYMouse = rawVal => {
        return Dimensioning.pixelToCm(rawVal - this.canvasElement.offset().top) + Dimensioning.pixelToCm(this.originY);
    };

    //TODO: Find way to check if mouse is on canvas.
    isOnCanvas = mouse => {
        //var x = this.calculateXMouse(mouse.x);
        //var y = this.calculateYMouse(mouse.y);
        //console.log(x);
        //console.log(y);
        return true;
    };

    touchEnd(event) {
        if (event.touches) {
            if (event.touches.length === 0) {
                this.zooming = false;


            }
        }
        this.mouseup(event,true);
    }
    /** */
    mousedown(event) {

        if (IS_TOUCH_DEVICE) {
            if (this.mode === floorplannerModes.DRAW) {


            }
        }
        SM.model.floorplan.getCorners().forEach(corner => {
            corner.removeDuplicateWalls();
        })

        this.mouseDown = true;
        this.mouseMoved = false;
        if (event.touches) {
//			event.stopPropagation();
//			event.preventDefault();
            this.rawMouseX = event.touches[0].clientX;
            this.rawMouseY = event.touches[0].clientY;
            //The below line is very important for touch events to work
            event = event.touches[0];
        }

        this.lastX = this.rawMouseX;
        this.lastY = this.rawMouseY;


        // delete
        if (this.mode === floorplannerModes.DELETE) {
            if (this.activeCorner) {
                this.activeCorner.removeAll();
            } else if (this.activeWall) {
                this.activeWall.remove();
            } else {
                //Continue the mode of deleting walls, this is necessary for deleting multiple walls
                //				this.setMode(floorplannerModes.MOVE);
            }
        }
        this.mouseX = Dimensioning.pixelToCm((event.clientX - this.canvasElement.offset().left)) + Dimensioning.pixelToCm(this.originX);
        this.mouseY = Dimensioning.pixelToCm((event.clientY - this.canvasElement.offset().top)) + Dimensioning.pixelToCm(this.originY);

//		this.mouseX = (event.clientX - this.canvasElement.offset().left)  * this.cmPerPixel + this.originX * this.cmPerPixel;
//		this.mouseY = (event.clientY - this.canvasElement.offset().top) * this.cmPerPixel + this.originY * this.cmPerPixel;

        if (this._clickedWall) {
            this._clickedWallControl = this.floorplan.overlappedControlPoint(this._clickedWall, this.mouseX, this.mouseY);
            if (this._clickedWallControl != null) {
                this.view.draw();
                return;
            }
        }
        //console.log("If over another wall, do not allow click??");


        var mDownCorner = this.floorplan.overlappedCorner(this.mouseX, this.mouseY);
        var mDownWall = this.floorplan.overlappedWall(this.mouseX, this.mouseY);
        var mDownRoom = this.floorplan.overlappedRoom(this.mouseX, this.mouseY);
        var mDownItem = this.floorplan.overlappedItem(this.mouseX, this.mouseY, this.view.context);

        this._clickedWallControl = null;

        //EVENT: Nothing clicked
        if (mDownCorner == null && mDownWall == null && mDownRoom == null && mDownItem == null) {
            this._clickedCorner = undefined;
            this._clickedWall = undefined;
            this._clickedRoom = undefined;
            this._clickedItem = undefined;
            this.floorplan.dispatchEvent({type: EVENT_NOTHING_CLICKED});
        }
        //EVENT: Item clicked
        else if (mDownItem != null) {
            this._clickedCorner = undefined;
            this._clickedWall = undefined;
            this._clickedRoom = undefined;
            this._clickedCorner = undefined;
            this._clickedItem = mDownItem;
            document.body.style.cursor = "grab";
            this.floorplan.dispatchEvent({type: EVENT_CORNER_2D_CLICKED, item: this._clickedCorner});
        }
        //EVENT: Corner Clicked...
        else if (mDownCorner != null) {
            this._clickedCorner = undefined;
            this._clickedWall = undefined;
            this._clickedRoom = undefined;
            this._clickedItem = undefined;
            this._clickedCorner = mDownCorner;
            this.floorplan.dispatchEvent({type: EVENT_CORNER_2D_CLICKED, item: this._clickedCorner});
        } else if (mDownWall != null) {
            this._clickedCorner = undefined;
            this._clickedWall = undefined;
            this._clickedRoom = undefined;
            this._clickedItem = undefined;
            this._clickedWall = mDownWall;

            this.floorplan.dispatchEvent({type: EVENT_WALL_2D_CLICKED, item: this._clickedWall});
        } else if (mDownRoom != null) {
            this._clickedCorner = undefined;
            this._clickedWall = undefined;
            this._clickedRoom = undefined;
            this._clickedItem = undefined;
            this._clickedRoom = mDownRoom;
            this.floorplan.dispatchEvent({type: EVENT_ROOM_2D_CLICKED, item: this._clickedRoom});
        }


        SM.onClickedWall2D(this._clickedWall,this.mode);
        SM.onClickedRoom2D(this._clickedRoom);
        SM.onClickedCorner2D(this._clickedCorner);
        SM.setSelectedItem(this._clickedItem);
        SM.onItemSelected(this._clickedItem);

        this.view.draw();
    }

    onTouchMove(event) {
        event.preventDefault();
        event.stopPropagation();


        switch (event.touches.length) {

            case 1: // one-fingered touch: rotate
                this.mousemove(event,true);
                break;
            case 2: // two-fingered touch: dolly-pan
                this.onTouchZoom(event);
                break;

            default:
                break;
        }
    }

    getOverlappedRoom = mouse => {
        this.mouseX = Dimensioning.pixelToCm((mouse.x - this.canvasElement.offset().left)) + Dimensioning.pixelToCm(this.originX);
        this.mouseY = Dimensioning.pixelToCm((mouse.y - this.canvasElement.offset().top)) + Dimensioning.pixelToCm(this.originY);

        return this.floorplan.overlappedRoom(this.mouseX, this.mouseY);
    }

    /** */
    mousemove(event,touching=false) {

        this.mouseMoved = true;

        if (event.touches) {
            event.stopPropagation();
            event.preventDefault();
            event = event.touches[0];
        }

        // update mouse
        this.rawMouseX = event.clientX;
        this.rawMouseY = event.clientY;

        this.mouseX = Dimensioning.pixelToCm(event.clientX - this.canvasElement.offset().left) + Dimensioning.pixelToCm(this.originX);
        this.mouseY = Dimensioning.pixelToCm(event.clientY - this.canvasElement.offset().top) + Dimensioning.pixelToCm(this.originY);

//		this.mouseX = (event.clientX - this.canvasElement.offset().left)  * this.cmPerPixel + this.originX * this.cmPerPixel;
//		this.mouseY = (event.clientY - this.canvasElement.offset().top) * this.cmPerPixel + this.originY * this.cmPerPixel;

        //This is so the draw mode does not move.
        if (IS_TOUCH_DEVICE && this.mode === floorplannerModes.DRAW ) {
            this.updateTarget();
            return;
        }


        // update object target
        if (this.mode !== floorplannerModes.DRAW && (!this.mouseDown)) {
            var hoverCorner = this.floorplan.overlappedCorner(this.mouseX, this.mouseY);
            var hoverWall = this.floorplan.overlappedWall(this.mouseX, this.mouseY);
            var hoverRoom = this.floorplan.overlappedRoom(this.mouseX, this.mouseY);
            var hoverItem = this.floorplan.overlappedItem(this.mouseX, this.mouseY, this.view.context);

            var draw = false;

            //object takes precendence
            if (hoverItem !== this.activeItem) {
                this.activeItem = hoverItem;
                this.activeWall = null;
                this.floorplan.dispatchEvent({type: EVENT_ITEM_2D_HOVER, item: hoverItem});
                draw = true;
            }


            // corner takes precendence
            if (hoverCorner !== this.activeCorner) {
                this.activeCorner = hoverCorner;
                this.floorplan.dispatchEvent({type: EVENT_CORNER_2D_HOVER, item: hoverCorner});
                draw = true;
            }

            if (hoverItem) {
                document.body.style.cursor = "pointer";
            } else if (hoverWall) {
                document.body.style.cursor = "pointer";
            } else {
                document.body.style.cursor = "auto";
            }


            if (hoverWall !== this.activeWall && this.activeCorner == null && hoverItem === null) {
                this.activeWall = hoverWall;
                this.floorplan.dispatchEvent({type: EVENT_WALL_2D_HOVER, item: hoverWall});
                draw = true;

            } else if (hoverWall === null) {
                this.activeWall = null;
            }

            if (this.activeWall == null && this.activeCorner == null) {
                this.activeRoom = hoverRoom;
            }

            if (this.activeCorner == null && this.activeWall == null && this.activeRoom != null) {
                this.floorplan.dispatchEvent({type: EVENT_ROOM_2D_HOVER, item: hoverRoom});
            }

            if (this.activeRoom == null) {
                //draw = true;
            }

            if (draw) {
                this.view.draw();

            }
            //alert(hoverWall);
        } else {
            this.updateTarget(); //?? does not do
            //alert("Updating target");
            //console.log("Update target");
            if (!this.activeItem) {
                this._updatedTarget = true;
            }


        }

        var mx, my;
        // panning.
        if (this.zooming) {
            return;
        }
        if (this.mouseDown && !this._clickedItem && !this._clickedCorner && !this._clickedWall && !this._clickedWallControl && !this.inTouchDraw)
//		else if (this.mouseDown && (this.activeCorner==null) && (this.activeWall==null) && (this._clickedWallControl == null))
//		else if (this.mouseDown && (!this._clickedCorner) && (!this._clickedWall) && (this._clickedWallControl == null))
        {
            //alert("SCALING");

//			console.log('PANNING :: ', this.activeCorner, this.activeWall);
            this.originX += (this.lastX - this.rawMouseX);
            this.originY += (this.lastY - this.rawMouseY);
            this.unScaledOriginX += (this.lastX - this.rawMouseX) * (1 / Configuration.getNumericValue('scale'));
            this.unScaledOriginY += (this.lastY - this.rawMouseY) * (1 / Configuration.getNumericValue('scale'));
            this.lastX = this.rawMouseX;
            this.lastY = this.rawMouseY;
            //this.view.draw();
        }
        // dragging
        if (this.mode === floorplannerModes.MOVE && this.mouseDown)
//		if (this.mode == floorplannerModes.MOVE && this.mouseDown && (this._clickedCorner || this._clickedWall || this._clickedWallControl != null))
        {
           // alert('dragging');
            if (this._clickedWallControl != null) {

                mx = this.mouseX;
                my = this.mouseY;
                if (this.gridsnapmode || Configuration.getNumericValue('snapToGrid')) {
                    mx = Math.floor(this.mouseX / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);
                    my = Math.floor(this.mouseY / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);
                }

                this._clickedWallControl.x = mx;
                this._clickedWallControl.y = my;
                this._clickedWall.updateControlVectors();
                this.view.draw();
                return;
            }
            if (this._clickedItem) {
                //alert("ACTIVE ITEM");curtai
                this._clickedItem.moveOnCanvas(this.mouseX, this.mouseY);
                //this.lastX = this.rawMouseX;
                //this.lastY = this.rawMouseY;
            }
            if (this._clickedCorner) {
                if ((this.gridsnapmode || Configuration.getNumericValue('snapToGrid'))) {
//					var mx = (Math.abs(this.mouseX - this.activeCorner.x) < Configuration.getNumericValue(snapTolerance)) ? this.activeCorner.x : this.mouseX;
//					var my = (Math.abs(this.mouseY - this.activeCorner.y) < Configuration.getNumericValue(snapTolerance)) ? this.activeCorner.y : this.mouseY;

                    mx = Math.floor(this.mouseX / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);
                    my = Math.floor(this.mouseY / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);

                    this._clickedCorner.move(Math.round(mx), Math.round(my));
                } else {
                    this._clickedCorner.move(this.mouseX, this.mouseY);
                }
                this.dirty = true;
//
            } else if (this._clickedWall) {

                var dx = Dimensioning.pixelToCm(this.rawMouseX - this.lastX);
                var dy = Dimensioning.pixelToCm(this.rawMouseY - this.lastY);
                this._clickedWall.relativeMove(dx, dy);

                /*
                console.log({dx,dy});
                if (dx) {
                    console.log("move on x")
                }
                if (dy) {
                    console.log("move on y");
                }
                 */
//				this.activeWall.relativeMove((this.rawMouseX - this.lastX) * this.cmPerPixel, (this.rawMouseY - this.lastY) * this.cmPerPixel);
                if (this.gridsnapmode || Configuration.getNumericValue('snapToGrid')) {
                    this._clickedWall.snapToAxis(Configuration.getNumericValue(snapTolerance));
                }
                this.lastX = this.rawMouseX;
                this.lastY = this.rawMouseY;

                this.dirty = true;

            }

            this.view.draw();
        }
        if (this.mode === floorplannerModes.DRAW) {
            document.body.style.cursor = "crosshair";
        } else if (this.mode === floorplannerModes.DELETE) {
            document.body.style.cursor = "not-allowed";
        }
    }

    /** */
    mouseup(event,touching=false) {
        this.mouseDown = false;
        this._updatedTarget = false;

//		if(event.touches)
//		{
//			event.stopPropagation();
//			event.preventDefault();
//		}

        // drawing
        //alert("MOUSE IS UP!");
        if (this.mode === floorplannerModes.DRAW && !this.mouseMoved) {

            // This creates the corner already
            var corner = this.floorplan.newCorner(this.targetX, this.targetY);

            // further create a newWall based on the newly inserted corners
            // (one in the above line and the other in the previous mouse action
            // of start drawing a new wall)
            if (this.lastNode != null) {
                //console.log("NEW WALL BASED ON LAST NODES?");
                //TODO: This just draws the new wall.
                //alert("LAST NODE IS NOT NULL");

                //Create a new wall, and basically tell the method it is a new wall.
                this.floorplan.newWall(this.lastNode, corner, null, null, true);
                this.floorplan.newWallsForIntersections(this.lastNode, corner);
                this.view.draw();

            }
            if (corner.mergeWithIntersected() && this.lastNode != null) {
                this.setMode(floorplannerModes.MOVE);
            }
            this.lastNode = corner;


        } else {

            if (this.activeCorner != null) {
                this.activeCorner.updateAttachedRooms(true);
            }
            if (this.activeWall != null) {
                this.activeWall.updateAttachedRooms(true);
            }

            if (this._clickedCorner) {
                this._clickedCorner.updateAttachedRooms(true);
            }
            if (this._clickedWall) {
                this._clickedWall.updateAttachedRooms(true);
            }
        }
        this.view.draw();

        if (this.dirty) {
            SM.onWallsStopMoving();
            this.dirty = false;
        }
        //SM.onRedrawRoomLite();
        SM.model.floorplan.getCorners().forEach(corner => {
            corner.removeDuplicateWalls();
        })
    }

    /** */
    mouseleave() {
        this.mouseDown = false;
        // scope.setMode(scope.modes.MOVE);
    }

    __updateInteractiveElements() {

    }

    /** */
    reset() {
        this.view.carbonSheet.clear();
        this.resizeView();
        this.setMode(floorplannerModes.MOVE);
        this.resetOrigin();
        this.view.draw();
    }

    /** */
    resizeView() {
        this.view.handleWindowResize();
    }
    centerLayout = () => {
        this.view.carbonSheet.clear();
        this.resizeView();
        this.scale = 1;
        this.resetOrigin();
        this.view.draw();

    }
    drawAll = () => {
        this.view.draw();
    }
    /** */
    setMode(mode,touching) {

        if (touching) {
            if (this.mode === floorplannerModes.DRAW) {
                this.inTouchDraw = true;
            } else {
                this.inTouchDraw = false;
            }
        }
        this.lastNode = null;
        this.mode = mode;
        this.dispatchEvent({type: EVENT_MODE_RESET, mode: mode});
        if (SM) {
            SM.setFloorPlannerMode(mode);

        }
        // this.modeResetCallbacks.fire(mode);
        this.updateTarget();
        this.lastNode = null;

    }

    stopResize = () => {
        this.view.stopResize();
    };

    /** Sets the origin so that floorplan is centered */
    resetOrigin() {
        var centerX = this.canvasElement.innerWidth() / 2.0;
        var centerY = this.canvasElement.innerHeight() / 2.0;

        var centerFloorplan = this.floorplan.getCenter();
        this.originX = Dimensioning.cmToPixel(centerFloorplan.x) - centerX;
        this.originY = Dimensioning.cmToPixel(centerFloorplan.z) - centerY;

        this.unScaledOriginX = Dimensioning.cmToPixel(centerFloorplan.x, false) - centerX;
        this.unScaledOriginY = Dimensioning.cmToPixel(centerFloorplan.z, false) - centerY;

//		this.originX = centerFloorplan.x * this.pixelsPerCm - centerX;
//		this.originY = centerFloorplan.z * this.pixelsPerCm - centerY;
    }

    getFingerDistance = e => {
        var dx = e.touches[0].pageX - e.touches[1].pageX;
        var dy = e.touches[0].pageY - e.touches[1].pageY;
        //pythagorean theorem
        return Math.sqrt(dx * dx + dy * dy);
    };

    onTouchStart = e => {
        if (e.touches.length > 1) { // if multiple touches (pinch zooming)
            this.finger_dist = this.getFingerDistance(e); // Save current finger distance
        } // Else just moving around
        else {
            this.mousedown(e);
        }
    };

    onTouchZoom = e => {
        //if (!this.scale) this.scale = 1;
        if (!this.finger_dist) this.finger_dist = this.getFingerDistance(e);

        var new_finger_dist = this.getFingerDistance(e); // Get current distance between fingers
        var distance = new_finger_dist/this.finger_dist; // Zoom is proportional to change

       // alert(distance);
        this.scale = this.scale * distance // Zoom is proportional to change
        this.finger_dist = new_finger_dist; // Save current distance for next time

        Configuration.setValue("scale", this.scale);

        //alert(distance);
        //Configuration.setValue("scale",distance/100);
        this.zooming = true;
        this.view.draw();
    };

    onMouseZoom = (e) => {
        if (e.originalEvent.wheelDelta / 120 > 0) {
            this.scale += 0.1;
        } else {
            if (this.scale > 0.5) this.scale -= 0.1;
        }
        Configuration.setValue("scale", this.scale);
        this.view.draw();

    };

    zoom = () => {
        //console.log(Configuration.getNumericValue('scale'));
        var centerX = this.canvasElement.innerWidth() / 2.0;
        var centerY = this.canvasElement.innerHeight() / 2.0;
        var originScreen = new Vector2(centerX, centerY);
        var currentPan = new Vector2(this.unScaledOriginX + centerX, this.unScaledOriginY + centerY);
        currentPan = currentPan.multiplyScalar(Configuration.getNumericValue('scale')).sub(originScreen);

        this.originX = currentPan.x;
        this.originY = currentPan.y;
    };

    /** Convert from THREEjs coords to canvas coords. */
    convertX(x) {
        return Dimensioning.cmToPixel(x - Dimensioning.pixelToCm(this.originX));
//		return (x - (this.originX * this.cmPerPixel)) * this.pixelsPerCm;
    }

    /** Convert from THREEjs coords to canvas coords. */
    convertY(y) {
        return Dimensioning.cmToPixel(y - Dimensioning.pixelToCm(this.originY));
//		return (y - (this.originY * this.cmPerPixel)) * this.pixelsPerCm;
    }
}
