import React from 'react';
import {center, down, left, right, up} from '../../../assets/images/controls';

const PanControls = ({translateCamera,mobile, testMode, width}) => {
    return (
        <div id={mobile ? "pan-controls_mobile" : "pan-controls"}>
            <div className="btn button_custom" href="#" id="leftview" title="Show side view (left)"
                 onClick={() => translateCamera("left")}>
                <img className={'pan-controls-img'} alt={'left'} src={left}/>
            </div>
            <span className="btn-group-vertical">
		              <div className="btn button_custom" href="#" id="topview" title="Show top view"
                           onClick={() => translateCamera("forward")}>
                        <img className={'pan-controls-img'} alt={'up'} src={up}/>
		              </div>
		              <div className="btn button_custom" href="#" id="isometryview" title="Show 3d view"
                           onClick={() => translateCamera("center")}>
                        <img className={'pan-controls-img'} alt={'center'} src={center}/>
		              </div>
		              <div className="btn button_custom" href="#" id="frontview" title="Show front view"
                           onClick={() => translateCamera("down")}>
                        <img className={'pan-controls-img'} alt={'down'} src={down}/>
		              </div>
            </span>
            <div className="btn button_custom bottom" href="#" id="rightview" title="Show side view (right)"
                 onClick={() => translateCamera("right")}>
                <img className={'pan-controls-img'} alt={'right'} src={right}/>
            </div>
        </div>
    );

};

export default PanControls;
