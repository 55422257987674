import {
    Color,
    EventDispatcher,
    ExtrudeGeometry,
    Geometry,
    Mesh,
    MeshPhongMaterial,
    RepeatWrapping,
    Shape,
    TextureLoader,
    Vector3,
} from 'three';
//import {MTLLoader, OBJLoader} from 'three-obj-mtl-loader'
import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader';
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {Utils} from '../utils.js';
import {Factory} from '../items/factory.js';
import {EVENT_ITEM_LOADED, EVENT_ITEM_LOADING, EVENT_ITEM_REMOVED} from '../events.js';
import {SM} from "../../../components/SceneManager";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader.js';
import {ColladaLoader} from 'three/examples/jsm/loaders/ColladaLoader.js';
import {WallItem} from "../items";

/**
 * The Scene is a manager of Items and also links to a ThreeJS scene.
 */
export class Scene extends EventDispatcher {
    /**
     * Constructs a scene.
     * @param model The associated model.
     * @param textureDir The directory from which to load the textures.
     */
    constructor(model, textureDir) {
        super();
        this.model = model;

//		var grid = new GridHelper(4000, 200);

        //USE THE SM (my scene) for now.
        this.scene = SM.scene;
        this.scene.background = new Color(0xffffff);
        //this.scene.fog = new Fog(0xFAFAFA, 0.001, 6000);
        this.items = [];
        this.needsUpdate = false;
        // init item loader
        //this.loader = new JSONLoader();
        //this.loader.setCrossOrigin('');

        this.gltfloader = new GLTFLoader(SM.loadingManager);
        this.fbxLoader = new FBXLoader(SM.loadingManager);
        this.colladaLoader = new ColladaLoader(SM.loadingManager);

        //this.gltfloader.setCrossOrigin('');

        this.itemLoadingCallbacks = null;
        this.itemLoadedCallbacks = null;
        this.itemRemovedCallbacks = null;
    }

    /** Adds a non-item, basically a mesh, to the scene.
     * @param mesh The mesh to be added.
     */
    add(mesh) {
        this.scene.add(mesh);
    }

    /** Removes a non-item, basically a mesh, from the scene.
     * @param mesh The mesh to be removed.
     */
    remove(mesh) {
        this.scene.remove(mesh);
        Utils.removeValue(this.items, mesh);
    }

    /** Gets the scene.
     * @returns The scene.
     */
    getScene() {
        return this.scene;
    }

    /** Gets the items.
     * @returns The items.
     */
    getItems() {
        return this.items;
    }

    /** Gets the count of items.
     * @returns The count.
     */
    itemCount() {
        return this.items.length;
    }

    /** Removes all items. */
    clearItems() {
        //console.log("Clearing all items from the scene.");
        // var items_copy = this.items ;
        var scope = this;
        this.items.forEach((item) => {
            scope.removeItem(item, true);
        });
        this.items = [];
    }

    /**
     * Removes an item.
     * @param item The item to be removed.
     * @param dontRemove If not set, also remove the item from the items list.
     */
    removeItem(item, keepInList) {
        keepInList = keepInList || false;
        // use this for item meshes
        this.dispatchEvent({type: EVENT_ITEM_REMOVED, item: item});
        //this.itemRemovedCallbacks.fire(item);
        item.removed();
        this.scene.remove(item);
        if (!keepInList) {
            Utils.removeValue(this.items, item);
        }
    }

    switchWireframe(flag) {
        this.items.forEach((item) => {
            item.switchWireframe(flag);
        });
    }

    createMaterial = (material) => {

        function onTextureLoad(texture) {

            texture.wrapS = texture.wrapT = RepeatWrapping;
            texture.repeat.set(0.02, 0.02);


            /*
            let tileLengthMetres = this.details.measurements.length;
            let tileWidthMetres = this.details.measurements.width;
            let tileSqMetres = tileLengthMetres * tileWidthMetres;
            let roomSqMetres = this.length * this.width / 10;
            let tilesThatShouldFitInArea = roomSqMetres / tileSqMetres;
            let textureLength = (this.length) / tileLengthMetres;
            let textureWidth = (this.width) / tileWidthMetres;
            this.details.itemCount = tilesThatShouldFitInArea;

             */
        }

        var loader = new TextureLoader();
        var texture = loader.load(`${material === null ? 'olive_leaf.png' : material}`, texture => onTextureLoad(texture));

        var mat = new MeshPhongMaterial({
            transparent: false,
            map: texture
        });

        return mat;
    };


    addCustomItem = (details) => {
        var scope = this;

        console.log(details);
        //create geometry and material.
        let shape = new Shape();
        shape.moveTo(details.start.x, details.start.y);
        shape.lineTo(details.start.x, details.end.y);
        shape.lineTo(details.end.x, details.end.y);
        shape.lineTo(details.end.x, details.start.y);

        let extrusionSettings = {
            depth: 2,
            bevelEnabled: false,
            material: 1
        };

        let geometry = new ExtrudeGeometry(shape, extrusionSettings);

        var material = new MeshPhongMaterial({
            transparent: false,
            color: "#FFFFFF"
        });

        if (details.itemType === 11) {
            material = this.createMaterial(details.material);
        }

        var metadata = {
            itemName: details.slug,
            resizable: false,
            modelUrl: "",
            itemType: details.itemType,
            format: "",
            start: details.start,
            end: details.end,
            material: details.material
        };

        var widthOfObject = Math.abs(details.start.x - details.end.x);
        var heightOfObject = Math.abs(details.start.y - details.end.y);

        /*
        var spawnX = widthOfObject + details.spawnPoint.x;
        var spawnY = heightOfObject + details.spawnPoint.y;

        var midX = (details.start.x + details.end.x) / 2;
        var midY = (details.start.y + details.end.y) / 2;
         */


        //FIXME: PIVOT IS IN THE CENTER OF THE OBJECT.
        var spawnPoint = new Vector3(details.spawnPoint.x, details.spawnPoint.y - heightOfObject / 2, details.spawnPoint.z - widthOfObject / 2);

        var item = new (Factory.getClass(details.itemType))(this.model, metadata, geometry, material, spawnPoint, null, null, false);
        scope.items.push(item);
        item.translateX(widthOfObject / 2);
        scope.add(item);
        item.initObject();
        scope.dispatchEvent({type: EVENT_ITEM_LOADED, item: item});
    };

    /**
     * Creates an item and adds it to the scene.
     * @param itemType The type of the item given by an enumerator.
     * @param fileName The name of the file to load.
     * @param metadata Position,scale,etc... details are taken from a database online.
     * @param position The initial position.
     * @param rotation The initial rotation around the y axis.
     * @param scale The initial scaling.
     * @param fixed True if fixed.
     * @param newItemDefinitions - Object with position and 'edge' attribute if it is a wall item
     */
    addItem(itemType, fileName, metadata, position, rotation, scale, fixed, newItemDefinitions) {
        if (itemType === undefined) {
            itemType = 1;
        }

        var scope = this;

        function addToMaterials(materials, newmaterial) {
            for (var i = 0; i < materials.length; i++) {
                var mat = materials[i];
                if (mat.name === newmaterial.name) {
                    return [materials, i];
                }
            }
            materials.push(newmaterial);
            return [materials, materials.length - 1];
        }

        var loaderCallback = function (geometry, materials, isgltf = false) {
//			var item = new (Factory.getClass(itemType))(scope.model, metadata, geometry, new MeshFaceMaterial(materials), position, rotation, scale);
            try {
                var item = new (Factory.getClass(itemType))(scope.model, metadata, geometry, materials, position, rotation, scale, isgltf);
                item.fixed = fixed || false;
                scope.items.push(item);
                //Sorting items in manner of item type so that they appear from top down in 2D.
                scope.items.sort((item1, item2) => {
                    if (item1.metadata.category === 'Carpets') return -1;
                    if (item1.metadata.category === 'Chairs') return -1;
                    if (item1.metadata.type > item2.metadata.type) return 1;
                    if (item1.metadata.type < item2.metadata.type) return -1;
                    return 0;
                });
                scope.add(item);
                item.initObject();
                scope.dispatchEvent({type: EVENT_ITEM_LOADED, item: item});
                if (item instanceof WallItem) {
                    if (newItemDefinitions) {
                        //console.log(newItemDefinitions);
                        item.moveToPosition(item.position, newItemDefinitions.edge);
                        item.wallEdgeMountedTo = newItemDefinitions.edge;
                        //item.placeInRoom();
                    }
                }


                SM.onItemLoaded(item);
            } catch (e) {
                console.error(e);
                SM.addActionResultError("Error on loader callback.")
            }

        };
        var gltfCallback = function (gltfModel) {
            objCallback(gltfModel.scene);
            /*
            return;
            var newmaterials = [];
            var newGeometry = new Geometry();

            gltfModel.scene.traverse(function (child) {
                if (child.type === 'Mesh') {
                    child.receiveShadow = false;
                    //child.material.color = 0xffffff;
                    var materialindices = [];
                    if (child.material.length) {
                        for (var k = 0; k < child.material.length; k++) {
                            var newItems = addToMaterials(newmaterials, child.material[k]);
                            newmaterials = newItems[0];
                            materialindices.push(newItems[1]);
                        }
                    } else {
                        newItems = addToMaterials(newmaterials, child.material);//materials.push(child.material);
                        newmaterials = newItems[0];
                        materialindices.push(newItems[1]);
                    }

                    if (child.geometry.isBufferGeometry) {
                        var tGeometry = new Geometry().fromBufferGeometry(child.geometry);
                        tGeometry.faces.forEach((face) => {
//							face.materialIndex = face.materialIndex + newmaterials.length;i
                            face.materialIndex = materialindices[face.materialIndex];
                        });
                        child.updateMatrix();
                        newGeometry.merge(tGeometry, child.matrix);
                    } else {
                        child.geometry.faces.forEach((face) => {
//							face.materialIndex = face.materialIndex + newmaterials.length;
                            face.materialIndex = materialindices[face.materialIndex];
                        });
                        child.updateMatrix();
                        newGeometry.mergeMesh(child);
                    }
                }
            });


            //gltfModel.scene.scale.set(20,20,20) // scale here
            //SM.scene.add(gltfModel.scene);
            //loaderCallback(newGeometry, newmaterials);
            //console.log(gltfModel);
            //gltfModel.scene.position.set(250,250,250);
            //gltfModel.scene.scale.set(30,30,30)
            loaderCallback(gltfModel, newmaterials, true);

             */
        };


        var objCallback = function (object) {
            var materials = [];
            var newGeometry = new Geometry();

            //console.log("OBJECT CALLBACK, CHECK IF THIS METHOD IS MISALIGNING OBJECTS, CHILDREN, GEOMETRY")


            //NOTE: TESTING HERE THAT OBJECT IS PROPER
            /*
            if (object.materialLibraries[0] !== "box.mtl") {
                //console.log(object.position);
                //object.position.set(200,200,200);
                object.scale.set(100,100,100);
                SM.scene.add(object);
                console.log(object);
                var helper = new BoxHelper(object, 0xff0000);
                helper.update();
                SM.scene.add(helper);
            }
             */





            try {
                object.traverse(function (child) {
                    //console.log(child);
                    //Bones dont work, animated objects have these, dont need their geometry to trace outline
                    //child.receiveShadow = false;
                    //child.material.color = 0xffffff;
                    var materialindices = [];
                    if (child.material && child.material.length) {
                        for (var k = 0; k < child.material.length; k++) {
                            var newItems = addToMaterials(materials, child.material[k]);
                            materials = newItems[0];
                            materialindices.push(newItems[1]);
                        }
                    } else {
                        if (child.material) {
                            newItems = addToMaterials(materials, child.material);//materials.push(child.material);
                            materials = newItems[0];
                            materialindices.push(newItems[1]);
                        }

                    }

                    if (child.geometry && child.geometry.isBufferGeometry) {
                        try {
                            var tGeometry = new Geometry().fromBufferGeometry(child.geometry);

                            tGeometry.faces.forEach((face) => {
							        //face.materialIndex = face.materialIndex + newmaterials.length;i
                                face.materialIndex = materialindices[face.materialIndex];

                            });

                            child.updateMatrix();
                            newGeometry.merge(tGeometry, child.matrix);
                        } catch (exception) {
                            throw exception;
                        }

                        } else {
                            if (child.geometry) {

                            child.geometry.faces.forEach((face) => {
//							face.materialIndex = face.materialIndex + newmaterials.length;
                                 face.materialIndex = materialindices[face.materialIndex];
                            });
                            child.updateMatrix();
                             newGeometry.mergeMesh(child);
                        }
                    }

                });
                //console.log(newGeometry);
                //console.log(materials);

                //LOADED INTO SINGLE MESH
                //var uv = uv = [[1, 1], [ 0, 1], [1,  0], [ 0,  0]];

                //newGeometry.faceVertexUvs[0] = [];

                loaderCallback(newGeometry, materials);
            } catch (exception) {

                SM.addActionResultError("Incorrect format. Please try another object.")

            }


        };
        let mtlName = metadata.mtlURL;
        let texture = metadata.texture;
        var businessName = metadata.businessName;
        //If generic item, stored on this server.
        var genericURL = `${process.env.PUBLIC_URL}/Assets/objects/${metadata.slug}/`;
        var onlineURL = `https://mastersuite-78d43.appspot.com.storage.googleapis.com/objects/${businessName}/`;

        if (metadata.generic) {
            fileName = genericURL+ fileName;
            if (mtlName) {
                mtlName = genericURL+ metadata.mtlURL;
            }
            if (texture) {
                texture = genericURL + metadata.texture;
            }
        }

        this.dispatchEvent({type: EVENT_ITEM_LOADING});

        if (metadata.format.toLowerCase() === 'gltf' || metadata.format.toLowerCase() === "glb") {

            if (businessName) {
                this.gltfloader.setResourcePath(onlineURL);
                this.gltfloader.setCrossOrigin("Anonymous")
            } else {
                this.gltfloader.setResourcePath('');
            }
            this.gltfloader.load(fileName, gltfCallback);
        } else if (metadata.format.toLowerCase() === 'dae') {
            if (businessName) {
                //NEED METADATA - to only set resource path if businessName.
                //BUCKET IS LINKED TO FIREBASE.
                this.colladaLoader.setResourcePath(onlineURL);
                //console.log("Not working because of ?alt=media needed");
                this.colladaLoader.setCrossOrigin("Anonymous")
            } else {
                this.colladaLoader.setResourcePath('');
            }
            this.colladaLoader.load(fileName, gltfCallback)
        } else if (metadata.format.toLowerCase() === 'fbx') {
            if (businessName) {
                //NEED METADATA - to only set resource path if businessName.
                //BUCKET IS LINKED TO FIREBASE.
                this.fbxLoader.setResourcePath(onlineURL);
                //console.log("Not working because of ?alt=media needed");
                this.fbxLoader.setCrossOrigin("Anonymous")
            } else {
                this.fbxLoader.setResourcePath('');
            }
            this.fbxLoader.load(fileName, objCallback);
        } else if (metadata.format.toLowerCase() === 'obj') {

            //redefining because of async issues if spawning objects too quickly, need seperate instances.
            var mtlLoader = new MTLLoader(SM.loadingManager);
            var objLoader = new OBJLoader(SM.loadingManager);


            if (mtlName) {

                if (businessName) {
                    //NEED METADATA - to only set resource path if businessName.
                    //BUCKET IS LINKED TO FIREBASE.
                    mtlLoader.setResourcePath(onlineURL);
                    //console.log("Not working because of ?alt=media needed");
                    mtlLoader.setCrossOrigin("Anonymous")
                } else {
                    mtlLoader.setResourcePath('');
                }
                //async
                mtlLoader.load(mtlName, materials => {
                   // console.log({msg:"Loaded in materials",materials})
                    materials.preload();
                    objLoader.setMaterials(materials);
                    objLoader.load(fileName, function(object) {
                        if (texture) {
                            var textureLoaded = new TextureLoader().load(texture);
                            object.traverse(function (child) {
                                if (child instanceof Mesh) {
                                    child.material.map = textureLoaded;
                                }
                            })
                        }
                        objCallback(object);
                    });
                })
            } else {
                objLoader.setMaterials(null);
                objLoader.load(fileName, objCallback);
            }
        } else {
            console.error("UNKNOWN FILE FORMAT, CANNOT LOAD.");
        }
    }
}
