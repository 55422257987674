import React, {Component} from 'react';

class AdvertiserPanel extends Component {
    render() {
        const {advertiser} = this.props;
        return (
            <div className={"bg-normal-interactive catalogue-advertiser-panel"} onClick={() => this.props.selectAdvertiser(advertiser)}>
                <img alt={'Advertiser Avatar'} className={'catalogue-advertiser-avatar'} src={advertiser.avatar}/>
            </div>
        );
    }
}

export default AdvertiserPanel;