import React, {Component} from 'react';
import HomeHeader from "./Website/Navigation/HomeHeader";
import SceneManager from "./SceneManager";
import {isMobile} from 'react-device-detect';

class SceneManagerWrapper extends Component {
    render() {
        document.body.style.overflow = "hidden";
        document.getElementById('root').style.overflow = 'hidden';
        //console.log("So an advertiser account is still a user, but if not logged in prompted to create a user account.");
        //console.log(this.props);
       // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        if (isMobile) {
            this.props.history.push(`/home`);
            window.open("https://play.google.com/store/apps/details?id=com.envate.mastersuite&hl=en");

            return null;
        }
        return (
            <div>
                <HomeHeader lite={true}/>
                <SceneManager/>
                {
                    /* <SiteFooter/> */
                }
            </div>
        );
    }
}

export default SceneManagerWrapper;
