//https://material-ui.com/components/icons/
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {grey} from '@material-ui/core/colors';

import React from 'react';
import {Link} from "react-router-dom";

// eslint-disable-next-line no-unused-vars
const pages = [
    {
        title: 'Items',
        href: '/admin/items',
        icon: <ShoppingBasketIcon style={{ color: grey[500] }}/>
    },
    {
        title: 'Extras',
        href: '/admin/extras',
        icon: <DashboardIcon style={{ color: grey[500] }}/>
    },
    {
        title: 'Test Suite',
        href: '/admin/test-suite',
        icon: <LockOpenIcon style={{ color: grey[500] }}/>
    }
    /*
    {
        title: 'Settings',
        href: '/admin/settings',
        icon: <SettingsIcon style={{ color: grey[500] }}/>
    }
     */
];
const Sidebar = (props) => {

        var {profile,path} = props;

        return (
            <div className={'side-bar main-gradient-static'}>
                <div className={'side-bar-top'}>
                    <div className={'side-bar-top-left'}>
                        {profile.avatar && <img alt="company avatar" src={profile.avatar}/>}
                    </div>
                    <div className={'side-bar-top-right'}>
                        {profile.businessName && <p className={'side-bar-title'}>{profile.businessName}</p>}
                    </div>
                </div>
                <div className={'side-bar-links main-gradient-static'}>
                    {pages.map(page => {
                        return (
                            <Link key={page.title} to={page.href}>
                                <div className={'side-bar-link  ' + (path.path === page.href ? 'selected-link selected-gradient' : '')}>
                                    <div style={{paddingLeft:'0.4em',paddingTop:'0.4em'}}>
                                        {page.icon}
                                    </div>
                                    <p style={{paddingRight:'0.4em',paddingTop:'0.4em'}}>
                                        {page.title}
                                    </p>
                                </div>
                            </Link>
                    )
                    })}
                </div>
            </div>
        );
}

export default Sidebar;
