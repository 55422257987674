import React, {Component} from 'react';
import 'intro.js/introjs.css';
import { Steps, Hints } from 'intro.js-react';

class Tutorial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stepsEnabled: false,
            initialStep: 0,
            steps: [
                {
                    element: ".change-modes",
                    intro: "Click 'Floorplan' to view and edit the floorplan of the home."
                },
                {
                    element: ".show-or-hide-house",
                    intro: "Toggle the exterior or interior view of your home here."
                },
                {
                    element: ".preset-shapes",
                    intro: "Select a new preset home here."
                },
                {
                    element: ".save-load-designs",
                    intro: "Save and load your designs."
                },
                {
                    element: "#Catalogue",
                    intro: "Choose from a selection of items, drag the item images into the scene."
                }
            ],
            hintsEnabled: false,
            hints: [
                {
                    element: ".dev-modes",
                    hint: "Hello hint",
                    hintPosition: "middle-right"
                }
            ]
        };
    }
    render() {
        const {
            stepsEnabled,
            steps,
            initialStep,
            hintsEnabled,
            hints
        } = this.state;

        return (
            <div>
                <Steps
                    enabled={stepsEnabled}
                    steps={steps}
                    initialStep={initialStep}
                    onExit={this.onExit}
                />
                <Hints enabled={hintsEnabled} hints={hints} />

                <div>
                        <button className={'btn-low main-gradient'} style={{width:'100%'}} onClick={this.toggleSteps}>Show Tutorial</button>
                </div>
            </div>
        );
    }

    onExit = () => {
        this.setState(() => ({ stepsEnabled: false }));
    };

    toggleSteps = () => {
        this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
    };

    addStep = () => {
        const newStep = {
            element: ".alive",
            intro: "Alive step"
        };

        this.setState(prevState => ({ steps: [...prevState.steps, newStep] }));
    };

    toggleHints = () => {
        this.setState(prevState => ({ hintsEnabled: !prevState.hintsEnabled }));
    };

    addHint = () => {
        const newHint = {
            element: ".alive",
            hint: "Alive hint",
            hintPosition: "middle-right"
        };

        this.setState(prevState => ({ hints: [...prevState.hints, newHint] }));
    };
}

export default Tutorial;
