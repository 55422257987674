import {ACTION_LOADING, ACTION_RESULT_ERROR, ACTION_RESULT_SUCCESS, CLEAR_ALL_ACTION_RESULTS} from "./types";


export const clearAllActionResults = () => ({
    type: CLEAR_ALL_ACTION_RESULTS
});

export const addActionResultSuccess = message => ({
    type: ACTION_RESULT_SUCCESS,
    message: message
});

export const addActionResultError = message => ({
    type: ACTION_RESULT_ERROR,
    message: message
});

export const actionResultError = message => {
    return (dispatch) => {
        dispatch(addActionResultError(message))
    }
};
export const actionResultSuccess = message => {
    return (dispatch) => {
        dispatch(addActionResultSuccess(message))
    }
};

export const loadingState = (state) => (
    {
        type: ACTION_LOADING,
        payload: state
    }
);


export const changeLoadingState = isLoading => {
    return (dispatch) => {
        dispatch(
            loadingState(isLoading)
        )
    }
};

