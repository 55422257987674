import withStyles from "@material-ui/core/styles/withStyles";
import Slider from "@material-ui/core/Slider";

export const PrettoSlider = withStyles({
    root: {
        color: '#af1623',
        height: 8,
    },
    thumb: {
        height: 12,
        width: 12,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -2,
        marginLeft: -6,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);
