//Redux can be used to dispatch events when objects are updated.
//remember
//STORE -> central storage of state across applications
//ACTIONS -> Call from any file to dispatch to the Website application
//REDUCERS -> Catch dispatch and usually update the store state, which in turn updates everything.
import {applyMiddleware, compose, createStore} from 'redux'
import rootReducer from './reducers' // where reducers is an object of reducers
import thunk from 'redux-thunk';
import {getFirestore, reduxFirestore} from 'redux-firestore';
import {getFirebase} from 'react-redux-firebase';
import fbConfig from '../config/firebase';

const initialState = {};

// react-redux-firebase config
const rrfConfig = {
    userProfile: 'advertisers',
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
    attachAuthIsReady: true
    // enableClaims: true // Get custom claims along with the profile
};

var store = createStore(
    rootReducer,
    initialState,
    //Store enhancers.
    compose(
        applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})),
        reduxFirestore(fbConfig),
    )
);

//console.warn("Using other store");

export const rrfProps = {
    firebase: fbConfig,
    config: rrfConfig,
    dispatch: store.dispatch,
    // createFirestoreInstance // <- needed if using firestore
};

export default store;