import {EVENT_GLTF_READY, EVENT_LOADED, EVENT_LOADING} from '../events.js';
import {EventDispatcher, Mesh, Vector3} from 'three';

import GLTFExporter from 'three-gltf-exporter';
import {Floorplanbridge} from "./floorplanbridge";
import {Scene} from './scene'
import {SM} from "../../../components/SceneManager";

/**
 * A Model is an abstract concept the has the data structuring a floorplan. It connects a {@link Floorplanbridge} and a {@link Scene}
 */
export class Model extends EventDispatcher {
    /** Constructs a new model.
     * @param textureDir The directory containing the textures.
     */
    constructor(textureDir) {
        super();
        this.floorplan = new Floorplanbridge();
        this.scene = new Scene(this, textureDir);
        this.roomLoadingCallbacks = null;
        this.roomLoadedCallbacks = null;
        this.roomSavedCallbacks = null;
        this.roomDeletedCallbacks = null;

    }

    printFloorPlan = () => {
        console.log(this.floorplan);
    };

    update() {
        this.floorplan.update();

    }

    switchWireframe(flag) {
        this.scene.switchWireframe(flag);
    }

    loadSerialized(json) {
        // TODO: better documentation on serialization format.
        // TODO: a much better serialization format.
        this.dispatchEvent({type: EVENT_LOADING, item: this});
        //      this.roomLoadingCallbacks.fire();

        var data = JSON.parse(json);
        if (data.items.length <= 0) {
            SM.stopLoading();
        }
        this.newRoom(data.floorplan, data.items);

        this.dispatchEvent({type: EVENT_LOADED, item: this});
        //      this.roomLoadedCallbacks.fire();
    }

    exportMeshAsObj() {
        //var exporter = new OBJExporter();
        //return exporter.parse(this.scene.getScene());
    }

    exportForBlender() {
        var scope = this;
        var gltfexporter = new GLTFExporter();
        var meshes = [];
        this.scene.getScene().traverse(function (child) {
            if (child instanceof Mesh) {
                if (child.material) {
                    if (child.material.length || child.material.visible) {
                        var op = (child.material.transparent) ? child.material.opacity : undefined;
                        meshes.push(child);
                        if (op) {
                            child.material.opacity = op;
                        }
                    }
                }
            }
        });

        gltfexporter.parse(meshes, function (result) {
            var output = JSON.stringify(result, null, 2);
            scope.dispatchEvent({type: EVENT_GLTF_READY, item: this, gltf: output});
        });
    }

    exportSerialized() {
        var items_arr = [];

        var objects = this.scene.getItems();
        for (var i = 0; i < objects.length; i++) {
            var obj = objects[i];
//			items_arr[i] = {item_name: obj.metadata.itemName,item_type: obj.metadata.itemType,model_url: obj.metadata.modelUrl,xpos: obj.position.x,ypos: obj.position.y,zpos: obj.position.z,rotation: obj.rotation.y,scale_x: obj.scale.x,scale_y: obj.scale.y,scale_z: obj.scale.z,fixed: obj.fixed};
            items_arr[i] = obj.getMetaData();
            //console.log(obj.getMetaData());
        }

        var room = {floorplan: (this.floorplan.saveFloorplan()), items: items_arr};
        return JSON.stringify(room, null,4);
    }

    newRoom(floorplan, items) {

        this.scene.clearItems();
        this.floorplan.loadFloorplan(floorplan);
        items.forEach((item) => {
            //var matColors = (item.material_colors) ? item.material_colors : [];

            if (item.itemType === 10 || item.itemType === 11) {
                var details = {
                    ...item
                };
                details.spawnPoint = item.position;
                /*
                start: item.start,
                    end: item.end,
                    material: item.material,
                    type: item.item_type,
                    measurements,
                    scale,
                    spawnPoint: position,
                 */
                console.warn("These custom items are not being positioned correctly, has to do with spawnpoint taking current position of object.");
                this.scene.addCustomItem(details);
            } else {
                //Position needs to be held in a vector3 to work, not as a regular object.
                item.position = new Vector3(item.position.x, item.position.y, item.position.z);
                SM.addItem(item, item.position);
            }
        });
    }
}
