import {LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT_SUCCESS, REGISTER_FAILURE, REGISTER_SUCCESS} from "../actions/types";

const initialState = {
    authError: null,
    user: null
};

export default function (state = initialState, action) {

    switch (action.type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                authError: null
            };
        case REGISTER_FAILURE:
            return {
                ...state,
                authError: action.err
            };
        case LOGOUT_SUCCESS:
            return state;

        case LOGIN_FAILURE:
            return {
                ...state,
                authError: action.err
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.data.user,
                authError: null
            };
        default:
            return state;

    }
}
