import {Clock, EventDispatcher} from 'three';
import {EVENT_UPDATED} from '../events.js';
import {Floor} from './floor.js';
import {Edge} from '../three/edge.js';
import {SM} from "../../../components/SceneManager";

export class Floorplan3D extends EventDispatcher {
    constructor(scene, floorPlan, controls) {
        super();
        this.scene = scene;
        this.floorplan = floorPlan;
        this.controls = controls;
        this.floors = [];
        this.edges = [];
        var scope = this;
        // floorPlan.fireOnUpdatedRooms(redraw);
        this.updatedroomsevent = () => {
            scope.redraw();
        };
        this.floorplan.addEventListener(EVENT_UPDATED, this.updatedroomsevent);

        //redraw intially
        scope.redrawRoom();

        this.clock = new Clock(true);
        this.clock.start();
        this.timeLastRedrawed = 0;


    }

    switchWireframe(flag) {
        this.floors.forEach((floor) => {
            floor.switchWireframe(flag);
        });
        this.edges.forEach((edge) => {
            edge.switchWireframe(flag);
        });
    }


    showRoofs() {
        //REMOVE ALL FLOOR AND ROOF PLANES.
        this.floors.forEach((floor) => {
            floor.showRoof();
        });
    }
    redrawRoom() {

       // console.log("Using this redraw event called once on change to 3D mode, uncheck below method 'redraw' if you want to have it change at every event, e.g. live view.");
        var scope = this;


        // clear scene

        //REMOVE ALL FLOOR AND ROOF PLANES.
        this.floors.forEach((floor) => {
            floor.removeFromScene();
        });

        this.floorplan.orphanWalls.forEach(wall => {
            wall.fireRedraw();
        })
        //REMOVE ALL EDGES. - REMEMBER THIS IS JUST VISUALLY DRAWN HALFEDGE
        this.edges.forEach((edge) => {
            edge.remove();
        });
        this.floors = [];
        this.edges = [];

        // draw floors

        this.floorplan.getRooms().forEach((room) => {


            var threeFloor = new Floor(this.scene, room);
            room.floor = threeFloor;
            this.floors.push(threeFloor);
            threeFloor.addToScene();
        });

        //console.log("Floors: " + this.floors.length);

        var eindex = 0;

        this.floorplan.walls.forEach((wall) => {
            //Make both walls.
            var frontEdgeIsMain =wall.frontEdge;
            var backEdgeIsMain =wall.backEdge;
            var isInnerRoom = (wall.frontEdge && wall.frontEdge.main) && (backEdgeIsMain && wall.backEdge.main);

            var halfEdge,threeEdge = null;

            if (frontEdgeIsMain) {
                halfEdge = wall.frontEdge;
                //One edge is created for every half edge.
                threeEdge = new Edge(scope.scene, halfEdge, scope.controls,isInnerRoom,true);
                threeEdge.name = 'edge_' + eindex;
                this.edges.push(threeEdge);
                eindex += 1;
            }
            if (backEdgeIsMain) {
                halfEdge = wall.backEdge;
                //One edge is created for every half edge.
                threeEdge = new Edge(scope.scene, halfEdge, scope.controls,isInnerRoom,false);
                threeEdge.name = 'edge_' + eindex;
                this.edges.push(threeEdge);
                eindex += 1;

            }

        });
        //DRAW THE EDGES OF THEE WALLS. NOT SAME AS MAIN WALL PLANES

        //SM.onRedrawRoom();
    }

    redraw() {
        //TODO: ignoring redraw events so that we can just trigger it once to save lots of lagg and resources.
        //FIXME: using this event though
        SM.onRedrawRoomLite();

        //SM.onRedrawRoomLite();
        //TODO: Live update viewer.
        /*
                return;
                var scope = this;
                // clear scene
                this.floors.forEach((floor) => {
                    floor.removeFromScene();
                });

                this.edges.forEach((edge) => {
                    edge.remove();
                });
                this.floors = [];
                this.edges = [];

                // draw floors

                this.floorplan.getRooms().forEach((room) => {
                    var threeFloor = new Floor(this.scene, room);
                    this.floors.push(threeFloor);
                    threeFloor.addToScene();
                });

                var eindex = 0;
                // draw edges
                this.floorplan.wallEdges().forEach((edge) => {
                    var threeEdge = new Edge(scope.scene, edge, scope.controls);
                    threeEdge.name = 'edge_'+eindex;
                    this.edges.push(threeEdge);
                    eindex+=1;
                });

                SM.onRedrawRoom();

         */
    }

    showRoof(flag) {
        // draw floors
        this.floors.forEach((threeFloor) => {
            threeFloor.showRoof(flag);
        });
    }
}
