import {
    AdditiveBlending,
    AnimationMixer,
    Box3,
    BoxGeometry,
    BoxHelper,
    CanvasTexture,
    Color,
    DoubleSide,
    Geometry,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial,
    PlaneGeometry,
    Vector2,
    Vector3
} from 'three';
import {Utils} from '../utils.js';
import {Dimensioning} from '../dimensioning.js';
import React from "react";
import {SM} from "../../../components/SceneManager";
import missingFile from '../../../assets/images/missing_file.png'
import {GLASS_MATERIAL} from "../../../utils/constants/constantValues";

/**
 * An Item is an abstract entity for all things placed in the scene, e.g. at
 * walls or on the floor.
 */
export class Item extends Mesh {
    /**
     * Constructs an item.
     *
     * @param model - SCENE.model
     * @param metadata - metadata for the item, position, etc
     * @param geometry - shape geometry to use to measure bounds, construct helper objects, etc.
     * for custom item, just pass in shapegeometry determined in pervious step
     * @param material - the material class, e.g. MeshPhongMaterial object.
     * @param position - v3 position to spawn this in.
     * @param rotation - Can be NULL - the rotation of this object.
     * @param scale - v3 scale of this object.
     */
    constructor(model, metadata, geometry, material, position, rotation, scale, isgltf = false) {
        super();

        this.model = model;
        this.metadata = metadata;

        function validMeasurements(measurements) {
            if (!measurements) return null;
            if (!measurements.height || isNaN(measurements.height)) return null;
            if (!measurements.width || isNaN(measurements.width)) return null;
            if (!measurements.length || isNaN(measurements.length)) return null;
            if (measurements.height === 0 || measurements.width === 0 || measurements.length === 0) return null;
            return measurements;
        }

        const validScale = (scale) => {
            if (!scale) return null;
            if (!scale.x || isNaN(scale.x)) return null;
            if (!scale.y || isNaN(scale.y)) return null;
            if (!scale.z || isNaN(scale.z)) return null;
            this.scaleWasGiven = true;
            return scale;
        };

        this.realLifeMeasurements = validMeasurements(metadata.measurements);

        //console.error("WHY IS CONE NOT SHRINKING, SCALE STUFFS THINGS UP, NEED TO USE MEASUREMENTS");
        //console.error(this.realLifeMeasurements);
        //console.log("Real life measurements: " + this.realLifeMeasurements);

        /** */
        this.errorGlow = new Mesh();
        /** */
        this.hover = false;
        /** */
        this.selected = false;
        /** */
        this.highlighted = false;
        /** */
        this.error = false;
        /** */
        this.emissiveColor = 0x111111;
        /** Does this object affect other floor items */
        this.obstructFloorMoves = true;
        /** */
        this.position_set = false;
        /** Show rotate option in context menu */
        this.allowRotate = true;
        /** */
        this.fixed = false;
        /** dragging */
        this.dragOffset = new Vector3();

        this.render2dControls = false;
        /** */
        this.halfSize = new Vector3(0, 0, 0);
        this.bhelper = null;

        this.scene = this.model.scene;
        this._freePosition = true;

        var i = 0;

        if (isgltf) {
            isgltf = false;
        }
        if (!isgltf) {
            this.geometry = geometry;
            this.material = material;

            this.castShadow = true;
            this.receiveShadow = true;
            for (i = 0 ; i < this.children.length ; i++) {
                this.children[i].castShadow = true;
                this.children[i].receiveShadow = true;
            }
            // center in its boundingbox
            this.geometry.computeBoundingBox();
            this.geometry.applyMatrix4(new Matrix4().makeTranslation(-0.5 * (this.geometry.boundingBox.max.x + this.geometry.boundingBox.min.x), -0.5 * (this.geometry.boundingBox.max.y + this.geometry.boundingBox.min.y), -0.5 * (this.geometry.boundingBox.max.z + this.geometry.boundingBox.min.z)));
            this.geometry.computeBoundingBox();
        } else {



            /*
            this.geometry = geometry;
            this.material = material;

            // center in its boundingbox
            this.geometry.computeBoundingBox();
            this.geometry.applyMatrix(new Matrix4().makeTranslation(-0.5 * (this.geometry.boundingBox.max.x + this.geometry.boundingBox.min.x), -0.5 * (this.geometry.boundingBox.max.y + this.geometry.boundingBox.min.y), -0.5 * (this.geometry.boundingBox.max.z + this.geometry.boundingBox.min.z)));
            this.geometry.computeBoundingBox();
            */



            //Represents an axis-aligned bounding box in 3D space.
            var objectBox = new Box3();
            var gltfObject = geometry.scene;
            gltfObject.castShadow = false;
            gltfObject.receiveShadow = false;
            gltfObject.traverse( function( node ) {
                if ( node.isMesh ) { node.castShadow = true; node.receiveShadow=false; }
            } );
            objectBox.setFromObject(gltfObject);
            var hsize = objectBox.max.clone().sub(objectBox.min).multiplyScalar(0.5);
            this.geometry = new BoxGeometry(hsize.x * 2, hsize.y * 2, hsize.z * 2);
            this.geometry.computeBoundingBox();
            this.material = new MeshStandardMaterial({color: 0x00ff00, wireframe: true, visible: true});
            this.geometry.computeBoundingBox();
            gltfObject.position.set(gltfObject.position.x,0,gltfObject.position.z);
            gltfObject.material = this.material;
            this.objectgltf = gltfObject;
            //this.S(gltfObject);
            //this.add(this.objectgltf);
            //SM.scene.add(geometry.scene);
            //SM.mixer= new AnimationMixer(this.objectgltf);
            if (geometry.animations) {
                if (geometry.animations[0]) {
                    SM.mixer = new AnimationMixer( geometry.scene );
                    var action = SM.mixer.clipAction( geometry.animations[ 0 ] );
                    action.play();
                }
            }
            this.setItemGeometryToGLTF(gltfObject);
            this.castShadow = false;
            this.receiveShadow = true;
            for (i = 0 ; i < this.children.length ; i++) {
                this.children[i].castShadow = false;
                this.children[i].receiveShadow = true;
            }
        }

        if (!this.material.color) {
           // this.material.color = new Color('#bfbfbf');
        }
        this.wirematerial = new MeshBasicMaterial({color: 0x000000, wireframe: true});

        this.errorColor = 0xff0000;

        this.resizable = metadata.resizable;


        this.originalmaterial = material;
        //this.texture = this.material.texture;

        this.position_set = false;
        if (position) {
            this.position.copy(position);
            this.position_set = true;
        }

        this.halfSize = this.objectHalfSize();
        this.canvasWH = document.createElement('canvas');
        this.canvasWH.width = this.getWidth() + 1.0;
        this.canvasWH.height = this.getHeight() + 1.0;

        this.canvascontextWH = this.canvasWH.getContext('2d');
        this.canvasTextureWH = new CanvasTexture(this.canvasWH);
        this.canvasMaterialWH = new MeshBasicMaterial({map: this.canvasTextureWH, side: DoubleSide, transparent: true});
        this.canvasPlaneWH = new Mesh(new PlaneGeometry(this.getWidth(), this.getHeight(), 1, 1), this.canvasMaterialWH);
        this.canvasPlaneWH.scale.set(1, 1, 1);
        this.canvasPlaneWH.position.set(0, 0, this.getDepth() * 0.5 + 0.3);

        this.canvasWD = document.createElement('canvas');
        this.canvasWD.width = this.getWidth() + 1.0;
        this.canvasWD.height = this.getDepth() + 1.0;

        this.canvascontextWD = this.canvasWD.getContext('2d');
        this.canvasTextureWD = new CanvasTexture(this.canvasWD);
        this.canvasMaterialWD = new MeshBasicMaterial({map: this.canvasTextureWD, side: DoubleSide, transparent: true});
        this.canvasPlaneWD = new Mesh(new PlaneGeometry(this.getWidth(), this.getDepth(), 1, 1), this.canvasMaterialWD);
        this.canvasPlaneWD.rotateX(-Math.PI * 0.5);
        this.canvasPlaneWD.scale.set(1, 1, 1);
        this.canvasPlaneWD.position.set(0, this.getHeight() * 0.5 + 0.3, 0);
        this.canvasPlaneWH.visible = this.canvasPlaneWD.visible = false;

        this.add(this.canvasPlaneWH);
        this.add(this.canvasPlaneWD);
        this.resizeProportionally = false;

        if (rotation) {
            this.rotation.y = rotation;
        }


        scale = validScale(scale);

        if (scale != null) {
            this.setScale(scale.x, scale.y, scale.z);
        }

        if (this.metadata.materialColors) {
            if (this.metadata.materialColors.length) {
                if (this.material.length) {
                    for (i = 0; i < this.metadata.materialColors.length; i++) {
                        this.material[i].color = new Color(this.metadata.materialColors[i]);
                    }
                } else {
                    this.material.color = new Color(this.metadata.materialColors[0]);
                }
            }
        }

        if (this.realLifeMeasurements) {
            //console.log("Real life measurements!");
            this.rescale();
        }

        this.details = this.metadata;
        this.itemType = this.details.itemType;
        if (this.details.itemType === undefined) {
            this.itemType = 1;
        }

        this.designImage = new Image();
        this.buildingImage = new Image();



        var genericURL = `${process.env.PUBLIC_URL}/Assets/images/${this.details.slug}/${this.details.photoURL2D}`;

        if (this.details.photoURL2D) {
            if (this.details.generic) {
                this.designImage.src = genericURL
            } else {
                this.designImage.src = this.details.photoURL2D;
            }
        } else {
            this.designImage.src = missingFile;
        }

        if (this.details.photoURL2D) {
            if (this.details.generic) {
                this.buildingImage.src = process.env.PUBLIC_URL + genericURL
            } else {
                this.buildingImage.src = this.details.photoURL2D;
            }
        } else {
            this.buildingImage.src = missingFile;
        }

        if (this.metadata.rotation) {
            this.rotation.set(this.metadata.rotation.x,this.metadata.rotation.y,this.metadata.rotation.z);
        }

        if (this.metadata.gltfTesting) {
            for (i = 0 ; i < this.material.length ; i++) {
                this.material[i] = new MeshBasicMaterial( {color: 0x3a3a3a} );
            }
        }

        this.material = (SM.wireframeFlag) ? this.wirematerial : this.originalmaterial;

        //var box = new BoxHelper( this, 0xffff00 );
        //SM.scene.add( box );
        //TODO: This is crucial, artifacts are being left over, this object should have no children.
        //from scene obj line 340
        if (!this.metadata.isLight) this.children=[];

        this.buildingImage.onload = () => {
            SM.floorPlanner.drawAll();
        };

        this.isLocked = this.metadata.isLocked;
        this.sideways = this.metadata.sideways;

        //console.log(this.metadata);
        if (this.metadata.name) {
            if (this.metadata.name.includes('Glass')) {
                this.material = GLASS_MATERIAL;
            }
        }

    }
    setItemGeometryToGLTF = (gltfScene) => {
        var newGeometry = new Geometry();
        var newmaterials = [];

        function addToMaterials(materials, newmaterial) {
            for (var i = 0; i < materials.length; i++) {
                var mat = materials[i];
                if (mat.name === newmaterial.name) {
                    return [materials, i];
                }
            }
            materials.push(newmaterial);
            return [materials, materials.length - 1];
        }

        gltfScene.traverse(function (child) {
            //Bones dont work, animated objects have these, dont need their geometry to trace outline
            if (true) {
                //child.receiveShadow = false;
                //child.material.color = 0xffffff;
                var materialindices = [];
                if (child.material && child.material.length) {
                    for (var k = 0; k < child.material.length; k++) {
                        var newItems = addToMaterials(newmaterials, child.material[k]);
                        newmaterials = newItems[0];
                        materialindices.push(newItems[1]);
                    }
                } else {
                    if (child.material) {
                        newItems = addToMaterials(newmaterials, child.material);//materials.push(child.material);
                        newmaterials = newItems[0];
                        materialindices.push(newItems[1]);
                    }

                }

                if (child.geometry && child.geometry.isBufferGeometry) {
                    var tGeometry = new Geometry().fromBufferGeometry(child.geometry);
                    tGeometry.faces.forEach((face) => {
//							face.materialIndex = face.materialIndex + newmaterials.length;i
                        face.materialIndex = materialindices[face.materialIndex];
                    });
                    child.updateMatrix();
                    newGeometry.merge(tGeometry, child.matrix);
                } else {
                    if (child.geometry) {
                        child.geometry.faces.forEach((face) => {
//							face.materialIndex = face.materialIndex + newmaterials.length;
                            face.materialIndex = materialindices[face.materialIndex];
                        });
                        child.updateMatrix();
                        newGeometry.mergeMesh(child);
                    }
                }
            }
        });
        this.geometry = newGeometry;
        this.material = newmaterials;



    };

    get Measurements() {
        return this.realLifeMeasurements;
    }

    get freePosition() {
        return this._freePosition;
    }

    setMeasurements = measurements => {
        this.realLifeMeasurements = measurements;
        this.rescale();
        SM._dirty = true;
    };
    rotate = way => {
        //????????
        //super.rotate(mode);
        if (way === 'left') {
            this.rotateLeft();
            this.sideways = !this.sideways;
            this.metadata.sideways = this.sideways;
        }
        if (way === 'right') {
            this.rotateRight();
            this.sideways = !this.sideways;
            this.metadata.sideways = this.sideways;

        }
        if (way === 'up') {
            this.rotateUp();

        }
        if (way === 'down') {
            this.rotateDown();
        }
        SM.updateForViewer(this.rotation);

        SM._dirty = true;
    };
    rescale = () => {

        //console.log("Rescaling item to real life measurements...");
        this.resize(this.realLifeMeasurements.height, this.realLifeMeasurements.width, this.realLifeMeasurements.length);


        /*
        var size = this.getHeight()/100;
        //console.log("current size: " + size/100);
        //console.log("Resizing to: " + this.realLifeMeasurements.height/1000);

        var rescale = this.scale;
        var newSize = this.realLifeMeasurements.height/1000;
        rescale.y = newSize * rescale.y / size;
        this.setScale(rescale.x,rescale.y,rescale.z);
        //theGameObject.transform.localScale = rescale;

         */
    };

    updateCanvasTexture(canvas, context, material, w, h, wPrefix, hPrefix) {
        if (w < 1 || h < 1) {
            return;
        }

        wPrefix = (wPrefix) ? wPrefix : 'w:';
        hPrefix = (hPrefix) ? hPrefix : 'h:';

        w *= 3;
        h *= 3;

        canvas.width = w;
        canvas.height = h;
        canvas.style.letterSpacing = '-22.5px';

        context.font = 'bold 32pt Courier';
        context.fillStyle = '#DADADA99';
        context.fillRect(0, 0, w, h);
        context.textAlign = 'center';
        context.textBaseline = 'middle';

        context.lineWidth = 3;
        context.setLineDash([1, 2]);
        context.strokeStyle = '#000000';

        context.beginPath();
        context.moveTo(0, h * 0.5);
        context.lineTo(w, h * 0.5);
        context.closePath();
        context.stroke();

        context.beginPath();
        context.moveTo(w * 0.125, 0);
        context.lineTo(w * 0.125, h);
        context.closePath();
        context.stroke();

        context.lineWidth = 1;
        context.setLineDash([0]);
        context.strokeStyle = '#0000FF';
        context.strokeText(wPrefix + Dimensioning.cmToMeasure(w / 3), w * 0.5, h * 0.5);

        context.fillStyle = '#FF0000';
        context.fillText(wPrefix + Dimensioning.cmToMeasure(w / 3), w * 0.5, h * 0.5);

        context.translate(w * 0.125, 0);
        context.rotate(Math.PI * 0.5);
        context.strokeStyle = '#0000FF';
        context.strokeText(hPrefix + Dimensioning.cmToMeasure(h / 3), h * 0.5, 0);

        context.fillStyle = '#FF0000';
        context.fillText(hPrefix + Dimensioning.cmToMeasure(h / 3), h * 0.5, 0);
        context.restore();
        material.map.needsUpdate = true;


        //var helper = new BoxHelper(this, 0xff0000);
        //helper.update();
        //SM.scene.add(helper);
        //console.log(this);

    }

    canMoveItem = () => {
        return !this.isLocked;
    };

    moveOnCanvas = (x, y) => {
        //console.log("Get clean move here, w/ offset.");
        if (this.canMoveItem()) {
            this.position.x = x;
            this.position.z = y;
            this.moveToPosition(new Vector3(x, this.position.y, y),null,true);
        }
    };


    drawCanvasControls = () => {
        //var button = new document.createElement();
        SM.setSelectedItem(this);
    };

    drawMeasurements = (floorPlannerView, ctx, x, y, width, height, rotation) => {
        ctx.save();
        //WIDTH!
        ctx.moveTo(0, 0);
        ctx.translate(x, y);
        ctx.rotate(-rotation);
        ctx.translate(-width / 2, -height / 2);
        ctx.strokeStyle = "#000";
        var cm = Dimensioning.pixelToCm(width);
        var m = Dimensioning.cmToMeasureRaw(cm);
        ctx.strokeRect(0, height + 20, width, 1);
        floorPlannerView.drawTextLabel(m + "m", width / 2, height + 20);
        floorPlannerView.drawArrowhead(ctx, new Vector2(width - 20, height + 20), new Vector2(width, height + 20), 10);
        floorPlannerView.drawArrowhead(ctx, new Vector2(10, height + 20), new Vector2(0, height + 20), 10);


        //HEIGHT!
        cm = Dimensioning.pixelToCm(height);
        m = Dimensioning.cmToMeasureRaw(cm);
        ctx.strokeStyle = "#000";
        ctx.strokeRect(width + 20, 0, 1, height);
        floorPlannerView.drawTextLabel(m + "m", width + 20, height / 2);
        floorPlannerView.drawArrowhead(ctx, new Vector2(width + 20, height), new Vector2(width + 20, height + 2), 10);
        floorPlannerView.drawArrowhead(ctx, new Vector2(width + 20, 0), new Vector2(width + 20, -2), 10);


        ctx.restore();
    };

    /**
     * Draw this item to the canvas.
     * Overriden by different types of items as they need to be displayed differently.
     *
     * @param canvasContext Context of the 2D canvas.
     */
    drawToCanvas = (floorplannerView, activeItem, clickedItem, isBuilding, canvas) => {

        var isActiveItem = activeItem === this;
        var isClickedItem = clickedItem === this;

        /*
        var width = Dimensioning.cmToMeasureRaw(this.getWidth());
        var depth = Dimensioning.cmToMeasureRaw(this.getDepth());
        var height = Dimensioning.cmToMeasureRaw(this.getHeight());

        var position = new Vector3(
            Dimensioning.cmToMeasureRaw(this.position.x),
            Dimensioning.cmToMeasureRaw(this.position.y),
            Dimensioning.cmToMeasureRaw(this.position.z)
        );

         */

        var ctx = floorplannerView.context;

        /*
        const rotateAndCache = (image,angle) => {
            var offscreenCanvas = document.createElement('canvas');
            var offscreenCtx = offscreenCanvas.getContext('2d');

            var size = Math.max(image.width, image.height);
            offscreenCanvas.width = size;
            offscreenCanvas.height = size;

            offscreenCtx.translate(size/2, size/2);
            offscreenCtx.rotate(angle + Math.PI/2);
            offscreenCtx.drawImage(image, -(image.width/2), -(image.height/2));

            return offscreenCanvas;
        };

         */

        const drawImageCentered = (ctx, img, x, y, width, height, rotation) => {

            //https://stackoverflow.com/questions/24039599/how-to-add-stroke-outline-to-transparent-png-image-in-javascript-canvas
            //Save canvas context.
            ctx.save();
            //Move canvas to center of image point
            ctx.moveTo(0, 0);
            //Move to the x,y position.
            ctx.translate(x, y);
            //Rotate the canvas by inverse of the object position.

            if (this.floorItem) {
                if (rotation.toLocaleString() === '-0') {
                    rotation = -3.14;
                }
            }


            ctx.rotate(-rotation);
            //Translate it to the width and height in order to center the object.
            ctx.translate(-width / 2, -height / 2);
            //Drawing image at the canvas position now.


            ctx.drawImage(img, 0, 0, width, height);

            if (isClickedItem) {
                ctx.strokeStyle = '#C9302C';  // some color/style
                ctx.strokeRect(0, 0, width, height);
            } else if (isActiveItem) {
                ctx.strokeStyle = '#008cba';  // some color/style
                //console.log(this.geometry);
                //ctx.rect(0, 0, width, height);
                //ctx.stroke();
                ctx.strokeRect(0, 0, width, height);

                /*
                // pixel data of this image for the defineNonTransparent
                // function to use
                var imgData,data;
                // This is used by the marching ants algorithm
                // to determine the outline of the non-transparent
                // pixels on the image
                var defineNonTransparent=function(x,y){
                    var a=data[(y*canvas.width+x)*4+3];
                    return(a>20);
                }
                // draw the image
                // (this time to grab the image's pixel data
                ctx.drawImage(img, 0, 0, width, height);

                // grab the image's pixel data
                imgData=ctx.getImageData(0,0,canvas.width,canvas.height);
                data=imgData.data;
                 */

                //Way too much memory used here.
                //I think you need to make another canvas.
                //console.log(data);
                // call the marching ants algorithm
                // to get the outline path of the image
                // (outline=outside path of transparent pixels
                //points=contour(defineNonTransparent);

                /*
                points=contour(defineNonTransparent);

                ctx.strokeStyle="red";
                ctx.lineWidth=2;

                ctx.beginPath();
                ctx.moveTo(points[0][0],points[0][1]);
                for(var i=1;i<points.length;i++){
                    var point=points[i];
                    ctx.lineTo(point[0],point[1]);
                }
                ctx.closePath();
                ctx.stroke();
                */

                //ctx.strokeRect(0, 0, width, height);

            }
            //Restore canvas context.
            ctx.restore();
            //console.log("draw success");
        };

        //Change rotation

        //var rotObj = rotateAndCache(this.canvasImg,this.rotation.y);


        var xPos = floorplannerView.viewmodel.convertX(this.position.x);
        var yPos = floorplannerView.viewmodel.convertY(this.position.z);
        var pxWidth = Dimensioning.cmToPixel(this.getWidth());
        var pxDepth = Dimensioning.cmToPixel(this.getDepth());


        //If we are given a scale we need to divide this as it dosent properly scale in 2D.
        if (this.scaleWasGiven) {
            //pxWidth /= this.scale.x;
            //pxDepth /= this.scale.z;

        }
        //TODO: This is a bug, not sure what light is being doubled in size.
        if (this.metadata.isLight) {
            if (this.metadata.measurements) {
                pxWidth = Dimensioning.cmToPixel(this.metadata.measurements.length);
                pxDepth = Dimensioning.cmToPixel(this.metadata.measurements.width)
            }
        }
        //???
        //pxWidth *= this.scale.x;
        //pxDepth *= this.scale.z;
        try {
            drawImageCentered(ctx, isBuilding ? this.buildingImage : this.designImage,
                xPos,
                yPos,
                pxWidth,
                pxDepth,
                this.rotation.y
            );
        } catch (exception) {
            console.error("EXCEPTION");
            console.error(exception);
        }

        //rotate context back

        if (SM.state.floorPlannerDesignMode !== 'building') {
            return;
        }

        //DRAW RECT
        /*
        ctx.beginPath();
        ctx.rect(floorplannerView.viewmodel.convertX(this.position.x),
            floorplannerView.viewmodel.convertY(this.position.z),
            Dimensioning.pixelToCm(this.getWidth()),
            Dimensioning.pixelToCm(this.getDepth()));
        ctx.strokeStyle = "#FF0000";
        ctx.stroke();
        */

        var centerX = floorplannerView.viewmodel.convertX(this.position.x);
        var centerY = floorplannerView.viewmodel.convertY(this.position.z);

        //draw measurements
        //var w = this.getWidth();
        //var l = this.getDepth();

        //DRAW LABEL
        if (isBuilding) floorplannerView.drawTextLabel(this.metadata.name, centerX, centerY - 15);

        //if (isClickedItem) {
        this.drawCanvasControls();
        //}

        //if (isClickedItem)
        //this.drawMeasurements(floorplannerView, ctx, xPos, yPos, pxWidth, pxDepth, this.rotation.y);

    };
    computeBBOX = (object) => {

    };

    mouseOverlappedPosition = (x, y, ctx) => {
        //console.log(new Vector2(x,y));
        //TODO: this distnace works, but need to find if the point is in an area.
        //this.distanceFrom(new Vector2(x,y));
        //var topPoint = this.position.x +
        //var polygon = [];

        //console.log({x,y});

        //console.log("Check if overlapping image....???");
        //var pxWidth = Dimensioning.cmToPixel(this.getWidth());
        //var pxDepth = Dimensioning.cmToPixel(this.getDepth());
        //console.log({pxWidth,pxDepth})
       // console.log(this.rotation.y);
        //console.log()

        this.updateMatrix();
        //this.geometry.applyMatrix4(this.matrix);
        //Can make a box bigger than necessary.
        var bbox = new Box3().setFromObject(this);
        //var bbox = helper.bbox;


        //var width = this.getWidth();
        //var depth = this.getDepth();


        //console.log(this.computeBBOX(this));

        //Sometimes setFromObject above makes it bigger than necessary as it iterates all children.
        //We just want to get width and depth from halfsize of object and plug it into bounding box.
        //so the max of the bbox.max.A = bbox.min.A + objectDimensionA
        /*
        bbox.max = {
            x: bbox.min.x + width,
            y: bbox.max.y,
            z: bbox.min.z + depth
        };
         */
        //console.log(bbox);
        //console.log(bbox.containsPoint(new Vector3(x,y,y)));

        //console.log(bbox.containsPoint(new Vector3(x,y,y)))
        //console.log(bbox);

        //TODO: being thrown off because y isnt in the space.
        //TODO: ignoring y, as this is in 2D. 0 wasnt in the space always.
        //console.log(bbox);
        //Looking for a point inside a box. y needs to stay inside as we have no y and it needs to be in the box.
        return bbox.containsPoint(new Vector3(x, bbox.min.y, y));
    };

    /**
     * Returns the distance between this corner and a point in 2d space
     * @param {Vector2} point
     * @see https://threejs.org/docs/#api/en/math/Vector2
     * @return {Number} distance The distance
     **/
    distanceFrom(point) {
        var distance = Utils.distance(point, new Vector2(this.position.x, this.position.z));
        //console.log(distance);
        //console.log('x,y ' + x + ',' + y + ' to ' + this.getX() + ',' + this.getY() + ' is ' + distance);
        return distance;
    }


    switchWireframe(flag) {
        this.material = (flag) ? this.wirematerial : this.originalmaterial;
    }


    lock = () => {
        this.isLocked = !this.isLocked;
        this.metadata.isLocked = this.isLocked;
    };

    /** */
    remove() {
        this.scene.removeItem(this);

    }

    /**
     * Resize the object to its real sizing.
     *  */
    resize(height, width, depth) {

        var x = width / this.getWidth();
        var y = height / this.getHeight();
        var z = depth / this.getDepth();


        if (this.resizeProportionally) {
            if (Math.abs(width - this.getWidth()) > 0.1) {
                this.setScale(x, x, x);
            } else if (Math.abs(height - this.getHeight()) > 0.1) {
                this.setScale(y, y, y);
            } else {
                this.setScale(z, z, z);
            }
            return;
        }

        this.setScale(x, y, z);
    }

    getMaterial() {
        return this.material;
    }

    getMaterialColor(index) {
        index = (index) ? index : 0;
        if (this.material.length) {
            return '#' + this.material[index].color.getHexString();
        }
        return '#' + this.material.color.getHexString();
    }

    // Always send an hexadecimal string value for color - ex. '#FFFFFF'
    setMaterialColor(color, index) {
        var c = new Color(color);
        if (this.material.length) {
            index = (index) ? index : 0;
            this.material[index].color = c;
            return;
        }
        this.material.color = c;
    }

    /** */
    setScale(x, y, z) {

        var scaleVec = new Vector3(x, y, z);
        this.halfSize.multiply(scaleVec);
        scaleVec.multiply(this.scale);
        this.scale.set(scaleVec.x, scaleVec.y, scaleVec.z);
        this.resized();
        if (this.bhelper) {
            this.bhelper.update();
        }

//		this.updateCanvasTexture(canvas, context, material, w, h);
        this.updateCanvasTexture(this.canvasWH, this.canvascontextWH, this.canvasMaterialWH, this.getWidth(), this.getHeight(), 'w:', 'h:');
        this.updateCanvasTexture(this.canvasWD, this.canvascontextWD, this.canvasMaterialWD, this.getWidth(), this.getDepth(), 'w:', 'd:');

        this.scene.needsUpdate = true;

    }

    getProportionalResize() {
        return this.resizeProportionally;
    }

    setProportionalResize(flag) {
        this.resizeProportionally = flag;
    }

    /** */
    setFixed(fixed) {
        this.fixed = fixed;
    }

    /** Subclass can define to take action after a resize. */
    resized() {

    }

    /** */
    getHeight() {
        return this.halfSize.y * 2.0;
    }

    /** */
    getWidth() {
        return this.halfSize.x * 2.0;
    }

    /** */
    getDepth() {
        return this.halfSize.z * 2.0;
    }

    /** */
    placeInRoom() {

    }


    /** */
    initObject() {
        // An ugly hack to increase the size of gltf models
        if (this.halfSize.x < 1.0) {
            this.resize(this.getHeight() * 300, this.getWidth() * 300, this.getDepth() * 300);
        }
        try {
            this.bhelper = new BoxHelper(this);
            //this.scene.add(this.bhelper);
            //this.bhelper.visible = SM.TestMode ? true : false;
        } catch (exception) {

            SM.addActionResultError("Error adding box helper.");
        }


        //Create GUI buttons and add it to this object.
       // this.GUIControls = new ObjectButtonsTool(this);
       // this.GUIControls.changeVisibility(false);
       // this.GUIControls.update();

        // select and stuff
        this.scene.needsUpdate = true;

        this.placeInRoom();

    }

    calibrateGUIControls = () => {
        //this.GUIControls.setScale(this.scale);
        //this.GUIControls.setHeight(this.halfSize.y/this.scale.x+1);
        //this.add(this.GUIControls);
    };

    /** */
    removed() {
        this.scene.remove(this.bhelper);

    }

    /** on is a bool */
    updateHighlight() {

        if (this.metadata.isLight) return;

        var on = this.hover || this.selected;
        this.highlighted = on;
        var hex = on ? this.emissiveColor : 0x000000;
        if (this.material) {
            if (this.material.length) {
                this.material.forEach((material) => {
                    material.emissive.setHex(hex);
                    this.material.emissive = new Color(hex);
                });
            } else {
                if (this.material.emissive) {
                    this.material.emissive.setHex(hex);
                    this.material.emissive = new Color(hex);
                } else {
                    this.material.color.setHex(hex)
                }
            }
        }
    }

    /** */
    mouseOver() {
        this.hover = true;
        this.updateHighlight();
        SM._dirty=true;
    }

    /** */
    mouseOff() {
        this.hover = false;
        this.updateHighlight();
        SM._dirty=true;

    }

    /** */
    setSelected() {
        this.setScale(1, 1, 1);
        this.selected = true;
        //Quick convert to bool.
        this.bhelper.visible = !!SM.TestMode;

        this.canvasPlaneWH.visible = this.canvasPlaneWD.visible = false;
        this.updateHighlight();
        SM._dirty=true;

    }

    /** */
    setUnselected() {
        this.selected = false;
        this.bhelper.visible = false;
        this.canvasPlaneWH.visible = this.canvasPlaneWD.visible = false;
        //this.GUIControls.changeVisibility(false);
        this.updateHighlight();
        SM._dirty=true;

    }

    /** intersection has attributes point (vec3) and object (THREE.Mesh) */
    clickPressed(intersection) {
        this.dragOffset.copy(intersection.point).sub(this.position);


        SM.onHistoryChange({
            type: 'move',
            object: this,
            position: this.position.clone()
        })


    }

    /** */
    clickDragged(intersection) {
        if (intersection) {
            this.moveToPosition(intersection.point.sub(this.dragOffset), intersection);
            //Update GUI controls so they follow.
        }
    }

    rotate = way => {
        if (way === 'left') {
            this.rotateLeft();
        }
        if (way === 'right') {
            this.rotateRight();
        }
        if (way === 'up') {
            this.rotateUp();
        }
        if (way === 'down') {
            this.rotateDown();
        }
        if (way === 'center') {
            this.rotation.set(0,0,0);
        }
        SM.updateForViewer(this.rotation);
        this.resized();
        SM._dirty=true;

    };
    rotateUp = () => {
        this.rotateX(Math.PI / 2);
        SM.triggerRedraw2D();
    };
    rotateDown = () => {
        this.rotateX(Math.PI / 2);
        SM.triggerRedraw2D();
    };
    rotateLeft = () => {
        this.rotateY(Math.PI / 2);
        SM.triggerRedraw2D();
    };
    rotateRight = () => {
        this.rotateY(-Math.PI / 2);
        SM.triggerRedraw2D();
    };

    /** */
    rotateIntersection(intersection) {

        if (intersection) {
            var angle = Utils.angle(new Vector2(0, 1), new Vector2(intersection.point.x - this.position.x, intersection.point.z - this.position.z));
            var snapTolerance = Math.PI / 16.0;
            // snap to intervals near Math.PI/2
            for (var i = -4; i <= 4; i++) {
                if (Math.abs(angle - (i * (Math.PI / 2))) < snapTolerance) {
                    angle = i * (Math.PI / 2);
                    break;
                }
            }
            this.rotation.y = angle;
        }
    }

    //Will auto position object when room is resized.
    updatePosition(isSoftReset) {
        this.moveToPosition(this.position,null,false,true);
    }

    /** */
    moveToPosition(vec3) {
        if (vec3 === this.position) return;

        this.position.copy(vec3);
        //console.trace(vec3);
        //Move box helper.
        if (this.bhelper) {
            this.bhelper.update();
        }
        //Move GUI controls.
        if (this.GUIControls) {
            this.GUIControls.update();
        }
        //Move controller HUD (rotation arrow).
        if (SM.controller.hud) {
            SM.controller.hud.update();
        }
        SM._dirty = true;
    }

    /** */
    clickReleased() {
        if (this.error) {
            this.hideError();
        }

    }

    /**
     * Returns an array of planes to use other than the ground plane for passing
     * intersection to clickPressed and clickDragged
     */
    customIntersectionPlanes() {
        return [];
    }

    /**
     * returns the 2d corners of the bounding polygon
     *
     * offset is Vector3 (used for getting corners of object at a new position)
     *
     * TODO: handle rotated objects better!
     */
    getCorners(xDim, yDim, position) {
        position = position || this.position;
        var halfSize = this.halfSize.clone();
        var c1 = new Vector3(-halfSize.x, 0, -halfSize.z);
        var c2 = new Vector3(halfSize.x, 0, -halfSize.z);
        var c3 = new Vector3(halfSize.x, 0, halfSize.z);
        var c4 = new Vector3(-halfSize.x, 0, halfSize.z);

        var transform = new Matrix4();
        // console.log(this.rotation.y);
        transform.makeRotationY(this.rotation.y); // + Math.PI/2)

        c1.applyMatrix4(transform);
        c2.applyMatrix4(transform);
        c3.applyMatrix4(transform);
        c4.applyMatrix4(transform);

        c1.add(position);
        c2.add(position);
        c3.add(position);
        c4.add(position);

        // halfSize.applyMatrix4(transform);

        // var min = position.clone().sub(halfSize);
        // var max = position.clone().add(halfSize);

        var corners = [{x: c1.x, y: c1.z}, {x: c2.x, y: c2.z}, {x: c3.x, y: c3.z}, {x: c4.x, y: c4.z}];
        return corners;
    }

    /** */
    isValidPosition() {
        return false;
    }

    /** */
    showError(vec3) {
        vec3 = vec3 || this.position;
        if (!this.error) {
            this.error = true;
            this.errorGlow = this.createGlow(this.errorColor, 0.8, true);
            this.scene.add(this.errorGlow);
        }
        this.errorGlow.position.copy(vec3);

        SM._dirty= true;
    }

    /** */
    hideError() {
        if (this.error) {
            this.error = false;
            this.scene.remove(this.errorGlow);
        }
    }

    /** */
    objectHalfSize() {
        // var objectBox = new Box3();
        // objectBox.setFromObject(this);
        this.geometry.computeBoundingBox();
        var objectBox = this.geometry.boundingBox.clone();
        return objectBox.max.clone().sub(objectBox.min).divideScalar(2);
    }

    /** */
    createGlow(color, opacity, ignoreDepth) {
        ignoreDepth = ignoreDepth || false;
        opacity = opacity || 0.2;
        var glowMaterial = new MeshBasicMaterial({
            color: color,
            blending: AdditiveBlending,
            opacity: 0.2,
            transparent: true,
            depthTest: !ignoreDepth
        });
        var glow = new Mesh(this.geometry.clone(), glowMaterial);
        glow.position.copy(this.position);
        glow.rotation.copy(this.rotation);
        glow.scale.copy(this.scale);
        return glow;
    }


    getMetaData() {
        /*
        var matattribs = [];
        if (this.material.length) {
            this.material.forEach((mat) => {
                matattribs.push('#' + mat.color.getHexString());
            });

        } else {
            matattribs.push('#' + this.material.color.getHexString());
        }
         */
        //You are returning this.metadata object reference here, so need to clone or they are all linked.
        //this.metadata.wallEdgeMountedTo = this.wallEdgeMountedTo;
        if (this.wallEdgeMountedTo) {
            if (this.wallEdgeMountedTo.room) {
                this.metadata.roomId = this.wallEdgeMountedTo.room.roomIdentifier();

            }
        }
        var metadata = JSON.parse(JSON.stringify(this.metadata));

        //Update position of metadata.
        metadata.position = this.position;
        metadata.rotation = {x:this.rotation.x,y:this.rotation.y,z:this.rotation.z};
        return metadata;
    }


    getHTML = () => {
        //Get details from the "Database" that will probably be online.
        //Going to store more information that will be readily changed online (price, type, home, model).
        //Metadata is just immediate details, position, rotation, etc.
        const details = this.metadata;
        var photo = details.photoURL;

        if (details.generic) {
            photo = process.env.PUBLIC_URL + '/Assets/images/'+ details.photoURL;
        }
        return (
            <div className={"item-information"}>
                <h3>{details.name}</h3>
                <img alt={'item'} src={photo}/>
                <p>{details.description}</p>
            </div>
        );
    }
    /*
    HTML put in shower.js that inherits from wall object.
       <div>
                <button onClick={() => wrapperObject.onClickChangeShower("one")}>Custom</button>
                <button onClick={() => wrapperObject.onClickChangeShower("two")}>Two sided</button>
                <button onClick={() => wrapperObject.onClickChangeShower("three")}>Three sided</button>
            </div>
     */
}
