import {GET_ADVERTISER_AREAS, GET_ADVERTISER_CATEGORIES, GET_ADVERTISER_ITEMS, GET_ADVERTISERS} from "./types";

export const getAdvertisers = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('advertisers').where('public', '==', true)
            .get().then(snapshot => {
            var docs = [];
            snapshot.docs.forEach(doc => {
                var data = doc.data();
                docs.push(data);
            });
            dispatch({type: GET_ADVERTISERS, payload: docs});
        });

    }
};

export const getAdvertiserAreas = (businessName) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('areas').where('businessName', '==', businessName)
            .get().then(snapshot => {
            var docs = [];
            snapshot.docs.forEach(doc => {
                var data = doc.data();
                docs.push(data);
            });
            dispatch({type: GET_ADVERTISER_AREAS, payload: docs});
        });

    }
}
export const getAdvertiserCategories = (businessName) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('categories').where('businessName', '==', businessName)
            .get().then(snapshot => {
            var docs = [];
            snapshot.docs.forEach(doc => {
                var data = doc.data();
                docs.push(data);
            });
            dispatch({type: GET_ADVERTISER_CATEGORIES, payload: docs});
        });

    }
};
export const getAdvertiserItems = (businessName) => {

    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('items').where('businessName', '==', businessName)
            .get().then(snapshot => {
            var docs = [];
            snapshot.docs.forEach(doc => {
                var data = doc.data();
                docs.push(data);
            });
            dispatch({type: GET_ADVERTISER_ITEMS, payload: docs});
        });

    }
};
