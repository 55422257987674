import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/nav.css'
import './assets/css/reusableStyles.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./redux/store";
import {Provider} from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import './assets/css/sidebar.css';
import "./assets/css/bootstrap.min.css";
import './index.css';
import "./assets/css/Dashboard.css"
import './assets/css/inspector.css'
import './assets/css/colors.css'
import './assets/css/itemCard.css'
import './assets/css/home.css'

ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
