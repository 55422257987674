import {RoofItem} from "./roof_item";

export class RoofLightItem extends RoofItem {
    constructor(model, metadata, geometry, material, position, rotation, scale, isgltf = false) {
        super(model, metadata, geometry, material, position, rotation, scale, isgltf);
        this.addLightObject();
    }
    addLightObject = () => {
        //flat circle plane
    }
}

