import React, {memo, useEffect, useState} from 'react'
import Box from './Box'

const styles = {
    display: 'inline-block',
};

const ItemDragPreview = memo((props) => {
    const {title, canSpawn, img} = props;

    const [tickTock, setTickTock] = useState(false);

    useEffect(
        function subscribeToIntervalTick() {
            //console.log(props);
            const interval = setInterval(() => setTickTock(!tickTock), 500);
            return () => clearInterval(interval)
        },
        [tickTock],
    );
    return (
        <div style={styles}>
            <Box title={title} yellow={canSpawn} img={img}/>
        </div>
    )
});
export default ItemDragPreview
