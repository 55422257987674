import {Item} from './item.js';
import {
    HemisphereLight,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial,
    Plane,
    PointLight,
    SphereBufferGeometry,
    Triangle,
    Vector3
} from 'three';
import {SM} from "../../../components/SceneManager";

/**
 * A Floor Item is an entity to be placed related to a floor.
 */
export class RoofItem extends Item {
    constructor(model, metadata, geometry, material, position, rotation, scale, isgltf = false) {
        super(model, metadata, geometry, material, position, rotation, scale, isgltf);
        this.allowRotate = false;
        this.boundToFloor = false;
        this._freePosition = false;

        if (this.geometry) {
            this.geometry.applyMatrix4(new Matrix4().makeTranslation(-0.5 * (this.geometry.boundingBox.max.x + this.geometry.boundingBox.min.x), -0.5 * (this.geometry.boundingBox.max.y - this.geometry.boundingBox.min.y), -0.5 * (this.geometry.boundingBox.max.z + this.geometry.boundingBox.min.z)));
            this.geometry.computeBoundingBox();
        }
        //this.halfSize = this.objectHalfSize();
        this.canvasPlaneWH.position.set(0, this.getHeight() * -0.5, this.getDepth() * 0.5);
        this.canvasPlaneWD.position.set(0, -this.getHeight(), 0);

        var co = this.closestCeilingPoint();
        this.moveToPosition(co);

        var bulbMat, hemiLight;
        var d = 300;

        if (metadata.putLight) {
            //reseting children.. why waste loading in geo and mat.
            this.children = [];
            //this.geometry = new SphereBufferGeometry( 1, 16, 8 );

            this.light = new PointLight(SM.LightColor, SM.Luminosity,500, 1 );
            this.light.castShadow = true;
            this.light.shadow.mapSize.width = 1024;
            this.light.shadow.mapSize.height = 1024;
            this.light.shadow.camera.left = - d;
            this.light.shadow.camera.right = d;
            this.light.shadow.camera.top = d;
            this.light.shadow.camera.bottom = - d;
            this.light.shadow.radius = 8;

            this.light.shadow.bias = - 0.01;
            //this.light.shadow.norm = -0.0005;

            //dirLight.shadow.camera.near = 0.1;
            //dirLight.shadow.camera.far = 40;

            SM.addLight(this.light);

            this.add(this.light);
            this.castShadow = false;
            hemiLight = new HemisphereLight( 0xddeeff, 0x0f0e0d, 0.02 );
            SM.scene.add( hemiLight );
            this.position.set(position.x,295,position.z);

         }
        if (metadata.isLight) {
            //reseting children.. why waste loading in geo and mat.
            this.children = [];
            this.geometry = new SphereBufferGeometry( 1, 16, 8 );

            this.light = new PointLight(SM.LightColor, SM.Luminosity,500, 1 );
            this.light.castShadow = true;
            this.light.shadow.mapSize.width = 1024;
            this.light.shadow.mapSize.height = 1024;
            this.light.shadow.camera.left = - d;
            this.light.shadow.camera.right = d;
            this.light.shadow.camera.top = d;
            this.light.shadow.camera.bottom = - d;
            this.light.shadow.radius = 8;

            this.light.shadow.bias = - 0.01;
            //this.light.shadow.norm = -0.0005;

            //dirLight.shadow.camera.near = 0.1;
            //dirLight.shadow.camera.far = 40;
            var topSegment = new Mesh(this.geometry,new MeshBasicMaterial( {color: 0x3a3a3a} ))
            topSegment.position.set(0,1,0);
            this.add(topSegment);
            SM.addLight(this.light);

            bulbMat = new MeshStandardMaterial( {
                emissive: 0xffffee,
                emissiveIntensity: 0.95,
                color: 0x000000
            } );
            this.material = bulbMat;
            //bulbLight.add( new Mesh( bulbGeometry, bulbMat ) );

            this.add(this.light);
            this.castShadow = false;
            hemiLight = new HemisphereLight( 0xddeeff, 0x0f0e0d, 0.02 );
            SM.scene.add( hemiLight );
            this.position.set(position.x,295,position.z);

            //BUG with object half size.
            this.halfSize = this.objectHalfSize();
            this.halfSize = new Vector3(this.metadata.measurements.length/2,this.metadata.measurements.width/2,this.metadata.measurements.width/2);
        } else {

            //Get config???
            if (position) {
                this.position.set(position.x,299,position.z);
            } else {
                this.position.set(0,299,0);
            }
        }
        if (this.metadata.isFan) {
            //var A = new TWEEN.Tween(this.rotation).to({ y: this.rotation.y + Math.PI }, 500).start();
            //var C = new TWEEN.Tween(this.rotation).to({ y: this.rotation.y - Math.PI  }, 500);
            //A.chain(C);
            //C.chain(A);
        }


    }
    /** */
    moveToPosition(vec3) {
        // keeps the position in the room and on the floor
        if (!this.isValidPosition(vec3)) {
            this.showError(vec3);

        } else {
            this.hideError();
            vec3.y = this.position.y; // keep it on the floor!
//			this.position.copy(vec3);
            super.moveToPosition(vec3);
        }
    }
    removed() {
        this.hideError();

    }
    moveOnCanvas = (x, y) => {
        if (this.canMoveItem()) {
            this.moveToPosition(new Vector3(x, this.position.y, y));
        }
    };
    isValidPosition(vec3) {

        //console.log("Checking if valid position.");
        //console.log(vec3);
        //Actually need to check 4 corners of given object.
        var cornerTopLeft = {...vec3};
        var cornerTopRight = {...vec3};
        var cornerBottomLeft = {...vec3};
        var cornerBottomRight = {...vec3};
        //var cornerBottomLeft = {...vec3};
        //var cornerBottomRight = {...vec3};

        var xSize = !this.sideways ? this.getWidth() : this.getDepth();
        var zSize = !this.sideways ? this.getDepth() : this.getWidth();

        cornerTopLeft.x -= xSize / 2;
        cornerTopLeft.z -= zSize / 2;

        cornerTopRight.x += xSize / 2;
        cornerTopRight.z += zSize / 2;

        cornerBottomLeft.x += xSize / 2;
        cornerBottomLeft.z -= zSize / 2;

        cornerBottomRight.x -= xSize / 2;
        cornerBottomRight.z += zSize / 2;

        //var toCheck = [cornerTopLeft,cornerTopRight];

        //var corners = this.getCorners('x', 'z', vec3);
        // check if we are in a room
        //var rooms = this.model.floorplan.getRooms();
        var isInARoom = false;

        var roofs = this.model.floorplan.roofPlanes();
        var roof = null;
        //var globalResult = {distance: Number.MAX_VALUE, point: null};
        var result = null;

        var topLeftGood = false;
        var topRightGood = false;
        var bottomLeftGood = false;
        var bottomRightGood = false;

        for (var i = 0; i < roofs.length; i++) {
            roof = roofs[i];
            result = this.roofContainsPoint(roof, cornerTopLeft,true);
            if (result) {
                topLeftGood = true;
            }
            result = this.roofContainsPoint(roof, cornerTopLeft,true);
            if (result) {
                topRightGood = true;
            }
            result = this.roofContainsPoint(roof, cornerBottomLeft,true);
            if (result) {
                bottomLeftGood = true;
            }
            result = this.roofContainsPoint(roof, cornerBottomRight,true);
            if (result) {
                bottomRightGood = true;
            }
        }
        if (topLeftGood && topRightGood && bottomLeftGood && bottomRightGood) {
            isInARoom = true;
        }

        //TODO: above algorithm stops working randomly after resizing, even with one room... also need to check object bounds.
        //isInARoom = SM.controller.isHoveringOverFloor();

        /*
      if (this.obstructFloorMoves) {
          var objects = this.model.scene.items;
          for (var i = 0; i < objects.length; i++) {
              if (objects[i] === this || !objects[i].obstructFloorMoves) {
                  continue;
              }
              if (!Utils.polygonOutsidePolygon(corners, objects[i].getCorners('x', 'z'), new Vector2(0,0)) ||
                  Utils.polygonPolygonIntersect(corners, objects[i].getCorners('x', 'z'), new Vector2(0,0))) {
                  //console.log('object not outside other objects');
                  return false;
              }
          }
      }
         */
        return isInARoom;

    }
    /** Returns an array of planes to use other than the ground plane
     * for passing intersection to clickPressed and clickDragged */
    customIntersectionPlanes() {
        //return this.model.floorplan.roofPlanes();
    }

    roofContainsPoint(roof, forpoint, returnIfNone=false) {
        var g = roof.geometry;
        var result = {distance: Number.MAX_VALUE, contains: false, point: null, closestPoint: null};
        var closestPoint = null;
        for (var i = 0; i < g.faces.length; i++) {
            var f = g.faces[i];
            var plane = new Plane();
            var triangle = new Triangle(g.vertices[f.a], g.vertices[f.b], g.vertices[f.c]);
            var ipoint = new Vector3();
            var cpoint = new Vector3();
            var contains = false;
            var distance = 0.0;
            closestPoint = triangle.closestPointToPoint(forpoint, cpoint);
            triangle.getPlane(plane);
            plane.projectPoint(forpoint, ipoint);
            contains = triangle.containsPoint(ipoint);
            distance = plane.distanceToPoint(forpoint);
            if (distance < result.distance && contains) {
                result.distance = distance;
                result.contains = contains;
                result.point = ipoint;
                result.closestPoint = closestPoint.clone();
            }
        }
        //No good result so return the closest point of the last triangle in this roof mesh
        if (result.point == null) {
            if (returnIfNone) return false;
            result.closestPoint = closestPoint.clone();
        }

        return result;
    }

    closestCeilingPoint() {
        var roofs = this.model.floorplan.roofPlanes();
        var roof = null;
        var globalResult = {distance: Number.MAX_VALUE, point: null};
        var result = null;
        for (var i = 0; i < roofs.length; i++) {
            roof = roofs[i];
            result = this.roofContainsPoint(roof, this.position);
            if (result.point != null && result.distance < globalResult.distance && result.contains) {
                globalResult.distance = result.distance;
                globalResult.point = result.point.clone();
            }
        }
        //No good results so assign the closestPoint of the last roof in the above iteration
        if (globalResult.point == null) {
            if (!result) {
                return new Vector3(0,0,0);
            }
            return result.closestPoint.clone();
        }
        return globalResult.point.clone();
    }

    /** */
    placeInRoom() {
        if (!this.position_set) {
            var co = this.closestCeilingPoint();
            this.moveToPosition(co);
        }
    }
}
