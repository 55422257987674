
export function renderFade(flag,dontFade=false) {
    if (flag) {
        return "fade-in"
    } else {
        if (dontFade) {
            return "hidden"
        }
        return "fade-out"
    }
}