import {SelectionBox} from '../../../utils/gui/SelectionBox';
import {SelectionHelper} from '../../../utils/gui/SelectionHelper';
import {Raycaster, Vector2, Vector3} from 'three';
import {SM} from '../../SceneManager';
import Manager from "./Manager";

/**
 * Handle dynamic building creation such as tiles and feature walls.
 */
export default class BuilderManager extends Manager {

    constructor() {
        super();
        //Is building in process?
        this.isBuilding = false;
        //Is a wall locked on?
        this.wallLockedOn = false;
        //Which wall was just tiled?
        this.wallJustTiled = "";

        this.setupSelectionBox();
    }

    /**
     * Are we on the target wall?
     * @returns {boolean} if on the target wall.
     */
    get OnTargetWall() {
        return this.onWall;
    }

    /**
     * Is the drawing tile process disabled?
     * @returns {boolean} If the tiling draw process is disabled.
     */
    get DrawDisabled() {

        if (!this.OnTargetWall) {
            return true;
        }
        if (!this.isBuilding) {
            return true;
        }
        if (!this.wallLockedOn) {
            return true;
        }
        if (this.tileAreaOverlapsAnotherTile) {
            return true;
        }
        return false;
    }

    /**
     * TESTING: check if a tile overlaps another tile.
     * @returns {boolean}
     */
    get tileAreaOverlapsAnotherTile() {

        return false;

        /*
        const {camera} = SM.CameraManager;

        var distance = 0;
        if (["wall2","wall4"].includes(this.wall.name)) {
            distance = Math.abs(camera.position.x - this.wall.position.x);
        } else {
            var distance = Math.abs(camera.position.z - this.wall.position.z); //TODO need to take from object looking at, but should be at 0
        }
        var vFOV = camera.fov * Math.PI / 180;
        var height = 2 * Math.tan(vFOV/2) * distance;

        var width = height * camera.aspect;

        var roomDimensions = SM.RoomDimensions;
        let wallLength = 0;
        if (["wall2","wall4"].includes(this.wall.name)) {
            wallLength = roomDimensions.z; //metres
        } else {
            wallLength = roomDimensions.x;
        }
        let wallHeight = roomDimensions.y;  //metres

        var fraction = wallHeight / height;
        var fractionW = wallLength / width;

        let coordinateStartX = (this.selectionBox.startPoint.x+1)/2;
        let coordinateEndX = (this.selectionBox.endPoint.x+1)/2;

        let coordinateStartY = (this.selectionBox.startPoint.y+1)/2;
        let coordinateEndY = (this.selectionBox.endPoint.y+1)/2;

        let normalizedWallLength = wallLength / fractionW;
        let normalizedWallHeight = wallHeight / fraction;

        var offsetOfWallX = (normalizedWallLength-wallLength)/2;
        var offsetOfWallY = (normalizedWallHeight-wallHeight)/2;

        let normalizedStartX = coordinateStartX*normalizedWallLength - offsetOfWallX;
        let normalizedStartY = coordinateStartY*normalizedWallHeight - offsetOfWallY;

        let normalizedEndX = coordinateEndX*normalizedWallLength - offsetOfWallX;
        let normalizedEndY = coordinateEndY*normalizedWallHeight - offsetOfWallY;

        this.startPoint = new Vector2(normalizedStartX,normalizedStartY);
        this.endPoint = new Vector2(normalizedEndX,normalizedEndY);

        var tiles = SM.RoomManager.tiles;

        function objectsIntersect(firstTile,secondTile) {

            console.log('Check if objects intersect here so the user cannot draw over other tiled sections.');
            //if (firstTile.X < this.X + this.Width && this.X < rect.X + rect.Width && rect.Y < this.Y + this.Height)
            //    return this.Y < rect.Y + rect.Height;
        }

        for (let i = 0 ; i < tiles.length ; i++) {
            let tile = tiles[i];

            //TODO add width of each object to complete algorithm.
            let firstTile = {
                startPoint:this.startPoint,
                endPoint:this.endPoint,
                Width: Math.abs(this.startPoint.x - this.endPoint.x),
                Height: Math.abs(this.startPoint.y - this.endPoint.y)
            };

            let secondTile = {
                startPoint: tile.userData.startPoint,
                endPoint: tile.userData.endPoint,
                Width: Math.abs(tile.userData.startPoint.x - tile.userData.endPoint.x),
                Height: Math.abs(tile.userData.startPoint.y - tile.userData.endPoint.y)
            };

            objectsIntersect(firstTile,secondTile);

        }


        return false;

         */
    }

    //On start();
    start = () => {
    };

    //On update();
    update = () => {
    };

    /**
     * Whenever an object is selected, notify all managers.
     * @param object
     */
    notifyOnObjectClicked = (object) => this.setToWall(object);

    /**
     * Whenever an object is hovered, notify all managers.
     * @param object
     */
    notifyOnObjectHovered = (object) => this.setToWallHover(object);

    /**
     * Start the tiling a wall process.
     * @param material
     */
    startTileWall = (material) => {
        SM.GUIManager.notifyClientInfo("Select a wall to tile.");
        this.wallTileSelected = material;
        this.isBuilding = true;


    };

    buildTile = (wall) => {
        //Set the camera in front of the wall.
        SM.CameraManager.positionInFrontOfWall(wall);
        this.wallJustTiled = wall;
        this.isBuilding = true;
        this.setToWall(wall);
    };

    /**
     * Start the tiling floor process.
     * @param material
     */
    startTileFloor = (material,length,width) => {
        //Right now, just telling RoomManager to redraw the floor with the material chosen.
        //SM.RoomManager.redrawFloor(material);
        SM.GUIManager.notifyClientInfo("Select a floor to tile.");
        //var details = SM.ObjectManager.getObjectInformation(material);
        //details.slug = material;
        //details.url =  details.photoURL;//`Assets/textures/${material}.png`;
        var details = {
            slug: null,
            url:material,
            measurements: {
                "length": length,
                "width": width
            },
        };
        this.selectedFloorTile = details;
    };

    /**
     * Draw a wall.
     */
    startDrawWall = (slug) => {
        this.isBuilding = true;
        this.wallDrawing = slug;
        SM.CameraManager.goToBirdsEyeView();
        this.setToWall(SM.RoomManager.FloorObject);
    };

    startMirrorDraw = () => {
        SM.GUIManager.notifyClientInfo("Select a wall to draw your mirror on.");
        this.isBuilding = true;
        this.isBuildingObject = true;
    };

    buildWall = (start, end) => {
        SM.ObjectManager.createInternalWall(start, end, this.spawnPoint, this.wallDrawing);
    };

    buildTiles = (wall, start, end) => {
        SM.ObjectManager.createTiles(wall, start, end, this.spawnPoint);
    };

    buildMirror = (wall, start, end) => {
        SM.ObjectManager.createMirror(wall, start, end, this.spawnPoint);
    };

    /**
     * Tile the selected area.
     */
    tileArea = () => {
        if (this.wallJustTiled === "floor") {
            //SM.RoomManager.drawFeatureWall(this.startPoint,this.endPoint);
            this.buildWall(this.startPoint, this.endPoint);
            this.needsTiling = false;
        } else {
            //let obj = SM.ObjectManager.getObjectInformation(this.wallTileSelected);

            //TODO: Fix this!
            var details = {
                slug: this.isBuildingObject ? "mirror" : this.wallTileSelected,
                itemType: this.isBuildingObject ? 10 : 11,
                wall: this.wallJustTiled,
                start: this.startPoint,
                end: this.endPoint,
                length: this.lengthOfArea,
                height: this.heightOfArea,
                material: this.wallTileSelected,
                spawnPoint: this.spawnPoint,

            };

            SM.addCustomItem(details);
            this.wallTileSelected = null;

        }
    };

    /**
     * Set the previous wall.
     * @param wall
     */
    setToWallHover = (wall) => {

        this.previousWallSelected = wall;
    };

    /**
     * Set to an active wall.
     * @param wall
     */
    setToWall = (wall) => {
        if (!wall || this.wall) {
            return;
        }
        if (this.isBuilding) {
            this.wallLockedOn = true;
            this.wall = wall;
            this.wallJustTiled = wall;
            SM.onBuilderManagerStarted();
        }
    };

    /**
     * Setup a selection box.
     */
    setupSelectionBox = () => {
        //FIXME: put this logic in GUIManager.
        this.selectionBox = new SelectionBox(SM.CameraManager.camera, SM.scene);
        this.selectionHelper = new SelectionHelper(this.selectionBox, SM.renderer, "selectBox", this);

        SM.renderer.domElement.addEventListener('mousedown', this.onMouseDown);
        SM.renderer.domElement.addEventListener('mousemove', this.onMouseMove);
        SM.renderer.domElement.addEventListener('mouseup', this.onMouseUp);
        SM.renderer.domElement.addEventListener('touchstart', this.onTouchDown);
        SM.renderer.domElement.addEventListener('touchmove', this.onTouchMove);
        SM.renderer.domElement.addEventListener('touchend', this.onMouseUp);
    };

    /**
     * Teardown the selection box and associated resources.
     */
    tearDownSelectionBox = () => {

        if (this.selectionHelper && this.selectionHelper.startPoint) {
            this.selectionHelper.clear();
        }
        this.isBuilding = false;
        this.wall = null;
        this.wallLockedOn = false;
        this.wallTileSelected = null;
        this.isBuildingObject = false;
        this.selectionHelper.startPoint = null;
    };

    onMouseDown = (event) => {

        if (this.DrawDisabled) return;

        var rect = SM.renderer.domElement.getBoundingClientRect();
        var _mouse_X = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        var _mouse_Y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

        this.selectionBox.startPoint.set(
            _mouse_X,
            _mouse_Y,
            0.5);

        var _raycaster = new Raycaster();
        _raycaster.setFromCamera(new Vector2(_mouse_X, _mouse_Y), SM.CameraManager.camera);
        var intersects = _raycaster.intersectObject(this.wallJustTiled.edge.plane, true);
        if (intersects.length > 0) {
            this.spawnPoint = intersects[0].point;
        }
    };

    onMouseMove = (event) => {

        if (!this.isBuilding) return;
        if (!this.wallJustTiled) return;

        var rect = SM.renderer.domElement.getBoundingClientRect();
        var _mouse_X = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        var _mouse_Y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

        //check if on wall
        var _raycaster = new Raycaster();
        _raycaster.setFromCamera(new Vector2(_mouse_X, _mouse_Y), SM.CameraManager.camera);
        var intersects = _raycaster.intersectObject(this.wallJustTiled.edge.plane, true);

        this.onWall = intersects.length > 0;

        if (this.DrawDisabled) return;

        if (this.selectionHelper.isDown) {
            this.selectionBox.endPoint.set(
                _mouse_X,
                _mouse_Y,
                0.5);

        }

    };

    onTouchDown = (event) => {

        if (this.DrawDisabled) return;

        event = event.changedTouches[0];

        var rect = SM.renderer.domElement.getBoundingClientRect();
        var _mouse_X = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        var _mouse_Y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

        this.selectionBox.startPoint.set(
            _mouse_X,
            _mouse_Y,
            0.5);

        var _raycaster = new Raycaster();
        _raycaster.setFromCamera(new Vector2(_mouse_X, _mouse_Y), SM.CameraManager.camera);
        var intersects = _raycaster.intersectObjects(this.wallJustTiled, true);

        if (intersects.length > 0) {
            //Point these objects intersected at!
            this.spawnPoint = intersects[0].point;
        }
    };

    onTouchMove = (event) => {

        if (this.DrawDisabled) return;

        event = event.changedTouches[0];

        var rect = SM.renderer.domElement.getBoundingClientRect();
        var _mouse_X = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        var _mouse_Y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

        if (this.selectionHelper.isDown) {
            this.selectionBox.endPoint.set(
                _mouse_X,
                _mouse_Y,
                0.5);

        }
    };

    workoutSpawnPoint(event) {
        var rect = SM.renderer.domElement.getBoundingClientRect();
        var _mouse_X = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        var _mouse_Y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

        var _raycaster = new Raycaster();
        _raycaster.setFromCamera(new Vector2(_mouse_X, _mouse_Y), SM.CameraManager.camera);
        var intersects = _raycaster.intersectObjects(this.wallJustTiled, true);

        if (intersects.length > 0) {
            //Point these objects intersected at!
            this.endSpawnPoint = intersects[0].point;
            //This will be the start point and the endpoint middle.
            this.spawnPoint = new Vector3((this.spawnPoint.x + this.endSpawnPoint.x) / 2, (this.spawnPoint.y + this.endSpawnPoint.y) / 2, (this.spawnPoint.z + this.endSpawnPoint.z) / 2);
        }

    }

    onMouseUp = (event) => {

        //As we want to get the last mouse up event.
        if (!this.isBuilding) return;
        if (!this.wall) return;

        //Find the point to spawn the tile.
        //this.workoutSpawnPoint(event);

        //Grab the camera.
        const {camera} = SM.CameraManager;
        var distance = 0;
        /*
        if (["wall2","wall4"].includes(this.wall.name)) {
            distance = Math.abs(camera.position.x - this.wall.position.x);
        } else if (this.wall.name === "floor") {
            distance = Math.abs(camera.position.y - this.wall.position.y);
        } else {
        }



         */
        //Position of wall is at the center;
        //TODO: work out the distance b/w the wall and the camera
        var wallCenter = this.wall.edge.center;
        distance = camera.position.distanceTo(wallCenter);

        //TODO: Work out the width and height of the frame dependent on the distance.
        var vFOV = camera.fov * Math.PI / 180;
        var height = 2 * Math.tan(vFOV / 2) * distance;
        var width = height * camera.aspect;


        //TODO: work out the wall length and height. (min and max)

        var wallLength = 500;

        let wallHeight = 300; //metres

        var fraction = wallHeight / height;
        var fractionW = wallLength / width;

        let coordinateStartX = (this.selectionBox.startPoint.x + 1) / 2;
        let coordinateEndX = (this.selectionBox.endPoint.x + 1) / 2;

        let coordinateStartY = (this.selectionBox.startPoint.y + 1) / 2;
        let coordinateEndY = (this.selectionBox.endPoint.y + 1) / 2;

        let normalizedWallLength = wallLength / fractionW;
        let normalizedWallHeight = wallHeight / fraction;

        var offsetOfWallX = (normalizedWallLength - wallLength) / 2;
        var offsetOfWallY = (normalizedWallHeight - wallHeight) / 2;

        let normalizedStartX = coordinateStartX * normalizedWallLength - offsetOfWallX;
        let normalizedStartY = coordinateStartY * normalizedWallHeight - offsetOfWallY;

        let normalizedEndX = coordinateEndX * normalizedWallLength - offsetOfWallX;
        let normalizedEndY = coordinateEndY * normalizedWallHeight - offsetOfWallY;

        this.startPoint = new Vector2(normalizedStartX, normalizedStartY);
        this.endPoint = new Vector2(normalizedEndX, normalizedEndY);

        var temp = 0;

        if (this.startPoint.x > this.endPoint.x) {
            temp = this.endPoint.x;
            this.endPoint.x = this.startPoint.x;
            this.startPoint.x = temp;
        }
        if (this.startPoint.y < this.endPoint.y) {
            temp = this.endPoint.y;
            this.endPoint.y = this.startPoint.y;
            this.startPoint.y = temp;
        }

        this.lengthOfArea = Math.abs(this.startPoint.x - this.endPoint.x);
        this.heightOfArea = Math.abs(this.startPoint.y - this.endPoint.y);

        /*
        console.log("STARTPOINT")
        console.log(this.startPoint);
        console.log("ENDPOINT")
        console.log(this.endPoint);
        console.log("So draw a wall from " + this.startPoint.x + " to " + this.endPoint.x);
        console.log("The total length of this area is : " + (this.lengthOfArea) + " metres.")
        console.log("The total height of this area is : " + (this.heightOfArea) + " metres.")
        */

        this.needsTiling = true;
    };

    onTiledWall = () => {
        this.needsTiling = false;
    };

    stop = () => {
        this.isBuilding = false;
    };

}
