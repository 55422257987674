import DropContainer from "../DragNDrop/dropContainer";
import React from 'react';

const FloorPlannerContainer = ({isDragging,isHidden,isLoading}) => {
    return (
        <div id={"floor-planner"} className={`game-container ${isHidden ? "hidden" : ""}`}>
            <DropContainer isDragging={isDragging}/>
            <div id="floorplanner-body" className='front'>
                <canvas width="1638" height="900" id="floorplanner-canvas"/>
            </div>
        </div>
    );
};
export default FloorPlannerContainer;
