import React, {Component} from 'react';
import SceneManager from "../../../../SceneManager";

class TestSuite extends Component {
    render() {
        document.body.style.overflow = "hidden";

        return (
            <SceneManager onObjectLoadedUpdateForm={this.props.onObjectLoadedUpdateForm}
                          showcaseObj={this.props.showcaseObj}
                          viewerMode={this.props.viewerMode}
                          updateForViewer={this.props.updateForViewer}
                          isLoading={this.props.isLoading}
                          testMode={true}/>
        );
    }
}

export default TestSuite;