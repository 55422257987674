import React, {Component} from 'react';
import LinearProgress from "@material-ui/core/LinearProgress";

class LoadingSpinner extends Component {
    render() {
        return (
            <LinearProgress color="primary"/>
        );
    }
}

export default LoadingSpinner;
