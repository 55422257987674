import React, {Component} from 'react';
import FolderControls from "./FolderControls";
import EditItem from "./EditItem";
import {connect} from "react-redux";
import {deleteArea, deleteCategory, deleteItem, editItem} from "../../../../../redux/actions/itemActions";

class ItemFolder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAreas: [],
            selectedCategories: [],
            selectedItems: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            let categories = [...this.state.selectedCategories];
            this.state.selectedCategories.forEach(category => {
                if (!this.props.categories.includes(category)) {
                    categories = categories.filter(e => e !== category);
                }
            });
            this.setState({selectedCategories: categories});
        }
    }

    render() {

        return (
            <div className={"item-folder-wrapper"}>
                <div className="item-folder-inner main-gradient-static" style={this.renderFolderStyle()}>
                    <FolderControls deselectAll={this.deselectAll}
                                    selectedItems={this.state.selectedItems}
                                    selectedCategories={this.state.selectedCategories}
                                    goBack={this.props.goBack}
                                    selectedCategory={this.state.selectedCategory}
                                    selectedItem={this.state.selectedItem}
                                    toggleCreateItem={this.props.toggleCreateItem}
                                    toggleCategory={this.props.toggleCategory}
                                    onClickHome={this.onClickHome}/>
                    {this.renderDatabase()}
                </div>
            </div>
        );
    }

    renderColumnZero = () => {
        return (
            <div className={'database-column column-one'}>
                <div className={'database-column-title main-gradient'}>
                    <span className={'fas fa-list-alt center-span'}/>
                    <p>Areas</p>
                    <span className={'fas fa-trash center-span span-delete'} onClick={this.deleteSelectedArea}/>
                </div>
                <div className={'database-create main-gradient'} onClick={() => this.props.toggleArea(true)}>
                    <span className={'fas fa-plus center-span'}></span>
                    <p>Create Area</p>
                </div>
                <div className={'database-scroll'}>
                    {this.props.areas.map(area => {
                        return (
                            <div key={area.name} className={'database-item ' + this.chooseDatabaseItemStyle('selectedArea', area.name)} onClick={() => this.selectedArea(area)}
                            >
                                <img src={area.iconURL} alt={area.name}/>
                                <p>{area.name}</p>
                                {
                                    //this.state.selectedCategory === cat.name ? <span className={'fas fa-arrow-right center-span-right'}/> : <span/>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    };
    renderColumnOne = () => {
        var categories = this.props.categories;
        categories.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        return (
            <div className={'database-column column-one'}>
                <div className={'database-column-title main-gradient'}>
                    <span className={'fas fa-list-alt center-span'}/>
                    <p>Categories</p>
                    <span className={'fas fa-trash center-span span-delete'} onClick={this.deleteSelectedCategory}/>
                </div>
                <div className={'database-create main-gradient'} onClick={() => this.props.toggleCategory(true)}>
                    <span className={'fas fa-plus center-span'}></span>
                    <p>Create Category</p>
                </div>
                {this.state.selectedArea ? this.renderColumnOneInner() : null}
            </div>
        )
    };

    renderColumnOneInner = () => {
        var area = this.state.selectedArea;
        var categories = [];
        this.props.categories.forEach(cat => {
            if (cat.area === area.name) {
                categories.push(cat);
            }
        });

        return (
            <div className={'database-scroll'}>
                {categories.map(cat => {
                    return (
                        <div key={cat.name} className={'database-item ' + this.chooseDatabaseItemStyle('selectedCategory', cat.name)} onClick={() => this.selectedCategory(cat)}>
                            <img src={cat.iconURL} alt={cat.name}/>
                            <p>{cat.name}</p>
                            {
                                //this.state.selectedCategory === cat.name ? <span className={'fas fa-arrow-right center-span-right'}/> : <span/>
                            }
                        </div>
                    )
                })}
            </div>
        )
    };
    renderColumnTwo = () => {
        return (
            <div className={'database-column column-two'}>
                {this.state.selectedCategory ? this.renderColumnTwoInner() : null}
            </div>
        )
    };

    renderColumnTwoInner = () => {
        var cat = this.state.selectedCategory;
        var area = this.state.selectedArea;

        var items = [];
        this.props.items.forEach(item => {
            if (item.category === cat.name && item.area === area.name) {
                items.push(item);
            }
        });

        return (
            <div>
                <div className={'database-column-title main-gradient'}>
                    <span className={cat.icon + ' center-span'}/>
                    <p>{cat.name}</p>
                    <span className={'fas fa-trash center-span span-delete'} onClick={this.deleteSelectedItem}/>
                </div>
                <div className={'database-create main-gradient'} onClick={() => this.props.toggleCreateItem(true)}>
                    <span className={'fas fa-plus center-span'}></span>
                    <p>Create Item</p>
                </div>
                <div className={'database-scroll'}>

                    {items.map(item => {
                        return (
                            <div key={item.name + item.photoURL} className={'database-item ' + this.chooseDatabaseItemStyle('selectedItem', item.name)} onClick={() => this.selectedItem(item)}>
                                <img alt={'selected item'} src={item.photoURL}/>
                                <p>{item.name}</p>
                                {
                                    // ( this.state.selectedItem && this.state.selectedItem.name === item.name) ? <span className={'fas fa-arrow-right center-span-right'}/> : <span/>
                                }
                            </div>
                        )
                    })}
                </div>

            </div>
        )
    };

    renderColumnThree = () => {

        return (
            <div className={'database-column column-three'}>
                {this.state.selectedItem ? this.renderColumnThreeInner() : null}
            </div>
        )
    };
    renderColumnThreeInner = () => {
        var {selectedItem} = this.state;

        return (
            <div>
                <div className={'database-column-title main-gradient'} style={{justifyContent: 'center'}}>
                    <p>{selectedItem.name}</p>
                </div>
                <div>
                    {
                        this.renderEditItem()
                    }
                </div>
            </div>
        )
    };
    chooseDatabaseItemStyle = (field, name) => {
        if (this.state[field] === name || (this.state[field] && this.state[field].name === name)) {
            return "selected-gradient"
        }
        return ''
    };
    selectedCategory = (cat) => {
        this.props.onSelectCategory(cat);
        this.setState({selectedCategory: cat, selectedItem: null})
    };
    selectedArea = (area) => {
        this.props.onSelectArea(area);
        this.setState({selectedArea:area, selectedItem: null})
    };
    deleteSelectedArea = () => {
        var {selectedArea} = this.state;

        if (selectedArea) {
            this.props.deleteArea(selectedArea);
            this.setState({selectedArea:null,selectedCategory:null,selectedItem:null})
        }
    };
    deleteSelectedCategory = () => {
        var {selectedCategory,selectedArea} = this.state;

        if (selectedCategory) {
            this.props.deleteCategory(selectedCategory,selectedArea);
            this.setState({selectedCategory:null,selectedItem:null})
        }
    };
    deleteSelectedItem = () => {
        var {selectedItem} = this.state;
        if (selectedItem) {
            this.props.deleteItem(selectedItem);
            this.setState({selectedItem: null})
        }
    };
    selectedItem = (item) => {
        this.setState({selectedItem: item})
    };
    renderEditItem = () => {
        var {selectedItem} = this.state;

        return (
            <EditItem categories={this.props.categories}
                      areas={this.props.areas}
                      item={selectedItem}
                      lite={true}
                      onEditItem={this.props.editItem}/>
        )
    };

    renderDatabase = () => {
        return (
            <div className={'database'}>
                {this.renderColumnZero()}
                {this.renderColumnOne()}
                {this.renderColumnTwo()}
                {this.renderColumnThree()}
            </div>
        )
    };
    onClickHome = () => {
        this.setState({selectedCategory: null, selectedItem: null})
    };

    renderFolderStyle = () => {
        return {}
    };

    onCheckedCategory = (val, checked) => {
        var categories = [...this.state.selectedCategories];
        if (checked) {
            categories.push(val);
        } else {
            categories = categories.filter(e => e !== val);
        }
        this.setState({selectedCategories: categories})
    };
    onCheckedItem = (val, checked) => {
        var items = [...this.state.selectedItems];
        if (checked) {
            items.push(val);
        } else {
            items = items.filter(e => e !== val);
        }
        this.setState({selectedItems: items})
    };
    deselectAll = () => {
        this.setState({selectedCategories: [], selectedItems: []})
    };

    selectAll = () => {
        var selectedCategories = [];
        this.props.categories.forEach(category => {
            selectedCategories.push(category.name);
        });
        this.setState({selectedCategories})
    };

    deselectAllItems = () => {
        this.setState({selectedCategories: [], selectedItems: []})
    };

    selectAllItems = () => {
        var selectedItems = [];

        this.props.items.forEach(item => {
            if (item.category === this.props.selectedCategory) {
                selectedItems.push(item.name);
            }
        });
        this.setState({selectedItems})
    };

}

export default connect(null, {editItem, deleteItem, deleteCategory, deleteArea})(ItemFolder);
