import {toast} from "react-toastify";
import Manager from "./Manager";
import {SM} from "../../SceneManager";

/**
 * Handles anything GUI related.
 */
export default class GUIManager extends Manager {

    update = () => {
        //Do every frame...
    };

    start = () => {
        //On object creation...
        // this.setupGUI();
    };

    /**
     * Notify the client of an information message.
     * @param message
     * @returns {ToastId}
     */
    notifyClientInfo = (message) => {
        toast(message, {
            className: 'black-background',
            bodyClassName: "grow-font-size",
            progressClassName: 'fancy-progress-bar'
        });
    };
    /**
     * Notify the client of an error message.
     * @param message
     * @returns {ToastId}
     */
    notifyClientError = (message) => toast.error(message);

    /**
     * Whenever an object is selected, notify all managers.
     * Update GUI here.
     * @param object
     */
    notifyOnObjectClicked = (object) => {
        //TODO:
        if (object && (object.userData.type === "sceneObject" || object.userData.type === "featureWall" || object.name === "tiledArea")) {
            SM.updateObjectSelectedForWebsite(object);
        } else {
            SM.updateObjectSelectedForWebsite(null);
        }
    };

    /**
     * Whenever an object is hovered, notify all managers.
     * @param object
     */
    notifyOnObjectHovered = (object) => {
    };

    /**
     * Setup the GUI.
     */
    setupGUI = () => {
        /*
        var gui = new GUI();
        var params = {
            render : () => {
                SM.renderObjectWireframes();
            },
            import: () => {
                SM.importScene();
            },
            export: () => {
                SM.exportScene();
            },
            room_x: 25,
            room_y: 20,
            room_z: 25,
            resize: () => {
                let state = {
                    room_x: params.room_x,
                    room_y: params.room_y,
                    room_z: params.room_z,
                };
                SM.updateRoomSize(state);
            },
            print_scene: () => {
                console.log(SM.renderer.info);
            },
            bird: () => {
                SM.CameraManager.goToBirdsEyeView(true);
            },
            front: () => {
                SM.CameraManager.setCameraInMiddle();

            }
        };
        gui.add(params, 'render').name('Render Wireframe');
        gui.add(params, 'import').name('Import Scene');
        gui.add(params, 'export').name('Export Scene');
        gui.add(params, 'bird').name("Top Down View");
        gui.add(params, 'front').name("Front View");
        gui.add(params,'room_x').name("Room X");
        gui.add(params,'room_y').name("Room Y");
        gui.add(params,'room_z').name("Room Z");
        gui.add(params,'resize').name("Resize Room");
        gui.add(params, 'print_scene').name("WEBGL MEMORY");
        gui.closed = true;
    };

         */
    };


}