import {Plane, Triangle, Vector3} from 'three';
import {Item} from './item.js';
import {SM} from "../../../components/SceneManager";

/**
 * A Floor Item is an entity to be placed related to a floor.
 */
export class ExteriorItem extends Item {
    constructor(model, metadata, geometry, material, position, rotation, scale, isgltf = false) {
        super(model, metadata, geometry, material, position, rotation, scale, isgltf);
        this._freePosition = false;
        this.render2dControls = true;
        this.sideways = false;

        //this.renderOrder = 999;
        //this.onBeforeRender = function( renderer ) { renderer.clearDepth(); };
        //console.log(this.position);
        this.position.y = this.halfSize.y;
        this.floorItem = true;

    }

    roofContainsPoint(roof, forpoint, returnIfNone=false) {
        var g = roof.geometry;
        var result = {distance: Number.MAX_VALUE, contains: false, point: null, closestPoint: null};
        var closestPoint = null;
        for (var i = 0; i < g.faces.length; i++) {
            var f = g.faces[i];
            var plane = new Plane();
            var triangle = new Triangle(g.vertices[f.a], g.vertices[f.b], g.vertices[f.c]);
            var ipoint = new Vector3();
            var cpoint = new Vector3();
            var contains = false;
            var distance = 0.0;
            closestPoint = triangle.closestPointToPoint(forpoint, cpoint);
            triangle.getPlane(plane);
            plane.projectPoint(forpoint, ipoint);
            contains = triangle.containsPoint(ipoint);
            distance = plane.distanceToPoint(forpoint);
            if (distance < result.distance && contains) {
                result.distance = distance;
                result.contains = contains;
                result.point = ipoint;
                result.closestPoint = closestPoint.clone();
            }
        }
        //No good result so return the closest point of the last triangle in this roof mesh
        if (result.point == null) {
            if (returnIfNone) return false;
            result.closestPoint = closestPoint.clone();
        }

        return result;
    }
    /** */
    placeInRoom() {
        if (!this.position_set) {
            var center = this.model.floorplan.getCenter();
            this.position.x = center.x;
            this.position.z = center.z;
            this.position.y = 0.5 * (this.geometry.boundingBox.max.y - this.geometry.boundingBox.min.y);
        } else {
            //ensure not at 0
            //this.position.y = 0.5 * (this.geometry.boundingBox.max.y - this.geometry.boundingBox.min.y);
            //console.log(this.position.y);
        }
    }

    rotate = way => {
        //????????
        //super.rotate(mode);
        if (way === 'left') {
            this.rotateLeft();
            this.sideways = !this.sideways;

        }
        if (way === 'right') {
            this.rotateRight();
            this.sideways = !this.sideways;

        }
        if (way === 'up') {
            this.rotateUp();

        }
        if (way === 'down') {
            this.rotateDown();
        }
        SM.updateForViewer(this.rotation);
        SM._dirty = true;

    };
    /** Returns an array of planes to use other than the ground plane
     * for passing intersection to clickPressed and clickDragged */
    customIntersectionPlanes() {
        //return this.model.floorplan.floorPlanes();
    }
    /** Take action after a resize */
    resized() {
        this.position.y = this.halfSize.y;
    }

    moveOnCanvas = (x, y) => {
        if (this.canMoveItem()) {
            this.moveToPosition(new Vector3(x, this.position.y, y));
        }
    };

    /** */
    moveToPosition(vec3) {
        // keeps the position in the room and on the floor
        //this.hideError();
        //vec3.y = this.position.y; // keep it on the floor!
//			this.position.copy(vec3);
        super.moveToPosition(vec3);
    }
    /** */
    removed() {
        this.hideError();

    }
    /** */
    setUnselected() {
        this.selected = false;
        this.bhelper.visible = false;
        this.canvasPlaneWH.visible = this.canvasPlaneWD.visible = false;
        //this.GUIControls.changeVisibility(false);
        this.updateHighlight();
        this.hideError();
    }
    /** */
    isValidPosition(vec3) {

        //console.log("Checking if valid position.");
        //console.log(vec3);
        //Actually need to check 4 corners of given object.
        var cornerTopLeft = {...vec3};
        var cornerTopRight = {...vec3};
        var cornerBottomLeft = {...vec3};
        var cornerBottomRight = {...vec3};
        //var cornerBottomLeft = {...vec3};
        //var cornerBottomRight = {...vec3};
        var xSize = !this.sideways ? this.getWidth() : this.getDepth();
        var zSize = !this.sideways ? this.getDepth() : this.getWidth();

        cornerTopLeft.x -= xSize / 2;
        cornerTopLeft.z -= zSize / 2;

        cornerTopRight.x += xSize / 2;
        cornerTopRight.z += zSize / 2;

        cornerBottomLeft.x += xSize / 2;
        cornerBottomLeft.z -= zSize / 2;

        cornerBottomRight.x -= xSize / 2;
        cornerBottomRight.z += zSize / 2;

        //var toCheck = [cornerTopLeft,cornerTopRight];

        //var corners = this.getCorners('x', 'z', vec3);
        // check if we are in a room
        //var rooms = this.model.floorplan.getRooms();
        var isInARoom = false;

        var roofs = this.model.floorplan.roofPlanes();
        var roof = null;
        //var globalResult = {distance: Number.MAX_VALUE, point: null};
        var result = null;

        var topLeftGood = false;
        var topRightGood = false;
        var bottomLeftGood = false;
        var bottomRightGood = false;

        for (var i = 0; i < roofs.length; i++) {
            roof = roofs[i];
            result = this.roofContainsPoint(roof, cornerTopLeft,true);
            if (result) {
                topLeftGood = true;
            }
            result = this.roofContainsPoint(roof, cornerTopLeft,true);
            if (result) {
                topRightGood = true;
            }
            result = this.roofContainsPoint(roof, cornerBottomLeft,true);
            if (result) {
                bottomLeftGood = true;
            }
            result = this.roofContainsPoint(roof, cornerBottomRight,true);
            if (result) {
                bottomRightGood = true;
            }
        }
        if (topLeftGood && topRightGood && bottomLeftGood && bottomRightGood) {
            isInARoom = true;
        }

        //TODO: above algorithm stops working randomly after resizing, even with one room... also need to check object bounds.
        //isInARoom = SM.controller.isHoveringOverFloor();

        /*
      if (this.obstructFloorMoves) {
          var objects = this.model.scene.items;
          for (var i = 0; i < objects.length; i++) {
              if (objects[i] === this || !objects[i].obstructFloorMoves) {
                  continue;
              }
              if (!Utils.polygonOutsidePolygon(corners, objects[i].getCorners('x', 'z'), new Vector2(0,0)) ||
                  Utils.polygonPolygonIntersect(corners, objects[i].getCorners('x', 'z'), new Vector2(0,0))) {
                  //console.log('object not outside other objects');
                  return false;
              }
          }
      }
         */

        return isInARoom;
    }
}
