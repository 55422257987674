import React, {Component} from 'react';

import {googleplay} from '../../../assets/images/socialMedia'

class SiteFooter extends Component {
    render() {
        return (
            <div className={'nav-footer bg-normal'}>
                <p>Copyright © MasterSuite, Inc. All rights reserved. <a href={'/terms-of-use'}>Terms of Use</a> | <a
                    href={'/privacy-policy'}>Privacy Policy</a></p>
                <div className={'social-media-icons'}>
                    {
                        /*
                         <a href={'/suite'}  rel="noopener noreferrer" target={'_blank'} onClick={() => toast.info("Coming soon.")}>
                        <img src={facebook} alt={'facebook'}/>
                    </a>
                    <a href={'/suite'}  rel="noopener noreferrer" target={'_blank'} onClick={() => toast.info("Coming soon.")}>
                        <img src={instagram} alt={'instagram'}/>
                    </a>
                    <a href={'/suite'}  rel="noopener noreferrer" target={'_blank'} onClick={() => toast.info("Coming soon.")}>
                        <img src={twitter} alt={'twitter'}/>
                    </a>
                    <a href={'/suite'}  rel="noopener noreferrer" target={'_blank'} onClick={() => toast.info("Coming soon.")}>
                        <img src={youtube} alt={'youtube'}/>
                    </a>
                        */
                    }

                    <a href={'https://play.google.com/store/apps/details?id=com.envate.mastersuite&hl=en'} rel="noopener noreferrer" target={'_blank'}>
                        <img src={googleplay} alt={'youtube'}/>
                    </a>
                </div>

                <div style={{display: 'flex', flexDirection: 'row',paddingBottom:'1em'}}>
                    {
                        /*
                                            <p style={{color:'gray'}}>Built by</p>

                    <a rel="noopener noreferrer" target={'_blank'} href={'https://www.envate.com.au'}>
                        <img alt={"Envate Logo"} style={{maxWidth:'72px',margin:'0.5em'}} src={envatePhoto}/>
                    </a>
                         */
                    }

                </div>
            </div>
        );
    }
}

export default SiteFooter;
