import {Item,FloorItem,WallItem,InWallItem,InWallFloorItem,OnFloorItem,WallFloorItem,RoofItem,Mirror,CustomItem,Shower,RoofLightItem} from './index';
import {ExteriorItem} from "./exterior_item";


export const item_types = {
    1: FloorItem,
    2: WallItem,
    3: InWallItem,
    4: RoofItem,
    7: InWallFloorItem,
    8: OnFloorItem,
    9: WallFloorItem,
    10: Mirror,
    15: RoofLightItem,
    0: Item,
    11: CustomItem,
    12: Shower,
    20: ExteriorItem
};

export function canPlaceAnywhere(type) {
    return [20].includes(type)
}
export function isFloorItem(type) {
    return [0, 1, 8, 11, 20].includes(type)
}

export function isWallItem(type) {
    return [2, 3, 7, 9, 10, 12].includes(type)
}

export function isRoofItem(type) {
    return [4].includes(type)
}

/** Factory class to create items. */
export class Factory {
    /** Gets the class for the specified item. */
    static getClass(itemType) {
        return item_types[itemType];
    }
}
