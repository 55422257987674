import React, {Component} from 'react';
import lock from '../../../assets/images/lock.png'
import cross from '../../../assets/images/delete.png'
import left from '../../../assets/images/left.png'
import right from '../../../assets/images/right.png'
import plus from '../../../assets/images/plus.png'

import {SM} from "../../SceneManager";

class ObjectControls extends Component {
    render() {

        var {selectedItem} = this.props;
        if (!selectedItem) return null;
        return (
            <div className={'transparent-background item-controls'} >
                <p className={'title-clean'}>{selectedItem.metadata.name}</p>
                <p className={'subtitle-clean'}>Item Controls</p>
                {this.renderDeleteAndLock()}
                {this.renderRotate()}
            </div>
        );
    }

    renderRotate = () => {
        var {selectedItem} = this.props;
        if (!selectedItem || !selectedItem.render2dControls) return null;
        //if relevant item type.
    return (
        <div>
            <p className={'subtitle-clean'}>Rotation</p>
            <div className={"rotate-controls"}>
                <button onClick={() => selectedItem.rotate('left')}
                        className={"category-tab main-gradient"}>
                    <img alt={'left-arrow'} src={left}/>
                    <p>Left</p>
                </button>
                <button onClick={() => selectedItem.rotate('right')}
                        className={"category-tab main-gradient"}>
                    <img alt={'right-arrow'} src={right}/>
                    <p>Right</p>
                </button>
            </div>
        </div>

        )
    };

    renderDeleteAndLock = () => {
        var {selectedItem} = this.props;

        return (
            <div className={"obj-controls"} >
                <button onClick={() => this.removeItem()}
                     className={"category-tab main-gradient"}>
                    <img alt={'remove'} src={cross}/>
                    <p>Remove</p>
                </button>
                <button onClick={() => this.lockItem()}
                     className={(selectedItem && selectedItem.isLocked) ? "category-tab red-gradient" : "category-tab main-gradient"}>
                    <img alt={'lock'} src={lock}/>
                    <p>Lock</p>
                </button>
                <button onClick={() => this.duplicateItem()}
                     className={"category-tab main-gradient"}>
                    <img alt={'plus'} src={plus}/>
                    <p>Clone</p>
                </button>
            </div>
        )
    };
    removeItem = () => {
        this.props.selectedItem.setUnselected();
        SM.onDeleteItem(this.props.selectedItem);
    };

    lockItem = () => {
        this.props.selectedItem.lock();
        SM.updateObjectSelectedForWebsite(this.props.selectedItem);
    };

    duplicateItem = () => {
        //duplicate item.
        SM.duplicateSelectedObject();
    }
}

export default ObjectControls;
