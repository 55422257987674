import React, {useEffect} from 'react';
import {useDrag} from 'react-dnd'
import {ItemTypes} from "../../../../utils/constants/constantValues";
import {getEmptyImage} from "react-dnd-html5-backend";
import {SM} from "../../../SceneManager";
import {Vector3} from "three";

function getStyles(left, top, isDragging) {
    //const transform = `translate3d(${left}px, ${top}px, 0)`
    return {
        // IE fallback: hide the real node using CSS when dragging
        // because IE will ignore our custom "empty image" drag preview.
        //opacity: isDragging ? 0 : 1,
        //height: isDragging ? 0 : '',
    }
}

const ItemCard = (props) => {

    const {left, top} = props;

    let canSpawnHere = false;
    let spawnPoint = null;
    let objectMountedTo = null;

    function changeAllowToSpawn(canSpawn) {
        canSpawnHere = canSpawn;
    }

    function changeSpawnPoint(point, object) {
        spawnPoint = point;
        objectMountedTo = object;
    }

    function canSpawn() {
        return canSpawnHere;
    }

    function getImgUrl() {
        if (props.item.generic) {
            return `${process.env.PUBLIC_URL}/Assets/images/${props.item.slug}/${props.item.photoURL}`;
        }
        return props.item.photoURL;
    }

    function getImgUrl2D() {
        return props.item.photoURL2D;
    }

    const [{isDragging}, drag, preview] = useDrag({
        item: {
            type: ItemTypes.SHOWER,
            changeAllow: (allow) => changeAllowToSpawn(allow),
            canSpawn: () => canSpawn(),
            img: () => getImgUrl(),
            img2D: () => getImgUrl2D(),
            changeSpawnPoint: (point, object) => changeSpawnPoint(point, object),
            onSpawn: (mouse) => onSpawn(mouse),
            itemType: props.item.type,
            mountedTo: props.mountedTo,
            name: props.name
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => {
        preview(getEmptyImage(), {captureDraggingState: true})
    });

    function onSpawn(mouse) {

        //TODO: if in 2d mode, spawn based on mouse.
        var item = props.item;

        if (SM.isIn2D) {
            //CALCULATE EXACTLY LIKE FLOORPLANNER.
            var x = SM.floorPlanner.calculateXMouse(mouse.x);
            var y = SM.floorPlanner.calculateYMouse(mouse.y);

            var floorOverlapped = SM.floorPlanner.getOverlappedRoom(mouse);
            if (floorOverlapped) {
                item.roomId = floorOverlapped.roomIdentifier()
            } else {
                item.roomId = 'outside';
            }
            var spawnPoint2D = new Vector3(x, 0, y);
            SM.addItem(item, spawnPoint2D, objectMountedTo)
        } else if (canSpawn()) {
            SM.addItem(item, spawnPoint, objectMountedTo)
        }
        //Spawn item if able to here at the location recorded.
    }

    function isClickableType() {
        return !!props.onClick;
    }

    function onClick() {
        if (isClickableType()) {
            console.log("clickable type");
            props.onClick();
        }
    }

    function renderInnerItemCard() {
        return (

            <div className={"item-card"}>
                <div className={'item-card-content'}>
                    <div  onClick={onClick} ref={drag} style={{margin:0}}>
                        <img alt={'Item'} className={'item-card-img'} draggable={false} src={getImgUrl()}/>
                    </div>
                    <div>
                        <p className={'item-card-title'}>{props.item.name}</p>
                        <p className={'item-card-description'}>{`${props.item.description}`}</p>
                    </div>
                    <div>

                        {
                            /*
                            props.item.storeURL ?
                            <a href={'//'+props.item.storeURL} onClick={()=>console.error("RETRIEVE URL BASED ON ITEM ID FROM SERVER - DONT SHOW PUBLIC, AND INCREMENT ON BACKEND")} target={'_blank'} rel="noopener noreferrer">
                                <p>View Store Page</p>
                            </a> : null

                             */
                        }

                    </div>

                    {renderItemCount()}
                    {renderListOfProperties()}
                </div>
            </div>
        )
    }

    function renderItemCount() {
        if (props.itemCount) {
            return <h6>Count: {props.itemCount}</h6>
        }
        return <div></div>
    }

    function renderListOfProperties() {
        if (props.listOfProperties) {
            return (
                <ul>
                    {props.listOfProperties.map(property => (<li>{property}</li>))}
                </ul>
            )
        }
    }

    function renderIfCanDrag() {
        if (isClickableType() || props.isDisplay) {
            return (
                <div onClick={onClick} style={getStyles(left, top, isDragging)}>
                    {renderInnerItemCard()}
                </div>
            )
        } else {
            return (
                renderInnerItemCard()
            )
        }
    }

    return (
        renderIfCanDrag()
    );
};

export default ItemCard;
